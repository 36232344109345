import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Protection(props) {
    const {Component} = props
    const navigate = useNavigate()
    useEffect(()=>{
        let userToken = localStorage.getItem('userToken')
        let userData = localStorage.getItem('userData')     
           if(!userToken){
            navigate("/signin")
        }
    })
  return (
   <Component/>
  )
}
