import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Button,
  Badge,
  Input,
  Text,
  Select,
  HStack,
  Flex,
  IconButton,
  useDisclosure,
  ChakraProvider,
  extendTheme,
  Image,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { FaEllipsisV } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import {
  IoMailOutline,
  IoPerson,
  IoGitBranch,
  IoCallOutline,
} from "react-icons/io5";
import CompanyProfile from "../Header/CompanyProfile";
import AddNewEvent from "./AddEvent";
import { CallAPI } from "../../Services/service";
import endpoints from "../../Services/endpoint";
import moment from "moment"
import "@fontsource/noto-sans";
import Call from "../Assets/Call.png"

const theme = extendTheme({
  fonts: {
    heading: `'Noto Sans', sans-serif`,
    body: `'Noto Sans', sans-serif`,
  },
});



const typeColors = {
  Meeting: "purple",
  Calls: "teal",
  Email: "orange",
  Task: "blue",
};

const EventsTable = () => {
  const [filter, setFilter] = useState(""); // For search filtering
  const [sortField, setSortField] = useState(""); // For sorting
  const [startDate, setStartDate] = useState(null); // Filter by date range
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [eventData, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await CallAPI(endpoints.getEvents); // Replace with your actual endpoint
      if (res?.code === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Filtering logic
  const filteredData = eventData.filter((lead) => {
    const matchesFilter =
      filter === "" ||
      lead.title.toLowerCase().includes(filter.toLowerCase());
    
    return matchesFilter 
  });

  // Sorting logic
  const sortedData = [...filteredData].sort((a, b) => {
    if (sortField === "name") {
      return a.leadName.localeCompare(b.leadName);
    } else if (sortField === "date") {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
    return 0; // No sorting
  });

  // Pagination logic
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);
  const paginatedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box p={4} overflowX="auto">
        <AddNewEvent isOpen={isOpen} onClose={onClose} getData={getData} />

        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          mb={4}
        >
          <Flex
            align="center"
            justifyContent="space-between"
            p="3"
            borderRadius="md"
            width="100%"
            gap={10}
            direction={{ base: "column", md: "row" }}
          >
            <CompanyProfile />
          </Flex>
        </Box>

        <Box
          p={4}
          m="2"
          borderWidth="1px"
          borderRadius="md"
          bg="white"
          overflowX="auto"
        >
          {/* Search and Button Section */}
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={4}
            flexDirection={{ base: "column", sm: "row" }}
          >
            <Input
              placeholder="Search Events"
              width={{ base: "100%", sm: "300px" }}
              mb={{ base: 4, sm: 0 }}
            />
            <Button
              colorScheme="red"
              ml={{ base: 0, sm: 4 }}
              onClick={onOpen}
              width={{ base: "100%", sm: "auto" }}
            >
              Add New Activity
            </Button>
          </Box>

          {/* Filters and Sorting Section */}
          <Flex
            display="flex"
            justifyContent="space-between"
            mb="3"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <HStack spacing={4} mb={{ base: 3, sm: 0 }}>
              <Text
                fontSize="18px"
                fontWeight="700"
                color="#262A2A"
                mt={"15px"}
              >
                All Events
              </Text>
              <IconButton
  icon={<Image src={Call} alt="Call Icon" />}
  bg="white"
  border="1px solid grey"
  size="sm"
  aria-label="Table View"
/>;
              <IconButton
                icon={<IoMailOutline />}
                bg="white"
                border="1px solid grey"
                size="sm"
                aria-label="Calendar View"
              />
              <IconButton
                icon={<IoGitBranch />}
                bg="white"
                border="1px solid grey"
                size="sm"
                aria-label="Team View"
              />
              <IconButton
                icon={<IoPerson />}
                bg="white"
                border="1px solid grey"
                size="sm"
                aria-label="User View"
              />
            </HStack>

            {/* Sort and Filter Controls */}
            <Flex align="center" flexDirection={{ base: "column", sm: "row" }}>
              <Select
                placeholder="Sort"
                width={{ base: "100%", sm: "auto" }}
                mr={2}
                mb={{ base: 3, sm: 0 }}
              >
                <option value="name">Name</option>
                <option value="date">Date</option>
              </Select>
              <Flex flexDirection={{ base: "column", md: "row" }} gap={2}>
                <Button
                  variant="outline"
                  fontSize="13px"
                  bg={"#F3EDFF"}
                  color={"#4A00E5"}
                  w={"164px"}
                  h={"41px"}
                >
                  Manage Columns
                </Button>
                <Button variant="outline" fontSize="13px" w={"88px"} h={"41px"}>
                  Filter
                </Button>
              </Flex>
            </Flex>
          </Flex>

          {/* Table Section */}
          <Box overflowX="auto">
            <Table variant="simple" border="1px solid" borderColor="gray.200">
              <Thead bg="#E8E8E8">
                <Tr>
                  <Th>
                    <Checkbox />
                  </Th>
                  <Th
                    className=""
                    fontWeight={"500"}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                  >
                    Title
                  </Th>
                  <Th
                    className="table-header-style"
                    fontWeight={"500"}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                  >
                    Activity Type
                  </Th>
                  <Th
                    fontWeight={"500"}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    className="table-header-style"
                  >
                    Due Date
                  </Th>
                  <Th
                    fontWeight={"500"}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                    className="table-header-style"
                  >
                    Owner
                  </Th>
                  <Th
                    className="table-header-style"
                    fontWeight={"500"}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                  >
                    Created At
                  </Th>
                  <Th
                    className="table-header-style"
                    fontWeight={"500"}
                    fontSize={"14px"}
                    lineHeight={"20px"}
                  >
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedData.map((event) => (
                  <Tr key={event.id}>
                    <Td>
                      <Checkbox />
                    </Td>
                    <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
                      {event.title}
                    </Td>
                    <Td>
                      <Badge
                        bg={typeColors[event.activityType]}
                        borderRadius="md"
                        p="2"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        width="auto"
                        fontWeight="600"
                        color="white"
                        fontFamily="Poppins"
                      >
                        <IconButton
                          icon={<IoCallOutline />}
                          color={'white'}                       
                          size="sm"
                          px={2}
                          bg={'none'}
                        />
                        {event.activityType}
                      </Badge>
                    </Td>
                    <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
                      {moment(event.dueDate).format("DD-MM-YYYY")}
                    </Td>
                    <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
                      {event.owner}
                    </Td>
                    <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
                      {moment(event.createdAt).format("DD-MM-YYYY")}
                    </Td>
                    <Td>
                      <IconButton
                        icon={<FaEllipsisV />}
                        variant="ghost"
                        aria-label="Options"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          {/* Pagination Section */}
          <HStack
            justifyContent="space-between"
            mt={4}
            alignItems="center"
            flexDirection={{ base: "column", sm: "row" }}
          >
            <HStack spacing={2} mb={{ base: 3, sm: 0 }}>
              <Box color="#6F6F6F">Show</Box>
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                width="80px"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </Select>
              <Box color="#6F6F6F">entries</Box>
            </HStack>

            <HStack spacing={2}>
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(-1)}
                disabled={currentPage === 1}
                leftIcon={<ChevronLeftIcon />}
              >
                Prev
              </Button>
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index + 1}
                  onClick={() => setCurrentPage(index + 1)}
                  colorScheme={currentPage === index + 1 ? "blue" : "gray"}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === totalPages}
                rightIcon={<ChevronRightIcon />}
              >
                Next
              </Button>
            </HStack>
          </HStack>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default EventsTable;
