import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Components/HomePage/HomePage';
import SignIn from './Components/SignUp/SignIn';
import SignUp from './Components/SignUp/SignUp';
import EmailVerification from './Components/SignUp/EmailVerification';
import LogIn from './Components/LogIn/LogIn';
import RaiseTicket from './Components/Tickets/RaiseTicket';
import FeaturedApp from './Components/FeaturedApp/FeaturedApp';
import Customer from './Components/Customer/Customer';
import CRMPage from './Components/CRM/CRMPage';
import Contacts from './Components/CRM/Contacts';
import EmailCampaign from './Components/CampaignManagementPages/EmailCampaign';
import WhatsappCampaign from './Components/CampaignManagementPages/WhatsappCampaign';
import CampaignType from './Components/CampaignManagementPages/CampaignType';
import LeadManagement from './Components/LeadManagementPage/LeadManagement';
import CampaignDraft from './Components/CampaignManagementPages/CampaignDraft';
import ABTestingCampaign from './Components/CampaignManagementPages/ABTestingCampaign';
import MyCampaigns from './Components/CampaignManagementPages/MyCampaigns';
import LeadImport from './LeadImport';
import Profile from './Components/User Profile/Profile';
import SettingsPage from './Components/Settings/SettingsPage';
import Lead from './Components/LeadManagementPage/Lead';
import EmailAnalytics from './Components/Analytics/EmailAnalytics';
import Notifications from './Components/Notifications/Notifications';
import Supports from './Components/Supports/Supports';
import AllCampaigns from './Components/CampaignManagementPages/AllCampaigns';
import User from './Components/Users/User';
import Protection from './Components/Protected Route/Protection';

import CampaignDetail from './Components/CampaignManagementPages/CampaignDetail';
import FacebookCampaign from './Components/CampaignManagementPages/FacebookCampaign';
import LinkedinCampaign from './Components/CampaignManagementPages/LinkedinCampaign';
import InstagramCampaign from './Components/CampaignManagementPages/InstagramCampaign';
import TwitterCampaign from './Components/CampaignManagementPages/TwitterCampaign';

import ForgetPassword from './Components/SignUp/ForgetPassword';
import NotificationSettings from './Components/Settings/NotificationSettings';
import HelpCenter from './Components/Supports/HelpCenter';
import Layout from './Components/Layout/Layout';
import CommunityGroup from './Components/CommunityGroup/CommunityGroup';
import Billing from './Components/Billing/Billing';
import CompaignDashboard from './Components/CampaignManagementPages/CampaignDashboard';
import LeadManagements from './Components/LeadManagementPage/LeadManagements';
import DashboardPage from "./Components/Dashboard/Dashboard"
import Integration from './Components/SocialMediaIntegration/Integration';
import UserManagements from './Components/UserManagement/UserManagement';
import "./App.css"
import EventsTable from './Components/Events/Events';
import ReportContainer from './Components/ReportScreens/Reports';
import ProjectReport from './Components/ReportScreens/ProjectReport';
import CompanyReport from './Components/ReportScreens/CompanyReport';
import ContactReport from './Components/ReportScreens/ContactReport';
import Support from './Components/Support/Support.js';
import TaskReport from './Components/ReportScreens/TaskReport.js';
import Wisdom from './Components/Wisdom/Wisdom.js';
import Analytics from './Components/Analytic/Analytics.js';
import InvoiceTable from './Components/Invoices/Invoice.js';
import EstimationsTable from './Components/Estimations/Estimation.js';
import ContractManagement from './Components/Contract/Contract.js';
import ProposalsTable from './Components/Proposal/Proposal.js';
import Project from './Components/Projects/Project.js';
import PipelineTable from './Components/Pipeline/Pipeline.js';
import TaskList from './Components/Task/Task.js';
import DealsTable from './Components/Deals/Deal.js';
import CompanyTable from './Components/Companies/Company.js';
import PostCreator from './Components/Posts/Posts.jsx';
import CampaignTable from './Components/Sequence/sequence.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AddSequence from './Components/Sequence/addSequence';
import CreateSequence from './Components/Sequence/addSequence2.js';
import Payments from './Components/payments/Payment.js';
import ContactForm from './Components/Contact.js/Contact.js';
import About from './Components/Aboutus/About.js';
import Services from './Components/services/Services.js';
import Home from './Components/Home/Home.js';
import Contact from './Components/Contacts/Contact.js';
import Task from './Components/Task/Task.js';


function App() {
  return (
    <div>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LogIn />} />
            <Route
              path="/RaiseTicket"
              element={<Layout><Protection Component={RaiseTicket} /></Layout>}
            />
            <Route
              path="/FeaturedApp"
              element={<Layout><Protection Component={FeaturedApp} /></Layout>}
            />
            <Route
              path="/customer"
              element={<Layout><Protection Component={Customer} /></Layout>}
            />
               <Route
              path="/posts-on-social-media"
              element={<Layout>
                
                <DndProvider backend={HTML5Backend}>               
                   <Protection Component={PostCreator} />
                </DndProvider>
                </Layout>}
            />
            
            <Route
              path="/CRMPage"
              element={<Layout><Protection Component={CRMPage} /></Layout>}
            />
            {/* <Route
              path="/Contacts"
              element={<Layout><Protection Component={Contacts} /></Layout>}
            /> */}
              <Route
              path="/sessions"
              element={<Layout><Protection Component={EventsTable} /></Layout>}
            />
            
            {/* <Route path="/LeadManagement" element={<LeadManagement/>} /> */}
            <Route
              path="/campaigns"
              element={<Layout><Protection Component={CompaignDashboard} /></Layout>}
            />
            <Route
              path="/EmailCampaign"
              element={<Layout><Protection Component={EmailCampaign} /></Layout>}
            />
            <Route
              path="/WhatsappCampaign"
              element={<Layout><Protection Component={WhatsappCampaign} /></Layout>}
            />
            <Route 
             path="/reports"
             element={<Layout><Protection Component={ReportContainer} /></Layout>}
           />
            <Route
              path="/LeadImport"
              element={<Layout><Protection Component={LeadImport} /></Layout>}
            />
            <Route
              path="/CampaignType"
              element={<Layout><Protection Component={CampaignType} /></Layout>}
            />
            <Route
              path="/CampaignDraft"
              element={<Layout><Protection Component={CampaignDraft} /></Layout>}
            />
            <Route
              path="/ABtesting"
              element={<Layout><Protection Component={ABTestingCampaign} /></Layout>}
            />
            <Route
              path="/MyCampaigns"
              element={<Layout><Protection Component={MyCampaigns} /></Layout>}
            />
            <Route
              path="/FacebookCampaign"
              element={<Layout><Protection Component={FacebookCampaign} /></Layout>}
            />
            <Route
              path="/LinkedinCampaign"
              element={<Layout><Protection Component={LinkedinCampaign} /></Layout>}
            />
            <Route
              path="/InstagramCampaign"
              element={<Layout><Protection Component={InstagramCampaign} /></Layout>}
            />
               <Route
              path="/social-media-Integration"
              element={<Layout><Protection Component={Integration} /></Layout>}
            />
            <Route
              path="/TwitterCampaign"
              element={<Layout><Protection Component={TwitterCampaign} /></Layout>}
            />
            <Route
              path="/profile"
              element={<Layout><Protection Component={Profile} /></Layout>}
            />
            <Route
              path="/user-management"
              element={<Layout><Protection Component={UserManagements} /></Layout>}
            />
            <Route
              path="/Dashboard"
              element={<Layout><Protection Component={DashboardPage} /></Layout>}
            />
            <Route
              path="/CampaignDetails/:id/:type"
              element={<Layout><Protection Component={CampaignDetail} /></Layout>}
            />
            <Route
              path="/SettingsPage"
              element={<Layout><Protection Component={SettingsPage} /></Layout>}
            />

            <Route path="/leads" element={<Layout><Protection Component={LeadManagements} /></Layout>} />

            <Route
              path="/EmailAnalytics"
              element={<Layout><Protection Component={EmailAnalytics} /></Layout>}
            />
            <Route
              path="/notifications"
              element={<Layout><Protection Component={Notifications} /></Layout>}
            />
            <Route
              path="/support"
              element={<Layout><Protection Component={Support} /></Layout>}
            />
            <Route
              path="/about"
              element={<Layout><About/></Layout>}
            />
            <Route path="/User" element={<Layout><Protection Component={User} /></Layout>} />
            <Route
              path="/campaigns/:id"
              element={<Layout><Protection Component={CampaignDetail} /></Layout>}
            />
            <Route
              path="/Billing"
              element={<Layout><Protection Component={Billing} /></Layout>}
            />
            <Route
              path="/wisdom"
              element={<Layout><Protection Component={Wisdom} /></Layout>}
            />
            <Route
              path="/analytics"
              element={<Layout><Protection Component={Analytics} /></Layout>}
            />
            <Route
              path="/invoice"
              element={<Layout><Protection Component={InvoiceTable} /></Layout>}
            />
            <Route
              path="/estimations"
              element={<Layout><Protection Component={EstimationsTable} /></Layout>}
            />
            <Route
              path="/contracts"
              element={<Layout><Protection Component={ContractManagement} /></Layout>}
            />
            <Route
              path="/proposals"
              element={<Layout><Protection Component={ProposalsTable} /></Layout>}
            />
            <Route
              path="/services"
              element={<Layout><Services/></Layout>}
            />
            <Route
              path="/project"
              element={<Layout><Protection Component={Project} /></Layout>}
            />
            <Route
              path="/pipeline"
              element={<Layout><Protection Component={PipelineTable} /></Layout>}
            />
            <Route
              path="/tasks"
              element={<Layout><Protection Component={Task} /></Layout>}
            />
            <Route
              path="/company"
              element={<Layout><Protection Component={CompanyTable} /></Layout>}
            />
            <Route
              path="/deals"
              element={<Layout><Protection Component={DealsTable} /></Layout>}
            />
            <Route
              path="/sequences"
              element={<Layout><Protection Component={CampaignTable} /></Layout>}
            />
            <Route
              path="/homepage"
              element={<Layout><Home /></Layout>}
            />
            <Route
              path="/contacts"
              element={<Layout><Protection Component={Contact} /></Layout>}
            />
            <Route
              path="/contact"
              element={<Layout><ContactForm /></Layout>}
            />
            <Route
              path="/payments"
              element={<Layout><Protection Component={Payments} /></Layout>}
            />
            
            <Route path="/emailverification" element=<EmailVerification /> />
            <Route path="/verify-email/:token" element=<EmailVerification /> />
            <Route path="/forget-password/:email" element=<ForgetPassword /> />
            <Route path="/account-profile" element={<Layout><Protection Component={HelpCenter}/></Layout>} />
            <Route path="/getting-started" element={<Layout><Protection Component={HelpCenter}/></Layout>} />
            <Route path="/learning-support" element={<Layout><Protection Component={HelpCenter}/></Layout>} />
            <Route path="/purchase-refund" element={<Layout><Protection Component={HelpCenter}/></Layout>} />
            <Route path="/security" element={<Layout><Protection Component={HelpCenter}/></Layout>} />
            <Route path="/Communitygroup" element={<Layout><Protection Component={CommunityGroup}/></Layout>} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
