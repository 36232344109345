import React, { useState } from "react";
import {
  Box,
  Button,
  VStack,
  HStack,
  Text,
  SimpleGrid,
  useToast,
  Input,
  Flex,
} from "@chakra-ui/react";
import Calendar from "react-calendar"; // Install this package: npm install react-calendar
import "react-calendar/dist/Calendar.css"; // Default styles for react-calendar

const ScheduleComponent = ({
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
}) => {
  const toast = useToast();

  // Function to handle time selection
  const handleTimeChange = (event) => {
    const time = event.target.value; // Extract time from the input field
    setSelectedTime(time);
    toast({
      title: "Time Selected",
      description: `You selected ${time} on ${
        selectedDate ? selectedDate.toDateString() : "no date selected"
      }`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box
    
    >
      <Text fontSize="2xl" fontWeight="bold" mb={4}>
        Schedule a Post
      </Text>
      <Flex>
        {/* Calendar for Date Selection */}
        <Box mb={6}>
          <Text fontWeight="medium" mb={2}>
            Select a Date
          </Text>
          <Calendar
            onChange={(date) => setSelectedDate(date)}
            value={selectedDate}
            minDate={new Date()} // Disable past dates
            tileDisabled={({ date }) => date.getDay() === 0} // Example: Disable Sundays
          />
        </Box>

        <Box mt="5" ml="1">
          <Text fontWeight="medium" mb={2}>
            Select a Time
          </Text>
          <Input
            mt="2"
            type="time"
            onChange={handleTimeChange} // Attach the handler here
          />
        </Box>
      </Flex>

      {/* Confirmation Message */}
      {selectedDate && selectedTime && (
        <Box mt={6} p={4} borderWidth="1px" borderRadius="md" bg="blue.50">
          <Text fontWeight="medium">
            You selected: {selectedTime} on {selectedDate.toDateString()}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default ScheduleComponent;
