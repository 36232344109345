import React, { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  HStack,
  Tr,
  Th,
  Td,
  Badge,
  Button,
  Input,
  Select,
  useDisclosure,
  Flex,
  Text,
 

  FormControl,
  FormLabel,
  Textarea,
  Stack,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";


const AddNewPropsal = ({isOpen,onClose}) => {
  const theme = extendTheme({
    fonts: {
      heading: `'Poppins', sans-serif`,
      body: `'Raleway', sans-serif`,
    },
  });
  return (
    <ChakraProvider theme={theme}>
      <Drawer isOpen={isOpen} placement="right" size={"md"} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            fontWeight={"400"}
            fontSize={"25px"}
            lineHeight={"37px"}
          >
            Add New Event
          </DrawerHeader>
          <DrawerBody>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel
                  fontWeight={"400"}
                  fontSize={"20px"}
                  lineHeight={"30px"}
                >
                  Subject<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input placeholder="Enter subject" />
              </FormControl>
              <Flex gap={4}>
                <FormControl>
                  <FormLabel
                    fontWeight={"400"}
                    fontSize={"20px"}
                    lineHeight={"30px"}
                  >
                    Due Date<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input type="date" />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontWeight={"400"}
                    fontSize={"20px"}
                    lineHeight={"30px"}
                  >
                    Open Till<span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input type="date" />
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel
                  fontWeight={"400"}
                  fontSize={"20px"}
                  lineHeight={"30px"}
                >
                  Client<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input placeholder="Enter client name" />
              </FormControl>
              <FormControl>
                <FormLabel
                  fontWeight={"400"}
                  fontSize={"20px"}
                  lineHeight={"30px"}
                >
                  Project<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input placeholder="Enter project name" />
              </FormControl>
              <Flex gap={4}>
                <FormControl>
                  <FormLabel
                    fontWeight={"400"}
                    fontSize={"20px"}
                    lineHeight={"30px"}
                  >
                    Currency
                  </FormLabel>
                  <Select placeholder="Select currency">
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontWeight={"400"}
                    fontSize={"20px"}
                    lineHeight={"30px"}
                  >
                    Status
                  </FormLabel>
                  <Select placeholder="Select status">
                    <option value="accepted">Accepted</option>
                    <option value="declined">Declined</option>
                  </Select>
                </FormControl>
              </Flex>
              <FormControl>
                <FormLabel
                  fontWeight={"400"}
                  fontSize={"20px"}
                  lineHeight={"30px"}
                >
                  Description<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Textarea placeholder="Enter description" />
              </FormControl>
              <FormControl>
                <FormLabel
                  fontWeight={"400"}
                  fontSize={"20px"}
                  lineHeight={"30px"}
                >
                  Deals<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select placeholder="Select deal">
                  <option value="deal1">Deal 1</option>
                  <option value="deal2">Deal 2</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel
                  fontWeight={"400"}
                  fontSize={"20px"}
                  lineHeight={"30px"}
                >
                  Attachment<span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input type="file" />
              </FormControl>
            </Stack>
            <Flex justifyContent="space-between" mt={6}>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red">Create</Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
};

export default AddNewPropsal;
