import {
  Box,
  Table,
  Thead,
  Tbody,
  HStack,
  Tr,
  Th,
  Td,
  Checkbox,
  Button,
  Badge,
  Avatar,
  Input,
  Select,
  useDisclosure,
  IconButton,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  useBreakpointValue,
  MenuItem,
  Divider,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import { AddIcon, HamburgerIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import AddNewInvoiceModal from "./AddInvoice";
import { useState } from "react";

const invoices = [
  {
    id: "#1254058",
    client: "NovaWave LLC",
    project: "Trulysell",
    dueDate: "21 May 2024",
    amount: "$2,15,000",
    paidAmount: "$2,15,000",
    balanceAmount: "$0",
    status: "Partially Paid",
  },
  {
    id: "#1254057",
    client: "BlueSky Industries",
    project: "Dreamschat",
    dueDate: "19 Oct 2023",
    amount: "$1,45,000",
    paidAmount: "$1,45,000",
    balanceAmount: "$0",
    status: "Paid",
  },
  {
    id: "#1254056",
    client: "Silver Hawk",
    project: "Trulysell",
    dueDate: "24 Oct 2023",
    amount: "$2,15,000",
    paidAmount: "$1,00,000",
    balanceAmount: "$1,15,000",
    status: "Partially Paid",
  },
  {
    id: "#1254055",
    client: "Summit Peak",
    project: "Servbook",
    dueDate: "10 Nov 2023",
    amount: "$4,80,380",
    paidAmount: "$4,80,380",
    balanceAmount: "$0",
    status: "Paid",
  },
  {
    id: "#1254054",
    client: "RiverStone Venture",
    project: "DreamPOS",
    dueDate: "18 Nov 2023",
    amount: "$2,12,000",
    paidAmount: "$0",
    balanceAmount: "$2,12,000",
    status: "Unpaid",
  },
  {
    id: "#1254053",
    client: "CoastalStar Co.",
    project: "Kofejob",
    dueDate: "20 Nov 2023",
    amount: "$3,50,000",
    paidAmount: "$1,50,000",
    balanceAmount: "$2,00,000",
    status: "Partially Paid",
  },
  {
    id: "#1254052",
    client: "HarborView",
    project: "Doccure",
    dueDate: "07 Dec 2023",
    amount: "$1,23,000",
    paidAmount: "$0",
    balanceAmount: "$1,23,000",
    status: "Overdue",
  },
  {
    id: "#1254051",
    client: "Golden Gate Ltd",
    project: "Best@laundry",
    dueDate: "14 Dec 2023",
    amount: "$3,12,500",
    paidAmount: "$3,12,500",
    balanceAmount: "$0",
    status: "Paid",
  },
  {
    id: "#1254050",
    client: "Redwood Inc",
    project: "Dreamsports",
    dueDate: "22 Dec 2023",
    amount: "$4,18,000",
    paidAmount: "$0",
    balanceAmount: "$4,18,000",
    status: "Unpaid",
  },
  {
    id: "#1254049",
    client: "NovaWave LLC",
    project: "Trulysell",
    dueDate: "28 Dec 2023",
    amount: "$5,00,000",
    paidAmount: "$5,00,000",
    balanceAmount: "$0",
    status: "Paid",
  },
  // Add other invoices as required...
];

const statusColorScheme = {
  Paid: "green",
  "Partially Paid": "orange",
  Unpaid: "red",
  Overdue: "purple",
};

const InvoiceTable = () => {
  const [startDate, setStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [sortOption, setSortOption] = useState(""); // State for sort option
  const[check,setCheck]=useState('')
  const [selectedPriority, setSelectedPriority] = useState("");

  const filteredData = invoices.filter((lead) => {
    // Add your filtering logic here
    return true;
  });

  const filteredPipelines = invoices.filter(
    (pipeline) =>
      pipeline.client.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const sortPipelines = (data, option) => {
    if (option === "client") {
      return [...data].sort((a, b) => a?.client.localeCompare(b.name)); // Alphabetical order
    } else if (option === "amount") {
      return [...data].sort(
        (a, b) => parseFloat(b.amount) - parseFloat(a.amount)
      ); // Highest to lowest deal value
    }
    return data;
  };

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };
  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Raleway', sans-serif`,
    },
  });

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const onChangeEvent = (e) => {
    setCheck(e.target.checked);
  };

  const sortedPipelines = selectedPriority
    ? invoices.filter((project) => project.status=== selectedPriority)
    : sortPipelines(filteredPipelines, sortOption);

  return (
    <ChakraProvider theme={theme}>
      <Box p={5}>
        <Text
          fontWeight="600"
          fontSize="18px"
          lineHeight="21px"
          bg="white"
          width="50%"
          p="15px"
        >
          Invoices
        </Text>

        <Box bg="white" px="15px" overflowX="auto">
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            mt="25px"
          >
            <Input
              placeholder="Search Invoices"
              p="15px"
              w={{ base: "100%", md: "30%" }}
              mb={{ base: 2, md: 0 }}
              boxShadow={"5px"}
              bg={"white"}
              mt={"10px"}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Flex
              justifyContent="space-around"
              gap="10px"
              w={{ base: "100%", md: "auto" }}
              direction={{ base: "column", md: "row" }}
              mt="10px"
            >
              <Select
                placeholder="Export"
                h="41px"
                fontWeight="400"
                fontSize="14px"
              >
                <option value="date">Date</option>
                <option value="amount">Amount</option>
              </Select>
              <Button
                colorScheme="red"
                p="15px"
                w={{ base: "100%", md: "120%" }}
                fontWeight="400"
                fontSize="14px"
                onClick={onOpen}
                ml="2px"
              >
                Add New Invoice
              </Button>
            </Flex>
          </Flex>

          <AddNewInvoiceModal onClose={onClose} isOpen={isOpen} />
          <Divider mb={4} />

          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            mb={4}
          >
            <Flex gap="7px" direction={{ base: "column", md: "row" }}>
              <Select
                placeholder="Sort"
                w="40%"
                mt={{ base: 2, md: 0 }}
                fontWeight={"bold"}
                fontSize={"14px"}
                lineHeight={"20px"}
                p="7px"
                onChange={(e) => setSortOption(e.target.value)}
              >
                <option value="client">Client</option>
                <option value="amount">Amount</option>
              </Select>
              <Flex mt="5px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  customInput={<Input />}
                />
              </Flex>
            </Flex>

            <Flex mt={{ base: 2, md: 0 }} gap="5px">
              <Button
                variant="outline"
                bg="#F3EDFF"
                color="#4A00E5"
                fontSize="13px"
                fontWeight="600"
              >
                Manage Columns
              </Button>

              <Select
                placeholder="Filter"
                onChange={(e) => setSelectedPriority(e.target.value)}
                w="120px"
                fontSize={"14px"}
              >
                <option value="Paid">Paid</option>
                <option value="Unpaid">Unpaid</option>
                <option value="Overdue">Overdue</option>
                <option value="Partially Paid">Partially Paid</option>
              </Select>
            </Flex>
          </Flex>

          <Table variant="simple">
            <Thead gap={'3px'}>
              <Tr>
                <Th>
                  <input
                    type="checkbox"
                    checked={check}
                    onChange={(e) => onChangeEvent(e)}
                  />
                </Th>
                <Th fontWeight={"500"} maxW={'250px'} fontSize={"14px"} lineHeight={"20px"}>
                  Invoice
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Client
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Project
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Due Date
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Amount
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Paid Amount
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Balance Amount
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Status
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
            
              {sortedPipelines.map((invoice) => (
                <Tr key={invoice.id}>
                  <Td>
                    <input type="checkbox" checked={check} />
                  </Td>
                  <Td fontWeight={"300"} fontSize={"14px"} lineHeight={"19px"}>
                    {invoice.id}
                  </Td>
                  <Td fontWeight={"300"} fontSize={"14px"} lineHeight={"19px"}>
                    {invoice.client}
                  </Td>
                  <Td fontWeight={"300"} fontSize={"14px"} lineHeight={"19px"}>
                    {invoice.project}
                  </Td>
                  <Td fontWeight={"300"} fontSize={"14px"} lineHeight={"19px"}>
                    {invoice.dueDate}
                  </Td>
                  <Td fontWeight={"300"} fontSize={"14px"} lineHeight={"19px"}>
                    {invoice.amount}
                  </Td>
                  <Td fontWeight={"300"} fontSize={"14px"} lineHeight={"19px"}>
                    {invoice.paidAmount}
                  </Td>
                  <Td>{invoice.balanceAmount}</Td>
                  <Td fontWeight={"300"} fontSize={"14px"} lineHeight={"19px"}>
                    <Badge colorScheme={statusColorScheme[invoice.status]}>
                      {invoice.status}
                    </Badge>
                  </Td>
                  <Td fontWeight={"300"} fontSize={"14px"} lineHeight={"19px"}>
                    <IconButton
                      icon={<HamburgerIcon />}
                      variant="outline"
                      aria-label="Actions"
                    />
                  </Td>
                </Tr>
              // </Thead>
              // <Tbody>
              //   {paginatedData.map((invoice) => (
              //     <Tr key={invoice.id}>
              //       <Td>
              //         <Checkbox />
              //       </Td>
              //       <Td>{invoice.id}</Td>
              //       <Td>{invoice.client}</Td>
              //       <Td>{invoice.project}</Td>
              //       <Td>{invoice.dueDate}</Td>
              //       <Td>{invoice.amount}</Td>
              //       <Td>{invoice.paidAmount}</Td>
              //       <Td>{invoice.balanceAmount}</Td>
              //       <Td>
              //         <Badge colorScheme={statusColorScheme[invoice.status]}>
              //           {invoice.status}
              //         </Badge>
              //       </Td>
              //       <Td>
              //         <IconButton
              //           icon={<HamburgerIcon />}
              //           variant="outline"
              //           aria-label="Actions"
              //         />
              //       </Td>
              //     </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <HStack
            justifyContent="space-between"
            mt={4}
            alignItems="center"
            flexWrap="wrap"
          >
            {/* <HStack spacing={2} mb={{ base: 4, md: 0 }}> */}
            <Box color="#6F6F6F">Show</Box>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              width="80px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
            <Box color="#6F6F6F">entries</Box>
            {/* </HStack> */}

            <HStack spacing={2}>
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(-1)}
                disabled={currentPage === 1}
                leftIcon={<ChevronLeftIcon />}
              >
                Prev
              </Button>
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  colorScheme={currentPage === index + 1 ? "blue" : "gray"}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === totalPages}
                rightIcon={<ChevronRightIcon />}
              >
                Next
              </Button>
            </HStack>
          </HStack>
        </Box>
        </ChakraProvider>
  );
};

export default InvoiceTable;
