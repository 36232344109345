import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  extendTheme,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useBreakpointValue,
  useDisclosure,
  HStack,
  Divider,
  ChakraProvider,
} from "@chakra-ui/react";
import { FiFilter, FiMoreVertical } from "react-icons/fi";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ClientInfo from "../Analytic/ClientInfo";
import DatePicker from "react-datepicker";
import AddContractModal from "./AddnewContract";
import "@fontsource/noto-sans";
import CompanyProfile from "../Header/CompanyProfile";
const ContractManagement = () => {
  const data = [
    {
      id: "#1493024",
      subject: "SEO Proposal",
      customer: "NovaWave LLC",
      value: "$2,05,426",
      type: "Contracts under Seal",
      startDate: "15 Aug 2024",
      endDate: "15 May 2024",
    },
    {
      id: "#1493023",
      subject: "Web Design",
      customer: "Redwood Inc",
      value: "$2,105",
      type: "Implied Contracts",
      startDate: "15 Sep 2024",
      endDate: "15 Apr 2024",
    },
    {
      id: "#1493022",
      subject: "Logo & Branding",
      customer: "HarborView",
      value: "$4,05,656",
      type: "Implied Contracts",
      startDate: "15 Nov 2024",
      endDate: "15 Mar 2024",
    },
    {
      id: "#1493021",
      subject: "Development",
      customer: "CoastalStar Co.",
      value: "$2,05,426",
      type: "Executory Contracts",
      startDate: "15 Jun 2024",
      endDate: "15 Feb 2024",
    },
    {
      id: "#1493020",
      subject: "SEO Proposal",
      customer: "RiverStone Ventur",
      value: "$3,15,145",
      type: "Voidable Contracts",
      startDate: "15 Oct 2024",
      endDate: "15 Dec 2023",
    },
  ];

  const isMobile = useBreakpointValue({ base: true, md: false });
  const [startDate, setStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [sortOption, setSortOption] = useState(""); // State for sort 
   const [check, setCheck] = useState("");

  const filteredData = data.filter((lead) => {
    // Add your filtering logic here
    return true;
  });

  

 const filteredPipelines = data.filter(
    (pipeline) =>
      pipeline?.subject?.toLowerCase().includes(searchQuery?.toLowerCase()) // Case-insensitive search
  );

const sortPipelines = (data, option) => {
    if (option === "name") {
      return [...data].sort((a, b) => a.subject.localeCompare(b.subject)); // Alphabetical order
    } else if (option === "value") {
      return [...data].sort(
        (a, b) =>
          parseFloat(b.value) -parseFloat(a.value)
      ); // Highest to lowest deal value
    }
    return data;
  };


    const sortedPipelines = selectedPriority
      ? data.filter(
          (project) =>
            project.status.toLowerCase() === selectedPriority.toLowerCase()
        )
      : sortPipelines(filteredPipelines, sortOption);


  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Noto Sans', sans-serif`,
    },
  });

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };


  const onChangeEvent = (e) => {
    setCheck(e.target.checked);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box p={4} bg={"gray.50"} w={"100%"} letterSpacing={"-2%"}>
        <CompanyProfile />
        {/* <ClientInfo /> */}
        <Box bg={"white"} mt={"15px"}>
          <Flex
            justifyContent="space-between"
            mb={4}
            flexWrap="wrap"
            gap={4}
            bg={"white"}
          >
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              mt={"25px"}
              w={"100%"}
            >
              <Input
                placeholder="Search Contracts"
                textAlign={"left"}
                p={"20px"}
                ml={'10px'}
                w={{ base: "100%", md: "40%" }}
                // mb={{ base: 2, md: 0 }}
                boxShadow={"5px"}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <Flex
                justifyContent="space-around"
                gap="10px"
                mr="15px"
                w={{ base: "100%", md: "auto" }}
                direction={{ base: "column", md: "row" }}
              >
                <Select
                  placeholder="Export"
                  h={"41px"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                >
                  <option value="date">Date</option>
                  <option value="amount">Amount</option>
                </Select>
                <Button
                  colorScheme="red"
                  p={"15px"}
                  w={"100%"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  onClick={onOpen}
                  ml={"2px"}
                >
                  Add New Contracts
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <AddContractModal isOpen={isOpen} onClose={onClose} />
          <Divider mb={4} />
          <Box overflowX="auto" bg={"white"} px={'10px'}>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
              mb={4}
            >
              <Flex
                gap="7px"
                maxW={{ base: "100%", md: "30%" }}
                direction={{ base: "column", md: "row" }}
              >
                <Select
                  placeholder="Sort"
                  w="40%"
                  mt={{ base: 2, md: 0 }}
                  fontWeight={"bold"}
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  p="7px"
                  onChange={(e) => setSortOption(e.target.value)}
                >
                  <option value="name">Name</option>
                  <option value="value">Value</option>
                </Select>
                <Flex mt="5px">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    customInput={<Input />}
                  />
                </Flex>
              </Flex>

              <Flex mt={{ base: 2, md: 0 }} gap={"5px"} mr={"3px"}>
                <Button
                  variant="outline"
                  mr={2}
                  bg={"#F3EDFF"}
                  color={"#4A00E5"}
                  fontSize={"13px"}
                  fontWeight={"600"}
                >
                  Manage Columns
                </Button>
                <Button variant="outline" fontWeight={"500"} fontSize={"14px"}>
                  Filter
                </Button>
              </Flex>
            </Flex>
          </Box>
          <Box bg={"white"} overflow={"auto"}>
            <Table  mx={"auto"} borderBottom={'none'} >
              <Thead bg={"#E8E8E8"}>
                <Tr>
                  <Th>
                    <input
                      type="checkbox"
                      checked={check}
                      onChange={(e) => onChangeEvent(e)}
                    />
                  </Th>
                  <Th className="" fontWeight={"500"} fontSize={"14px"}>
                    Contract ID
                  </Th>
                  <Th
                    className="table-header-style"
                    fontWeight={"500"}
                    fontSize={"14px"}
                  >
                    Subject
                  </Th>
                  <Th
                    className="table-header-style"
                    fontWeight={"500"}
                    fontSize={"14px"}
                  >
                    Customer
                  </Th>
                  <Th
                    className="table-header-style"
                    fontWeight={"500"}
                    fontSize={"14px"}
                  >
                    Contract Value
                  </Th>
                  <Th
                    className="table-header-style"
                    fontWeight={"500"}
                    fontSize={"14px"}
                  >
                    Contract Type
                  </Th>
                  <Th
                    className="table-header-style"
                    fontWeight={"500"}
                    fontSize={"14px"}
                  >
                    Start Date
                  </Th>
                  <Th
                    className="table-header-style"
                    fontWeight={"500"}
                    fontSize={"14px"}
                  >
                    End Date
                  </Th>
                  <Th
                    className="table-header-style"
                    fontWeight={"500"}
                    fontSize={"14px"}
                  >
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {sortedPipelines.map((contract) => (
                  <Tr key={contract.id}>
                    <Td>
                      <input
                        type="checkbox"
                        checked={check}
                      />
                    </Td>
                    <Td
                      fontWeight={"500"}
                      fontSize={"14px"}
                      lineHeight={"19px"}
                    >
                      {contract.id}
                    </Td>
                    <Td
                      fontWeight={"500"}
                      fontSize={"14px"}
                      lineHeight={"19px"}
                    >
                      {contract.subject}
                    </Td>
                    <Td
                      fontWeight={"500"}
                      fontSize={"14px"}
                      lineHeight={"19px"}
                    >
                      {contract.customer}
                    </Td>
                    <Td fontSize={"14px"} lineHeight={"19px"}>
                      {contract.value}
                    </Td>
                    <Td fontSize={"14px"} lineHeight={"19px"}>
                      {contract.type}
                    </Td>
                    <Td fontSize={"14px"} lineHeight={"19px"}>
                      {contract.startDate}
                    </Td>
                    <Td fontSize={"14px"} lineHeight={"19px"}>
                      {contract.endDate}
                    </Td>
                    <Td fontSize={"14px"} lineHeight={"19px"}>
                      <IconButton
                        aria-label="Actions"
                        icon={<FiMoreVertical />}
                        variant="ghost"
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>

        <HStack
          justifyContent="space-between"
          mt={4}
          alignItems="center"
          flexWrap="wrap"
        >
          <HStack spacing={2} mb={{ base: 4, md: 0 }}>
            <Box color="#6F6F6F">Show</Box>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              width="80px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
            <Box color="#6F6F6F">entries</Box>
          </HStack>

          <HStack spacing={2}>
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(-1)}
              disabled={currentPage === 1}
              leftIcon={<ChevronLeftIcon />}
            >
              Prev
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                colorScheme={currentPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === totalPages}
              rightIcon={<ChevronRightIcon />}
            >
              Next
            </Button>
          </HStack>
        </HStack>
      </Box>
    </ChakraProvider>
  );
};

export default ContractManagement;
