import React from "react";
import Chart from "react-apexcharts";
import {
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const WonDealsChart = () => {
  const options = {
    chart: {
      type: "bar",
      height: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "50%",
      },
    },
    colors: ["#00E396"], // Green color for the chart
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Conversation", "Follow Up", "Inpipeline"],
    },
  };

  const series = [
    {
      name: "Won Deals",
      data: [350, 200, 100], // Example data
    },
  ];

  return (
    <Box maxW={"780px"} p={4} bg={"white"} overflowX={"auto"}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Heading size="sm" color="gray.600" mt={"4px"}>
          Won Deals Stage
        </Heading>
        <Flex gap={"6px"}>
          <Menu>
            <MenuButton
              w="auto"
              as={Button}
              fontSize="12px"
              fontWeight="400"
              textAlign={"center"}
              variant="outline"
              rightIcon={<ChevronDownIcon />}
            >
              Marketing pipeline
            </MenuButton>
            <MenuList>
              <MenuItem>Sales</MenuItem>
              <MenuItem>All</MenuItem>
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton
              w="auto"
              as={Button}
              fontSize="12px"
              fontWeight="400"
              textAlign={"center"}
              variant="outline"
              rightIcon={<ChevronDownIcon />}
            >
              Last 30 Days
            </MenuButton>
            <MenuList>
              <MenuItem>Last 7 Days</MenuItem>
              <MenuItem>Last 30 Days</MenuItem>
              <MenuItem>All Time</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      <Chart options={options} series={series} type="bar" height={350} />
    </Box>
  );
};

export default WonDealsChart;
