import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  RadioGroup,
  Stack,
  Radio,
  Switch,
  Box,
  Flex,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import "@fontsource/noto-sans";


const AddNewCompanyDrawer = ({ isOpen, onClose }) => {

     const theme = extendTheme({
       fonts: {
         heading: `'Noto Sans', sans-serif`,
         body: `'Noto Sans', sans-serif`,
       },
     });
  return (
    <ChakraProvider>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader w={"343px"} fontWeight={"400"} fontSize={"19px"}>
            Add New Company
            <DrawerCloseButton />
          </DrawerHeader>
          <DrawerBody>
            <Box
              borderWidth="1px"
              borderRadius="md"
              p={4}
              mb={4}
              textAlign="center"
            >
              <Button size="sm" variant="outline" mb={2}>
                Upload File
              </Button>
              <Box fontSize="sm" color="gray.500">
                JPG, GIF or PNG. Max size of 800K
              </Box>
            </Box>
            <FormControl isRequired mb={4}>
              <FormLabel fontWeight={"400"} fontSize={"16px"}>
                Company Name
              </FormLabel>
              <Input placeholder="Enter company name" />
            </FormControl>
            <FormControl isRequired mb={4}>
              <Flex justifyContent={"space-between"}>
                <FormLabel fontWeight={"400"} fontSize={"16px"}>
                  Email
                </FormLabel>
                <Switch colorScheme="green" size="sm" defaultChecked />
              </Flex>

              <Box display="flex" alignItems="center" gap={2}>
                <Input placeholder="Enter email address" />
              </Box>
            </FormControl>
            <Stack direction={{ base: "column", md: "row" }} spacing={4} mb={4}>
              <FormControl isRequired>
                <FormLabel fontWeight={"400"} fontSize={"16px"}>
                  Phone 1
                </FormLabel>
                <Input placeholder="Enter phone number" />
              </FormControl>
              <FormControl>
                <FormLabel fontWeight={"400"} fontSize={"16px"}>
                  Phone 2
                </FormLabel>
                <Input placeholder="Enter secondary phone number" />
              </FormControl>
            </Stack>
            <Stack direction={{ base: "column", md: "row" }} spacing={4} mb={4}>
              <FormControl>
                <FormLabel fontWeight={"400"} fontSize={"16px"}>
                  Fax
                </FormLabel>
                <Input placeholder="Enter fax number" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontWeight={"400"} fontSize={"16px"}>
                  Website
                </FormLabel>
                <Input placeholder="Enter website URL" />
              </FormControl>
            </Stack>
            <Stack direction={{ base: "column", md: "row" }} spacing={4} mb={4}>
              <FormControl isRequired>
                <FormLabel fontWeight={"400"} fontSize={"16px"}>
                  Source
                </FormLabel>
                <Select placeholder="Choose">
                  <option value="source1">Source 1</option>
                  <option value="source2">Source 2</option>
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontWeight={"400"} fontSize={"16px"}>
                  Industry
                </FormLabel>
                <Select placeholder="Choose">
                  <option value="industry1">Industry 1</option>
                  <option value="industry2">Industry 2</option>
                </Select>
              </FormControl>
            </Stack>
            <Stack direction={{ base: "column", md: "row" }} spacing={4} mb={4}>
              <FormControl isRequired>
                <FormLabel fontWeight={"400"} fontSize={"16px"}>
                  Currency
                </FormLabel>
                <Input placeholder="Enter currency" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel fontWeight={"400"} fontSize={"16px"}>
                  Language
                </FormLabel>
                <Select placeholder="Choose">
                  <option value="english">English</option>
                  <option value="spanish">Spanish</option>
                </Select>
              </FormControl>
            </Stack>
            <FormControl isRequired mb={4}>
              <FormLabel fontWeight={"400"} fontSize={"16px"}>
                Description
              </FormLabel>
              <Textarea placeholder="Enter description" />
            </FormControl>
            <FormControl isRequired>
              <FormLabel fontWeight={"400"} fontSize={"16px"}>
                Status
              </FormLabel>
              <RadioGroup defaultValue="Active">
                <Stack direction="row" spacing={4}>
                  <Radio
                    value="Active"
                    colorScheme="blue"
                    fontWeight={"400"}
                    fontSize={"16px"}
                  >
                    Active
                  </Radio>
                  <Radio
                    value="Inactive"
                    colorScheme="red"
                    fontWeight={"400"}
                    fontSize={"16px"}
                  >
                    Inactive
                  </Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button
              onClick={onClose}
              variant="outline"
              mr={3}
              w={"170px"}
              fontWeight={"400"}
              fontSize={"16px"}
            >
              Cancel
            </Button>
            <Button colorScheme="red" fontWeight={"400"} fontSize={"16px"}>
              Create
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
};

export default AddNewCompanyDrawer;
