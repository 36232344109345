import React from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Badge,
  Avatar,
  HStack,
  Select,
  useBreakpointValue,
  extendTheme,
  ChakraProvider,
  Flex,
} from "@chakra-ui/react";

const data = [
  {
    leadName: "Collins",
    companyName: "NovaWaveLLC",
    location: "Newyork, USA",
    phone: "+1 875455453",
    status: "Closed",
    statusColor: "green",
    logo: "https://via.placeholder.com/20/4CAF50/FFFFFF?text=N",
  },
  {
    leadName: "Collins",
    companyName: "Konopelski",
    location: "Winchester, KY",
    phone: "+1 989757485",
    status: "Not Contacted",
    statusColor: "purple",
    logo: "https://via.placeholder.com/20/3F51B5/FFFFFF?text=K",
  },
  {
    leadName: "Adams",
    companyName: "SilverHawk",
    location: "Jametown, NY",
    phone: "+1 546555455",
    status: "Closed",
    statusColor: "green",
    logo: "https://via.placeholder.com/20/FF5722/FFFFFF?text=S",
  },
  {
    leadName: "Schumm",
    companyName: "SummitPeak",
    location: "Compton, RI",
    phone: "+1 454478787",
    status: "Contacted",
    statusColor: "orange",
    logo: "https://via.placeholder.com/20/FF9800/FFFFFF?text=S",
  },
  {
    leadName: "Wisozk",
    companyName: "RiverStone Ventur",
    location: "Dayton, OH",
    phone: "+1 124547845",
    status: "Closed",
    statusColor: "green",
    logo: "https://via.placeholder.com/20/4CAF50/FFFFFF?text=R",
  },
];

const LeadTable = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Noto Sans', sans-serif`,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box p={4}  bg={"white"}>
        <HStack justifyContent="space-between" mb={4}>
          <Text fontSize="18px" fontWeight="700" w={"210px"}>
            Recently Created Leads
          </Text>
          <Select maxW="150px" placeholder="Last 30 Days">
            <option>Last 7 Days</option>
            <option>Last 30 Days</option>
            <option>Last 90 Days</option>
          </Select>
        </HStack>

        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>Lead Name</Th>
              <Th>Company Name</Th>
              <Th>Phone</Th>
              <Th>Lead Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((lead, index) => (
              <Tr key={index}>
                <Td>{lead.leadName}</Td>
                <Td w="120.91px" h="63px">
                  <Flex
                    alignItems="center"
                    h={"50px"}
                    lineHeight={"2px"}
                    w={"150px"}
                  >
                    <Avatar size="sm" name={lead.companyName} mr={2} />
                    <Flex mt={"15px"} flexDirection={"column"}>
                      <Text fontWeight="600" fontSize="14px">
                        {lead.companyName}
                      </Text>
                      <Text fontSize="12px" w={"180px"}>
                        {lead.location}
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
                <Td>{lead.phone}</Td>
                <Td>
                  <Badge colorScheme={lead.statusColor} variant="solid">
                    {lead.status}
                  </Badge>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </ChakraProvider>
  );
};

export default LeadTable;
