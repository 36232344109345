import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   Text,
//   Badge,
//   Avatar,
//   IconButton,
//   Stack,
//   Input,
//   Select,
//   Flex,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   FormControl,
//   FormLabel,
//   useDisclosure,
// } from "@chakra-ui/react";
// import { AddIcon, ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Select,
  Textarea,
  FormControl,
  FormLabel,
  Stack,
  useDisclosure,
  ChakraProvider,
  extendTheme,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";



const AddEstimation = ({isOpen,onClose}) => {
  
   const [formData, setFormData] = useState({
     client: "",
     billTo: "",
     shipTo: "",
     amount: "",
     currency: "",
     estimateDate: "",
     expiryDate: "",
     project: "",
     estimateBy: "",
     attachment: null,
     description: "",
   });

   const theme = extendTheme({
     fonts: {
       heading: `'Poppins', sans-serif`,
       body: `'Poppins', sans-serif`,
     },
   });
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <ChakraProvider theme={theme}>
      <Box p={5}>
        {/* Existing Table Code */}

        {/* Add Estimate Modal */}
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader fontWeight={400}>Add New Estimations</DrawerHeader>

            <DrawerBody>
              {/* Grid Layout for Proper Alignment */}
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                {/* Client */}
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel fontWeight={400}>Client</FormLabel>
                    <Input
                      name="client"
                      placeholder="Enter Client Name"
                      onChange={handleChange}
                    />
                  </FormControl>
                </GridItem>

                {/* Bill To */}
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel fontWeight={400}>Bill To</FormLabel>
                    <Input
                      name="billTo"
                      placeholder="Enter Billing Address"
                      onChange={handleChange}
                    />
                  </FormControl>
                </GridItem>

                {/* Ship To */}
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel fontWeight={400}>Ship To</FormLabel>
                    <Input
                      name="shipTo"
                      placeholder="Enter Shipping Address"
                      onChange={handleChange}
                    />
                  </FormControl>
                </GridItem>

                {/* Amount */}
                <GridItem colSpan={1}>
                  <FormControl isRequired>
                    <FormLabel fontWeight={400}>Amount</FormLabel>
                    <Input
                      name="amount"
                      placeholder="Enter Amount"
                      type="number"
                      onChange={handleChange}
                    />
                  </FormControl>
                </GridItem>

                {/* Currency */}
                <GridItem colSpan={1}>
                  <FormControl>
                    <FormLabel fontWeight={400}>Currency</FormLabel>
                    <Select
                      name="currency"
                      placeholder="Choose Currency"
                      onChange={handleChange}
                    >
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="INR">INR</option>
                    </Select>
                  </FormControl>
                </GridItem>

                {/* Estimate Date */}
                <GridItem colSpan={1}>
                  <FormControl>
                    <FormLabel fontWeight={400}>Estimate Date</FormLabel>
                    <Input
                      name="estimateDate"
                      type="date"
                      onChange={handleChange}
                    />
                  </FormControl>
                </GridItem>

                {/* Expiry Date */}
                <GridItem colSpan={1}>
                  <FormControl>
                    <FormLabel fontWeight={400}>Expiry Date</FormLabel>
                    <Input
                      name="expiryDate"
                      type="date"
                      onChange={handleChange}
                    />
                  </FormControl>
                </GridItem>

                {/* Project */}
                <GridItem colSpan={1}>
                  <FormControl>
                    <FormLabel fontWeight={400}>Project</FormLabel>
                    <Select
                      name="project"
                      placeholder="Select Project"
                      onChange={handleChange}
                    >
                      <option value="Trulysell">Trulysell</option>
                      <option value="Dreamschat">Dreamschat</option>
                    </Select>
                  </FormControl>
                </GridItem>

                {/* Estimate By */}
                <GridItem colSpan={1}>
                  <FormControl>
                    <FormLabel fontWeight={400}>Estimate By</FormLabel>
                    <Select
                      name="estimateBy"
                      placeholder="Select Estimator"
                      onChange={handleChange}
                    >
                      <option value="Manager">Manager</option>
                      <option value="Engineer">Engineer</option>
                    </Select>
                  </FormControl>
                </GridItem>

                {/* Attachment */}
                <GridItem colSpan={2}>
                  <FormControl>
                    <FormLabel fontWeight={400}>Attachment</FormLabel>
                    <Button leftIcon={<FiUpload />} variant="outline">
                      Upload File
                    </Button>
                  </FormControl>
                </GridItem>

                {/* Description */}
                <GridItem colSpan={2}>
                  <FormControl>
                    <FormLabel fontWeight={400}>Description</FormLabel>
                    <Textarea
                      name="description"
                      placeholder="Enter Description"
                      onChange={handleChange}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </DrawerBody>

            <Box p={4} textAlign="right" borderTop="1px solid #E2E8F0">
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red">Create</Button>
            </Box>
          </DrawerContent>
        </Drawer>
      </Box>
    </ChakraProvider>
  );
};

export default AddEstimation;
