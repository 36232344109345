import { StarIcon } from "@chakra-ui/icons";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ChakraProvider,
  extendTheme,
  Box,
  Button,
  Flex,
  Heading,
  VStack,
  HStack,
  Image,
  SimpleGrid,
  Text,
  Link,
  useColorModeValue,
  Avatar,
} from "@chakra-ui/react";
import React from 'react'
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";



const theme = extendTheme({
  fonts: {
    heading: "'Manrope', sans-serif",
    body: "'Manrope', sans-serif",
  },
});

const teamMembers = [
  {
    name: "Sarah Taylor",
    role: "Marketing Lead",
    avatar: "https://via.placeholder.com/150",
  },
  {
    name: "Ben Stokes",
    role: "Art Director",
    avatar: "https://via.placeholder.com/150",
  },
  {
    name: "Ellyse Perry",
    role: "Designer",
    avatar: "https://via.placeholder.com/150",
  },
  {
    name: "Steven Smith",
    role: "Development",
    avatar: "https://via.placeholder.com/150",
  },
  {
    name: "Suzie Bates",
    role: "Front-End Development",
    avatar: "https://via.placeholder.com/150",
  },
  {
    name: "David Warner",
    role: "Head of Team",
    avatar: "https://via.placeholder.com/150",
  },
];

const reviews = [ 
    { name: 'Deanna Hodges', title: 'Spotify Developer', review: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type.', category: 'Theme Customization', rating: 5, }, 
    { name: 'Londynn Vargas', title: 'PHP Developer', review: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type.', category: 'Customer Support', rating: 5, },
     { name: 'James Andy', title: 'Moodle Developer', review: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type.', category: 'Responsive Design', rating: 4, }
    ]


const About = () => {
     const bgColor = useColorModeValue("gray.50", "gray.800");
     const cardBgColor = useColorModeValue("white", "gray.700");
     const textColor = useColorModeValue("gray.600", "gray.400");
  return (
    <ChakraProvider theme={theme}>
      <Box mx="auto" p={4} bg={"white"}>
        <Box
          position="relative"
          bgImage="/Rectangle 3909.jpg"
          bgSize="cover"
          bgPosition="center"
          bgRepeat="no-repeat"
          height="300px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          color="white"
          textAlign="center"
          overflow="hidden"
        >
          {/* Overlay for gradient effect */}
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex="0"
          />

          {/* Content */}
          <Box
            position="relative"
            zIndex="1"
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="4xl" fontWeight="bold" color={"white"}>
              About Us
            </Text>
            <Breadcrumb color="whiteAlpha.800" separator="-">
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink href="/contact">about us</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Box>
        </Box>

        <Box bg={"white"}>
          <Flex
            direction={{ base: "column", lg: "row" }}
            align="center"
            justify="space-between"
            p={15}
            maxW={"90%"}
            mx="auto"
            mt={"20px"}
            gap={"25%"}
          >
            <Box flex="1">
              <Text fontSize="sm" color="red.500" fontWeight="bold" mb={2}>
                ABOUT MARCOM
              </Text>
              <Heading as="h2" size="lg" mb={4}>
                A Few Words About Us
              </Heading>
              <Text color="gray.600" mb={4}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </Text>
              <Text color="gray.600" mb={4}>
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Text>
              <HStack spacing={4}>
                <Button
                  size="sm"
                  rounded={"2xl"}
                  color={"white"}
                  bgGradient="linear(to-r, #590E0E,#871616)"
                >
                  Start Free Trial
                </Button>
                <Button size="sm" bg={"white"} rounded={"xl"}>
                  See All Features
                </Button>
              </HStack>
            </Box>

            <Flex flex="1" justify="center">
              <Image
                src="moniter.jpg"
                alt="MacBook Pro"
                maxW="450px"
              />
            </Flex>
          </Flex>

          {/* BOX IMAGES */}
          <Flex flex="1" justify="center" mt={"5%"}>
            <Image src="/Group 1321314130.jpg" alt="MacBook Pro" maxW="65%" />
          </Flex>
        </Box>

        <Box
          display={"flex"}
          gap={"30px"}
          maxW={"90%"}
          mx="auto"
          mt={"5%"}
          p={25}
          bgImage={"bg2.jpeg"}
        >
          <Flex gap={10}>
            <Image src={`sidebar.jpg`} h={"300px"} alt="Sidebar" />
            <Image
              src={`Marcom Dashboard (1).jpg`}
              alt="Dashboard"
              h={"400px"}
              w={"500px"}
            />
            <Box width="30%" padding="4" flex={2}>
              <Text fontSize="2xl" fontWeight="bold" color="white">
                Set Up The Challenges and Track Your Progress
              </Text>
              <Text mt="4" color="white">
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </Text>
              <Button mt="4" colorScheme="red">
                Start Free Trial
              </Button>
            </Box>
          </Flex>
         
        </Box>

        {/* world image */}
        <Box mt={"5%"}>
          <Image src="Container.jpg" mx={"auto"} alt="MacBook Pro" maxW="65%" />
        </Box>

        {/* integrations */}
        <Box
          p={20}
          bgImage={"bg2.jpeg"}
          textAlign="center"
          color="white"
          maxW={"90%"}
          mx={"auto"}
          mt={10}
        >
          <Flex>
            <Flex
              flexDirection={"column"}
              justifyContent={"space-between"}
              textAlign={"left"}
            >
              <Text fontSize="sm" fontWeight="bold" color={"white"}>
                SOFTWARE INTEGRATIONS
              </Text>
              <Text fontSize="3xl" fontWeight="bold" color={"white"}>
                Easy & Perfect Solution With Latest Software Integrations
              </Text>
              <Text color={"white"}>
                Cloud based storage for your data backup just log in with your
                mail account from play store and using whatever you want for
                your business purpose orem ipsum dummy text. Never miss your
                chance, it's just begun. Backup just log in with your mail
                account from.{" "}
              </Text>
              <Text color={"white"}>
                Most probably best for your data backup just log in with your
                mail account from play store and using whatever you want for
                your business purpose orem ipsum dummy.{" "}
              </Text>
              <Button
                colorScheme="whiteAlpha"
                variant="outline"
                w={"15%"}
                color={"red"}
                bg={"white"}
                rounded={"2xl"}
              >
                Get Started
              </Button>
            </Flex>
            <Image src="List.jpg" w={"35%"} h={"35%"} bgSize="cover" />
          </Flex>
        </Box>

        {/* 
        team members */}
        <Box mt={"5%"} maxW={"90%"} mx={"auto"}>
          <VStack spacing={3} textAlign="center" mb={10}>
            <Text fontSize="sm" color="red.500" fontWeight="bold">
              OUR EXPERTS
            </Text>
            <Heading fontSize="3xl">Meet With Our Team Member</Heading>
          </VStack>
          <Flex gap={1}>
            {teamMembers.map((member, index) => (
              <VStack
                key={index}
                p={2}
                textAlign="center"
                w={{ base: "80%", sm: "40%", md: "10%", lg: "20%" }}
              >
                <Avatar
                  size="lg"
                  name={member.name}
                  src={member.avatar}
                  mb={4}
                  border="2px solid red"
                />
                <Flex mt={"15px"} flexDirection={"column"} lineHeight={"2px"}>
                  <Text fontWeight="600" fontSize="14px">
                    {member.name}
                  </Text>
                  <Text fontSize="12px" w={"180px"}>
                    {member.role}
                  </Text>
                </Flex>
              </VStack>
            ))}
          </Flex>
        </Box>

        {/* reviews */}
        <Box py={10} bgImage={"bg2.jpeg"} color={"white"} mt={5}>
          <Box textAlign="center" mb={10}>
            <Text fontSize="sm" color={"white"}>
              CLIENT REVIEWS
            </Text>
            <Heading size="lg" fontWeight={"700"}>
              Some of Our Customer Reviews About Our Products
            </Heading>
            <Text fontSize="lg" fontWeight="700" color={"white"}>
              About Our Products
            </Text>
          </Box>
          <Flex justify="center" gap={6}>
            {reviews.map((review, index) => (
              <Box bg="white" p={5} borderRadius="md" boxShadow="md" maxW="sm">
                <HStack spacing={4}>
                  <Avatar name={review.name} />
                  <VStack align="start">
                    <Heading size="md">{review.name}</Heading>
                    <Text color="gray.500">{review.title}</Text>
                  </VStack>
                </HStack>
                <Text mt={4}>"{review.review}"</Text>{" "}
                <Flex justifyContent={"space-between"}>
                  <Text mt={4} fontWeight="bold">
                    {review.category}
                  </Text>
                  <HStack spacing={1}>
                    {Array(5)
                      .fill("")
                      .map((_, i) => (
                        <StarIcon
                          key={i}
                          color={i < review.rating ? "yellow.400" : "gray.300"}
                        />
                      ))}
                  </HStack>
                </Flex>
              </Box>
            ))}
          </Flex>
        </Box>

        {/* footer */}
        <Box
          bgImage="/vecteezy_abstract-red-speed-neon-light-effect-on-black-background_8440572 1.jpg"
          bgSize="cover"
          bottom={"0"}
          bgPosition="center"
          color="white"
          py={8}
          mt={"5%"}
        >
          <Flex direction="column" align="center" gap={"10px"} bottom={"0px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Image
                src="MarcomLogo.jpg"
                boxSize="60px"
                borderRadius="full"
                fit="cover"
              />
              <Image
                src="marcom2.png"
                alt="Marcom Logo"
                w={"150px"}
                h={"40px"}
                borderRadius="full"
                fit="cover"
                bg={"red"}
              />
            </Flex>

            <Flex gap={8} mb={4}>
              <Link
                href="#home"
                fontSize="lg"
                _hover={{ textDecoration: "underline" }}
              >
                Home
              </Link>
              <Link
                href="#about"
                fontSize="lg"
                _hover={{ textDecoration: "underline" }}
              >
                About Us
              </Link>
              <Link
                href="#services"
                fontSize="lg"
                _hover={{ textDecoration: "underline" }}
              >
                Services
              </Link>
              <Link
                href="#pricing"
                fontSize="lg"
                _hover={{ textDecoration: "underline" }}
              >
                Pricing
              </Link>
            </Flex>
            <Flex gap={6} mb={4}>
              <Link href="https://facebook.com" isExternal>
                <FaFacebook size="24px" />
              </Link>
              <Link href="https://instagram.com" isExternal>
                <FaInstagram size="24px" />
              </Link>
              <Link href="https://youtube.com" isExternal>
                <FaYoutube size="24px" />
              </Link>
              <Link href="https://twitter.com" isExternal>
                <FaTwitter size="24px" />
              </Link>
              <Link href="https://linkedin.com" isExternal>
                <FaLinkedin size="24px" />
              </Link>
            </Flex>
            <Text fontSize="sm" mt={4} color={"#FFFFFF"}>
              Copyright © 2024 Marcom. All rights reserved.
            </Text>
          </Flex>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default About
