import React from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Heading,
  VStack,
  Box,
  Flex,
  Link,
  Text,
  Image,
  SimpleGrid,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  GridItem,
  Grid,
  Center,
  extendTheme,
  ChakraProvider,
} from "@chakra-ui/react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";


const theme = extendTheme({
  fonts: {
    heading: "'Manrope', sans-serif",
    body: "'Manrope', sans-serif",
  },
});


const ContactForm = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box mx="auto" p={4} bg={"white"}>
        {/* <Image src="/images/vecteezy_abstract-red-speed-neon-light-effect-on-black-background_8440572 1.jpg" /> */}
        <Box
          position="relative"
          bgImage="/Rectangle 3909.jpg"
          bgSize="cover"
          bgPosition="center"
          bgRepeat="no-repeat"
          height="300px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          color="white"
          textAlign="center"
          overflow="hidden"
        >
          {/* Overlay for gradient effect */}
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex="0"
          />

          {/* Content */}
          <Box
            position="relative"
            zIndex="1"
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="4xl" fontWeight="bold" color={"white"}>
              Contact
            </Text>
            <Breadcrumb color="whiteAlpha.800" separator="-">
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem isCurrentPage>
                <BreadcrumbLink href="/contact">Contact</BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
          </Box>
        </Box>

        <Flex justify="center" align="center" p={4}>
          <Box p={8} maxWidth="800px" width="100%">
            <Box textAlign="center" mb={8}>
              <Heading as="h1" size="lg" mb={4}>
                Get In Touch
              </Heading>
              <Text color="gray.600">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec
                tellus tincidunt, hendrerit eros vel, vulputate ipsum. Ut vel
                urna vitae mi sodales volutpat non vel justo.
              </Text>
            </Box>

            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6}>
              <GridItem>
                <FormControl id="name" isRequired>
                  <FormLabel>Name</FormLabel>
                  <Input type="text" placeholder="Your Name" bg={"#D6D6D6"} />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl id="email" isRequired>
                  <FormLabel>Mail ID</FormLabel>
                  <Input type="email" placeholder="Your Email" bg={"#D6D6D6"} />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl id="phone" isRequired>
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    type="tel"
                    placeholder="Your Phone Number"
                    bg={"#D6D6D6"}
                  />
                </FormControl>
              </GridItem>

              <GridItem>
                <FormControl id="subject" isRequired>
                  <FormLabel>Subject</FormLabel>
                  <Input type="text" placeholder="Subject" bg={"#D6D6D6"} />
                </FormControl>
              </GridItem>
            </Grid>

            <FormControl id="message" isRequired mt={6}>
              <FormLabel>Message</FormLabel>
              <Textarea placeholder="Your Message" bg={"#D6D6D6"} rows={15} />
            </FormControl>
            <Center>
              <Button colorScheme="red" w={"30%"} mt={6}>
                Send Message
              </Button>
            </Center>
          </Box>
        </Flex>

        <Box
          bgImage="/vecteezy_abstract-red-speed-neon-light-effect-on-black-background_8440572 1.jpg"
          bgSize="cover"
          bgPosition="center"
          color="white"
          py={8}
        >
          <Flex direction="column" align="center" gap={"10px"} bottom={"0px"}>
            <Flex alignItems={"center"} gap={"5px"}>
              <Image
                src="MarcomLogo.jpg"
                boxSize="60px"
                borderRadius="full"
                fit="cover"
              />
              <Image
                src="marcom2.png"
                alt="Marcom Logo"
                w={"150px"}
                h={"40px"}
                borderRadius="full"
                fit="cover"
                bg={"red"}
              />
            </Flex>

            <Flex gap={8} mb={4}>
              <Link
                href="#home"
                fontSize="lg"
                _hover={{ textDecoration: "underline" }}
              >
                Home
              </Link>
              <Link
                href="#about"
                fontSize="lg"
                _hover={{ textDecoration: "underline" }}
              >
                About Us
              </Link>
              <Link
                href="#services"
                fontSize="lg"
                _hover={{ textDecoration: "underline" }}
              >
                Services
              </Link>
              <Link
                href="#pricing"
                fontSize="lg"
                _hover={{ textDecoration: "underline" }}
              >
                Pricing
              </Link>
            </Flex>
            <Flex gap={6} mb={4}>
              <Link href="https://facebook.com" isExternal>
                <FaFacebook size="24px" />
              </Link>
              <Link href="https://instagram.com" isExternal>
                <FaInstagram size="24px" />
              </Link>
              <Link href="https://youtube.com" isExternal>
                <FaYoutube size="24px" />
              </Link>
              <Link href="https://twitter.com" isExternal>
                <FaTwitter size="24px" />
              </Link>
              <Link href="https://linkedin.com" isExternal>
                <FaLinkedin size="24px" />
              </Link>
            </Flex>
            <Text fontSize="sm" mt={4} color={"#FFFFFF"}>
              Copyright © 2024 Marcom. All rights reserved.
            </Text>
          </Flex>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default ContactForm;
