import React, { useState } from 'react';
import { Box, Flex, Image, Text} from '@chakra-ui/react';
import img1 from './icons/v1.png';
import img2 from './icons/ic-trending-up-24px.png';
import img3 from './icons/i1.png';
import img4 from './icons/i2.png';
import img5 from './icons/i3.png';
import img6 from './icons/ic-trending-down-24px.png';
import  './Dashboard.css';

const LeadsCards = () => {

    const [campaigns, setCampaigns] = useState({
        created: 200,
        running: 92,
        ended: 88,
        draft: 20,
      });
return (
    <>
<Box width={"100%"} display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"} mt="10px" >
{/* ************************************Box 1**************************************** */}
<Box width={{ base: "100%", sm: "100%", md: "48%", lg: "23%" }} bg="white" mt={"5px"}  boxShadow={"1px 2px 10px lightgray"} borderRadius={"15px"} p={"20px"}>
  <Text color="#202224"  className={"textBottomMargin"} display={"flex"} justifyContent={"space-between"} fontFamily="Poppins"
          fontSize="16px"
          fontWeight="500"
          lineHeight="21.5px">
    Total Leads <Image src={img1} w={"40px"} h={"40px"} bg={"purple.100"} p={"10px"} borderRadius={"15px"} />
  </Text>
  <Text fontSize={"27px"} fontFamily="Poppins" color={"#202224"} fontWeight={"700"}>{campaigns.created}</Text>
  <Flex>
    <Image src={img2} />
    <Text pl={"5px"} className={"textBottomMargin"} color={"#00B69B"}>8.5%</Text>
    <Text pl={"5px"}           fontWeight="600" className={"textBottomMargin"}>Up from May</Text>
  </Flex>
</Box>
{/* ************************************Box 2**************************************** */}
<Box width={{ base: "100%", sm: "100%", md: "48%", lg: "23%" }} bg="white" mt={"5px"} height={"auto"} boxShadow={"1px 2px 10px lightgray"} borderRadius={"15px"} p={"20px"}>
  <Text color="#202224"  className={"textBottomMargin"} display={"flex"} justifyContent={"space-between"}fontFamily="Poppins"
          fontSize="16px"
          fontWeight="500"
          lineHeight="21.5px">
    Active Compaigns <Image src={img3} w={"40px"} h={"40px"} bg={"yellow.100"} p={"10px"} borderRadius={"15px"} />
  </Text>
  <Text fontSize={"27px"} fontFamily="Poppins" color={"#202224"} fontWeight={"700"}>{campaigns.running}</Text>
</Box>
{/* ************************************Box 3**************************************** */}
<Box width={{ base: "100%", sm: "100%", md: "48%", lg: "23%" }} bg="white" mt={"5px"} height={"auto"} boxShadow={"1px 2px 10px lightgray"} borderRadius={"15px"} p={"20px"}>
  <Text  color="#202224" className={"textBottomMargin"} display={"flex"} justifyContent={"space-between"}fontFamily="Poppins"
          fontSize="16px"
          fontWeight="500"
          lineHeight="21.5px">
    Upcoming Sessions <Image src={img4} w={"40px"} h={"40px"} bg={"red.100"} p={"10px"} borderRadius={"15px"} />
  </Text>
  <Text fontSize={"27px"} fontFamily="Poppins" color={"#202224"} fontWeight={"700"}>{campaigns.ended}</Text>
</Box>
{/* ************************************Box 4**************************************** */}
<Box width={{ base: "100%", sm: "100%", md: "48%", lg: "23%" }} bg="white" mt={"5px"} height={"auto"} boxShadow={"1px 2px 10px lightgray"} borderRadius={"15px"} p={"20px"}>
  <Text color="#202224" className={"textBottomMargin"} display={"flex"} justifyContent={"space-between"}fontFamily="Poppins"
          fontSize="16px"
          fontWeight="500"
          lineHeight="21.5px">
    Top Performing Platform<Image src={img5} w={"40px"} h={"40px"} bg={"green.100"} p={"10px"} borderRadius={"15px"} />
  </Text>
  <Text fontSize={"27px"} fontFamily="Poppins" color={"#202224"} fontWeight={"700"}>{campaigns.draft}</Text>
  <Flex>
    <Image src={img6} />
    <Text pl={"5px"} className={"textBottomMargin"} color={"red.500"}>4.2%</Text>
    <Text pl={"5px"}           fontWeight="600" className={"textBottomMargin"}>Down from May</Text>
  </Flex>
</Box>
</Box>
</>
)
}


export default LeadsCards