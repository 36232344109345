import {
  Box,
  Text,
  Flex,
  Tag,
  Button,
  Select,
  AvatarGroup,
  Avatar,
  Stack,
  Container,
  Divider,
} from "@chakra-ui/react";

const campaignsData = [
  {
    name: "Distribution",
    type: "Public Relations",
    opened: "30.5%",
    closed: "20.5%",
    unsubscribe: "30.5%",
    delivered: "70.5%",
    conversation: "35.0%",
    status: "Bounced",
    statusColor: "red",
    dueDate: "25 Sep 2023",
    avatars: 3,
  },
  {
    name: "Pricing",
    type: "Social Marketing",
    opened: "42.5%",
    closed: "38.2%",
    unsubscribe: "48.8%",
    delivered: "62.7%",
    conversation: "22.5%",
    status: "Running",
    statusColor: "teal",
    dueDate: "14 Oct 2023",
    avatars: 4,
  },
  {
    name: "Merchandising",
    type: "Content Marketing",
    opened: "52.5%",
    closed: "29.3%",
    unsubscribe: "62.8%",
    delivered: "71.3%",
    conversation: "39.5%",
    status: "Paused",
    statusColor: "blue",
    dueDate: "14 Oct 2023",
    avatars: 6,
  },
  {
    name: "Repeat Customers",
    type: "Rebranding",
    opened: "17.5%",
    closed: "49.5%",
    unsubscribe: "35.2%",
    delivered: "54.8%",
    conversation: "60.5%",
    status: "Success",
    statusColor: "green",
    dueDate: "29 Oct 2023",
    avatars: 2,
  },
];

const CampaignCard = ({ campaign }) => (
  <Box bg="white" p={4} borderRadius="md" boxShadow="sm" mb={4} width="100%">
    <Flex gap={"5px"}>
      <Flex alignItems="center" h={"50px"} lineHeight={"2px"} w={"150px"}>
        <Flex mt={"15px"} flexDirection={"column"} w={'143px'}>
          <Text fontWeight="600" fontSize="14px">
            {campaign.name}
          </Text>
          <Text fontSize="12px">{campaign.type}</Text>
        </Flex>
      </Flex>

      <Flex justify="space-between" align="center" mb={2} ml={"10px"}>
        <Stack direction="row" spacing={8}>
          <Text fontWeight="bold">
            {campaign.opened}{" "}
            <Text as="span" color="gray.500" fontWeight="normal">
              Opened
            </Text>
          </Text>
          <Text fontWeight="bold">
            {campaign.closed}{" "}
            <Text as="span" color="gray.500" fontWeight="normal">
              Closed
            </Text>
          </Text>
          <Text fontWeight="bold">
            {campaign.unsubscribe}{" "}
            <Text as="span" color="gray.500" fontWeight="normal">
              Unsubscribe
            </Text>
          </Text>
          <Text fontWeight="bold">
            {campaign.delivered}{" "}
            <Text as="span" color="gray.500" fontWeight="normal">
              Delivered
            </Text>
          </Text>
          <Text fontWeight="bold">
            {campaign.conversation}{" "}
            <Text as="span" color="gray.500" fontWeight="normal">
              Conversation
            </Text>
          </Text>
        </Stack>
      </Flex>
    </Flex>
    <Divider />
    <Flex justify="space-between" align="center" mt={"5px"}>
      <Flex justifyContent={"space-between"} alignItems={"center"} gap={"5px"}>
        <Tag size="md" colorScheme={campaign.statusColor}>
          {campaign.status}
        </Tag>
        <Text color="gray.500" fontSize="sm" mt={"15px"}>
          Due Date: {campaign.dueDate}
        </Text>
      </Flex>
      <AvatarGroup size="sm" max={2}>
        {[...Array(campaign.avatars)].map((_, i) => (
          <Avatar key={i} name={`User ${i + 1}`} />
        ))}
      </AvatarGroup>
    </Flex>
  </Box>
);

const CampaignsList = () => {
  return (
    <Box p={4} w={'716px'} bg={'white'}>
      <Flex justify="space-between" align="center" mb={4}>
        <Text fontSize="lg" fontWeight="bold">
          Recently Created Campaign
        </Text>
        <Flex align="center">
          <Select width="150px" defaultValue="Last 30 Days" mr={2}>
            <option value="Last 7 Days">Last 7 Days</option>
            <option value="Last 30 Days">Last 30 Days</option>
            <option value="Last 90 Days">Last 90 Days</option>
          </Select>
          <Button colorScheme="red">Add Pipeline</Button>
        </Flex>
      </Flex>
      {campaignsData.map((campaign, index) => (
        <CampaignCard key={index} campaign={campaign} />
      ))}
    </Box>
  );
};

export default CampaignsList;
