import React, { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Flex,
  Input,
  Divider,
  Select,
  useDisclosure,
  extendTheme,
  Checkbox,
  Text,
  HStack,
  ChakraProvider,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import CompanyProfile from "../Header/CompanyProfile";
import Addpipeline from "./Addpipeline";
import DatePicker from "react-datepicker";
import "@fontsource/noto-sans";


const PipelineTable = () => {
  const [startDate, setStartDate] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortCriterion, setSortCriterion] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [sortOption, setSortOption] = useState(""); // State for sort option
  const [selectedPriority, setSelectedPriority] = useState("");
    const [check, setCheck] = useState("");

  const pipelines = [
    {
      name: "Sales",
      value: "$4,50,664",
      deals: 315,
      stage: "Win",
      date: "25 Sep 2023",
      status: "Active",
    },
    {
      name: "Marketing",
      value: "$3,12,893",
      deals: 447,
      stage: "Win",
      date: "29 Sep 2023",
      status: "Active",
    },
    {
      name: "Email",
      value: "$2,89,274",
      deals: 654,
      stage: "In Pipeline",
      date: "15 Oct 2023",
      status: "Active",
    },
    {
      name: "Chats",
      value: "$1,59,326",
      deals: 768,
      stage: "Win",
      date: "29 Oct 2023",
      status: "Active",
    },
    {
      name: "Operational",
      value: "$2,90,173",
      deals: 142,
      stage: "Win",
      date: "03 Nov 2023",
      status: "Inactive",
    },
    {
      name: "Collaborative",
      value: "$4,51,417",
      deals: 315,
      stage: "Conversation",
      date: "17 Nov 2023",
      status: "Active",
    },
    {
      name: "Differentiate",
      value: "$3,17,589",
      deals: 478,
      stage: "Lost",
      date: "23 Nov 2023",
      status: "Active",
    },
    {
      name: "Interact",
      value: "$1,69,146",
      deals: 664,
      stage: "Lost",
      date: "09 Dec 2023",
      status: "Active",
    },
  ];

  //  const filteredPipelines = filterCriterion
  //    ? pipelines.filter((pipeline) => {
  //        if (filterCriterion === "name") {
  //          return pipeline.name.sort((a, b) => a.name.localeCompare(b.name));

  //        } else if (filterCriterion === "value") {
  //          return pipeline.value
  //            .toLowerCase()
  //            .includes(filterCriterion.toLowerCase());
  //        }
  //        return true;
  //      }): pipelines;

  // const sortedPipelines = [...pipelines]; // Copy array to avoid mutation
  // if (sortCriterion === "name") {
  //   sortedPipelines.sort((a, b) => a.name.localeCompare(b.name));
  // }
  // if (sortCriterion === "deals") {
  //   sortedPipelines.sort((a, b) => a.deals - b.deals);
  // }

  // const filteredPipelines = pipelines.filter(
  //   (pipeline) =>
  //     pipeline.name.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
  // );

  const sortPipelines = (data, option) => {
    if (option === "name") {
      return [...data].sort((a, b) => a.name.localeCompare(b.name)); // Alphabetical order
    }
    if (option === "date") {
      return data.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA;
      });
    } else if (option === "value") {
      return [...data].sort(
        (a, b) =>
          parseFloat(b.value.replace(/[$,]/g, "")) -
          parseFloat(a.value.replace(/[$,]/g, ""))
      ); // Highest to lowest deal value
    }
    return data;
  };

  // Filter pipelines based on the search query
  const filteredPipelines = pipelines.filter(
    (pipeline) =>
      pipeline.name.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
  );

  // Sort filtered pipelines based on the selected sort option
  // const sortedPipelines = sortPipelines(filteredPipelines, sortOption);

  const totalPages = Math.ceil(pipelines.length / itemsPerPage);
  const paginatedData = pipelines.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };
  

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const getStatusBadge = (status) => {
    return (
      <Badge colorScheme={status === "Active" ? "green" : "red"}>
        {status}
      </Badge>
    );
  };

  const getStageBadge = (stage) => {
    switch (stage) {
      case "Win":
        return (
          <Flex alignItems={"center"} gap={"1px"}>
            <Box
              height="5px" // Set the height of the line
              width="60px" // Set the width of the line
              bg="green.500" // Set the background color to green
              borderRadius="lg"
              mt={"-20px"} // Optional: Add rounded edges
            />
            <Text>Win</Text>
          </Flex>
        );
      case "In Pipeline":
        return (
          <Flex alignItems={"center"} gap={"1px"}>
            <Box
              height="5px" // Set the height of the line
              width="60px" // Set the width of the line
              bg="purple.500" // Set the background color to green
              borderRadius="lg"
              mt={"-20px"} // Optional: Add rounded edges
            />
            <Text>In Pipeline</Text>
          </Flex>
        );
      case "Conversation":
        return (
          <Flex alignItems={"center"} gap={"1px"}>
            <Box
              height="5px" // Set the height of the line
              width="60px" // Set the width of the line
              bg="green.700" // Set the background color to green
              borderRadius="lg"
              mt={"-20px"} // Optional: Add rounded edges
            />
            <Text>Conversation</Text>
          </Flex>
        );
      case "Lost":
        return (
          <Flex alignItems={"center"} gap={"3px"}>
            <Box
              height="5px" // Set the height of the line
              width="60px" // Set the width of the line
              bg="red.500" // Set the background color to green
              borderRadius="lg"
              mt={"-20px"} // Optional: Add rounded edges
            />
            <Text>Lost</Text>
          </Flex>
        );
      default:
        return <Badge>{stage}</Badge>;
    }
  };

    const stageColors = {
      Win: "green.500",
      "In Pipeline": "purple.500",
      Conversation: "green.700",
      Lost: "red.500",
    };

    // return (
    //   <Flex alignItems="center" gap={2}>
    //     <Box
    //       height="5px"
    //       width="60px"
    //       bg={ "gray.500"}
    //       borderRadius="lg"
    //     />
    //     <Text mt={3}></Text>
    //   </Flex>
    // )

  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Noto Sans', sans-serif`,
    },
  });
const onChangeEvent = (e) => {
  setCheck(e.target.checked);
};
 

  const sortedPipelines = selectedPriority
    ? pipelines.filter((project) => project.status.toLowerCase() === selectedPriority.toLowerCase())
    : sortPipelines(filteredPipelines, sortOption);

  return (
    <ChakraProvider theme={theme}>
      <Box p={5}>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Flex
            align="center"
            justifyContent="space-between"
            borderRadius="md"
            width="100%"
            p="3"
            gap={10}
            direction={{ base: "column", md: "row" }}
          >
            <CompanyProfile />
          </Flex>
        </Box>
        <Box bg={"white"}>
          <Flex
            justifyContent="space-between"
            mt={4}
            mb={4}
            flexWrap="wrap"
            gap={4}
            bg={"white"}
            p={"10px"}
          >
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              mt={"15px"}
              w={"100%"}
            >
              <Input
                placeholder="Search Pipeline"
                p={"5px"}
                w={{ base: "100%", md: "40%" }}
                mb={{ base: 2, md: 0 }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Flex>
          </Flex>
          <Addpipeline isOpen={isOpen} onClose={onClose} />
          <Divider />
          <Box overflowX="auto" bg={"white"}>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
              mb={4}
            >
              <Flex
                gap="7px"
                maxW={{ base: "100%", md: "30%" }}
                direction={{ base: "column", md: "row" }}
              >
                <Select
                  placeholder="Sort"
                  fontSize={"14px"}
                  w="100%"
                  h={"31px"}
                  mt={{ base: 2, md: 0 }}
                  p="10px"
                  onChange={(e) => setSortOption(e.target.value)}
                >
                  <option value="name">Name</option>
                  <option value="deals">Deals</option>
                </Select>
                <Flex w={"100%"} mt="5px">
                  <Select placeholder="Export" w="100%" fontSize={"14px"}>
                    <option value="name">Name</option>
                    <option value="deals">Deals</option>
                  </Select>
                </Flex>
              </Flex>

              <Flex mt={{ base: 2, md: "5px" }} gap={"5px"}>
                <Button
                  variant="outline"
                  w={"168px"}
                  h={"40px"}
                  p={"1px"}
                  mr={2}
                  bg={"#F3EDFF"}
                  color={"#4A00E5"}
                  fontSize={"13px"}
                >
                  Manage Columns
                </Button>

                <Select
                  placeholder="Filter"
                  onChange={(e) => setSelectedPriority(e.target.value)}
                  w="120px"
                >
                  <option value="Active">Active</option>
                  <option value="InActive">Inactive</option>
                </Select>

                <Button
                  colorScheme="red"
                  w="133px"
                  h={"38px"}
                  mr={"5px"}
                  onClick={onOpen}
                  fontSize={"14px"}
                >
                  Add Pipeline
                </Button>
              </Flex>
            </Flex>
            <Table variant="simple" border="1px solid" borderColor="gray.200">
    <Thead  bg="#E8E8E8">
                <Tr>
                  <Th
                    w={"98px"}
                    h={"19px"}
                    fontWeight={"500"}
                    fontSize={"14px"}
                  >
                    <input
                      type="checkbox"
                      checked={check}
                      onChange={(e) => onChangeEvent(e)}
                    />
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Pipeline Name
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Total Deal Value
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    No of Deals
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Stages
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Created Date
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Status
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {sortedPipelines &&
                  sortedPipelines.map((pipeline, index) => (
                    <Tr key={index}>
                      <Td>
                        <input
                          type="checkbox"
                          checked={check}
                        />
                      </Td>
                      <Td
                        w={"35px"}
                        h={"19px"}
                        fontWeight={"550"}
                        fontSize={"14px"}
                      >
                        {pipeline.name}
                      </Td>
                      <Td
                        w={"98px"}
                        h={"19px"}
                        fontWeight={"400"}
                        fontSize={"14px"}
                      >
                        {pipeline.value}
                      </Td>
                      <Td
                        w={"98px"}
                        h={"19px"}
                        fontWeight={"400"}
                        fontSize={"14px"}
                      >
                        {pipeline.deals}
                      </Td>
                      <Td
                        w={"98px"}
                        h={"19px"}
                        fontWeight={"400"}
                        fontSize={"14px"}
                      >
                        {getStageBadge(pipeline.stage)}
                      </Td>
                      <Td
                        w={"98px"}
                        h={"19px"}
                        fontWeight={"400"}
                        fontSize={"14px"}
                      >
                        {pipeline.date}
                      </Td>
                      <Td
                        w={"98px"}
                        h={"19px"}
                        fontWeight={"400"}
                        fontSize={"14px"}
                      >
                        {getStatusBadge(pipeline.status)}
                      </Td>
                      <Td
                        w={"98px"}
                        h={"19px"}
                        fontWeight={"400"}
                        fontSize={"14px"}
                      >
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<FiMoreVertical />}
                            variant="outline"
                          />
                          <MenuList>
                            <MenuItem>Edit</MenuItem>
                            <MenuItem>Delete</MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>

            <Flex
              justify="space-between"
              align="center"
              mt={4}
              w={"100%"}
              p={"7px"}
            >
              <HStack gap={"5px"}>
                <Text>Show </Text>
                <Select
                  placeholder="10"
                  w="73px"
                  h="38px"
                  mt={"-15px"}
                  fontSize={"14px"}
                ></Select>
                <Text>entries</Text>
              </HStack>
              <HStack>
                <Button
                  colorScheme="white"
                  color="#6F6F6F"
                  onClick={() => handlePageChange(-1)}
                  disabled={currentPage === 1}
                  leftIcon={<ChevronLeftIcon />}
                >
                  Prev
                </Button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <Button
                    key={index + 1}
                    onClick={() => setCurrentPage(index + 1)}
                    colorScheme={currentPage === index + 1 ? "blue" : "gray"}
                  >
                    {index + 1}
                  </Button>
                ))}
                <Button
                  colorScheme="white"
                  color="#6F6F6F"
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === totalPages}
                  rightIcon={<ChevronRightIcon />}
                >
                  Next
                </Button>
              </HStack>
            </Flex>
          </Box>

          {/* Pagination
          <Flex
            justify="space-between"
            align="center"
            mt={4}
            w={"100%"}
            flexDirection={{ base: "column", md: "row" }}
            p={"7px"}
          >
            <HStack gap={"5px"}>
              <Text>Show </Text>
              <Select
                placeholder="10"
                w="73px"
                h="38px"
                mt={"-15px"}
                fontSize={"14px"}
              ></Select>
              <Text>entries</Text>
            </HStack>
            <HStack>
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(-1)}
                disabled={currentPage === 1}
                leftIcon={<ChevronLeftIcon />}
              >
                Prev
              </Button>
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index + 1}
                  onClick={() => setCurrentPage(index + 1)}
                  colorScheme={currentPage === index + 1 ? "blue" : "gray"}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === totalPages}
                rightIcon={<ChevronRightIcon />}
              >
                Next
              </Button>
            </HStack>
          </Flex> */}
        </Box>
      </Box>

      {/* Add Pipeline Modal */}
      <Addpipeline isOpen={isOpen} onClose={onClose} />
    </ChakraProvider>
  );
};

export default PipelineTable;
