import React, { useState } from "react";
import {
  Box,
  VStack,
  HStack,
  Button,
  Image,
  Text,
  AspectRatio,
} from "@chakra-ui/react";

const SideFileRepo = () => {
  const [activeTab, setActiveTab] = useState("images"); // Active tab state

  // Sample data for images and videos
  const files = {
    images: [
      "https://via.placeholder.com/150",
      "https://via.placeholder.com/200",
      "https://via.placeholder.com/250",
    ],
    videos: [
      "https://www.w3schools.com/html/mov_bbb.mp4",
      "https://samplelib.com/lib/preview/mp4/sample-5s.mp4",
    ],
  };

  return (
    <Box p={6}  mx="auto" borderWidth="1px" borderRadius="lg" shadow="md">
    <Box w="100%" p={4} borderRight="1px solid #e2e8f0" h="50vh" overflowY="auto">
      {/* Tab Buttons */}
      <HStack spacing={4} mb={4}>
        <Button
          colorScheme={activeTab === "images" ? "blue" : "gray"}
          onClick={() => setActiveTab("images")}
        >
          Images
        </Button>
        <Button
          colorScheme={activeTab === "videos" ? "blue" : "gray"}
          onClick={() => setActiveTab("videos")}
        >
          Videos
        </Button>
      </HStack>

      {/* Content Area */}
      <VStack align="stretch" spacing={4}>
        {activeTab === "images" &&
          files.images.map((src, index) => (
            <Box key={index} borderRadius="md" overflow="hidden" shadow="sm">
              <Image src={src} alt={`Image ${index + 1}`} borderRadius="md" />
              <Text textAlign="center" mt={2} fontSize="sm" color="gray.500">
                Image {index + 1}
              </Text>
            </Box>
          ))}

        {activeTab === "videos" &&
          files.videos.map((src, index) => (
            <Box key={index} borderRadius="md" overflow="hidden" shadow="sm">
              <AspectRatio ratio={16 / 9}>
                <video src={src} controls />
              </AspectRatio>
              <Text textAlign="center" mt={2} fontSize="sm" color="gray.500">
                Video {index + 1}
              </Text>
            </Box>
          ))}
      </VStack>
      </Box>
    </Box>
  );
};

export default SideFileRepo;
