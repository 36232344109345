import React from "react";
import Chart from "react-apexcharts";
import {
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const LostLeadsChart = () => {
  const options = {
    chart: {
      type: "bar",
      height: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    colors: ["#FF4560"], // Red color for the chart
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Conversation", "Follow Up", "Inpipeline"],
    },
    title: {
      text: "",
      align: "left",
    },
  };

  const series = [
    {
      name: "Lost Leads",
      data: [450, 300, 480],
    },
  ];

  return (
    <Box p={4} bg={"white"} w={'auto'}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Heading
          fontSize={"18px"}
          fontWeight={"700"}
          color="#262A2A"
          mt={"4px"}
          w={"150px"}
        >
          Lost Leads Stage
        </Heading>
        <Flex gap={"6px"}>
          <Menu>
            <MenuButton
              w="auto"
              as={Button}
              fontSize="12px"
              fontWeight="400"
              textAlign={"center"}
              variant="outline"
              rightIcon={<ChevronDownIcon />}
            >
              Marketing pipeline
            </MenuButton>
            <MenuList>
              <MenuItem>Sales</MenuItem>
              <MenuItem>All</MenuItem>
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton
              w="auto"
              as={Button}
              fontSize="12px"
              fontWeight="400"
              textAlign={"center"}
              variant="outline"
              rightIcon={<ChevronDownIcon />}
            >
              Last 30 Days
            </MenuButton>
            <MenuList>
              <MenuItem>Last 7 Days</MenuItem>
              <MenuItem>Last 30 Days</MenuItem>
              <MenuItem>All Time</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      <Chart options={options} series={series} type="bar" height={350} />
    </Box>
  );
};

export default LostLeadsChart;
