import React, { useState } from 'react';
import Styles from './Sidebar.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Text, Flex, Divider, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { FaBars } from 'react-icons/fa';
import { GoHome } from "react-icons/go";
import { RxDashboard } from "react-icons/rx";
import { BsClipboardCheck } from 'react-icons/bs';
import { BiPulse } from 'react-icons/bi';
import { CgBox } from "react-icons/cg";
import { GrGroup } from "react-icons/gr";
import { BsGraphUpArrow } from "react-icons/bs";
import { BsChatSquare } from "react-icons/bs";
import { IoSettingsOutline ,IoDocumentTextOutline} from "react-icons/io5";
import { CiSquareQuestion } from "react-icons/ci";
import { FcDownload } from "react-icons/fc";
import { TbBasketDollar } from "react-icons/tb";
import { IoGiftSharp } from "react-icons/io5";
import { IoMdLogOut } from "react-icons/io";
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaBookOpen, FaRegUser } from 'react-icons/fa';
import {AiOutlineCluster} from "react-icons/ai" 
import { AiTwotoneCalendar } from "react-icons/ai";
import { MdOutlineGroups } from "react-icons/md";
import { BiNotepad } from "react-icons/bi";
import { FaTasks } from "react-icons/fa";
import { RiHandCoinLine } from "react-icons/ri";
import { CiUser } from "react-icons/ci";
import { PiBookOpen } from "react-icons/pi";
import { LiaFileContractSolid } from "react-icons/lia";
import { CiCreditCard1 } from "react-icons/ci";
import { CgNotes } from "react-icons/cg";
// import { BsGraphUpArrow } from "react-icons/bs";
import { CiSettings } from "react-icons/ci";
// import { LuContact2 } from "react-icons/lu";
import { PiBuildingApartment } from "react-icons/pi";
import { TbMedal } from "react-icons/tb";
// import { LuFileBarChart2 } from "react-icons/lu";
import { TfiNotepad } from "react-icons/tfi";
import { MdOutlineBarChart } from "react-icons/md";
import { BsGraphDown } from "react-icons/bs";

const Sidebar = ({ isCollapsed, onToggle}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useBreakpointValue({ base: false, md: true });

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/signin');
  };

  const getActiveStyle = (path) => {
    return location.pathname === path
      ? { backgroundColor: '#F5F5F5', borderLeft: '4px solid red', fontWeight: '500' }
      : {};
  };

  return (
    <Box
      position="fixed"
      top="70"
      left="0"
      width={{
        base: isCollapsed ? "12%" : "12%",
        md: isCollapsed ? "6%" : "12%",
      }}
      height="90vh"
      bg="white"
      boxShadow="1px 0px 10px lightgray"
      padding={{ base: "5px", md: "20px" }}
      overflowY="auto"
      zIndex="1000"
      className={Styles?.scrollbar}
    >
      <Flex align="center">
        <IconButton
          aria-label="Toggle Sidebar"
          icon={<FaBars />}
          onClick={onToggle}
          variant="ghost"
          mr={2}
        />
      </Flex>
      <Flex direction="column" as="nav">
        {menuItems.map(({ path, icon, label }) => (
          <Flex
            key={label}
            style={getActiveStyle(path)}
            p="10px"
            cursor="pointer"
            borderRadius="10px"
            alignItems="center"
            onClick={() => navigate(path)}
            _hover={{ backgroundColor: "#F5F5F5" }}
            mt={"3px"}
          >
            <Box mr={"12px"}>{icon}</Box>
            {/* Conditionally render the label based on the state */}
            {!isCollapsed && (
              <Text
                className={Styles.textBottomMargin}
                display={{ base: "none", md: "block" }}
                fontSize="16px"
              >
                {label}
              </Text>
            )}
          </Flex>
        ))}
        {/* <Text className={Styles?.textBottomMargin} mt="5px" fontWeight="500" display={{ base: "none", md: "block" }}>
          Groups
        </Text> */}
        {/* {groupItems.map(({ path, icon, label }) => (
          <Flex
            key={label}
            style={getActiveStyle(path)}
            p="8px"
            cursor="pointer"
            borderRadius="10px"
            alignItems="center"
            onClick={() => navigate(path)}
            _hover={{ backgroundColor: "#F5F5F5" }}
            mt="3px"
          >
            <Box mr={"12px"}>{icon}</Box>
            {/* Conditionally render the label based on the state 
            {!isCollapsed && (
              <Text className={Styles.textBottomMargin} display={{ base: "none", md: "block" }} fontSize="14px">
                {label}
              </Text>
            )}
          </Flex>
        ))} */}
        {/* <Divider mt="2px" />
        <Flex
          mt="2px"
          p="8px"
          cursor="pointer"
          borderRadius="10px"
          alignItems="center"
          onClick={handleLogout}
          _hover={{ backgroundColor: "#F5F5F5" }}
        >
          <Box mr={"12px"}><IoMdLogOut size={20} color='red'/></Box>
          {!isCollapsed && (
            <Text className={Styles.textBottomMargin} display={{ base: "none", md: "block" }} fontSize="14px">
              Logout
            </Text>
          )}
        </Flex>*/}
      </Flex>
    </Box>
  );
};

const menuItems = [
  { path: "/dashboard", icon: <GoHome size={20} />, label: "Dashboard" },
  // { path: '/dashboard', icon: <RxDashboard size={18}/>, label: 'Dashboard' },
  { path: "/contacts", icon: <TbMedal size={18} />, label: "Contacts" },
  {
    path: "/company",
    icon: <PiBuildingApartment size={18} />,
    label: "Companies",
  },
  { path: "/deals", icon: <TbMedal size={20} />, label: "Deals" },
  {
    path: "/leads",
    icon: (
      <Box border="1px" borderRadius="md" mt="1" display="inline-flex">
        {" "}
        <BiPulse size={18} />
      </Box>
    ),
    label: "Leads",
  },
  { path: "/pipeline", icon: <TfiNotepad size={18} />, label: "Pipeline" },
  { path: "/campaigns", icon: <TfiNotepad size={18} />, label: "Campaigns" },
  {
    path: "/social-media-Integration",
    icon: <MdOutlineBarChart size={18} />,
    label: "Integration",
  },
  { path: "/project", icon: <MdOutlineGroups size={18} />, label: "Projects" },
  {
    path: "/sessions",
    icon: <BiNotepad size={20} />,
    label: "Session & Events",
  },
  { path: "/tasks", icon: <FaTasks size={20} />, label: "Tasks" },
  {
    path: "/proposals",
    icon: <RiHandCoinLine size={20} />,
    label: "Proposals",
  },
  { path: "/user-management", icon: <CiUser size={20} />, label: "Users" },
  { path: "/wisdom", icon: <PiBookOpen size={20} />, label: "Wisdom & Tab" },
  {
    path: "/contracts",
    icon: <LiaFileContractSolid size={20} />,
    label: "Contracts",
  },
  {
    path: "/estimations",
    icon: <BsGraphDown size={20} />,
    label: "Estimations",
  },
  { path: "/invoice", icon: <CgNotes size={20} />, label: "Invoices" },
  { path: "/payments", icon: <CiCreditCard1 size={20} />, label: "Payments" },
  {
    path: "/analytics",
    icon: <BsGraphUpArrow size={20} />,
    label: "Analytics",
  },
  {
    path: "/reports",
    icon: <IoDocumentTextOutline size={22} />,
    label: "Report",
  },
  {
    path: "/sequences",
    icon: <IoDocumentTextOutline size={22} />,
    label: "Sequences",
  },
  { path: "/SettingsPage", icon: <CiSettings size={22} />, label: "Settings" },
  { path: "/support", icon: <CiSquareQuestion size={22} />, label: "Support" },
];

const groupItems = [
  { path: '/#', icon: <FcDownload size={18}/>, label: 'Downloads' },
  { path: '/Billing', icon: <TbBasketDollar size={20} color='#2E8B57'/>, label: 'Billings' },
  { path: '/#', icon: <IoGiftSharp size={20} color='#750080 '/>, label: 'Gifts' },
];

export default Sidebar;
