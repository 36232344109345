import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Textarea,
  ChakraProvider,
  extendTheme,
  Flex,
} from "@chakra-ui/react";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "@fontsource/poppins";

const AddContractModal = ({ isOpen, onClose }) => {
  const [subject, setSubject] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [openTill, setOpenTill] = useState(null);
  const [client, setClient] = useState("");
  const [contactValue, setContactValue] = useState("");
  const [description, setDescription] = useState("");
  const [deals, setDeals] = useState("");
  const [attachment, setAttachment] = useState(null);
  const theme = extendTheme({
    fonts: {
      heading: "Poppins, sans-serif",
      body: "Poppins, sans-serif",
      mono: "Poppins, sans-serif",
    },
  });

  const handleFileChange = (event) => {
    setAttachment(event.target.files[0]);
  };

  const handleCreate = () => {
    // Handle form submission here
    onClose();
  };


 return (
   <ChakraProvider theme={theme}>
     <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={"lg"}>
       <DrawerOverlay />
       <DrawerContent>
         <DrawerCloseButton />
         <DrawerHeader fontWeight={"400"} fontSize={"20px"} lineHeight={"37px"}>
           Add New Contract
         </DrawerHeader>
         <DrawerBody>
           <FormControl isRequired>
             <FormLabel
               fontWeight={"400"}
               fontSize={"15px"}
               lineHeight={"30px"}
             >
               Subject
             </FormLabel>
             <Input
               placeholder="Enter Subject"
               value={subject}
               onChange={(e) => setSubject(e.target.value)}
             />
           </FormControl>
           <Flex>
             <FormControl isRequired mt={4}>
               <FormLabel
                 fontWeight={"400"}
                 fontSize={"15px"}
                 lineHeight={"30px"}
               >
                 Due Date
               </FormLabel>
               <DatePicker
                 selected={dueDate}
                 onChange={(date) => setDueDate(date)}
                 customInput={<Input />}
               />
             </FormControl>

           <FormControl isRequired mt={4}>
             <FormLabel
               fontWeight={"400"}
               fontSize={"15px"}
               lineHeight={"30px"}
             >
               Open Till
             </FormLabel>
             <DatePicker
               selected={openTill}
               onChange={(date) => setOpenTill(date)}
               customInput={<Input />}
             />
           </FormControl>
           </Flex>

           <FormControl isRequired mt={4}>
             <FormLabel
               fontWeight={"400"}
               fontSize={"15px"}
               lineHeight={"30px"}
             >
               Client
             </FormLabel>
             <Input
               placeholder="Enter Client"
               value={client}
               onChange={(e) => setClient(e.target.value)}
             />
           </FormControl>
           <FormControl isRequired mt={4}>
             <FormLabel
               fontWeight={"400"}
               fontSize={"15px"}
               lineHeight={"30px"}
             >
               Contact Value
             </FormLabel>
             <Input
               placeholder="Enter Contact Value"
               value={contactValue}
               onChange={(e) => setContactValue(e.target.value)}
             />
           </FormControl>
           <FormControl isRequired mt={4}>
             <FormLabel
               fontWeight={"400"}
               fontSize={"15px"}
               lineHeight={"30px"}
             >
               Description
             </FormLabel>
             <Textarea
               placeholder="Enter Description"
               value={description}
               onChange={(e) => setDescription(e.target.value)}
             />
           </FormControl>
           <FormControl isRequired mt={4}>
             <FormLabel
               fontWeight={"400"}
               fontSize={"15px"}
               lineHeight={"30px"}
             >
               Deals
             </FormLabel>
             <Select
               placeholder="Select Deal"
               value={deals}
               onChange={(e) => setDeals(e.target.value)}
             >
               <option value="deal1">Deal 1</option>
               <option value="deal2">Deal 2</option>
             </Select>
           </FormControl>
           <FormControl isRequired mt={4}>
             <FormLabel
               fontWeight={"400"}
               fontSize={"15px"}
               lineHeight={"30px"}
             >
               Attachment
             </FormLabel>
             <Input type="file" onChange={handleFileChange} />
           </FormControl>
         </DrawerBody>
         <DrawerFooter>
           <Button colorScheme="red" mr={3} onClick={handleCreate}>
             Create
           </Button>
           <Button variant="outline" onClick={onClose}>
             Cancel
           </Button>
         </DrawerFooter>
       </DrawerContent>
     </Drawer>
   </ChakraProvider>
 );
};


export default AddContractModal