import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Button,
  Badge,
  Avatar,
  Input,
  HStack,
  VStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
  Flex,
  useBreakpointValue,
  useDisclosure,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import "@fontsource/noto-sans";
import { FaEllipsisV } from "react-icons/fa";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  AiOutlineDownload,
  AiOutlineFilter,
  AiOutlineColumnHeight,
} from "react-icons/ai";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import { FiFilter } from "react-icons/fi";
import companyicon from "../Dashboard/icons/companyicon.png";

const DealsReport = () => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const leadManagementData = [
    {
      id: 1,
      leadName: "John Doe",
      companyName: "ABC Corp",
      phone: "123-456-7890",
      email: "john.doe@abccorp.com",
      leadStatus: "Success",
      createdDate: "25 Sep 2023",
      leadOwner: "Alice Johnson",
      location: "New York, USA",
      avatar: companyicon,
    },
    {
      id: 2,
      leadName: "Jane Smith",
      companyName: "XYZ Inc",
      phone: "098-765-4321",
      email: "jane.smith@xyzinc.com",
      leadStatus: "Pending",
      createdDate: "03 Oct 2023",
      location: "New York, USA",
      avatar: companyicon,
      leadOwner: "Bob Lee",
    },
    // Add more lead records as needed
  ];

  const filteredData = leadManagementData.filter((lead) => true);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const barChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        barSpacing: 10,
        endingShape: "rounded",
      },
    },
    colors: ["#28a745", "#dc3545"],
    dataLabels: { enabled: false },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    yaxis: {
      title: { text: "" },
    },
    legend: { position: "top" },
  };

  const barChartData = [
    {
      name: "Won Deals",
      data: [100, 80, 100, 90, 85, 100, 95, 85, 90],
    },
    {
      name: "Lost Deals",
      data: [40, 50, 45, 40, 50, 45, 50, 40, 45],
    },
  ];
   const theme = extendTheme({
     fonts: {
       heading: `'Noto Sans', sans-serif`,
       body: `'Noto Sans', sans-serif`,
     },
   });

  const doughnutChartOptions = {
    chart: { type: "donut" },
    labels: ["Campaign", "Google", "Referrals", "Paid Social"],
    legend: { position: "bottom" },
  };

  const doughnutChartData = [44, 58, 41, 17];

  const LeadManagementTable = ({ data }) => (
    <Table variant="simple" border="1px solid" borderColor="gray.200">
      <Thead>
        <Tr bg={"#E8E8E8"}>
          <Th>
            <Checkbox />
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Lead Name
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Company Name
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Phone
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Email
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Lead Status
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Created Date
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Lead Owner
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Action
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((lead) => (
          <Tr key={lead.id}>
            <Td>
              <Checkbox />
            </Td>
            <Td>{lead.leadName}</Td>
            <Td>
              <Flex
                alignItems="center"
                h={"50px"}
                lineHeight={"2px"}
                w={"150px"}
              >
                <Avatar size="sm" name={lead.name} mr={2} />
                <Flex mt={"15px"} flexDirection={"column"}>
                  <Text fontWeight="600" fontSize="14px">
                    {lead.companyName}
                  </Text>
                  <Text fontSize="12px">{lead.location}</Text>
                </Flex>
              </Flex>
            </Td>
            <Td>{lead.phone}</Td>
            <Td>{lead.email}</Td>
            <Td>
              <Badge colorScheme="orange">{lead.leadStatus}</Badge>
            </Td>
            <Td>{lead.createdDate}</Td>
            <Td>{lead.leadOwner}</Td>
            <Td>
              <IconButton icon={<FaEllipsisV />} variant="ghost" />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <ChakraProvider theme={theme}>
      <Box
        p={5}
        borderWidth="1px"
        borderRadius="md"
        bg="white"
        overflowX="auto"
      >
        {/* Header Section */}
        <HStack
          justifyContent="space-between"
          mb="6"
          alignItems="center"
          // flexdirection={{ base: "column", sm: "column" }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Input placeholder="Search Leads" w={{ base: "full", sm: "300px" }} />
          <Button
            colorScheme="red"
            leftIcon={<AiOutlineDownload />}
            mt={{ base: 4, sm: 0 }}
          >
            Download Report
          </Button>
        </HStack>

        {/* Charts Section */}
        <Flex direction={{ base: "column", lg: "row" }} gap={6} mb="6">
          <Box
            w={{ base: "100%", lg: "50%" }}
            p={4}
            borderWidth="1px"
            borderRadius="md"
            bg="white"
          >
            <Text as="h2" fontWeight="500" fontSize="18px" mb={2}>
              Deals By Year
            </Text>
            <Chart
              options={barChartOptions}
              series={barChartData}
              type="bar"
              height={350}
            />
          </Box>
          <Box
            w={{ base: "100%", lg: "50%" }}
            p={5}
            borderWidth="1px"
            borderRadius="md"
            bg="white"
          >
            <Text as="h2" fontWeight="500" fontSize="18px" mb={2}>
              Deals By Source
            </Text>
            <Chart
              options={doughnutChartOptions}
              series={doughnutChartData}
              type="donut"
              height={350}
            />
          </Box>
        </Flex>

        <Box display="flex" justifyContent="space-between" mb={4}>
          <Flex
            gap="7px"
            maxW={{ base: "100%", md: "30%" }}
            direction={{ base: "column", md: "row" }}
          >
            <Select
              placeholder="Sort"
              w={{ base: "100%", md: "30%" }}
              mt={{ base: 2, md: 0 }}
              fontWeight={"bold"}
              fontSize={"14px"}
              lineHeight={"20px"}
              p="7px"
            >
              <option value="date">Date</option>
              <option value="amount">Amount</option>
            </Select>
            <Flex mt="5px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
                customInput={<Input />}
              />
            </Flex>
          </Flex>

          <Flex mt={{ base: 2, md: 0 }} gap={"5px"} mr={"3px"}>
            <Button
              variant="outline"
              mr={2}
              bg={"#F3EDFF"}
              color={"#4A00E5"}
              fontSize={"13px"}
              fontWeight={"600"}
            >
              Manage Columns
            </Button>
            <Button variant="outline" fontWeight={"500"} fontSize={"14px"}>
              Filter
            </Button>
          </Flex>
        </Box>

        <LeadManagementTable data={paginatedData} />

        {/* Pagination Section */}
        <HStack justifyContent="space-between" mt={4} alignItems="center">
          <HStack spacing={2}>
            <Box color="#6F6F6F">Show</Box>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              w="80px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
            <Box color="#6F6F6F">entries</Box>
          </HStack>

          <HStack spacing={2}>
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(-1)}
              leftIcon={<ChevronLeftIcon />}
              disabled={currentPage === 1}
            >
              Prev
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                colorScheme={currentPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(1)}
              rightIcon={<ChevronRightIcon />}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </HStack>
        </HStack>
      </Box>
    </ChakraProvider>
  );
};

export default DealsReport;
