import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  HStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Text,
  IconButton,
  Flex,
  Checkbox,
  Avatar,
  Badge,
} from "@chakra-ui/react";
import {
  AiOutlineDownload,
  AiOutlineFilter,
  AiOutlineColumnHeight,
} from "react-icons/ai";
import { Doughnut } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
import { ChevronLeftIcon, ChevronRightIcon, StarIcon } from "@chakra-ui/icons";

const TaskReport = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const barData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Leads",
        data: [100, 120, 300, 200, 450, 320, 230, 340, 180, 250, 300, 500],
        backgroundColor: "#00B5D8",
      },
    ],
  };

  const options = {
    chart: {
      id: "basic-line-chart",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Values",
      },
    },
    stroke: {
      curve: "stepline",
    },
    colors: ["#FF4500"],
  };

  const doughnutData = {
    labels: ["Campaign", "Google", "Referrals", "Paid Social"],
    datasets: [
      {
        data: [44, 58, 41, 17],
        backgroundColor: ["#4299E1", "#48BB78", "#ED8936", "#D53F8C"],
      },
    ],
  };

  // Mock data for table rows
  const leadsData = [
    {
      leadName: "Collins",
      companyName: "NovaWave LLC",
      phone: "+1 8753544533",
      email: "robertson@example.com",
      leadStatus: "Closed",
      leadOwner: "Hendry",
      source: "Paid Social",
      createdDate: "25 Sep 2023, 01:22 pm",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

  // const [startDate, setStartDate] = useState(null);


const filteredData = leadsData.filter((lead) => true);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    const handlePageChange = (direction) => {
      setCurrentPage((prev) =>
        Math.max(1, Math.min(totalPages, prev + direction))
      );
    };

    const handleItemsPerPageChange = (event) => {
      setItemsPerPage(Number(event.target.value));
      setCurrentPage(1);
    };

    const tasks = [
      {
        name: "Add a form to Update Task",
        assignedTo: "Adrian Davies",
        priority: "High",
        dueDate: "25 Sep 2023",
        type: "Calls",
        status: "Inprogress",
        createdDate: "25 Sep 2023, 01:22 pm",
      },
      {
        name: "Make all strokes thinner",
        assignedTo: "Adrian Davies",
        priority: "Medium",
        dueDate: "29 Sep 2023",
        type: "Meeting",
        status: "Completed",
        createdDate: "29 Sep 2023, 04:15 pm",
      },
      {
        name: "Update original content",
        assignedTo: "Adrian Davies",
        priority: "High",
        dueDate: "05 Oct 2023",
        type: "Email",
        status: "Inprogress",
        createdDate: "04 Oct 2023, 10:18 am",
      },
      {
        name: "Use only component colours",
        assignedTo: "Adrian Davies",
        priority: "Medium",
        dueDate: "14 Oct 2023",
        type: "Meeting",
        status: "Completed",
        createdDate: "17 Oct 2023, 03:31 pm",
      },
      {
        name: "Add images to the cards section",
        assignedTo: "Adrian Davies",
        priority: "Low",
        dueDate: "15 Nov 2023",
        type: "Task",
        status: "Inprogress",
        createdDate: "24 Oct 2023, 09:14 pm",
      },
      {
        name: "Design description banner & landing page",
        assignedTo: "Adrian Davies",
        priority: "High",
        dueDate: "25 Nov 2023",
        type: "Calls",
        status: "Completed",
        createdDate: "08 Nov 2023, 09:56 am",
      },
      {
        name: "Make sure all the padding should be 24px",
        assignedTo: "Adrian Davies",
        priority: "Low",
        dueDate: "08 Dec 2023",
        type: "Email",
        status: "Completed",
        createdDate: "14 Nov 2023, 04:19 pm",
      },
      {
        name: "Use border radius as 5px or 10 px",
        assignedTo: "Adrian Davies",
        priority: "Medium",
        dueDate: "21 Dec 2023",
        type: "Task",
        status: "Completed",
        createdDate: "23 Nov 2023, 11:14 pm",
      },
      {
        name: "Use Grey scale colors as body color",
        assignedTo: "Adrian Davies",
        priority: "Medium",
        dueDate: "21 Dec 2023",
        type: "Meeting",
        status: "Inprogress",
        createdDate: "10 Dec 2023, 06:43 am",
      },
    ];

     const statusColors = {
       Inprogress: "orange",
       Completed: "green",
     };

     const priorityColors = {
       High: "red",
       Medium: "yellow",
       Low: "green",
     };



  return (
    <Box p={{ base: "4", md: "5" }}>
      {/* Search and Download Section */}
      <HStack
        justifyContent="space-between"
        mb="4"
        flexDirection={{ base: "column", sm: "row" }}
        spacing={{ base: 4, sm: 6 }}
      >
        <Input placeholder="Search Leads" w={{ base: "100%", sm: "250px" }} />
        <Button colorScheme="red" leftIcon={<AiOutlineDownload />}>
          Download Report
        </Button>
      </HStack>

      {/* Charts Section */}
      <HStack
        spacing={{ base: "4", sm: "6" }}
        mb="6"
        flexDirection={{ base: "column", md: "row" }}
      >
        {/* Task by Year Chart */}
        <Box
          width={{ base: "100%", md: "48%" }}
          p="4"
          borderWidth="1px"
          borderRadius="md"
          bg={"white"}
          mb={{ base: "4", md: "0" }}
        >
          <Flex justify="space-between" align="center">
            <Text
              as="h2"
              fontFamily="Poppins, sans-serif"
              fontWeight="500"
              fontSize={{ base: "16px", sm: "18px" }}
              lineHeight="27px"
            >
              Task by Year
            </Text>
            <Box border="1px solid #E8E8E8" padding="5px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
              />
            </Box>
          </Flex>
          <ReactApexChart
            options={options}
            series={barData.datasets}
            type="line"
            height={350}
          />
        </Box>

        {/* Task by Types Chart */}
        <Box
          width={{ base: "100%", md: "48%" }}
          p="4"
          borderWidth="1px"
          borderRadius="md"
          bg={"white"}
        >
          <Flex justify="space-between" align="center">
            <Text
              as="h2"
              fontFamily="Poppins, sans-serif"
              fontWeight="500"
              fontSize={{ base: "16px", sm: "18px" }}
              lineHeight="27px"
            >
              Task by Types
            </Text>
            <Box border="1px solid #E8E8E8" padding="5px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
              />
            </Box>
          </Flex>
          <Doughnut data={doughnutData} />
        </Box>
      </HStack>

      {/* Table Section */}
      {/* <HStack
        justifyContent="space-between"
        mb="4"
        flexDirection={{ base: "column", sm: "row" }}
      >
        <Select placeholder="Sort By" w={{ base: "100%", sm: "200px" }}>
          <option value="name">Lead Name</option>
          <option value="company">Company</option>
          <option value="status">Lead Status</option>
        </Select> */}
      {/* 
        <HStack spacing={4}>
          <IconButton
            icon={<AiOutlineColumnHeight />}
            aria-label="Manage Columns"
          />
          <IconButton icon={<AiOutlineFilter />} aria-label="Filter" />
        </HStack> */}
      {/* </HStack> */}

      {/* / Badge color schemes for status and priority */}

      {/* Leads Table */}
      <Box overflowX="auto">
        <Box overflowX="auto" bg={"white"}>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            mb={4}
          >
            <Flex
              gap="7px"
              maxW={{ base: "100%", md: "30%" }}
              direction={{ base: "column", md: "row" }}
            >
              <Select
                placeholder="Sort"
                w={{ base: "100%", md: "30%" }}
                mt={{ base: 2, md: 0 }}
                fontWeight={"bold"}
                fontSize={"14px"}
                lineHeight={"20px"}
                p="7px"
              >
                <option value="date">Date</option>
                <option value="amount">Amount</option>
              </Select>
              <Flex mt="5px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  customInput={<Input />}
                />
              </Flex>
            </Flex>

            <Flex mt={{ base: 2, md: 2 }} gap={"5px"} mr={"3px"}>
              <Button
                variant="outline"
                mr={2}
                bg={"#F3EDFF"}
                color={"#4A00E5"}
                fontSize={"13px"}
                fontWeight={"600"}
              >
                Manage Columns
              </Button>
              <Button variant="outline" fontWeight={"500"} fontSize={"14px"}>
                Filter
              </Button>
            </Flex>
          </Flex>
        </Box>
        <Box overflowX="auto" p={4}>
          <Table variant="simple" size="md">
            <Thead>
              <Tr bg={"#E8E8E8"}>
                <Th>
                  <Checkbox />
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Task Name
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Assigned To
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Priority
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Due Date
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Type
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Status
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Created Date
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {tasks.map((task, index) => (
                <Tr key={index}>
                  <Td>
                    <Checkbox />
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <IconButton
                        aria-label="Favorite"
                        icon={<StarIcon />}
                        size="xs"
                        variant="ghost"
                        colorScheme="yellow"
                        mr={2}
                      />
                      <Text fontWeight={"500"} fontSize={"14px"}>
                        {task.name}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex alignItems="center">
                      <Avatar size="sm" name={task.assignedTo} mr={2} />
                      <Text fontWeight={"400"} fontSize={"14px"}>
                        {task.assignedTo}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Badge colorScheme={priorityColors[task.priority]}>
                      {task.priority}
                    </Badge>
                  </Td>
                  <Td fontWeight={"400"} fontSize={"14px"}>
                    {task.dueDate}
                  </Td>
                  <Td fontWeight={"400"} fontSize={"14px"}>
                    {task.type}
                  </Td>
                  <Td>
                    <Badge colorScheme={statusColors[task.status]}>
                      {task.status}
                    </Badge>
                  </Td>
                  <Td fontWeight={"400"} fontSize={"14px"}>
                    {task.createdDate}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>

      <HStack justifyContent="space-between" mt={4} alignItems="center">
        <HStack spacing={2}>
          <Box color="#6F6F6F">Show</Box>
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            w="80px"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </Select>
          <Box color="#6F6F6F">entries</Box>
        </HStack>

        <HStack spacing={2}>
          <Button
            colorScheme="white"
            color="#6F6F6F"
            onClick={() => handlePageChange(-1)}
            leftIcon={<ChevronLeftIcon />}
            disabled={currentPage === 1}
          >
            Prev
          </Button>
          {Array.from({ length: totalPages }, (_, index) => (
            <Button
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              colorScheme={currentPage === index + 1 ? "blue" : "gray"}
            >
              {index + 1}
            </Button>
          ))}
          <Button
            colorScheme="white"
            color="#6F6F6F"
            onClick={() => handlePageChange(1)}
            rightIcon={<ChevronRightIcon />}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </HStack>
      </HStack>
    </Box>
  );
};

// Helper function for Tag color based on status
function getTagColor(status) {
  switch (status) {
    case "Closed":
      return "green";
    case "Not Contacted":
      return "blue";
    case "Lost":
      return "red";
    case "Contacted":
      return "yellow";
    default:
      return "gray";
  }
}

export default TaskReport;
