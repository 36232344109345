import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  AspectRatio,
  Image,
  HStack,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Text,
  Icon,
  IconButton,
  Avatar,
  Card,
} from "@chakra-ui/react";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { FiUploadCloud } from "react-icons/fi";
import { useDrop } from 'react-dnd';
import { useLocation } from "react-router-dom";
import axios from "axios"; // Import axios for making API requests
import { CallAPI } from "../../Services/service";
import endpoints from "../../Services/endpoint";
import { useAuthContext } from "../CommunityGroup/context/AuthContext";
import SideFileRepo from "./SideFilesRepo";
import ScheduleComponent from "./ScheduleComponent";
import { CloseIcon } from "@chakra-ui/icons";

const PostCreator = () => {
  const [postText, setPostText] = useState("");
  const [mediaFiles, setMediaFiles] = useState([]);
  const { platformTokens } = useAuthContext();
  const [taggedPeople, setTaggedPeople] = useState([]); // To store selected people
  const [suggestions, setSuggestions] = useState([]); // To store tag suggestions
  const [showSuggestions, setShowSuggestions] = useState(false); // Toggle suggestions visibility
  const [cursorPosition, setCursorPosition] = useState(null); // Track cursor position
  const { authUser } = useAuthContext(); 
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [activeTab, setActiveTab] = useState("images"); // Active tab state
  // Sample data for images and videos
  const [files,setFiles] = useState({ images: [ ], videos: []});
  const [draggedItem, setDraggedItem] = useState(null);
  const [droppedItems, setDroppedItems] = useState([]); // State to hold dropped items

  const handlePostTextChange = (e) => {
    const text = e.target.value;

    // Check if "@" is typed and show suggestions
    const match = text.match(/@(\w*)$/); // Match "@" followed by characters
    if (match) {
      const searchTerm = match[1];
      fetchTagSuggestions(searchTerm); // Fetch matching suggestions
      setShowSuggestions(true);
      setCursorPosition(e.target.selectionStart); // Save cursor position
    } else {
      setShowSuggestions(false); // Hide suggestions if no "@" detected
    }

    setPostText(text);
  };
 
  
useEffect(()=>{
  getFiles()
},[])
  
  const getFiles = async () => { 
    try {
      const res = await CallAPI(endpoints.getfilesfromaws, {id:authUser.id}, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      let fileData = res.data;
      const categorizedFiles = {
        images: fileData.filter((file) => file.fileType === "image"),
        videos: fileData.filter((file) => file.fileType === "video"),
      };
  
      setFiles(categorizedFiles);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  }

  const fetchTagSuggestions = async (searchTerm) => {
    // Mock data or API request to fetch people
    const mockData = [
      {
        name: "panchal ",
        profileUrl: "https://www.linkedin.com/in/shreenath-panchal/",
      },
      {
        name: "Kundan",
        profileUrl: "https://www.linkedin.com/in/kundankumar99/",
      },
    ];

    // Filter based on search term
    const filtered = mockData.filter((person) =>
      person.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setSuggestions(filtered);
  };


  const handleTagSelect = (person) => {
    const beforeCursor = postText.slice(0, cursorPosition).replace(/@\w*$/, "");
    const afterCursor = postText.slice(cursorPosition);

    const updatedText = `${beforeCursor}@${person.name} ${afterCursor}`;
    setPostText(updatedText);

    // Add the person to taggedPeople
    setTaggedPeople((prev) => [...prev, person]);

    // Hide suggestions
    setShowSuggestions(false);
  };

  const location = useLocation();
  const { state } = location; // Assuming state is an array of selected entities
// Handle submitting the post and calling the API
const handlePostSubmit = async () => {
  try {
    const taggedUrls = taggedPeople.map((person) => person.profileUrl);
    const contentToSend = postText.replace(/@\w+/g, "").trim();
    const date = Intl.DateTimeFormat("en-GB").format(selectedDate);

    // Iterate over each selected entity
    const postPromises = state?.selectedEntities?.map(async (entity) => {
      const formData = {
        postContent: contentToSend,
        entityId: entity.id,
        entityType: entity.type,
        token: platformTokens.LinkedIn,
        taggedUrls,
        date,
        time: selectedTime,
        mediaFiles,
      };

      // Call the appropriate API endpoint based on the entity type
      if (entity.type === "profile") {
        return await CallAPI(endpoints.createlinkedinpost, formData);
      } else if (entity.type === "organization") {
        return await CallAPI(
          endpoints.createlinkedinOrganisationpost,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
      }
    });

    // Wait for all API calls to complete
    const results = await Promise.all(postPromises);

    // Handle success
    console.log("All posts submitted successfully", results);
    alert("Posts submitted successfully! Check the console for details.");
  } catch (error) {
    console.error("Error submitting posts:", error);
    alert("There was an error submitting your posts.");
  }
};


  const handleRemoveVideo = (video) => {
    // Filter out the video from the state

    setDroppedItems((prevMediaFiles) =>
      prevMediaFiles.filter((file) => file !== video)
    );
    setMediaFiles((prevMediaFiles) =>
      prevMediaFiles.filter((file) => file !== video)
    );
  };
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) =>
      ["image/jpeg", "image/png", "video/mp4"].includes(file.type) &&
      file.size <= 10 * 1024 * 1024 // Max size 10MB
    );
    setMediaFiles((prev) => [...prev, ...validFiles]);
  };

  const handleDragStart = (e, id, type) => {
    // Serialize id and type into a single string
    const data = JSON.stringify({ id, type });
    e.dataTransfer.setData("text", data);
  };
  

  const handleDrop = (e) => {
    e.preventDefault();
    
    // Retrieve the data and parse it back to an object
    const data = e.dataTransfer.getData("text");
    const { id, type } = JSON.parse(data);
    
    // Convert id to a number (assuming IDs are numbers)
    const droppedId = parseInt(id, 10);
    
    // Check if the dropped ID exists in images or videos
    const imagid = files.images.find((el) => el.id === droppedId);
    const vidid = files.videos.find((el) => el.id === droppedId);
    
    // If an image was found, add it to the media files array
    if (imagid && type === 'image') {
      setMediaFiles((prev) => [...prev, imagid]);
    }
    // If a video was found, add it to the media files array
    if (vidid && type === 'video') {
      setMediaFiles((prev) => [...prev, vidid]);
    }
  };
  
  

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileChange3 = async (event) => {
    const validFileTypes = ["image/jpeg", "image/png", "video/mp4"];
    let formData = new FormData();
    // Loop through files if multiple files are selected
    Array.from(event.target.files).forEach((file) => {
      if (validFileTypes.includes(file.type)) {
        formData.append("files", file); 
      } else {
        console.error("Invalid file type:", file.type);
      }
    });
   
    formData.append("id",authUser.id)
    try {
      const res = await CallAPI(endpoints.uploadtoaws, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      getFiles()
      alert("Files Uploaded Successfully")
        } catch (error) {
      console.error('Error uploading files:', error);
    }

  };
  
  

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  console.log(mediaFiles)
  const Draggable = ({ id,type, children, onDragStart }) => {
    return (
      <div
        id={id}
        className="draggable"
        draggable="true"
        onDragStart={(e) => onDragStart(e, id,type)}
      >
        {children}
      </div>
    );
  };
  
  const DropZone = ({ onDrop, children }) => {
    return (
      <div
        onDragOver={(e) => e.preventDefault()} // To allow drop
        onDrop={(e) => onDrop(e)}
      >
        {children}
      </div>
    );
  };

  console.log(files)

  return (
    <Flex direction={{ base: "column", md: "row" }} // Column for small screens, row for large
    justify="space-between"
    align="start"
    gap={4}
    p={5}>
      <Box p={6} mx="auto" borderWidth="1px" borderRadius="lg" shadow="md"  w={{ base: "100%", md: "25%" }}>
        <Box
          w="100%"
          p={4}
          borderRight="1px solid #e2e8f0"
          h="60vh"
          overflowY="auto"
        >
          {/* Tab Buttons */}
          <HStack spacing={4} mb={4}>
            <Button
              colorScheme={activeTab === "images" ? "blue" : "gray"}
              onClick={() => setActiveTab("images")}
            >
              Images
            </Button>
            <Button
              colorScheme={activeTab === "videos" ? "blue" : "gray"}
              onClick={() => setActiveTab("videos")}
            >
              Videos
            </Button>
          </HStack>

          {/* Content Area */}
          <VStack align="stretch" spacing={4}>
            

            {activeTab === "images" &&
              files.images.map((src, index) => (
                <Draggable id={src.id}  type="image"   onDragStart={ handleDragStart} 
>
                <Box
                  key={index}
                  borderRadius="md"
                  overflow="hidden"
                  shadow="sm"
                >
                  <Image
                  h="100px" w="100%"
                    src={src.fileUrl}
                    alt={`Image ${src.fileUrl}`}
                    borderRadius="md"
                  />
                 
                </Box>
                </Draggable>
              ))}

            {activeTab === "videos" &&
              files.videos.map((src, index) => (
                <Draggable id={src.id}  type="video" onDragStart={handleDragStart}>
                <Box
                  key={index}
                  borderRadius="md"
                  overflow="hidden"
                  shadow="sm"
                >
                  <AspectRatio ratio={16 / 9}>
                    <video   h="100px" w="100%" src={src.fileUrl} controls />
                  </AspectRatio>
                  <Text
                    textAlign="center"
                    mt={2}
                    fontSize="sm"
                    color="gray.500"
                  >
                     {src.name}
                  </Text>
                </Box>
                </Draggable>
              ))}
          </VStack>
        </Box>
        <Flex justify="center" align="center"  >
        <Button colorScheme="blue" onClick={handleButtonClick}>
        <input
        type="file"
        id="fileInput"
        multiple
        style={{ display: "none" }}
        onChange={handleFileChange3}
      />upload</Button>
        </Flex>
      </Box>
      <Box
        borderWidth="1px"
        borderRadius="lg"
        p={5}
        shadow="md"
        maxW="600px"
        w={{ base: "100%", md: "40%" }}
      >
        <VStack spacing={4}>
          {/* Post Text Field */}
          <FormControl>
            <FormLabel>Post Text</FormLabel>
            {/* <Textarea
              placeholder="Enter post text"
              value={postText}
              onChange={(e) => setPostText(e.target.value)}
            /> */}

            <Box position="relative">
              <Textarea
                value={postText}
                onChange={handlePostTextChange}
                placeholder="Write your post and tag people with @..."
              />

              {showSuggestions && (
                <Box
                  position="absolute"
                  bg="white"
                  border="1px solid"
                  borderColor="gray.200"
                  mt="2"
                  zIndex="10"
                  width="full"
                >
                  {suggestions.map((person) => (
                    <Box
                      key={person.urn}
                      p="2"
                      cursor="pointer"
                      _hover={{ bg: "gray.100" }}
                      onClick={() => handleTagSelect(person)}
                    >
                      {person.name}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </FormControl>

          {/* File Upload Box */}
          <FormControl>
            <FormLabel>Add Images or a Video</FormLabel>
            <Box
              borderWidth="2px"
              borderStyle="dashed"
              borderRadius="md"
              p={5}
              textAlign="center"
              cursor="pointer"
              _hover={{ borderColor: "blue.500" }}
            >
             

              <>
                <Flex
                  height="full"
                  direction={{ base: "column", md: "row" }}
                  gap={6}
                >
                  {/* Drag and Drop Card */}
                  <DropZone onDrop={handleDrop}   onDragOver={handleDragOver} 
                  >
  <Card
    color="#6D31ED"
    width={{ base: "100%", sm: "100%", md: "100%" }} 
    height={{ base: "100px", sm: "150px", md: "250px" }} 
    display="flex"
    alignItems="center"
    justifyContent="center"
    border="1px dotted gray"
    position="relative"
    p={2}
    backgroundColor="#FFFFFF"
    onClick={() => document.getElementById("fileInput").click()} // Trigger file input
  >
    <Text
      fontSize={{ base: "2xl", md: "3xl" }}
      mb={1}
      cursor="pointer"
      _hover={{
        color: "#7D7D7D",
        transform: "scale(1.2)",
        transition: "all 0.2s ease-in-out",
      }}
    >
      <Icon as={FiUploadCloud} />
    </Text>
    <span
  style={{
    color: "gray",
    fontSize: "12px",
    textAlign: "center",
  }}
>
  Drag files here or <br />
  click to upload
</span>
    <Input
      type="file"
      id="fileInput"
      multiple
      onChange={handleFileChange}
      display="none"
    />
  </Card>
</DropZone>

                  {/* Video List Container */}
                  <Flex
                    direction="column"
                    flex="1"
                    height="full"
                    overflowY="auto"
                  >
                    <Flex
                      direction="row"
                      overflowY="auto"
                      flexWrap="wrap"
                      gap={6}
                      mt={1}
                      height="250px"
                      sx={{
                        "&::-webkit-scrollbar": {
                          width: "8px",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "#E2E8F0",
                          borderRadius: "8px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#A0AEC0",
                          borderRadius: "8px",
                          border: "2px solid #F7FAFC",
                        },
                        "&::-webkit-scrollbar-thumb:hover": {
                          backgroundColor: "#718096",
                        },

                        // For Firefox
                        scrollbarWidth: "thin",
                        scrollbarColor: "#A0AEC0 #E2E8F0",
                      }}
                    >
                      {mediaFiles.map((video, index) => {
                        return (
                          <Card
                            key={index}
                            color="black"
                            height="90px"
                            width={"40%"}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            border="1px solid #E2E8F0"
                            borderRadius="md"
                            backgroundColor="white"
                            boxShadow="sm"
                            transition="transform 0.2s ease-in-out"
                            _hover={{ transform: "scale(1.05)" }}
                          >
                            <IconButton
                              icon={<CloseIcon />}
                              aria-label="Remove video"
                              position="absolute"
                              top={1}
                              right={1}
                              size="xs"
                              onClick={() => handleRemoveVideo(video)}
                            />
                           <Box>
                           <Box
                  key={index}
                  borderRadius="md"
                  overflow="hidden"
                  shadow="sm"
                >
                {video.fileType ==="image"?  <Image
                    src={video.fileUrl}
                    alt={`${video?.fileUrl?.slice(-8)}`}
                    borderRadius="md"
                  /> :

<video
                    src={video.fileUrl}
                    alt={`${video?.fileUrl?.slice(-8)}`}
                    borderRadius="md"
                  /> }
                  </Box>
                           </Box>
                          </Card>
                        );
                      })}

                      {/* Summary Card - Added as the last item in the same Flex */}
                      <Card
                        height="90px"
                        width={"40%"}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        border="1px solid #E2E8F0"
                        borderRadius="md"
                        backgroundColor={
                          mediaFiles.length > 0
                            ? "#E2E8F0"
                            : "gray.300"
                        }
                        color="gray.700"
                        _hover={{
                          color: "black",
                          backgroundColor: "#CBD5E0",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // handleAddVideosToTarget();
                          // showNotification();
                        }}
                      >
                        <Text
                          fontSize={12}
                          fontWeight={700}
                          cursor={"pointer"}
                          color={"gray"}
                        >
                          {mediaFiles.length > 0 ? (
                            `Add More Items`
                          ) : (
                            "Add  Items"
                          )}
                        </Text>
                      </Card>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            </Box>
           
          </FormControl>

          {/* Additional Options */}

          {/* Submit Button */}
          <Button colorScheme="blue" onClick={handlePostSubmit} w="full">
            Submit Post
          </Button>
        </VStack>
      </Box>
      <Box borderWidth="1px"
    borderRadius="lg"
    p={5}
    shadow="md"
    w={{ base: "100%", md: "40%" }}>
      <ScheduleComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate}
  selectedTime ={selectedTime} setSelectedTime= {setSelectedTime}/>
     </Box>
    </Flex>
    
  );
};

export default PostCreator;
