import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Flex,
  Button,
  Avatar,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";

const companiesData = [
  {
    name: "NovaWaveLLC",
    email: "caroo3@example.com",
    phone: "+1 124547845",
    createdAt: "25 Sep 2023, 12:12 pm",
    logo: "https://via.placeholder.com/40/1e90ff/ffffff?text=N",
  },
  {
    name: "SilverHawk",
    email: "dercha@example.com",
    phone: "+1 478845447",
    createdAt: "27 Sep 2023, 11:23 pm",
    logo: "https://via.placeholder.com/40/ff6347/ffffff?text=S",
  },
  {
    name: "SummitPeak",
    email: "rael@example.com",
    phone: "+1 215544845",
    createdAt: "04 Oct 2023, 04:12 pm",
    logo: "https://via.placeholder.com/40/4682b4/ffffff?text=S",
  },
  {
    name: "RiverStone Ventur",
    email: "joe@example.com",
    phone: "+1 124547845",
    createdAt: "17 Oct 2023, 11:34 am",
    logo: "https://via.placeholder.com/40/daa520/ffffff?text=R",
  },
  {
    name: "CoastalStar Co.",
    email: "joe@example.com",
    phone: "+1 124547845",
    createdAt: "17 Oct 2023, 11:34 am",
    logo: "https://via.placeholder.com/40/20b2aa/ffffff?text=C",
  },
  {
    name: "Redwood Inc",
    email: "sharon@example.com",
    phone: "+1 466701256",
    createdAt: "15 Nov 2023, 07:26 pm",
    logo: "https://via.placeholder.com/40/b22222/ffffff?text=R",
  },
];

const CompaniesTable = () => {
  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Noto Sans', sans-serif`,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box p={4} bg={"white"} w={"660px"} borderRadius={"md"} boxShadow={"md"}>
        <Flex justify="space-between" align="center" mb={4}>
          <Text fontSize={"18px"} fontWeight={"700"} color="#262A2A">
            Recently Added Companies
          </Text>
          <HStack spacing={4}>
            <Menu>
              <MenuButton
                as={Button}
                fontSize="12px"
                fontWeight="400"
                variant="outline"
                rightIcon={<ChevronDownIcon />}
              >
                Last 30 Days
              </MenuButton>
              <MenuList>
                <MenuItem>Last 7 Days</MenuItem>
                <MenuItem>Last 30 Days</MenuItem>
                <MenuItem>All Time</MenuItem>
              </MenuList>
            </Menu>
            <Button colorScheme="red" size="sm">
              Add Company
            </Button>
          </HStack>
        </Flex>

        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th fontWeight={"500"} fontSize={"14px"}>
                Company Name
              </Th>
              <Th fontWeight={"500"} fontSize={"14px"}>
                Email
              </Th>
              <Th fontWeight={"500"} fontSize={"14px"}>
                Phone
              </Th>
              <Th fontWeight={"500"} fontSize={"14px"}>
                Created At
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {companiesData.map((company, index) => (
              <Tr key={index}>
                <Td>
                  <Flex alignItems={"center"}>
                    <Avatar size="sm" src={company.logo} mr={2} />
                    <Text fontWeight="600" fontSize="12px" noOfLines={1}>
                      {company.name}
                    </Text>
                  </Flex>
                </Td>
                <Td isTruncated>
                  {" "}
                  <Text fontWeight="400" fontSize="12px" noOfLines={1}>
                    {company.email}
                  </Text>
                </Td>
                <Td>
                  <Text fontWeight="400" fontSize="12px" noOfLines={1}>
                    {company.phone}
                  </Text>
                </Td>
                <Td>
                  <Text fontWeight="400" fontSize="12px" noOfLines={1}>
                    {company.createdAt}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </ChakraProvider>
  );
};

export default CompaniesTable;
