import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  Image,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  Input,
  Icon,
} from "@chakra-ui/react";
import CampaignTabs from "./CampaignTabs";
import CampaignCards from "./CampaignCards";
import CompanyProfile from "../Header/CompanyProfile";

const CompaignDashboard=() =>{
  const initialBudgetData = {
    filter: "All",
    spend: 200,
    remaining: 800,
    details: [
      { month: "June", amount: 3000, spent: 1800, remaining: 1200 },
      { month: "July", amount: 2000, spent: 1200, remaining: 800 },
      { month: "August", amount: 3000, spent: 1800, remaining: 1200 },
      { month: "September", amount: 3000, spent: 1800, remaining: 1200 },
      { month: "October", amount: 2000, spent: 1200, remaining: 800 },
      { month: "November", amount: 2000, spent: 1200, remaining: 800 },
    ],
  };

  const [budgetData, setBudgetData] = useState(initialBudgetData);

  const [leadsList, setLeadsList] = useState([
    { course: "Docker Course", leads: 15, status: "Hot", campaigns: 2 },
    { course: "AI is the future", leads: 19, status: "Cold", campaigns: 4 },
    { course: "Cloud AWS Webinar", leads: 8, status: "Hot", campaigns: 1 },
    {
      course: "Fullstack Demanding Tec",
      leads: 24,
      status: "Warm",
      campaigns: 3,
    },
    { course: "Node.js Course", leads: 32, status: "Cold", campaigns: 1 },
    { course: "React.js Course", leads: 52, status: "Hot", campaigns: 2 },
    { course: "Devops Cloud Course", leads: 36, status: "Warm", campaigns: 4 },
  ]);

  const [chartData, setChartData] = useState({
    labels: [
      "5K",
      "10K",
      "15K",
      "20K",
      "25K",
      "30K",
      "35K",
      "40K",
      "45K",
      "50K",
    ],
    datasets: [
      {
        label: "Campaign",
        data: [20, 40, 10, 80, 30, 90, 50, 70, 100, 60],
        borderColor: "#ff8d8d",
        backgroundColor: "#ffdddd",
        fill: true,
      },
    ],
  });

  const [viewAllLeads, setViewAllLeads] = useState(false);

  const handleBudgetFilterChange = (e) => {
    const filter = e.target.value;
    let updatedDetails;

    switch (filter) {
      case "Daily":
        updatedDetails = [
          { day: "1", amount: 100, spent: 30, remaining: 70 },
          { day: "6", amount: 130, spent: 80, remaining: 50 },
          { day: "2", amount: 100, spent: 60, remaining: 40 },
          { day: "3", amount: 120, spent: 80, remaining: 40 },
          { day: "4", amount: 100, spent: 60, remaining: 40 },
          { day: "5", amount: 110, spent: 80, remaining: 30 },
        ];
        break;
      case "Monthly":
        updatedDetails = [
          { month: "June", amount: 3000, spent: 1800, remaining: 1200 },
          { month: "July", amount: 2000, spent: 1200, remaining: 800 },
          { month: "August", amount: 3000, spent: 1800, remaining: 1200 },
          { month: "September", amount: 3000, spent: 1800, remaining: 1200 },
          { month: "October", amount: 2000, spent: 1200, remaining: 800 },
          { month: "November", amount: 2000, spent: 1200, remaining: 800 },
        ];
        break;
      case "Yearly":
        updatedDetails = [
          { year: "2020", amount: 24000, spent: 14400, remaining: 9600 },
          { year: "2021", amount: 36000, spent: 21600, remaining: 14400 },
          { year: "2022", amount: 24000, spent: 14400, remaining: 9600 },
          { year: "2023", amount: 36000, spent: 21600, remaining: 14400 },
          { year: "2024", amount: 24000, spent: 14400, remaining: 9600 },
          { year: "2025", amount: 36000, spent: 21600, remaining: 14400 },
        ];
        break;
      default:
        updatedDetails = initialBudgetData.details;
        break;
    }

    setBudgetData((prevData) => ({
      ...prevData,
      filter,
      details: updatedDetails,
    }));
  };

  const handleViewAllLeads = () => {
    setViewAllLeads(!viewAllLeads);
  };

  return (
    <>
      <Box width={"100%"} p={"10px 10px"}>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          flexWrap={"wrap"}
        >
          <Flex
            align="center"
            justifyContent="space-between"
            p={4}
            borderRadius="md"
            width="100%"
            gap={10}
            direction={{ base: "column", md: "row" }} 
          >
            {/* Left side (Client info) */}
            <CompanyProfile />
          </Flex>

           {/* ********************************** CompainCards data*************************************** */}

          <CampaignCards />

                     {/* ********************************** CompainCards Tbs which can switch*************************************** */}


          <CampaignTabs />
        </Box>
      </Box>
      {/* </Box> */}
    </>
  );
}

export default CompaignDashboard