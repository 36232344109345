import React from 'react';
// import { Box, Heading, Text, Checkbox, Flex, useBreakpointValue,Select } from '@chakra-ui/react';
import { px } from 'framer-motion';
import {
  Box,
  Checkbox,
  Text,
  Heading,
  Divider,
  VStack,
  HStack,
  Switch,
  Select,
  Spacer,
  useBreakpointValue,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";


const NotificationSettings = () => {
  const sectionTitleColor = useColorModeValue("gray.700", "gray.300");
  const descriptionColor = useColorModeValue("gray.500", "gray.400");
  const checkboxColorScheme = "red"; // Matches the checkbox color in the image

  return (
    <Box py={10}>
      <Text fontSize="2xl" fontWeight="bold" mb={2}>
        Notification Settings
      </Text>
      <Text color="gray.500" mb={6}>
        We may still send you important notifications about your account outside
        of your notification settings.
      </Text>

      {/* Notification from us */}
      <Flex align="start" spacing={6} mb={6} gap={15}>
        <Box maxW={"30%"}>
          <Text fontSize="lg" fontWeight="bold">
            Notification from us
          </Text>
          <Text color="gray.500" fontSize="sm">
            Receive messages with our news, events, or any other campaigns we
            might find relevant for you
          </Text>
        </Box>
        <Box>
          <HStack align="start" spacing={4}>
            <Checkbox colorScheme="red" size="lg" />
            <VStack align="start" spacing={0}>
              <Text fontWeight="semibold">New Campaign launched</Text>
              <Text color="gray.500" fontSize="sm">
                Receive notification when new campaign is launched
              </Text>
            </VStack>
          </HStack>
          <HStack align="start" spacing={4}>
            <Checkbox colorScheme="red" size="lg" />
            <VStack align="start" spacing={0}>
              <Text fontWeight="semibold">Campaign Updates</Text>
              <Text color="gray.500" fontSize="sm">
                Campaign Performance: Graphical Reports: Views, Clicks,
                Conversions
              </Text>
            </VStack>
          </HStack>
        </Box>
      </Flex>
      <Divider />

      {/* Email Notifications */}
      <Flex align="start" spacing={6} mb={6} pt={4} gap={15}>
        <Box maxW={"30%"}>
          <Text fontSize="lg" fontWeight="bold">
            Email Notification
          </Text>
          <Text color="gray.500" fontSize="sm">
            User can send you email notification for any new direct messages
          </Text>
        </Box>
        <Box>
          <HStack align="start" spacing={4}>
            <Checkbox colorScheme="red" size="lg" />
            <Text fontWeight="semibold">Receive promotional emails</Text>
          </HStack>
          <HStack align="start" spacing={4}>
            <Checkbox colorScheme="red" size="lg" />
            <VStack align="start" spacing={0}>
              <Text fontWeight="semibold">Receive budget updates</Text>
              <Text color="gray.500" fontSize="sm">
                Receive a notification email of transaction
              </Text>
            </VStack>
          </HStack>
          <HStack align="start" spacing={4}>
            <Checkbox colorScheme="red" size="lg" />
            <VStack align="start" spacing={0}>
              <Text fontWeight="semibold">Daily Summary</Text>
              <Text color="gray.500" fontSize="sm">
                Receive a daily digest of data requests completed in a day
              </Text>
            </VStack>
          </HStack>
        </Box>
      </Flex>
      <Divider />

      {/* SMS Notifications */}
      <Flex align="start" spacing={6} mb={6} pt={4} gap={15}>
        <Box maxW={"30%"}>
          <Text fontSize="lg" fontWeight="bold">
            Sms Notification
          </Text>
          <Text color="gray.500" fontSize="sm">
            User can send you Sms notification for any new direct messages
          </Text>
        </Box>
        <Box>
          <HStack align="start" spacing={4}>
            <Checkbox colorScheme="red" size="lg" />
            <VStack align="start" spacing={0}>
              <Text fontWeight="semibold">Receive SMS Campaign alerts</Text>
              <Text color="gray.500" fontSize="sm">
                Campaign Performance: Views, Clicks, Conversions
              </Text>
            </VStack>
          </HStack>
        </Box>
      </Flex>
      <Divider />

      {/* Sound */}
      {/* <Flex gap={15} align="start" spacing={6} mb={6} pt={4}>
        <Box maxW={"50%"}>
          <Text fontSize="lg" fontWeight="bold">
            Sound
          </Text>
        </Box>
        <HStack align="start" spacing={4}>
          <Checkbox colorScheme="red" size="lg" />
          <Text fontWeight="semibold">Play sound for all notifications</Text>
        </HStack>
      </Flex>
      <Divider /> */}

      {/* Do Not Disturb */}
      <Flex gap={15} align="start" spacing={6} pt={4}>
        <Box maxW={"30%"}>
          <Text fontSize="lg" fontWeight="bold">
            Do Not Disturb
          </Text>
          <Text color="gray.500" fontSize="sm">
            Configure a period of time where notifications will not generate
            alerts.
          </Text>
        </Box>
        <Flex gap={4} align="center" flexDirection={"column"}>
          <Select
            w="120px"
            size="lg"
            borderColor="gray.300"
            bg="#F3EDFF"
            color="#4A00E5"
          >
            <option>01 Hr</option>
            <option>02 Hr</option>
            <option>04 Hr</option>
          </Select>
          <HStack align="start" spacing={4}>
            <Checkbox colorScheme="red" size="lg" />
            <Text fontWeight="semibold">Pause notification</Text>
          </HStack>
        </Flex>
      </Flex>
    </Box>
  );
};

export default NotificationSettings;
