import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  Box,
  Text,
  Spinner,
  Checkbox,
  Image,
  Flex,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { CallAPI } from "../../Services/service"; // Assuming you have an API call function
import endpoints from "../../Services/endpoint"; // Replace with actual endpoints
import { useAuthContext } from "../CommunityGroup/context/AuthContext";
import {useNavigate} from "react-router-dom";

const OrganizationModal = ({ isOpened, handleClose }) => {
  const [userProfile, setProfileData] = useState(null);
  const [organizations, setOrganizationsData] = useState([]);
  const [organizationDetails, setOrganizationDetails] = useState([]); // For detailed org data
  const [loadingOrganizations, setLoadingOrganizations] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const { platformTokens } = useAuthContext();
  const [selectedEntities, setSelectedEntities] = useState([]); // Array for multiple selections
  const navigate = useNavigate();

  // Handle entity selection
  const handleEntitySelection = (entityId, entityType) => {
    const entity = { id: entityId, type: entityType };
    setSelectedEntities((prevSelected) => {
      if (prevSelected.some((e) => e.id === entityId && e.type === entityType)) {
        // Deselect if already selected
        return prevSelected.filter((e) => e.id !== entityId || e.type !== entityType);
      } else {
        // Select if not already selected
        return [...prevSelected, entity];
      }
    });
  };

  // Fetch organizations and their details
  const fetchOrganizations = async () => {
    setLoadingOrganizations(true);
    try {
      const response = await CallAPI(endpoints.getoraganisations, {
        token: platformTokens.LinkedIn,
      });
      const organizations = response.data || [];

      // Fetch details for each organization
      const organizationDetailsPromises = organizations.map(async (org) => {
        try {
          const detailResponse = await CallAPI(endpoints.getorganisationdetails, {
            token: platformTokens.LinkedIn,
            orgId: org.organization.split(":").pop(),
          });
          return {
            ...org,
            details: detailResponse.data,
          };
        } catch {
          return { ...org, details: null }; // Handle failed detail fetch
        }
      });

      const detailedOrganizations = await Promise.all(organizationDetailsPromises);
      setOrganizationsData(detailedOrganizations);
    } catch (error) {
      toast.error("Error fetching organizations.");
    } finally {
      setLoadingOrganizations(false);
    }
  };

  // Fetch user profile
  const fetchUserProfile = async () => {
    setLoadingProfile(true);
    try {
      const response = await CallAPI(endpoints.getlinkedinprofile, {
        token: platformTokens.LinkedIn,
      });
      setProfileData(response.data || null);
    } catch (error) {
      toast.error("Error fetching profile.");
    } finally {
      setLoadingProfile(false);
    }
  };

  // Handle post submission
  const handlePost = async () => {
    if (selectedEntities.length === 0) {
      toast.error("Please select an entity (organization or profile).");
      return;
    }

    navigate("/posts-on-social-media", { state: {selectedEntities} });
    handleClose();
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchOrganizations();
    fetchUserProfile();
  }, []);

  return (
    <Modal isOpen={isOpened} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Organization or Profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={2} fontWeight="bold">
            Your Profile
          </Text>
          {loadingProfile ? (
            <Spinner />
          ) : (
            userProfile && (
              <Box
                mb={4}
                p={4}
                borderWidth={1}
                borderRadius="md"
                display="flex"
              >
                <Checkbox
 isChecked={selectedEntities.some(
  (e) => e.id === userProfile.sub && e.type === "profile"
)}                  onChange={() => handleEntitySelection(userProfile.sub, "profile")}
                />
                <Flex ml="2">
                  <Image boxSize="40px" src={userProfile.picture} alt={userProfile.name} />
                  <Text m="2">{userProfile.name}</Text>
                </Flex>
              </Box>
            )
          )}

          <Text mb={2} fontWeight="bold">
            Your Organizations
          </Text>
          {loadingOrganizations ? (
            <Spinner />
          ) : (
            organizations.map((org) => (
              <Box
                key={org.organization}
                mb={4}
                p={4}
                borderWidth={1}
                borderRadius="md"
                display="flex"
                flexDirection="column"
              >
                <Checkbox
 isChecked={selectedEntities.some(
  (e) => e.id === org.organization && e.type === "organization"
)}                  onChange={() => handleEntitySelection(org.organization, "organization")}
                >
                  

                  {org.details?.localizedName || org.organization}
                </Checkbox>
                
              </Box>
            ))
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            onClick={handlePost}
            disabled={selectedEntities.length ==0}
          >
            Continue
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrganizationModal;
