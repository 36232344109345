import React, { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  HStack,
  Tr,
  Th,
  Td,
  Badge,
  Button,
  Input,
  Select,
  useDisclosure,
  Flex,
  Text,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import { FaPlus } from "react-icons/fa";
const invoices = [
  {
    id: "#1254058",
    client: "NovaWave LLC",
    project: "Trulysell",
    dueDate: "21 May 2024",
    amount: "$2,15,000",
    paidAmount: "$2,15,000",
    balanceAmount: "$0",
    status: "Partially Paid",
  },
  // ... more invoices
];

const statusColorScheme = {
  Paid: "green",
  "Partially Paid": "orange",
  Unpaid: "red",
  Overdue: "purple",
};
 const theme = extendTheme({
   fonts: {
     heading: `'Poppins', sans-serif`,
     body: `'Raleway', sans-serif`,
   },
 });

const AddNewInvoiceModal = ({ isOpen, onClose }) => {
    //  const { isOpen, onOpen, onClose } = useDisclosure();
     const [invoiceDate, setInvoiceDate] = useState(null);
     const [dueDate, setDueDate] = useState(null);
  return (
    <ChakraProvider theme={theme}>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            fontWeight={"400"}
            fontSize={"25px"}
            lineHeight={"37px"}
          >
            Add New Invoice
          </DrawerHeader>

          <DrawerBody>
            <Flex direction="column" gap={4}>
              {/* Form fields as shown in the modal */}
              <Flex justifyContent="space-between">
                <Box w="48%">
                  <Text mb={2} fontWeight={"400"} fontSize={"20px"}>
                    Client <span style={{ color: "red" }}>*</span>
                  </Text>
                </Box>
                <Button leftIcon={<FaPlus />} colorScheme="red" size="sm">
                  Add New
                </Button>
              </Flex>
              <Input placeholder="Enter client name" />

              <Flex justifyContent="space-between">
                <Box w="48%">
                  <Text
                    mb={2}
                    fontWeight={"400"}
                    fontSize={"20px"}
                    lineHeight={"30px"}
                  >
                    Bill To <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Input placeholder="Enter billing address" />
                </Box>
                <Box w="48%">
                  <Text
                    mb={2}
                    fontWeight={"400"}
                    fontSize={"20px"}
                    lineHeight={"30px"}
                  >
                    Ship To <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Input placeholder="Enter shipping address" />
                </Box>
              </Flex>

              <Flex justifyContent="space-between">
                <Box w="48%">
                  <Text
                    mb={2}
                    fontWeight={"400"}
                    fontSize={"20px"}
                    lineHeight={"30px"}
                  >
                    Amount <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Input placeholder="Enter amount" />
                </Box>
                <Box w="48%">
                  <Text
                    mb={2}
                    fontWeight={"400"}
                    fontSize={"20px"}
                    lineHeight={"30px"}
                  >
                    Currency
                  </Text>
                  <Select placeholder="Choose currency">
                    <option value="usd">USD</option>
                    <option value="eur">EUR</option>
                  </Select>
                </Box>
              </Flex>

              <Flex justifyContent="space-between">
                <Box w="48%">
                  <Text
                    mb={2}
                    fontWeight={"400"}
                    fontSize={"20px"}
                    lineHeight={"30px"}
                  >
                    Invoice Date <span style={{ color: "red" }}>*</span>
                  </Text>
                  <DatePicker
                    selected={invoiceDate}
                    onChange={(date) => setInvoiceDate(date)}
                    customInput={<Input />}
                  />
                </Box>
                <Box w="48%">
                  <Text
                    mb={2}
                    fontWeight={"400"}
                    fontSize={"20px"}
                    lineHeight={"30px"}
                  >
                    Due Date <span style={{ color: "red" }}>*</span>
                  </Text>
                  <DatePicker
                    selected={dueDate}
                    onChange={(date) => setDueDate(date)}
                    customInput={<Input />}
                  />
                </Box>
              </Flex>

              <Box>
                <Text
                  mb={2}
                  fontWeight={"400"}
                  fontSize={"20px"}
                  lineHeight={"30px"}
                >
                  Description <span style={{ color: "red" }}>*</span>
                </Text>
                <Input placeholder="Enter description" />
              </Box>

              <Flex justifyContent="space-between">
                <Text fontWeight={"400"} fontSize={"20px"}>
                  Project <span style={{ color: "red" }}>*</span>
                </Text>
                <Button leftIcon={<FaPlus />} colorScheme="red" size="sm">
                  Add New
                </Button>
              </Flex>
              <Select placeholder="Choose project" mt={"-15px"}>
                <option value="project1">Project 1</option>
                <option value="project2">Project 2</option>
              </Select>

              {/* Item Table */}
              <Table size="sm">
                <Thead bg={"#D9D9D9"} p={"15px"}>
                  <Tr>
                    <Th>Item</Th>
                    <Th>Quantity</Th>
                    <Th>Price</Th>
                    <Th>Discount</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>
                      <Input size="sm" placeholder="Enter item" />
                    </Td>
                    <Td>
                      <Input size="sm" placeholder="Qty" />
                    </Td>
                    <Td>
                      <Input size="sm" placeholder="Price" />
                    </Td>
                    <Td>
                      <Input size="sm" placeholder="%" />
                    </Td>
                    <Td>
                      <IconButton icon={<FaPlus />} size="sm" />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>

              <Box>
                <Text
                  mb={2}
                  fontWeight={"400"}
                  fontSize={"20px"}
                  lineHeight={"30px"}
                >
                  Terms & Conditions <span style={{ color: "red" }}>*</span>
                </Text>
                <Input placeholder="Enter terms and conditions" />
              </Box>
            </Flex>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red">Create</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
};

export default AddNewInvoiceModal