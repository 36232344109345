import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
} from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { ChevronDownIcon } from "@chakra-ui/icons";

const LeadsByStageChart = () => {
  const chartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Inpipeline",
        "Follow Up",
        "Schedule",
        "Conversation",
        "Won",
        "Lost",
      ],
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    fill: {
      opacity: 1,
      colors: ["#4CAF50"],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " leads";
        },
      },
    },
  };

  const chartSeries = [
    {
      name: "Leads",
      data: [400, 30, 248, 470, 470, 180],
    },
  ];

  return (
    <Box p={5} bg={"white"} w={'660px'}>
      <Box display="flex" justifyContent="space-between" mb={4}>
        <Heading
          fontSize={"18px"}
          fontWeight={"700"}
          color="#262A2A"
          mt={"4px"}
          w={"135px"}
        >
          Leads By Stage
        </Heading>
        <Flex gap={"6px"}>
          <Menu>
            <MenuButton
              w="auto"
              as={Button}
              fontSize="12px"
              fontWeight="400"
              textAlign={"center"}
              variant="outline"
              rightIcon={<ChevronDownIcon />}
            >
              Sales pipeline
            </MenuButton>
            <MenuList>
              <MenuItem>Sales</MenuItem>
              <MenuItem>All</MenuItem>
            </MenuList>
          </Menu>

          <Menu>
            <MenuButton
              w="auto"
              as={Button}
              fontSize="12px"
              fontWeight="400"
              textAlign={"center"}
              variant="outline"
              rightIcon={<ChevronDownIcon />}
            >
              Last 30 Days
            </MenuButton>
            <MenuList>
              <MenuItem>Last 7 Days</MenuItem>
              <MenuItem>Last 30 Days</MenuItem>
              <MenuItem>All Time</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />
    </Box>
  );
};

export default LeadsByStageChart;
