import React from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
  Box,
  Flex,
  Text,
  Icon,
  Switch,
  GridItem,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  extendTheme,
  ChakraProvider,
} from "@chakra-ui/react";
import { ChevronRightIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { FaMapMarkerAlt, FaUserCircle } from "react-icons/fa";
import { FiUserPlus, FiUsers } from "react-icons/fi";
import { BsUpload } from "react-icons/bs";
import "@fontsource/noto-sans";


 const theme = extendTheme({
   fonts: {
     heading: `'Noto Sans', sans-serif`,
     body: `'Noto Sans', sans-serif`,
   },
 });


const AddNew1 = ({onClose,isOpen}) => {
  return (
    <ChakraProvider theme={theme}>
      <Drawer placement="right" onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent>
          {/* Close Button */}
          <DrawerCloseButton />

          {/* Drawer Header */}
          <DrawerHeader borderBottomWidth="1px" fontWeight={'500'}>Add New Contacts</DrawerHeader>

          {/* Drawer Body */}
          <DrawerBody>
            {/* Replace this content with your Add New Contacts form */}
            <Flex direction="column" gap={4}>
              <Box
                //   border="1px"
                //   borderColor="gray.200"
                // borderRadius="md"
                w={"100%"}
                cursor="pointer"
              >
                {/* <Flex justifyContent="space-between" align="center">
                <Text fontWeight="600" fontSize="md">
                  Basic Info
                </Text>
                <Icon as={ChevronRightIcon} boxSize={6} color="gray.500" />
              </Flex> */}
                <Accordion allowToggle>
                  {/* Accordion Item */}
                  <AccordionItem
                    // border="1px solid #E2E8F0"
                    borderRadius="md"
                    overflow="hidden"
                  >
                    <h2>
                      <AccordionButton>
                        <Flex align="center" flex="1" textAlign="left">
                          <Icon as={FiUserPlus} boxSize={5} mr={2} />
                          <Text
                            fontWeight={"500"}
                            fontSize={"16px"}
                            gap={2}
                            mt={4}
                          >
                            Basic Info
                          </Text>
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>

                    {/* Accordion Panel */}
                    <AccordionPanel pb={4}>
                      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                        {/* Upload Section */}
                        <GridItem colSpan={2}>
                          <Flex align="center" direction="column" mb={4}>
                            <Box
                              border="1px dashed #CBD5E0"
                              borderRadius="md"
                              w="100px"
                              h="100px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Icon
                                as={BsUpload}
                                boxSize={6}
                                color="gray.500"
                              />
                            </Box>
                            <Button size="sm" colorScheme="blue" mt={2}>
                              Upload File
                            </Button>
                            <Box fontSize="xs" color="gray.500">
                              JPG, GIF or PNG. Max size of 800K
                            </Box>
                          </Flex>
                        </GridItem>

                        {/* First Name */}
                        <FormControl isRequired>
                          <FormLabel>First Name</FormLabel>
                          <Input placeholder="First Name" />
                        </FormControl>

                        {/* Last Name */}
                        <FormControl isRequired>
                          <FormLabel>Last Name</FormLabel>
                          <Input placeholder="Last Name" />
                        </FormControl>

                        {/* Email */}
                        <FormControl isRequired>
                          <Flex alignItems={"center"} justifyContent={"center"}>
                            <FormLabel>Email</FormLabel>
                            <Flex align="center">
                              <Box fontSize="xs" mr={1} mb={2}>
                                Email opt out
                              </Box>
                              <Switch colorScheme="green" />
                            </Flex>
                          </Flex>
                          <Flex align="center">
                            <Input placeholder="Email" />
                            <Box ml={2}></Box>
                          </Flex>
                        </FormControl>

                        {/* Job Title */}
                        <FormControl isRequired>
                          <FormLabel>Job Title</FormLabel>
                          <Input placeholder="Job Title" />
                        </FormControl>

                        {/* Phone */}
                        <FormControl isRequired>
                          <FormLabel>Phone</FormLabel>
                          <Input placeholder="Phone" />
                        </FormControl>

                        {/* Source */}
                        <FormControl isRequired>
                          <FormLabel>Source</FormLabel>
                          <Input placeholder="Source" />
                        </FormControl>

                        {/* Description */}
                        <GridItem colSpan={2}>
                          <FormControl isRequired>
                            <FormLabel>Description</FormLabel>
                            <Textarea placeholder="Description" />
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>

              {/* Address Info */}
              <Box cursor="pointer">
                {/* <Flex justifyContent="space-between" align="center">
                <Text fontWeight="600" fontSize="md">
                  Address Info
                </Text>
                <Icon as={ChevronRightIcon} boxSize={6} color="gray.500" />
              </Flex> */}
                <Accordion allowToggle>
                  <AccordionItem>
                    <AccordionButton>
                      <Box
                        flex="1"
                        textAlign="left"
                        display="flex"
                        alignItems="center"
                      >
                        <Icon as={FaMapMarkerAlt} boxSize={5} mr={2} />
                        <Text
                          fontWeight={"500"}
                          fontSize={"16px"}
                          gap={2}
                          mt={4}
                        >
                          Address Info
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Box>
                        <FormControl isRequired mb={4}>
                          <FormLabel>Street Address</FormLabel>
                          <Input placeholder="Enter your street address" />
                        </FormControl>
                        <Flex gap={4} mb={4}>
                          <FormControl isRequired>
                            <FormLabel>City</FormLabel>
                            <Input placeholder="City" />
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel>State</FormLabel>
                            <Input placeholder="State" />
                          </FormControl>
                        </Flex>
                        <Flex gap={4}>
                          <FormControl isRequired>
                            <FormLabel>Country</FormLabel>
                            <Input placeholder="Country" />
                          </FormControl>
                          <FormControl isRequired>
                            <FormLabel>Pincode</FormLabel>
                            <Input placeholder="Pincode" />
                          </FormControl>
                        </Flex>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>

              {/* Social Profile */}
              <Box cursor="pointer">
                {/* <Flex justifyContent="space-between" align="center">
                <Text fontWeight="600" fontSize="md">
                  Social Profile
                </Text>
                <Icon as={ChevronRightIcon} boxSize={6} color="gray.500" />
              </Flex> */}
                <Accordion allowToggle>
                  <AccordionItem>
                    <AccordionButton>
                      <Box
                        flex="1"
                        textAlign="left"
                        display="flex"
                        alignItems="center"
                      >
                        <Icon as={FaUserCircle} boxSize={5} mr={2} />
                        {/* Profile Icon */}
                        <Text
                          fontWeight={"500"}
                          fontSize={"16px"}
                          gap={2}
                          mt={4}
                        >
                          Social Profile
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Box>
                        <Flex gap={4} mb={4}>
                          <FormControl>
                            <FormLabel>Linkedin</FormLabel>
                            <Input placeholder="Linkedin" />
                          </FormControl>
                          <FormControl>
                            <FormLabel>Whatsapp</FormLabel>
                            <Input placeholder="Whatsapp" />
                          </FormControl>
                        </Flex>
                        <Flex gap={4} mb={4}>
                          <FormControl>
                            <FormLabel>Facebook</FormLabel>
                            <Input placeholder="Facebook" />
                          </FormControl>
                          <FormControl>
                            <FormLabel>Instagram</FormLabel>
                            <Input placeholder="Instagram" />
                          </FormControl>
                        </Flex>
                        <Flex gap={4}>
                          <FormControl>
                            <FormLabel>Skype</FormLabel>
                            <Input placeholder="Skype" />
                          </FormControl>
                          <FormControl>
                            <FormLabel>Twitter</FormLabel>
                            <Input placeholder="Twitter" />
                          </FormControl>
                        </Flex>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>

              {/* Access */}
              <Box cursor="pointer">
                {/* <Flex justifyContent="space-between" alignItems="center">
                <Text fontWeight="600" fontSize="md">
                  Access
                </Text>
                <Icon as={ChevronRightIcon} boxSize={6} color="gray.500" />
              </Flex> */}
                <Accordion allowToggle>
                  {/* Accordion Item */}
                  <AccordionItem
                    border="1px solid #E2E8F0"
                    borderRadius="md"
                    overflow="hidden"
                  >
                    <h2>
                      <AccordionButton>
                        {/* Icon and Title */}
                        <Flex align="center" flex="1" textAlign="left" gap={2}>
                          <Icon as={FiUsers} boxSize={5} mr={2} />
                          <Text fontWeight={"500"} fontSize={"16px"} mt={4}>
                            Access
                          </Text>
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    {/* Accordion Panel */}
                    <AccordionPanel pb={4} pt={2}>
                      {/* Visibility Section */}
                      <Box mb={6}>
                        <Text fontSize="sm" fontWeight="semibold" mb={2}>
                          Visibility
                          <Box as="span" color="red">
                            *
                          </Box>
                        </Text>
                        <RadioGroup name="visibility">
                          <Stack direction="row" spacing={6}>
                            <Radio value="public">Public</Radio>
                            <Radio value="private">Private</Radio>
                            <Radio value="selectPeople">Select People</Radio>
                          </Stack>
                        </RadioGroup>
                      </Box>

                      {/* Status Section */}
                      <Box>
                        <Text fontSize="sm" fontWeight="semibold" mb={2}>
                          Status
                          <Box as="span" color="red">
                            *
                          </Box>
                        </Text>
                        <RadioGroup name="status" defaultValue="active">
                          <Stack direction="row" spacing={6}>
                            <Radio value="active" defaultChecked>
                              Active
                            </Radio>
                            <Radio value="inactive">Inactive</Radio>
                          </Stack>
                        </RadioGroup>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>

              {/* Footer Buttons */}
              <Flex justify="flex-end" gap={4} mt={6}>
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red">Create</Button>
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
};

export default AddNew1;
