import React from 'react'; 
import {
  Box,
  Button,
  Center,
  ChakraProvider,
  Checkbox,
  Divider,
  extendTheme,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import "@fontsource/noto-sans";
import { FaPaperPlane } from "react-icons/fa";

  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Raleway', sans-serif`,
    },
  });

const Payments = () => {
  return (
    <ChakraProvider theme={theme}>
      <Flex
        direction={{ base: "column", md: "row" }}
        p={5}
        gap={"16px"}
        bg={"#FAFAFA"}
      >
        <Box
          w={{ base: "full", md: "60%" }}
          p={5}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          bg={"white"}
        >
          <Flex direction={{ base: "column", md: "row" }} bg={"white"}>
            <Image
              src=""
              alt="Campaign Image"
              mb={4}
              w={"300px"}
              h={"300px"}
              objectFit="cover"
            />
            <Box
              ml={{ md: 5 }}
              p={"15px"}
              w={"473px"}
              h={"300px"}
              bg={"#FAFAFA"}
            >
              <Flex lineHeight={"8px"} flexDirection={"column"}>
                <Text>Platform: </Text>
                <Text fontWeight={"600"} fontSize={"14px"}>
                  Facebook (Meta)
                </Text>
              </Flex>
              <Flex flexDirection={"column"} lineHeight={"8px"}>
                <Text>Campaign Duration: </Text>
                <Text fontWeight={"600"} fontSize={"14px"}>
                  20/2/2024 - 21/2/2024
                </Text>
              </Flex>
              <Flex flexDirection={"column"} lineHeight={"8px"}>
                <Text>Schedule Time: </Text>
                <Text fontWeight={"600"} fontSize={"14px"}>
                  9.30 PM
                </Text>
              </Flex>
              <Flex flexDirection={"column"} lineHeight={"8px"}>
                <Text>Payment Deadline: </Text>
                <Text fontWeight={"600"} fontSize={"14px"}>
                  05/18/2020
                </Text>
              </Flex>
            </Box>
          </Flex>

          <Heading fontWeight={"600"} fontSize={"14px"}>
            Campaign Name: Boost Your Brand Awareness
          </Heading>
          <Text mt={2}>
            Description: Drive attention to your brand with a targeted campaign
            designed to increase visibility among potential customers. Perfect
            for expanding your reach and building brand recognition across
            social media.{" "}
          </Text>
          <Center>
            <Button colorScheme="red" variant="outline">
              Read More
            </Button>
          </Center>

          <Box mt={10}>
            <Heading fontSize={"16px"} fontWeight={"600"}>
              Payment
            </Heading>
            <Divider />
            <Box mt={5}>
              <FormControl as="fieldset">
                <FormLabel as="legend" fontSize={"14px"} fontWeight={"600"}>
                  Pay With:
                </FormLabel>
                <Stack spacing={5} direction="row">
                  <Checkbox defaultChecked>Card</Checkbox>{" "}
                  <Checkbox>Bank</Checkbox> <Checkbox>Transfer</Checkbox>
                </Stack>{" "}
              </FormControl>{" "}
              <FormControl mt={4}>
                <FormLabel fontSize={"14px"} fontWeight={"600"}>
                  Card Number
                </FormLabel>{" "}
                <Input placeholder="1234 5678 9101 1121" />{" "}
              </FormControl>{" "}
              <Flex mt={4}>
                <FormControl mr={2}>
                  <FormLabel fontSize={"14px"} fontWeight={"600"}>
                    Expiration Date
                  </FormLabel>{" "}
                  <Input placeholder="MM/YY" />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={"14px"} fontWeight={"600"}>
                    CVV
                  </FormLabel>
                  <Input placeholder="123" />{" "}
                </FormControl>
              </Flex>
              <Checkbox mt={4}>Save card details</Checkbox>
              <Text mt={4} fontSize="sm">
                Your personal data will be used to process your order, support
                your experience throughout this website, and for other purposes
                described in our privacy policy.{" "}
              </Text>
            </Box>
          </Box>
        </Box>

        <Flex
          flexDirection={{ base: "column", md: "column" }}
          w={{ base: "full", md: "430px" }}
          gap={{ base: "15px", md: "25px" }}
          p={{ base: "10px", md: "0" }}
        >
          <Box textAlign="center" p={4} bg={"white"} shadow="md">
            <Text
              mb={2}
              w={"100%"}
              fontWeight={"700"}
              fontSize={{ base: "14px", md: "16px" }}
            >
              Get Invoice on your mail
            </Text>
            <Button
              colorScheme="red"
              w={"100%"}
              leftIcon={<Icon as={FaPaperPlane} />}
            >
              Send Invoice
            </Button>
          </Box>

          <Box
            w={{ base: "full", md: "430px" }}
            borderWidth="1px"
            bg={"white"}
            borderRadius="lg"
            p={{ base: "10px", md: "15px" }}
            shadow="md"
          >
            <Heading
              size="md"
              mb={4}
              fontWeight={"600"}
              fontSize={{ base: "12px", md: "14px" }}
            >
              Billing Summary
            </Heading>
            <Box
              border={"solid black 1px"}
              rounded={"xl"}
              p={{ base: "10px", md: "15px" }}
            >
              <VStack spacing={1} align="stretch">
                <Flex
                  justifyContent={"space-between"}
                  fontWeight={"600"}
                  fontSize={{ base: "12px", md: "14px" }}
                >
                  <Text>Campaign Total:</Text>
                  <Text>$20</Text>
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  fontWeight={"600"}
                  fontSize={{ base: "12px", md: "14px" }}
                >
                  <Text>Platform Fee:</Text>
                  <Text>$10</Text>
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  fontWeight={"600"}
                  fontSize={{ base: "12px", md: "14px" }}
                >
                  <Text>Tax:</Text>
                  <Text>$2.5</Text>
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  fontWeight={"600"}
                  fontSize={{ base: "12px", md: "14px" }}
                >
                  <Text>Total Payable:</Text>
                  <Text>$32.5</Text>
                </Flex>
              </VStack>
              <Divider />
              <Box
                display={"flex"}
                flexDirection={"column"}
                mt={{ base: "10px", md: "5px" }}
              >
                <Heading
                  size="md"
                  fontWeight={"600"}
                  fontSize={{ base: "12px", md: "14px" }}
                >
                  Terms and Conditions
                </Heading>
                <Checkbox mt={4} defaultChecked>
                  I agree to the Terms and Conditions
                </Checkbox>
                <Checkbox mt={2} defaultChecked>
                  Save this card for future payments
                </Checkbox>
                <Button mt={4} colorScheme="red" w="full">
                  Pay Now
                </Button>
              </Box>
            </Box>
            <Divider />
          </Box>
        </Flex>

        {/* 
        <Flex flexDirection={"column"} w={"430px"} gap={"25px"}>
          <Box textAlign="center" p={4} bg={"white"}>
            <Text mb={2} w={"100%"} fontWeight={"700"} fontSize={"16px"}>
              Get Invoice on your mail
            </Text>
            <Button
              colorScheme="red"
              w={"100%"}
              leftIcon={<Icon as={FaPaperPlane} />}
            >
              {" "}
              Send Invoice
            </Button>
          </Box>
          <Box
            w={{ base: "full", md: "430px" }}
            h={""}
            borderWidth="1px"
            bg={"white"}
            borderRadius="lg"
            p={"15px"}
          >
            <Heading size="md" mb={4} fontWeight={"600"} fontSize={"14px"}>
              Billing Summary
            </Heading>
            <Box border={"solid black 1px"} rounded={"xl"} p={"15px"}>
              <VStack spacing={1} align="stretch">
                <Flex
                  justifyContent={"space-between"}
                  fontWeight={"600"}
                  fontSize={"14px"}
                >
                  <Text>Campaign Total:</Text>
                  <Text> $20</Text>
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  fontWeight={"600"}
                  fontSize={"14px"}
                >
                  <Text>Platform Fee:</Text>
                  <Text> $10</Text>
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  fontWeight={"600"}
                  fontSize={"14px"}
                >
                  <Text>Tax:</Text>
                  <Text> $2.5</Text>
                </Flex>
                <Flex
                  justifyContent={"space-between"}
                  fontWeight={"600"}
                  fontSize={"14px"}
                >
                  <Text>Total Payable:</Text>
                  <Text> $32.5</Text>
                </Flex>
              </VStack>
              <Divider />
              <Box display={"flex"} flexDirection={"column"} mt={"5px"}>
                <Heading size="md" fontWeight={"600"} fontSize={"14px"}>
                  Terms and Conditions
                </Heading>

                <Checkbox mt={4} defaultChecked>
                  I agree to the Terms and Conditions
                </Checkbox>
                <Checkbox mt={2} defaultChecked>
                  Save this card for future payments
                </Checkbox>
                <Button mt={4} colorScheme="red" w="full">
                  Pay Now
                </Button>
              </Box>
            </Box>
            <Divider />
          </Box>
        </Flex> */}
      </Flex>
    </ChakraProvider>
  );
}

export default Payments;
