import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Textarea,
  Input,
  Box,
  Button,
  Select,
  Flex,
  HStack,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Tag,
  Image,
} from "@chakra-ui/react";
import CreatableSelect from "react-select/creatable";

import { AiOutlinePhone, AiOutlineMail, AiOutlineCheckSquare, AiOutlineUser } from "react-icons/ai";
import endpoints from "../../Services/endpoint";
import { CallAPI } from "../../Services/service";
import { toast } from "react-toastify";
import Call from "../Assets/Call.png"


const AddNewEvent = ({ onClose, isOpen,getData }) => {
    const [guests, setGuests] = useState([]);

  const [formValues, setFormValues] = useState({
    title: "",
    activityType: "Calls",
    dueDate: "",
    time: "",
    reminder: "",
    reminderUnit: "Minutes",
    owner: "",
    guests: [],
    description: "",
    deals: "",
    contacts: "",
    companies: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        newErrors[key] = "This field is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSelectChange = (newValue) => {
    setGuests(newValue);
    setErrors((prevErrors) => ({
        ...prevErrors,
        targetAudience: "",
      }));
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      try {
      const res = await CallAPI(endpoints.createEvents, {...formValues,guests:guests}); // Replace with your actual endpoint
  
        if (res?.code === 201) {
          console.log(res);
          toast.success(res?.message || 'Event added successfully!');
          onClose(); // Close the form/modal
          getData()
          setFormValues({}); // Reset form values if needed
        } else {
          toast.error(res?.message || 'Failed to add Event.');
        }
      } catch (error) {
        console.error(error);
        toast.error('Failed to add Event due to an error.');
      } finally {
      }
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New Event</DrawerHeader>

        <DrawerBody>
          <Stack spacing="24px">
            <Box>
              <FormControl isInvalid={errors.title}>
                <FormLabel>
                  Title <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  name="title"
                  value={formValues.title}
                  onChange={handleChange}
                  placeholder="Enter event title"
                />
                <FormErrorMessage>{errors.title}</FormErrorMessage>
              </FormControl>
            </Box>

            {/* Activity Type */}
            <Box>     
              <FormLabel>Activity Type <span style={{ color: "red" }}>*</span></FormLabel>
              <HStack spacing="16px">
              <Button bg={formValues.activityType === 'Calls' ? 'blue.500' : 'white'} border={"1px solid"} borderColor="gray.500" color={"#00000099"} leftIcon={<Image src={Call} alt="Call Icon" />} onClick={() => setFormValues({ ...formValues, activityType: "Calls" })}>Calls</Button>
<Button bg={formValues.activityType === 'Mail' ? 'blue.500' : 'white'} border={"1px solid"} borderColor="gray.500" color={"#00000099"}  leftIcon={<AiOutlineMail />} onClick={() => setFormValues({ ...formValues, activityType: "Mail" })}>Mail</Button>
<Button bg={formValues.activityType === 'Task' ? 'blue.500' : 'white'} border={"1px solid"} borderColor="gray.500" color={"#00000099"}  leftIcon={<AiOutlineCheckSquare />} onClick={() => setFormValues({ ...formValues, activityType: "Task" })}>Task</Button>
<Button bg={formValues.activityType === 'Meeting' ? 'blue.500' : 'white'} border={"1px solid"} borderColor="gray.500"color={"#00000099"}  leftIcon={<AiOutlineUser />} onClick={() => setFormValues({ ...formValues, activityType: "Meeting" })}>Meeting</Button>

              </HStack>
            </Box>

            {/* Due Date and Time */}
            <Flex gap="4">
              <Box flex="1">
                <FormControl isInvalid={errors.dueDate}>
                  <FormLabel>Due Date <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    type="date"
                    name="dueDate"
                    value={formValues.dueDate}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.dueDate}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box flex="1">
                <FormControl isInvalid={errors.time}>
                  <FormLabel>Time <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    type="time"
                    name="time"
                    value={formValues.time}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.time}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            {/* Reminder */}
            <Flex gap="4">
              <Box flex="1">
                <FormControl isInvalid={errors.reminder}>
                  <FormLabel>Reminder <span style={{ color: "red" }}>*</span></FormLabel>
                  <Input
                    name="reminder"
                    value={formValues.reminder}
                    onChange={handleChange}
                    placeholder="Enter reminder time"
                  />
                  <FormErrorMessage>{errors.reminder}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box flex="1">
                <Flex>  
                    <FormControl>
    <FormLabel>
      Select
    </FormLabel>
    <Select
      name="reminderUnit"
      value={formValues.reminderUnit}
      onChange={handleChange}
    >
      <option value="Minutes">Minutes</option>
      <option value="Hours">Hours</option>
      <option value="Days">Days</option>
    </Select>
  </FormControl> 
  
   <p style={{ color: "gray", fontWeight: "bold",marginTop:"30px", marginLeft:"10px" }}>Before Due</p>

  </Flex>

</Box>

            </Flex>

            {/* Owner and Guests */}
            <Flex gap="4">
            <Box flex="1">
              <FormControl isInvalid={errors.owner}>
                <FormLabel>Owner <span style={{ color: "red" }}>*</span></FormLabel>
                <Select
                  name="owner"
                  value={formValues.owner}
                  onChange={handleChange}
                  placeholder="Select owner"
                >
                  <option value="owner1">Owner 1</option>
                  <option value="owner2">Owner 2</option>
                </Select>
                <FormErrorMessage>{errors.owner}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box flex="1">
              <FormControl isInvalid={errors.guests}>
                <FormLabel>Guests <span style={{ color: "red" }}>*</span></FormLabel>
                <CreatableSelect
                isMulti
                options={[
                  { value: "Small Business", label: "Small Business" },
                  { value: "Corporate Companies", label: "Corporate Companies" },
                ]}
                value={guests}
                onChange={handleSelectChange}
                placeholder="Select or type target audience"
              />
                <FormErrorMessage>{errors.guests}</FormErrorMessage>
              </FormControl>
            </Box>
            </Flex>

            {/* Description */}
            <Box>
              <FormControl isInvalid={errors.description}>
                <FormLabel>Description <span style={{ color: "red" }}>*</span></FormLabel>
                <Textarea
                  name="description"
                  value={formValues.description}
                  onChange={handleChange}
                  placeholder="Enter description"
                />
                <FormErrorMessage>{errors.description}</FormErrorMessage>
              </FormControl>
            </Box>

            {/* Deals, Contacts, Companies */}
            <Box>
              <FormControl isInvalid={errors.deals}>
                <FormLabel>Deals <span style={{ color: "red" }}>*</span></FormLabel>
                <Select
                  name="deals"
                  value={formValues.deals}
                  onChange={handleChange}
                  placeholder="Select deal"
                >
                  <option value="deal1">Deal 1</option>
                  <option value="deal2">Deal 2</option>
                </Select>
                <FormErrorMessage>{errors.deals}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box>
              <FormControl isInvalid={errors.contacts}>
                <FormLabel>Contacts <span style={{ color: "red" }}>*</span></FormLabel>
                <Select
                  name="contacts"
                  value={formValues.contacts}
                  onChange={handleChange}
                  placeholder="Select contact"
                >
                  <option value="contact1">Contact 1</option>
                  <option value="contact2">Contact 2</option>
                </Select>
                <FormErrorMessage>{errors.contacts}</FormErrorMessage>
              </FormControl>
            </Box>

            <Box>
              <FormControl isInvalid={errors.companies}>
                <FormLabel>Companies <span style={{ color: "red" }}>*</span></FormLabel>
                <Select
                  name="companies"
                  value={formValues.companies}
                  onChange={handleChange}
                  placeholder="Select company"
                >
                  <option value="company1">Company 1</option>
                  <option value="company2">Company 2</option>
                </Select>
                <FormErrorMessage>{errors.companies}</FormErrorMessage>
              </FormControl>
            </Box>
          </Stack>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>Cancel</Button>
          <Button colorScheme="red" onClick={handleSubmit}>Create</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default AddNewEvent;
