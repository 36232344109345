import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ChakraProvider,
  extendTheme,
  Box,
  Button,
  Flex,
  Heading,
  VStack,
  HStack,
  Image,
  SimpleGrid,
  Text,
  Link,
  useColorModeValue,
  Avatar,
  Icon,
  Center,
} from "@chakra-ui/react";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import {
  FaEye,
  FaLayerGroup,
  FaEdit,
  FaShieldAlt,
  FaCloud,
  FaTabletAlt,
} from "react-icons/fa";


const features = [
  {
    icon: FaEye,
    title: "High Resolution",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: FaLayerGroup,
    title: "Retina Ready Screen",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: FaEdit,
    title: "Easy Editable Data",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: FaShieldAlt,
    title: "Fully Secured",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: FaCloud,
    title: "Cloud Storage",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  {
    icon: FaTabletAlt,
    title: "Responsive Ready",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];






const Services = () => {
  return (
    <Box mx="auto" p={4} bg={"white"}>
      {/* <Image src="/images/vecteezy_abstract-red-speed-neon-light-effect-on-black-background_8440572 1.jpg" /> */}
      <Box
        position="relative"
        bgImage="/Rectangle 3909.jpg"
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        height="300px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        color="white"
        textAlign="center"
        overflow="hidden"
      >
        {/* Overlay for gradient effect */}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="0"
        />

        {/* Content */}
        <Box
          position="relative"
          zIndex="1"
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
        >
          <Text fontSize="4xl" fontWeight="bold" color={"white"}>
            Services
          </Text>
          <Breadcrumb color="whiteAlpha.800" separator="-">
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="/services">Services</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Box>

      <Box mt={5} maxW={"90%"} mx={"auto"}>
        <Image src="services1.jpg" />
      </Box>

      {/* key features */}
      <Box textAlign="center" p={8} mt={10} bgImage={"bg2.jpeg"}>
        <Text color="white" fontWeight="bold" textTransform="uppercase">
          Key Features
        </Text>
        <Heading as="h2" size="lg" mt={2} mb={8} color={"white"}>
          Most Probably Included Best Features Ever
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing={5}
          maxW={"80%"}
          mx={"auto"}
        >
          {features.map((feature, index) => (
            <Box
              key={index}
              borderWidth="1px"
              p={6}
              textAlign="center"
              bg={"white"}
            >
              <Flex gap={"25px"}>
                <Icon as={feature.icon} w={10} h={10} color="red.500" mb={4} />
                <Flex flexDirection={"column"} textAlign={"left"}>
                  <Heading as="h3" size="md" mb={2}>
                    {feature.title}
                  </Heading>
                  <Text>{feature.description}</Text>
                </Flex>
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
        <Center>
          <Button size="sm" rounded={"2xl"} color={"red"} bg="white" mt={10}>
            View More
          </Button>
        </Center>
      </Box>
      {/* services */}
      <Box
        maxW="100%"
        mx={"auto"}
        h="100vh"
        display="flex"
        alignItems="center"
        mt={10}
        justifyContent="center"
        px={{ base: 4, md: 10 }}
        bgImage={"bg2.jpeg"}
      >
        <HStack
          spacing={{ base: 6, md: 16 }}
          alignItems="center"
          justifyContent="space-between"
          maxW="1200px"
          w="100%"
        >
          {/* Left Content */}
          <VStack align="flex-start" spacing={4} maxW="500px">
            <Text
              fontSize="sm"
              textTransform="uppercase"
              fontWeight="bold"
              color={"white"}
            >
              Our Services
            </Text>
            <Heading
              fontSize={{ base: "2xl", md: "xl" }}
              fontWeight="bold"
              color={"white"}
            >
              Discover What We Can Do for You
            </Heading>
            <Text fontSize="sm" color={"white"}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </Text>
            <Button size="sm" bg={''} color={'white'}>
              Learn More
            </Button>
          </VStack>

          {/* Right Image Content */}
          <Box position="relative">
            {/* Main Image */}
            <Image
              src="services2.jpg"
              alt="Dashboard"
              borderRadius="lg"
              boxShadow="lg"
              maxW="600px"
              zIndex="1"
            />
            {/* Overlay Image */}
            <Image
              src="services3.jpg"
              alt="Chart"
              borderRadius="lg"
              boxShadow="lg"
              maxW="300px"
              position="absolute"
              bottom="-40px"
              right="-30px"
            />
          </Box>
        </HStack>
      </Box>

      {/* footer */}
      <Box
        bgImage="/vecteezy_abstract-red-speed-neon-light-effect-on-black-background_8440572 1.jpg"
        bgSize="cover"
        bgPosition="center"
        color="white"
        py={8}
        mt={15}
      >
        <Flex direction="column" align="center" gap={"10px"} bottom={"0px"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Image
              src="MarcomLogo.jpg"
              boxSize="60px"
              borderRadius="full"
              fit="cover"
            />
            <Image
              src="marcom2.png"
              alt="Marcom Logo"
              w={"150px"}
              h={"40px"}
              borderRadius="full"
              fit="cover"
              bg={"red"}
            />
          </Flex>

          <Flex gap={8} mb={4}>
            <Link
              href="#home"
              fontSize="lg"
              _hover={{ textDecoration: "underline" }}
            >
              Home
            </Link>
            <Link
              href="#about"
              fontSize="lg"
              _hover={{ textDecoration: "underline" }}
            >
              About Us
            </Link>
            <Link
              href="#services"
              fontSize="lg"
              _hover={{ textDecoration: "underline" }}
            >
              Services
            </Link>
            <Link
              href="#pricing"
              fontSize="lg"
              _hover={{ textDecoration: "underline" }}
            >
              Pricing
            </Link>
          </Flex>
          <Flex gap={6} mb={4}>
            <Link href="https://facebook.com" isExternal>
              <FaFacebook size="24px" />
            </Link>
            <Link href="https://instagram.com" isExternal>
              <FaInstagram size="24px" />
            </Link>
            <Link href="https://youtube.com" isExternal>
              <FaYoutube size="24px" />
            </Link>
            <Link href="https://twitter.com" isExternal>
              <FaTwitter size="24px" />
            </Link>
            <Link href="https://linkedin.com" isExternal>
              <FaLinkedin size="24px" />
            </Link>
          </Flex>
          <Text fontSize="sm" mt={4} color={"#FFFFFF"}>
            Copyright © 2024 Marcom. All rights reserved.
          </Text>
        </Flex>
      </Box>
    </Box>
  );
}

export default Services
