import React from "react";
import { Box, Flex, Badge, Text, Button, Select, Icon, HStack } from "@chakra-ui/react";
import { CiLogin } from "react-icons/ci";
import { GrLanguage } from "react-icons/gr";
import { RiComputerFill } from "react-icons/ri";
import { BsPcDisplay } from "react-icons/bs";
import { IoLanguage } from "react-icons/io5";
const SeassionsSettings = () => {
  return (
    <>
      {/* Language Selection */}
      <Flex  mb={6} alignItems={'center'} gap={10}>
        <Flex gap={3} >
          <Icon as={IoLanguage} boxSize={5} mt={5} />
          <Text fontWeight="400" fontSize="md"  mt={4}>
            Language
          </Text>
        </Flex>
        <Select maxW="200px" size="sm" defaultValue="Marathi">
          <option value="Marathi">Marathi</option>
          <option value="English">English</option>
        </Select>
      </Flex>

      <Flex
        direction={{ base: "column", md: "row" }}
        justify="flex-start"
        align="flex-start"
        padding="20px"
        maxW={{ base: "100%", md: "100%", lg: "80%" }}
      >
        <Box flex="1" marginRight={{ md: "20px", base: "0" }}>
          <Text
            display="flex"
            alignItems="center"
            fontSize="18px"
            fontWeight="700"
            lineHeight="28px"
            textAlign="left"
          >
            <CiLogin style={{ marginRight: "8px" }} />
            Login Sessions
          </Text>
        </Box>
        <Flex
          direction="column"
          align="flex-start"
          flex="1.5"
          mt={{ base: "4", md: "0" }}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            align="flex-start"
            padding="15px"
            border="1px solid #F3F4F6"
            width="100%"
          >
            <Box flex="1" marginRight={{ md: "20px", base: "0" }}>
              <Flex align="">
                <RiComputerFill boxSize={16} />
                <Text color="#171A1F" marginLeft="10px">
                  Chrome on Mac X
                </Text>
                <Badge
                  margin="auto"
                  marginLeft={{ base: "100%", md: "100%", lg: "10px" }}
                  marginBottom={{ base: "", md: "", lg: "20px" }}
                  colorScheme="green"
                >
                  Active
                </Badge>
              </Flex>
              <Text>
                11 Oct at 4:20 PM <br></br> California, USA{" "}
              </Text>
            </Box>
            <Flex direction="column" align="flex-end" flex="1">
              <Button width={"85px"} height={"36px"} padding={"10px"}>
                Log Out
              </Button>
            </Flex>
          </Flex>
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            align="flex-start"
            padding="15px"
            border="1px solid #F3F4F6"
            width="100%"
          >
            <Box flex="1" marginRight={{ md: "20px", base: "0" }}>
              <Flex align="">
                <BsPcDisplay boxSize={16} />
                <Text color="#171A1F" marginLeft="10px">
                  Safari on iPhone XS
                </Text>
              </Flex>
              <Text>
                10 Oct at 6:09 PM <br></br> California, USA
              </Text>
            </Box>
            <Flex direction="column" align="flex-end" flex="1">
              <Button width={"85px"} height={"36px"} padding={"10px"}>
                Log Out
              </Button>
            </Flex>
          </Flex>
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            align="flex-start"
            paddingTop={"20px"}
            padding="15px"
            border="1px solid #F3F4F6"
            width="100%"
          >
            <Box flex="1" marginRight={{ md: "20px", base: "0" }}>
              <Flex align="">
                <RiComputerFill boxSize={16} />
                <Text color="#171A1F" marginLeft="10px">
                  Chrome on Windows 10
                </Text>
              </Flex>
              <Text>
                07 Oct at 3:16 PM <br></br> California, USA
              </Text>
            </Box>
            <Flex direction="column" align="flex-end" flex="1">
              <Button width={"85px"} height={"36px"} padding={"10px"}>
                Log Out
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default SeassionsSettings;
