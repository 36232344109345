import React, { useState } from "react";
import {
  Box,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Text,
  VStack,
  HStack,
  IconButton,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import "@fontsource/noto-sans";

const CreateSequence = () => {
  const [leads, setLeads] = useState([
    {
      name: "Collins",
      company: "NovaWave LLC",
      location: "Newyork, USA",
      phone: "+1 875455453",
      email: "robertson@example.com",
      status: "Closed",
      createdDate: "25 Sep 2023, 01:22 pm",
      owner: "Hendry",
    },
    {
      name: "Konopelski",
      company: "BlueSky Industries",
      location: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      status: "Not Contacted",
      createdDate: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
    },
    {
      name: "Adams",
      company: "SilverHawk",
      location: "Jamestown, NY",
      phone: "+1 546555455",
      email: "vaughan12@example.com",
      status: "Closed",
      createdDate: "04 Oct 2023, 10:18 am",
      owner: "Jami",
    },
    // Add more leads as needed
  ]);

  const [draggedLead, setDraggedLead] = useState(null);
  const [droppedLeads, setDroppedLeads] = useState([]);


   const theme = extendTheme({
     fonts: {
       heading: `'Noto Sans', sans-serif`,
       body: `'Raleway', sans-serif`,
     },
   });

  // Handle dragging a lead
  const handleDragStart = (lead) => {
    setDraggedLead(lead);
  };

  // Handle dropping a lead
  const handleDrop = () => {
    if (draggedLead) {
      setDroppedLeads((prevLeads) => {
        // Avoid duplicating a dropped lead
        const alreadyDropped = prevLeads.some(
          (dropped) => dropped.name === draggedLead.name
        );
        if (!alreadyDropped) {
          return [...prevLeads, draggedLead];
        }
        return prevLeads;
      });
      setDraggedLead(null);
    }
  };

  // Remove a dropped lead
  const handleRemoveDroppedLead = (leadName) => {
    setDroppedLeads((prevLeads) =>
      prevLeads.filter((lead) => lead.name !== leadName)
    );
  };

  return (
    <ChakraProvider theme={theme}>
      <Box p={4} maxW="1200px" mx="auto">
        {/* Drag & Drop Area */}
        <Box
          border="2px dashed #CBD5E0"
          borderRadius="md"
          p={6}
          textAlign="center"
          bg="gray.50"
          mb={4}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          minH="150px"
        >
          {droppedLeads.length > 0 ? (
            <VStack spacing={2} align="stretch">
              <Text fontWeight="bold" fontSize="lg" textAlign="left">
                Dropped Leads:
              </Text>
              {droppedLeads.map((lead, index) => (
                <Flex
                  key={index}
                  p={3}
                  border="1px solid #E2E8F0"
                  borderRadius="md"
                  justify="space-between"
                  align="center"
                  bg="white"
                  shadow="sm"
                >
                  <Box>
                    <Text fontWeight="bold">{lead.name}</Text>
                    <Text fontSize="sm" color="gray.600">
                      {lead.company}
                    </Text>
                    <Text fontSize="sm" color="gray.600">
                      {lead.email}
                    </Text>
                  </Box>
                  <IconButton
                    aria-label="Remove lead"
                    icon={<CloseIcon />}
                    size="sm"
                    colorScheme="red"
                    onClick={() => handleRemoveDroppedLead(lead.name)}
                  />
                </Flex>
              ))}
            </VStack>
          ) : (
            <>
              <Text fontSize="lg" mb={2}>
                Drag and drop Leads here
              </Text>
              <Button colorScheme="red">Import Leads</Button>
            </>
          )}
        </Box>

        {/* Table */}
        <Box overflowX="auto">
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>
                  <Checkbox />
                </Th>
                <Th>Lead Name</Th>
                <Th>Company Name</Th>
                <Th>Phone</Th>
                <Th>Email</Th>
                <Th>Lead Status</Th>
                <Th>Created Date</Th>
                <Th>Lead Owner</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {leads.map((lead, index) => (
                <Tr
                  key={index}
                  draggable
                  onDragStart={() => handleDragStart(lead)}
                  cursor="grab"
                >
                  <Td>
                    <Checkbox />
                  </Td>
                  <Td>{lead.name}</Td>
                  <Td>
                    <Text fontSize="sm">{lead.company}</Text>
                    <Text fontSize="xs" color="gray.500">
                      {lead.location}
                    </Text>
                  </Td>
                  <Td>{lead.phone}</Td>
                  <Td>{lead.email}</Td>
                  <Td>
                    <Button
                      size="xs"
                      colorScheme={
                        lead.status === "Closed"
                          ? "green"
                          : lead.status === "Not Contacted"
                          ? "blue"
                          : "yellow"
                      }
                    >
                      {lead.status}
                    </Button>
                  </Td>
                  <Td>{lead.createdDate}</Td>
                  <Td>{lead.owner}</Td>
                  <Td>
                    <Button size="xs">...</Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        {/* Bottom Buttons */}
        <Flex justify="space-between" mt={4}>
          <Button variant="outline" onClick={() => console.log("Back clicked")}>
            Back
          </Button>
          <Button colorScheme="red" onClick={() => console.log("Submit Post")}>
            Submit Post
          </Button>
        </Flex>
      </Box>
    </ChakraProvider>
  );
};

export default CreateSequence;
