import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tag,
  Text,
  VStack,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  ChakraProvider,
  extendTheme,
  useDisclosure,
  Avatar,
  TagLabel,
  Icon,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { AiOutlineEllipsis } from "react-icons/ai";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, StarIcon } from "@chakra-ui/icons";
// import AdnewDeal from "./AdnewDeal";
import DatePicker from "react-datepicker";
import { FaStar } from "react-icons/fa";
import AddNewCompanyDrawer from "./AddNewCompany";
import "@fontsource/noto-sans";
import { color } from "framer-motion";
import { FiCalendar } from "react-icons/fi";



const CompanyTable = () => {
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
  const [startDate, setStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();


  const handleExport = () => {
   
  };
  const companies = [
    {
      name: "NovaWave LLC",
      phone: "+1 875455453",
      email: "robertson@example.com",
      tags: "Promotion",
      location: "Germany",
      rating: 4.2,
      owner: "Hendry",
      status: "Active",
    },
    {
      name: "BlueSky Industries",
      phone: "+1 989757485",
      email: "sharon@example.com",
      tags: "Rated",
      location: "USA",
      rating: 5.0,
      owner: "Guillory",
      status: "Inactive",
    },
    {
      name: "SilverHawk",
      phone: "+1 546555455",
      email: "vaughan12@example.com",
      tags: "Promotion",
      location: "Canada",
      rating: 3.5,
      owner: "Jami",
      status: "Active",
    },
    {
      name: "SummitPeak",
      phone: "+1 454478787",
      email: "jessica13@example.com",
      tags: "Promotion",
      location: "India",
      rating: 4.5,
      owner: "Theresa",
      status: "Active",
    },
    {
      name: "RiverStone Venture",
      phone: "+1 124547845",
      email: "caroltho3@example.com",
      tags: "Promotion",
      location: "China",
      rating: 4.7,
      owner: "Espinosa",
      status: "Active",
    },
    {
      name: "Bright Bridge Group",
      phone: "+1 478845447",
      email: "dawnmercha@example.com",
      tags: "Promotion",
      location: "Japan",
      rating: 5.0,
      owner: "Martin",
      status: "Active",
    },
  ];

  const filteredData = companies.filter((lead) => true);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  
  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Noto Sans', sans-serif`,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box
        p={8}
        bg="gray.50"
      
        overflowX="auto"
      >
        {/* Header */}
        <Box
  fontWeight="600"
  fontSize="18px"
  mb={4}
  bg="white"
  w="600px"
  h="36px"
  p="10px"
  display="flex" // Enables flexbox
  alignItems="center" // Centers content vertically
>
  Companies
</Box>



        {/* Top Controls */}
        <Box bg="white" p={4}>
          <Flex justifyContent="space-between" mb={4} flexWrap="wrap" gap={4}>
            <Input
              placeholder="Search Companies"
              w={{ base: "100%", md: "505px" }}
            />
            <Flex
              gap={2}
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={"center"}
            >

              <Menu>
                <MenuButton
                  as={Button}
                  colorScheme="white"
                  border="1px solid #E8E8E8"
                  color="#262A2A"
                  rightIcon={<ChevronDownIcon />}
                  mr={2}
                  fontSize="14px"
                  fontWeight="400"
                >
                  Export
                </MenuButton>
                <MenuList>
                  <MenuItem>Export as CSV</MenuItem>
                  <MenuItem>Export as PDF</MenuItem>
                  <MenuItem>Export as Excel</MenuItem>
                </MenuList>
              </Menu>
             
         

              <Button
                colorScheme="red"
                size={buttonSize}
                onClick={onOpen}
                w={"178px"}
                fontWeight={"600"}
                fontSize={"14px"}
              >
                Add New Company
              </Button>
            </Flex>
          </Flex>
          <AddNewCompanyDrawer onClose={onClose} isOpen={isOpen} />
          {/* <AdnewDeal isOpen={isOpen} onClose={onClose} /> */}
          <Divider />
          {/* Filters */}
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            p={2}
            mb={4}
            gap={2}
          >
            <Flex
              spacing={4}
              w="100%"
              direction={{ base: "column", md: "row" }}
              gap={2}
            >
              <Select
                placeholder="Sort"
                fontSize="14px"
                w={{ base: "100%", md: "93px" }}
                p="3px"
              >
                <option value="date">Date</option>
                <option value="amount">Amount</option>
              </Select>
              <Flex alignItems={"center"}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <FiCalendar color="gray.400" />
                  </InputLeftElement>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    customInput={
                      <Input
                        placeholder="Start Date"
                        textAlign="center"
                        fontSize="14px"
                        w={{ base: "100%", md: "180px" }}
                      />
                    }
                  />
                </InputGroup>
              </Flex>
            </Flex>
            <Flex flexDirection={{ base: "column", md: "row" }} gap={2}>
              <Button
                variant="outline"
                fontSize="14px"
                bg={"#F3EDFF"}
                color={"#4A00E5"}
                w={"164px"}
                h={"41px"}
              >
                Manage Columns
              </Button>
              <Button variant="outline" fontSize="13px" w={"88px"} h={"41px"}>
                Filter
              </Button>
            </Flex>
          </Flex>
          {/* Table */}
          <Box bg={"white"} overflow={"auto"}>
            <Table variant="simple" size="md">
              <Thead>
                <Tr bg={"#E8E8E8"} fontWeight={"500"} fontSize={"14px"}>
                  <Th>
                    <Checkbox />
                  </Th>
                  <Th fontWeight={"400"} fontSize={"14px"}>
                    Name
                  </Th>
                  <Th fontWeight={"400"} fontSize={"14px"}>
                    Phone
                  </Th>
                  <Th fontWeight={"400"} fontSize={"14px"}>
                    Email
                  </Th>
                  <Th fontWeight={"400"} fontSize={"14px"}>
                    Tags
                  </Th>
                  <Th fontWeight={"400"} fontSize={"14px"}>
                    Location
                  </Th>
                  <Th fontWeight={"400"} fontSize={"14px"}>
                    Rating
                  </Th>
                  <Th fontWeight={"400"} fontSize={"14px"}>
                    Owner
                  </Th>
                  <Th fontWeight={"400"} fontSize={"14px"}>
                    Contact
                  </Th>
                  <Th fontWeight={"400"} fontSize={"14px"}>
                    Status
                  </Th>
                  <Th fontWeight={"400"} fontSize={"14px"}>
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {companies.map((company, index) => (
                  <Tr key={index}>
                    <Td>
                      <Checkbox />
                    </Td>
                    <Td>
                      <Flex w={"183px"} align="center" gap={4}>
                        <Avatar size="sm" name={company.name} />
                        <Text fontWeight={"500"} fontSize={"14px"} mt={4}>
                          {company.name}
                        </Text>
                      </Flex>
                    </Td>
                    <Td w={"237px"} fontWeight={"400"} fontSize={"14px"}>
                      {company.phone}
                    </Td>
                    <Td w={"200px"} fontWeight={"400"} fontSize={"14px"}>
                      {company.email}
                    </Td>
                    <Td>
                      <Tag
                        size="sm"
                        colorScheme={
                          company.tags === "Promotion" ? "purple" : "yellow"
                        }
                      >
                        <TagLabel>{company.tags}</TagLabel>
                      </Tag>
                    </Td>
                    <Td fontWeight={"400"} fontSize={"14px"}>
                      {company.location}
                    </Td>
                    <Td w={"106px"} p={"10px"}>
                      <Flex align="center" gap={"5px"}>
                        <Icon as={FaStar} color="orange.400" boxSize={5} />
                        <Text ml={2} mt={4} fontSize="14px" fontWeight="400">
                          {company.rating}
                        </Text>
                      </Flex>
                    </Td>
                    <Td fontWeight={"400"} fontSize={"14px"}>
                      {company.owner}
                    </Td>
                    <Td fontWeight={"400"} fontSize={"14px"}>
                      <IconButton
                        icon={<AiOutlineEllipsis />}
                        size="sm"
                        variant="ghost"
                        aria-label="Contact"
                      />
                    </Td>
                    <Td w={"64px"}>
                      <Tag
                        size="sm"
                        colorScheme={
                          company.status === "Active" ? "green" : "red"
                        }
                      >
                        {company.status}
                      </Tag>
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<AiOutlineEllipsis />}
                          size="sm"
                          variant="ghost"
                          aria-label="Actions"
                        />
                        <MenuList>
                          <MenuItem>View</MenuItem>
                          <MenuItem>Edit</MenuItem>
                          <MenuItem>Delete</MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          {/* Pagination */}
          <Flex
            justify="space-between"
            align="center"
            mt={4}
            w={"100%"}
            p={"10px"}
            flexDirection={{ base: "column", md: "row" }}
          >
            <HStack gap={"8px"}>
              <Text>Show </Text>
              <Select
                placeholder="10"
                w="73px"
                h="38px"
                mt={"-15px"}
                fontSize={"14px"}
              ></Select>
              <Text>entries</Text>
            </HStack>
            <HStack>
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(-1)}
                fontWeight={"400"}
                fontSize={"16px"}
                disabled={currentPage === 1}
                leftIcon={<ChevronLeftIcon w={"26px"} h={"25px"} />}
              >
                Prev
              </Button>
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index + 1}
                  w={"26px"}
                  onClick={() => setCurrentPage(index + 1)}
                  bg="#00918E"
                  color={"white"}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                colorScheme="white"
                color="#6F6F6F"
                fontWeight={"400"}
                fontSize={"16px"}
                onClick={() => handlePageChange(1)}
                disabled={currentPage === totalPages}
                rightIcon={<ChevronRightIcon />}
              >
                Next
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

// Helper Functions
const getTagColor = (tag) => {
  switch (tag) {
    case "Collab":
      return "green";
    case "Rated":
      return "yellow";
    case "Rejected":
      return "red";
    default:
      return "gray";
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case "Won":
      return "green";
    case "Lost":
      return "red";
    case "Open":
      return "blue";
    default:
      return "gray";
  }
};

export default CompanyTable;
