import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tag,
  Text,
  VStack,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  ChakraProvider,
  extendTheme,
  useDisclosure,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { AiOutlineEllipsis } from "react-icons/ai";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import AdnewDeal from "./AdnewDeal";
import DatePicker from "react-datepicker";
import "@fontsource/noto-sans";
import { FiCalendar } from "react-icons/fi";


 const theme = extendTheme({
   fonts: {
     heading: `'Noto Sans', sans-serif`,
     body: `'Noto Sans', sans-serif`,
   },
 });


const DealsTable = () => {
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
  const [startDate, setStartDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [sortOption, setSortOption] = useState(""); // State for sort option
  const [selectedPriority, setSelectedPriority] = useState("");

  const dealsData = [
    {
      dealName: "Collins",
      stage: "Qualify To Buy",
      dealValue: "$04,51,000",
      tags: "Collab",
      closeDate: "25 Sep 2023",
      owner: "Hendry",
      probability: "90%",
      status: "Won",
    },
    {
      dealName: "Konopelski",
      stage: "Proposal Made",
      dealValue: "$03,12,500",
      tags: "Rated",
      closeDate: "29 Sep 2023",
      owner: "Guillory",
      probability: "15%",
      status: "Lost",
    },
    {
      dealName: "Adams",
      stage: "Contact Made",
      dealValue: "$04,14,800",
      tags: "Rejected",
      closeDate: "04 Oct 2023",
      owner: "Jami",
      probability: "95%",
      status: "Won",
    },
    {
      dealName: "Schumm",
      stage: "Qualify To Buy",
      dealValue: "$11,14,400",
      tags: "Collab",
      closeDate: "15 Oct 2023",
      owner: "Theresa",
      probability: "99%",
      status: "Won",
    },
    {
      dealName: "Wisozk",
      stage: "Presentation",
      dealValue: "$16,11,400",
      tags: "Rated",
      closeDate: "27 Oct 2023",
      owner: "Espinosa",
      probability: "10%",
      status: "Open",
    },
    {
      dealName: "Heller",
      stage: "Appointment",
      dealValue: "$78,11,800",
      tags: "Rated",
      closeDate: "07 Nov 2023",
      owner: "Martin",
      probability: "70%",
      status: "Won",
    },
  ];

  const filteredData = dealsData.filter((lead) => true);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const filteredPipelines = dealsData.filter(
    (pipeline) =>
      pipeline?.dealName.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
  );

  const sortPipelines = (data, option) => {
    if (option === "dealName") {
      return [...data].sort((a, b) => a.dealName.localeCompare(b.dealName)); // Alphabetical order
    } else if (option === "dealValue") {
      return [...data].sort(
        (a, b) => parseFloat(b.dealValue) - parseFloat(a.dealValue)
      ); // Highest to lowest deal value
    }
    return data;
  };

 
  
  
  const sortedPipelines = selectedPriority
    ? dealsData.filter(
        (project) =>
          project.status.toLowerCase() === selectedPriority.toLowerCase()
      ):sortPipelines(filteredPipelines, sortOption);

  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Noto Sans', sans-serif`,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box
        p={5}
        bg="gray.50"
     
        overflowX="auto"
      >
        {/* Header */}
        <Box
          fontWeight="500"
          fontSize="16px"
          mb={4}
          bg="white"
          w="600px"
          h="36px"
          p="5px"
        >
          Deals
        </Box>
        {/* Top Controls */}
        <Box bg="white" p={4}>
          <Flex justifyContent="space-between" mb={4} flexWrap="wrap" gap={4}>
            <Input
              placeholder="Search Deals"
              w={{ base: "100%", md: "502px" }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <HStack spacing={4}>
            <Menu>
                <MenuButton
                  as={Button}
                  colorScheme="white"
                  border="1px solid #E8E8E8"
                  color="#262A2A"
                  rightIcon={<ChevronDownIcon />}
                  mr={2}
                  fontSize="14px"
                  fontWeight="400"
                >
                  Export
                </MenuButton>
                <MenuList>
                  <MenuItem>Export as CSV</MenuItem>
                  <MenuItem>Export as PDF</MenuItem>
                  <MenuItem>Export as Excel</MenuItem>
                </MenuList>
              </Menu>
              <Button
                colorScheme="red"
                size={buttonSize}
                fontSize={"14px"}
                w={"178px"}
                onClick={onOpen}
              >
                Add New Deals
              </Button>
            </HStack>
          </Flex>
          <AdnewDeal isOpen={isOpen} onClose={onClose} />
          <Divider />
          {/* Filters */}
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            p={2}
            mb={4}
          >
            <HStack spacing={4}>
              <Select
                placeholder="Sort"
                fontSize="14px"
                w={{ base: "100%", md: "153px" }}
                p="3px"
                onChange={(e) => setSortOption(e.target.value)}
              >
                <option value="dealName">Name</option>
                <option value="dealValue">Value</option>
              </Select>

              <Flex alignItems={"center"}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <FiCalendar color="gray.400" />
                  </InputLeftElement>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    customInput={
                      <Input
                        placeholder="Start Date"
                        textAlign="center"
                        fontSize="14px"
                        w={{ base: "100%", md: "180px" }}
                      />
                    }
                  />
                </InputGroup>
              </Flex>
            </HStack>
            <HStack mt={{ base: 2, md: 0 }}>
              <Button
                variant="outline"
                fontSize="13px"
                w={"164px"}
                h={"40px"}
                bg={"#F3EDFF"}
                color={"#4A00E5"}
              >
                Manage Columns
              </Button>
              <Select
                placeholder="Filter"
                onChange={(e) => setSelectedPriority(e.target.value)}
                w="120px"
              >
                <option value="Won">Won</option>
                <option value="Lost">Lost</option>
                <option value="Open">Open</option>
              </Select>
            </HStack>
          </Flex>
          {/* Table */}
          <Box bg={"white"} overflow={"auto"}>
            <Table variant="simple" size="md">
              <Thead bg={"#E8E8E8"}>
                <Tr>
                  <Th>
                    <Checkbox />
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Deal Name
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Stage
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Deal Value
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Tags
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Expected Close Date
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Owner
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Probability
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Status
                  </Th>
                  <Th fontWeight={"500"} fontSize={"14px"}>
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {sortedPipelines.map((deal, index) => (
                  <Tr key={index}>
                    <Td>
                      <Checkbox />
                    </Td>
                    <Td fontSize={"14px"} fontWeight={"500"}>
                      {deal.dealName}
                    </Td>
                    <Td fontSize={"14px"} fontWeight={"400"}>
                      {deal.stage}
                    </Td>
                    <Td fontSize={"14px"} fontWeight={"400"}>
                      {deal.dealValue}
                    </Td>
                    <Td>
                      <Tag colorScheme={getTagColor(deal.tags)}>
                        {deal.tags}
                      </Tag>
                    </Td>
                    <Td fontSize={"14px"} fontWeight={"400"}>
                      {deal.closeDate}
                    </Td>
                    <Td fontSize={"14px"} fontWeight={"400"}>
                      {deal.owner}
                    </Td>
                    <Td fontSize={"14px"} fontWeight={"400"}>
                      {deal.probability}
                    </Td>
                    <Td>
                      <Tag colorScheme={getStatusColor(deal.status)}>
                        {deal.status}
                      </Tag>
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<AiOutlineEllipsis />}
                          variant="ghost"
                        />
                        <MenuList>
                          <MenuItem>View</MenuItem>
                          <MenuItem>Edit</MenuItem>
                          <MenuItem>Delete</MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          {/* Pagination */}
          <Flex
            justify="space-between"
            align="center"
            mt={4}
            w={"100%"}
            p={"10px"}
            flexDirection={{ base: "column", md: "row" }}
          >
            <HStack gap={"5px"}>
              <Text>Show </Text>
              <Select
                placeholder="10"
                w="73px"
                h="38px"
                mt={"-15px"}
                fontSize={"14px"}
              ></Select>
              <Text>entries</Text>
            </HStack>
            <HStack>
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(-1)}
                disabled={currentPage === 1}
                leftIcon={<ChevronLeftIcon w={"26px"} h={"25px"} />}
              >
                Prev
              </Button>
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index + 1}
                  onClick={() => setCurrentPage(index + 1)}
                  colorScheme={currentPage === index + 1 ? "blue" : "gray"}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === totalPages}
                rightIcon={<ChevronRightIcon />}
              >
                Next
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

// Helper Functions
const getTagColor = (tag) => {
  switch (tag) {
    case "Collab":
      return "green";
    case "Rated":
      return "yellow";
    case "Rejected":
      return "red";
    default:
      return "gray";
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case "Won":
      return "green";
    case "Lost":
      return "red";
    case "Open":
      return "blue";
    default:
      return "gray";
  }
};

export default DealsTable;
