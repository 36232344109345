import { ChakraProvider, Divider, extendTheme } from '@chakra-ui/react'
import React, { useState } from 'react'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Input,
  Select,
  Flex,
  IconButton,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { FaPlus } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "@fontsource/poppins";


const AddProject = ({isOpen,onClose}) => {
   

     const [projectID, setProjectID] = useState(null);
     const [projectType, setProjectType] = useState(null);
     const [startDate, setStartDate] = useState(null);
     const [dueDate, setDueDate] = useState(null);
      const theme = extendTheme({
        fonts: {
          heading: "Poppins, sans-serif",
          body: "Poppins, sans-serif",
          mono: "Poppins, sans-serif",
        },
        
      });


  return (
    <ChakraProvider theme={theme}>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add New Projects</DrawerHeader>
          <Divider />
          <DrawerBody>
            <Flex direction="column" gap={4}>
              <Box>
                <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                  Name <span style={{ color: "red" }}>*</span>
                </Text>
                <Input placeholder="" />
              </Box>

              <Flex gap={4}>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Project ID <span style={{ color: "red" }}>*</span>
                  </Text>
                  <DatePicker
                    selected={projectID}
                    onChange={(date) => setProjectID(date)}
                    customInput={<Input />}
                  />
                </Box>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Project Type <span style={{ color: "red" }}>*</span>
                  </Text>
                  <DatePicker
                    selected={projectType}
                    onChange={(date) => setProjectType(date)}
                    customInput={<Input />}
                  />
                </Box>
              </Flex>

              <Flex gap={4}>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Client <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Input placeholder="" />
                </Box>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Category <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Input placeholder="" />
                </Box>
              </Flex>

              <Flex gap={1}>
                <Box flex="1" mt={'-10px'}>
                  <Text  fontSize={"15px"} fontWeight={"500"}>
                    Project Timing <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Input placeholder="" />
                </Box>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Price <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Input placeholder="" />
                </Box>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Amount <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Input placeholder="" />
                </Box>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Total <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Input placeholder="" />
                </Box>
              </Flex>

              <Flex gap={4}>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Responsible Person <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Select placeholder="">
                    <option>John Doe</option>
                    <option>Jane Smith</option>
                  </Select>
                </Box>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Team Leader <span style={{ color: "red" }}>*</span>
                  </Text>
                  <Select placeholder="">
                    <option>John Doe</option>
                    <option>Jane Smith</option>
                  </Select>
                </Box>
              </Flex>

              <Flex gap={4}>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Start Date <span style={{ color: "red" }}>*</span>
                  </Text>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={<Input />}
                  />
                </Box>
                <Box flex="1">
                  <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                    Due Date <span style={{ color: "red" }}>*</span>
                  </Text>
                  <DatePicker
                    selected={dueDate}
                    onChange={(date) => setDueDate(date)}
                    customInput={<Input />}
                  />
                </Box>
              </Flex>

              <Box>
                <Text mb={2} fontSize={"15px"} fontWeight={"500"}>
                  Description <span style={{ color: "red" }}>*</span>
                </Text>
                <Input placeholder="" />
              </Box>
            </Flex>
          </DrawerBody>

          <DrawerFooter>
            <Button
              variant="outline"
              mr={3}
              onClick={onClose}
              fontSize={"15px"}
              fontWeight={"500"}
            >
              Cancel
            </Button>
            <Button colorScheme="red">Create</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
}

export default AddProject