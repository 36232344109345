import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Select,
  Box,
  Text,
  Spinner,
  Checkbox,
} from "@chakra-ui/react";
import { toast } from "react-toastify";
import { CallAPI } from "../../Services/service"; // Assuming you have an API call function
import endpoints from "../../Services/endpoint"; // Replace with actual endpoints
import { useAuthContext } from "../CommunityGroup/context/AuthContext";

const OrganizationModal = ({ isOpen, onClose, organizations,selectCampaign }) => {
  const [selectedOrg, setSelectedOrg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orgDetails, setOrgDetails] = useState(null);
  const { platformTokens, updatePlatformTokens } = useAuthContext(); 

  // Handle organization selection
  const handleCheckboxChange = (orgId) => {
    setSelectedOrg((prevSelectedOrgs) => {
      if (prevSelectedOrgs.includes(orgId)) {
        // If the orgId is already selected, remove it (deselect)
        return prevSelectedOrgs.filter((id) => id !== orgId);
      } else {
        // If the orgId is not selected, add it (select)
        return [...prevSelectedOrgs, orgId];
      }
    });
  };
  
  // Handle "View" button click to fetch organization details
  const fetchOrganizationDetails = async (organizationId) => {
    setLoading(true);
    try {
      let orgId=   organizationId.split(":").pop();
      // Fetch organization details using the organization ID
      const response = await CallAPI(endpoints.getorganisationdetails, {
        token: platformTokens.LinkedIn ,       orgId:  orgId ,
      });
      setOrgDetails(response.data);
            if (response?.code === 200) {
        
      } else {
        toast.error("Failed to fetch organization details.");
      }
    } catch (error) {
      toast.error("Error fetching organization details.");
    } finally {
      setLoading(false);
    }
  };

  // Handle posting selected organization
  const handlePost = async () => {
    if (!selectedOrg) {
      toast.error("Please select an organization.");
      return;
    }
    const orgId=   selectedOrg.split(":").pop();
    try {
      // Make your API POST call to submit the selected organization
      const res = await CallAPI(endpoints.postonlinkedin , {
        content:selectCampaign.name ,organizationId:orgId ,token: platformTokens.LinkedIn
      });

      if (res?.code === 200) {
        toast.success("Organization posted successfully!");
        onClose(); // Close modal after successful post
      } else {
        toast.error("Failed to post the organization.");
      }
    } catch (error) {
      toast.error("Error posting the organization.");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Organization</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Select Organization */}
          {organizations.map((org) => (
                    <Box key={org.organization} mb={4} style={{display:"flex",justifyContent:"space-between"}}>
          <Checkbox
                        value={org.organization}
                        isChecked={selectedOrg.includes(org.organization)}
                       onChange={() => handleCheckboxChange(org.organization)}
                      >
                        {org.organization} 
                      </Checkbox>
                      <Button
                        ml={2}
                        size="sm"
                        bg="green"
                        color={"white"}
                        onClick={() => fetchOrganizationDetails(org.organization)}
                      >
                        View
                      </Button>
                      </Box>
          ))}

          {/* Loading Spinner while fetching details */}
          {loading && <Spinner mt={4} />}

          {/* Display Organization Details */}
          {orgDetails && (
            <Box mt={4}>
              <Text fontWeight="bold"> Organization Details:</Text>
              <Text>Name: {orgDetails.localizedName}</Text>
              <Text>Role: {orgDetails.organizationType}</Text>
              {/* Add other details you need from the API response */}
            </Box>
          )}

          
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" onClick={handlePost} disabled={!selectedOrg}>
            Post to Organization
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrganizationModal;
