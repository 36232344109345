const endpoints = {
  signup: '/api/user/register',
  signin: '/api/user/login',
  googleSignIn: '/api/auth/google-signin',
  emailverify: '/api/user/emailverify',
  sendotp: '/api/user/send-otp',
  resetpassword: '/api/user/change-password',
  linkedinCampaign: '/api/user/linkedin-campaign',
  emailCampaign: '/api/user/email-campaign',
  whatsapp_campaign: '/api/user/whatsapp-campaign',
  allCampaigns: '/api/user/all-campaigns',
  twitterCampaign: '/api/user/twitter-campaign',
  instagram_campaign: '/api/user/instagram-campaign',
  facebook_campaign: '/api/user/facebook-campaign',
  updateUser: '/api/user/update-user-profile',
  newsletterSubscriber: '/api/user/newsletter-subscribers',
  findUser: '/api/user/find-user',
  createLead:"/api/user/create-lead",
  getleads: "/api/user/get-leads",
  createLeads:"/api/user/create-leads",

  getEvents:"/api/user/get-events",
  createEvents:"/api/user/create-events",

  createCampaigns:"/api/user/create-campaign",
  deletecampaign :"/api/user/delete-campaign",
  getCampaigns:"/api/user/getall-campaigns-api",
  socialmediatokens:"/api/user/get-social-media-tokens",
  getcampaigngroup :"/api/user/get-campaign-group",
  createcampaigngroup :"/api/user/create-campaign-group",
 gettargets:"/api/user/get-target-facets",
 gettargetsbyfacets:"/api/user/get-target-byfacets",
 uploadimage:"/api/user/upload-image",

createlinkedinauth:"/api/user/auth/linkedin",
getlinkedintoken :"/api/user/auth/linkedin/callback",
getoraganisations :"/api/user/get-organisations",
getlinkedinprofile :"/api/user/get-linkedin-profle",
createlinkedinpost :"/api/user/create-linkedin-posts",
createlinkedinOrganisationpost :"/api/user/create-organisation-linkedin-posts",

uploadtoaws:"/api/user/post-on-aws",
getfilesfromaws:"/api/user/get-files-from-aws",
getorganisationdetails:"/api/user/get-organisation-details",
getimageurn :"/api/user/get-image-urn",
  getUsers:"/api/user/get-users",
  createUsers:"/api/user/create-users",
postonlinkedin :"/api/user/poston-linkedin",
  setAlphaTesting:'/api/user/set-alpha-testing-type',
  setBetaTesting:'/api/user/set-beta-testing-type',
  campaignDetails:'/api/user/campaign-detail',
  findLeads:'/api/user/find-leads',
  addcard:'api/user/add-card',
  getCardData:'api/user/get-card',
  DeleteCard:'api/user/delete-card',
  updateCard:'api/user/update-card'
};

export default endpoints;
