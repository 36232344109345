import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Button,
  Badge,
  Avatar,
  Input,
  HStack,
  VStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Text,
  IconButton,
  Flex,
  useDisclosure,
  ChakraProvider,
  theme,
  extendTheme,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  AiOutlineDownload,
  AiOutlineFilter,
  AiOutlineColumnHeight,
} from "react-icons/ai";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import { FiFilter } from "react-icons/fi";
import companyicon from "../Dashboard/icons/companyicon.png";
import ReactApexChart from "react-apexcharts";
import "@fontsource/noto-sans";


const ContactReport = () => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [filter, setFilter] = useState("Active Campaign");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const leadManagementData = [
    {
      id: 1,
      leadName: "John Doe",
      companyName: "ABC Corp",
      phone: "123-456-7890",
      email: "john.doe@abccorp.com",
      leadStatus: "Success",
      createdDate: "25 Sep 2023",
      leadOwner: "Alice Johnson",
      location: "New York, USA",
      avatar: companyicon,
    },
    {
      id: 2,
      leadName: "Jane Smith",
      companyName: "XYZ Inc",
      phone: "098-765-4321",
      email: "jane.smith@xyzinc.com",
      leadStatus: "Pending",
      createdDate: "03 Oct 2023",
      location: "New York, USA",
      avatar: companyicon,
      leadOwner: "Bob Lee",
    },
    // Add more lead records as needed
  ];

  const filteredData = leadManagementData.filter((lead) => {
    // Add your filtering logic here
    return true;
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

     const theme = extendTheme({
       fonts: {
         heading: `'Noto Sans', sans-serif`,
         body: `'Noto Sans', sans-serif`,
       },
     });

  

  const chartdata = {
    series: [
      {
        name: "Value 1",
        data: [20, 40, 60, 90, 50, 70, 30, 50, 60, 30, 40, 30],
      },
    ],
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  };

  const options = {
    chart: {
      type: "area",
      height: 400,
      toolbar: { show: false },
    },
    stroke: { width: 2, curve: "smooth" },
    colors: ["#4285F4", "#FF5722", "#FFC107"],
    xaxis: {
      categories: chartdata.categories,
      title: {
        text: "",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: { position: "top" },
  };

  const doughnutChartOptions = {
    chart: { type: "donut" },
    labels: ["Campaign", "Google", "Referrals", "Paid Social"],
    legend: { position: "bottom" },
  };
  const doughnutChartData = [44, 58, 41, 17];

  const LeadManagementTable = ({ data }) => (
    <Table
      variant="simple"
      border="1px solid"
      borderColor="gray.200"
      size={{ base: "sm", md: "md" }}
    >
      <Thead>
        <Tr bg={"#E8E8E8"}>
          <Th>
            <Checkbox />
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"} p={"5px"}>
            Lead Name
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Company Name
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Phone
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Email
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Lead Status
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Created Date
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Lead Owner
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Action
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((lead) => (
          <Tr key={lead.id}>
            <Td>
              <Checkbox />
            </Td>
            <Td fontSize="16px" fontWeight="500" color="#26262A">
              {lead.leadName}
            </Td>
            <Td>
              <Flex
                alignItems="center"
                h={"50px"}
                lineHeight={"2px"}
                w={"150px"}
              >
                <Avatar size="sm" name={lead.name} mr={2} />
                <Flex mt={"15px"} flexDirection={"column"}>
                  <Text fontWeight="600" fontSize="14px">
                    {lead.companyName}
                  </Text>
                  <Text fontSize="12px">{lead.location}</Text>
                </Flex>
              </Flex>
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {lead.phone}
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {lead.email}
            </Td>
            <Td>
              <Badge
                bg={"orange"}
                borderRadius="md"
                p="2"
                textAlign="center"
                fontWeight="600"
                color="white"
                fontFamily="Poppins"
              >
                {lead.leadStatus}
              </Badge>
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {lead.createdDate}
            </Td>
            <Td fontSize="14px" fontWeight="500" color="#26262A">
              {lead.leadOwner}
            </Td>
            <Td>
              <IconButton
                icon={<FaEllipsisV />}
                variant="ghost"
                aria-label="Options"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <ChakraProvider theme={theme}>
      <Box
        p={{ base: 3, md: 5 }}
        borderWidth="1px"
        borderRadius="md"
        bg="white"
      >
        <HStack
          justifyContent="space-between"
          mb="6"
          alignItems="center"
          flexWrap="wrap"
        >
          <Input
            placeholder="Search Contacts"
            w={{ base: "100%", md: "300px" }}
            mb={{ base: 2, md: 0 }}
          />
          <Button colorScheme="red" leftIcon={<AiOutlineDownload />}>
            Download Report
          </Button>
        </HStack>

        <Flex direction={{ base: "column", lg: "row" }} gap={6} mb="6">
          <Box
            width={{ base: "100%", md: "50%" }}
            p="4"
            borderWidth="1px"
            borderRadius="md"
          >
            <Flex
              justify="space-between"
              flexDirection={{ base: "column", md: "row" }}
              gap={"4px"}
              align="center"
              mb="2"
            >
              <Text
                as="h2"
                fontFamily="Poppins, sans-serif"
                fontWeight="500"
                fontSize="18px"
                lineHeight="27px"
              >
                Contacts By Year
              </Text>
              <Box border="1px solid #E8E8E8" padding="5px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                />
              </Box>
            </Flex>
            <ReactApexChart
              options={options}
              series={chartdata.series}
              type="line"
              height={350}
            />
          </Box>

          <Box
            width={{ base: "100%", md: "50%" }}
            p="5"
            borderWidth="1px"
            borderRadius="md"
          >
            {/* <Flex justify="space-between" align="center" mb="2">
             */}
            <Flex
              justify="space-between"
              flexDirection={{ base: "column", md: "row" }}
              gap={"4px"}
              align="center"
              mb="2"
            >
              <Text
                as="h2"
                fontFamily="Poppins, sans-serif"
                fontWeight="500"
                fontSize="18px"
                lineHeight="27px"
              >
                Contacts By Source
              </Text>
              <Box border="1px solid #E8E8E8" padding="5px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                />
              </Box>
            </Flex>
            <Chart
              options={doughnutChartOptions}
              series={doughnutChartData}
              type="donut"
              height={350}
            />
          </Box>
        </Flex>

        <Box overflowX="auto">
          <Box overflowX="auto" bg={"white"}>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
              mb={4}
            >
              <Flex
                gap="7px"
                maxW={{ base: "100%", md: "30%" }}
                direction={{ base: "column", md: "row" }}
              >
                <Select
                  placeholder="Sort"
                  w={{ base: "100%", md: "30%" }}
                  mt={{ base: 2, md: 0 }}
                  fontWeight={"bold"}
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  p="7px"
                >
                  <option value="date">Date</option>
                  <option value="amount">Amount</option>
                </Select>
                <Flex mt="5px">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    customInput={<Input />}
                  />
                </Flex>
              </Flex>

              <Flex mt={{ base: 2, md: 0 }} gap={"5px"} mr={"3px"}>
                <Button
                  variant="outline"
                  mr={2}
                  bg={"#F3EDFF"}
                  color={"#4A00E5"}
                  fontSize={"13px"}
                  fontWeight={"600"}
                >
                  Manage Columns
                </Button>
                <Button variant="outline" fontWeight={"500"} fontSize={"14px"}>
                  Filter
                </Button>
              </Flex>
            </Flex>
          </Box>
          <LeadManagementTable data={paginatedData} />
        </Box>
        <HStack justifyContent="space-between" mt={4} alignItems="center">
          <HStack spacing={2}>
            <Box color="#6F6F6F">Show</Box>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              w="80px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
            <Box color="#6F6F6F">entries</Box>
          </HStack>

          <HStack spacing={2}>
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(-1)}
              leftIcon={<ChevronLeftIcon />}
              disabled={currentPage === 1}
            >
              Prev
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                colorScheme={currentPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(1)}
              rightIcon={<ChevronRightIcon />}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </HStack>
        </HStack>
      </Box>
    </ChakraProvider>
  );
};

export default ContactReport;
