import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Tag,
  Text,
  useBreakpointValue,
  VStack,
  useDisclosure,
  ChakraProvider,
  extendTheme,
  Badge,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { FiCalendar, FiMoreVertical } from "react-icons/fi";
import { HiOutlineSearch } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";
import ClientInfo from "./ClientInfo";
import { useState } from "react";
import DatePicker from "react-datepicker";
import AddNewProject from "./AddNewProject";
import AddProject from "./AddNewProject";
import "@fontsource/noto-sans";
import CompanyProfile from "../Header/CompanyProfile";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";


const projects = [
  {
    name: "Trulysell",
    client: "NovaWave LLC",
    priority: "High",
    startDate: "25 Sep 2023",
    endDate: "15 Oct 2023",
    type: "Web App",
    pipelineStage: "Plan",
    status: "Active",
  },
  {
    name: "Dreamschat",
    client: "BlueSky Industries",
    priority: "Medium",
    startDate: "29 Sep 2023",
    endDate: "19 Oct 2023",
    type: "Web App",
    pipelineStage: "Develop",
    status: "Inactive",
  },
  {
    name: "Trulysell",
    client: "SilverHawk",
    priority: "High",
    startDate: "05 Oct 2023",
    endDate: "12 Oct 2023",
    type: "Web App",
    pipelineStage: "Completed",
    status: "Active",
  },
  {
    name: "Servbook",
    client: "SummitPeak",
    priority: "Medium",
    startDate: "14 Oct 2023",
    endDate: "24 Oct 2023",
    type: "Web App",
    pipelineStage: "Design",
    status: "Inactive",
  },
  {
    name: "DreamPOS",
    client: "RiverStone Ventur",
    priority: "Low",
    startDate: "15 Nov 2023",
    endDate: "22 Nov 2023",
    type: "Web App",
    pipelineStage: "Design",
    status: "Inactive",
  },
];



const Project = () => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [startDate, setStartDate] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [sortOption, setSortOption] = useState(""); // State for sort option
   const [selectedPriority, setSelectedPriority] = useState(""); 
     const [check, setCheck] = useState("");


   const filteredPipelines = projects.filter(
    (pipeline) =>
      pipeline.name.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
  );

  
const onChangeEvent = (e) => {
  setCheck(e.target.checked);
};

const sortPipelines = (data, option) => {
    if (option === "name") {
      return [...data].sort((a, b) => a.name.localeCompare(b.name)); // Alphabetical order
    } 
    return data;
    }

  // const sortedPipelines = sortPipelines(filteredPipelines, sortOption);

  const sortedPipelines = selectedPriority
    ? projects.filter((project) => project.priority === selectedPriority)
    : sortPipelines(filteredPipelines, sortOption);;



  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Noto Sans', sans-serif`,
    },
  });

  const getStatusBadge = (status) => {
    if (status === "Active") {
      return <Badge colorScheme="green">Active</Badge>;
    } else if (status === "Inactive") {
      return <Badge colorScheme="red">Inactive</Badge>;
    }
  };

  const getStageBadge = (stage) => {
    switch (stage) {
      case "Completed":
        return (
          <Flex alignItems={"center"} gap={"1px"}>
            <Box
              height="5px" // Set the height of the line
              width="60px" // Set the width of the line
              bg="green.500" // Set the background color to green
              borderRadius="lg"
              mt={"-20px"} // Optional: Add rounded edges
            />
            <Text>Completed</Text>
          </Flex>
        );
      case "Plan":
        return (
          <Flex alignItems={"center"} gap={"1px"}>
            <Box
              height="5px" // Set the height of the line
              width="60px" // Set the width of the line
              bg="purple.500" // Set the background color to green
              borderRadius="lg"
              mt={"-20px"} // Optional: Add rounded edges
            />
            <Text>Plan</Text>
          </Flex>
        );
      case "Design":
        return (
          <Flex alignItems={"center"} gap={"1px"}>
            <Box
              height="5px" // Set the height of the line
              width="60px" // Set the width of the line
              bg="orange.500" // Set the background color to green
              borderRadius="lg"
              mt={"-20px"} // Optional: Add rounded edges
            />
            <Text>Design</Text>
          </Flex>
        );
      case "Develop":
        return (
          <Flex alignItems={"center"} gap={"3px"}>
            <Box
              height="5px" // Set the height of the line
              width="60px" // Set the width of the line
              bg="red.500" // Set the background color to green
              borderRadius="lg"
              mt={"-20px"} // Optional: Add rounded edges
            />
            <Text>Develop</Text>
          </Flex>
        );
      default:
        return <Badge>{stage}</Badge>;
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const filteredData = projects.filter((lead) => {
    // Add your filtering logic here
    return true;
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  return (
    <ChakraProvider theme={theme}>
      <Box width="100%" p={6}>
        {/* <ClientInfo /> */}
        <CompanyProfile />
        {/* Header with Search and Add Project */}
        <Box bg={"white"}  >
          <Flex
            justifyContent="space-between"
            mt={4}
            flexWrap="wrap"
            gap={4}
            bg={"white"}
          >
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              mt={"15px"}
              w={"100%"}
              px={"10px"}
            >
              <Input
                placeholder="Search Projects"
                p={"5px"}
                w={{ base: "100%", md: "40%" }}
                mb={{ base: 2, md: 0 }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <Flex
                justifyContent="space-around"
                gap="10px"
                mr="15px"
                w={{ base: "100%", md: "auto" }}
                direction={{ base: "column", md: "row" }}
              >
                <Select placeholder="Export" w="100%" fontSize={"14px"}>
                  <option value="date">Date</option>
                  <option value="amount">Amount</option>
                </Select>
                <Button
                  colorScheme="red"
                  w="100%"
                  onClick={onOpen}
                  fontSize={"14px"}
                >
                  Add New Project
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <AddProject isOpen={isOpen} onClose={onClose} />

          <Divider mt={4} />
          <Box overflowX="auto" bg={"white"}>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
              mb={4}
            >
              <Flex
                gap="7px"
                maxW={{ base: "100%", md: "30%" }}
                direction={{ base: "column", md: "row" }}
              >
                <Select
                  placeholder="Sort"
                  fontSize={"14px"}
                  w="40%"
                  mt={{ base: 2, md: 0 }}
                  p="3px"
                  onChange={(e) => setSortOption(e.target.value)}
                >
                  <option value="name">Name</option>
                </Select>
                <Flex alignItems={"center"}>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <FiCalendar color="gray.400" />
                    </InputLeftElement>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Start Date"
                      customInput={
                        <Input
                          placeholder="Start Date"
                          textAlign="center"
                          fontSize="14px"
                          w={{ base: "100%", md: "180px" }}
                        />
                      }
                    />
                  </InputGroup>
                </Flex>
              </Flex>

              <Flex mt={{ base: 2, md: 0 }}>
                <Button
                  variant="outline"
                  mr={2}
                  bg={"#F3EDFF"}
                  color={"#4A00E5"}
                  fontSize={"13px"}
                >
                  Manage Columns
                </Button>
                {/* <Button variant="outline" fontSize={"13px"}>
                  Filter
                </Button> */}
                <Select
                  placeholder="Filter"
                  onChange={(e) => setSelectedPriority(e.target.value)}
                  w="120px"
                >
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </Select>
              </Flex>
            </Flex>
            {/* Table */}
            <Table variant="simple">
              <Thead bg="#E8E8E8" color={"#FAFAFA"}>
                <Tr>
                  <Th>
                    <input
                      type="checkbox"
                      checked={check}
                      onChange={(e) => onChangeEvent(e)}
                    />
                  </Th>
                  <Th fontSize={"14px"} fontWeight={"500"}>
                    Name
                  </Th>
                  <Th fontSize={"14px"} fontWeight={"500"}>
                    Client
                  </Th>
                  <Th fontSize={"14px"} fontWeight={"500"}>
                    Priority
                  </Th>
                  <Th fontSize={"14px"} fontWeight={"500"}>
                    Start Date
                  </Th>
                  <Th fontSize={"14px"} fontWeight={"500"}>
                    End Date
                  </Th>
                  <Th fontSize={"14px"} fontWeight={"500"}>
                    Type
                  </Th>
                  <Th fontSize={"14px"} fontWeight={"500"}>
                    Pipeline Stage
                  </Th>
                  <Th fontSize={"14px"} fontWeight={"500"}>
                    Status
                  </Th>
                  <Th fontSize={"14px"} fontWeight={"500"}>
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody bg={"white"}>
                {sortedPipelines &&
                  sortedPipelines.map((project, index) => (
                    <Tr key={index}>
                      <Td>
                        <input type="checkbox" checked={check} />
                      </Td>
                      <Td fontSize={"14px"} fontWeight={"400"}>
                        {project.name}
                      </Td>
                      <Td fontSize={"14px"} fontWeight={"400"}>
                        {project.client}
                      </Td>
                      <Td>
                        <Tag
                          colorScheme={
                            project.priority === "High"
                              ? "red"
                              : project.priority === "Medium"
                              ? "yellow"
                              : "green"
                          }
                          fontSize={"14px"}
                          fontWeight={"600"}
                        >
                          {project.priority}
                        </Tag>
                      </Td>
                      <Td fontSize={"14px"} fontWeight={"200"}>
                        {project.startDate}
                      </Td>
                      <Td fontSize={"14px"} fontWeight={"200"}>
                        {project.endDate}
                      </Td>
                      <Td fontSize={"14px"} fontWeight={"200"}>
                        {project.type}
                      </Td>
                      <Td
                        w={"98px"}
                        h={"19px"}
                        fontWeight={"400"}
                        fontSize={"14px"}
                      >
                        {getStageBadge(project.pipelineStage)}
                      </Td>
                      <Td>
                        <Tag
                          colorScheme={
                            project.status === "Active" ? "green" : "red"
                          }
                        >
                          {project.status}
                        </Tag>
                      </Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<FiMoreVertical />}
                          />
                          <MenuList>
                            <MenuItem>Edit</MenuItem>
                            <MenuItem>Delete</MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>

            {/* Pagination */}
            <Flex
              justify="space-between"
              align="center"
              mt={4}
              w={"100%"}
              p={"7px"}
            >
              <HStack gap={"5px"}>
                <Text>Show </Text>
                <Select
                  placeholder="10"
                  w="73px"
                  h="38px"
                  mt={"-15px"}
                  fontSize={"14px"}
                ></Select>
                <Text>entries</Text>
              </HStack>
              <HStack>
                <Button
                  colorScheme="white"
                  color="#6F6F6F"
                  onClick={() => handlePageChange(-1)}
                  disabled={currentPage === 1}
                  leftIcon={<ChevronLeftIcon />}
                >
                  Prev
                </Button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <Button
                    key={index + 1}
                    onClick={() => setCurrentPage(index + 1)}
                    colorScheme={currentPage === index + 1 ? "blue" : "gray"}
                  >
                    {index + 1}
                  </Button>
                ))}
                <Button
                  colorScheme="white"
                  color="#6F6F6F"
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === totalPages}
                  rightIcon={<ChevronRightIcon />}
                >
                  Next
                </Button>
              </HStack>
            </Flex>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Project;
