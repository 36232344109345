import React, { useState } from "react";
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  Textarea,
  Select,
  useToast,
  Stack,
  Flex,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Table,
  Tbody,
  Tr,
  Td,
  Link,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file for the calendar
import "react-date-range/dist/theme/default.css"; // Default theme for the calendar
import CreateSequence from "./addSequence2";
import "@fontsource/noto-sans";


// import { DatePicker } from "chakra-dayzed-datepicker"; // Install this package for calendar functionality

const AddSequence = () => {
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
    const [campaigns, setCampaigns] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
     const [campaignDetails, setCampaignDetails] = useState({
       name: "",
       type: "",
       text: "",
       file: null,
       startTime: { hours: "08", minutes: "00", period: "AM" },
       endTime: { hours: "10", minutes: "00", period: "AM" },
     });
  const toast = useToast();
  const [selectedTimeZone, setSelectedTimeZone] = useState("IST");
  const [selectedTime, setSelectedTime] = useState({
    hours: "08",
    minutes: "16",
    period: "AM",
  });
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDateChange = (ranges) => {
      setDateRange([ranges.selection]);
      setSelectedDate(ranges.selection.startDate);
    };

    const filterCampaignsByDate = () => {
      return campaigns.filter(
        (campaign) =>
          new Date(campaign.startDate).toDateString() ===
          new Date(selectedDate).toDateString()
      );
    };
     const theme = extendTheme({
       fonts: {
         heading: `'Noto Sans', sans-serif`,
         body: `'Raleway', sans-serif`,
       },
     });


    const handleAddCampaign = () => {
      const { name, type, text, file, startTime, endTime } = campaignDetails;
      if (!name || !type || !text || !file) {
        toast({
          title: "Missing Details",
          description: "Please provide all campaign details and upload a file.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        return;
      }

      const newCampaign = {
        ...campaignDetails,
        startDate: dateRange[0].startDate,
        endDate: dateRange[0].endDate,
      };

      setCampaigns((prev) => [...prev, newCampaign]);
      toast({
        title: "Campaign Added",
        description: `Campaign "${name}" has been added.`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      setCampaignDetails({
        name: "",
        type: "",
        text: "",
        file: '',
        startTime: { hours: "08", minutes: "00", period: "AM" },
        endTime: { hours: "10", minutes: "00", period: "AM" },
      });
    };

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        setCampaignDetails({ ...campaignDetails, file });
        toast({
          title: "File Uploaded",
          description: `File "${file.name}" selected.`,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
    };

    const convertTo24Hour = (hours, period) => {
      let hour = parseInt(hours, 10);
      if (period === "PM" && hour !== 12) hour += 12;
      if (period === "AM" && hour === 12) hour = 0;
      return hour;
    };

    const formatTime = ({ hours, minutes, period }) =>
      `${hours}:${minutes} ${period}`;

    const formatDate = (date) => {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    };


 


  

  // const handleSubmit = () => {
  //   if (!startDate || !endDate || !uploadedFile) {
  //     toast({
  //       title: "Missing required fields!",
  //       description: "Please select dates and upload a file.",
  //       status: "error",
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //     return;
  //   }
  //   console.log("Campaign details:", { startDate, endDate, uploadedFile });
  //   toast({
  //     title: "Campaign created successfully!",
  //     status: "success",
  //     duration: 3000,
  //     isClosable: true,
  //   });
  // };

  return (
    <ChakraProvider theme={theme}>
      <Box p={3} maxW="100%" mx="auto" borderWidth={1} borderRadius="md">
        <Flex justifyContent={"space-between"} gap={"25px"}>
          {/* Campaign Name */}
          <Flex flexDirection={"column"} maxW={"80%"} gap={"10px"}>
            <FormControl>
              <FormLabel>Campaign Name</FormLabel>
              <Input
                placeholder="Enter campaign name"
                value={campaignDetails.name}
                onChange={(e) =>
                  setCampaignDetails({
                    ...campaignDetails,
                    name: e.target.value,
                  })
                }
              />
            </FormControl>

            {/* //Date scheduler */}
            <Box
              p={6}
              margin="auto"
              bg="white"
              boxShadow="lg"
              borderRadius="md"
              maxW={"100%"}
              display={"flex"}
              gap={"15px"}
            >
              <Flex
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                p={4}
                flexDirection={"column"}
              >
                <DateRangePicker
                  ranges={dateRange}
                  onChange={handleDateChange}
                  months={1}
                  direction="horizontal"
                />
                <Flex justify="space-between">
                  <Button
                    colorScheme="red"
                    variant="outline"
                    onClick={() =>
                      setDateRange([
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: "selection",
                        },
                      ])
                    }
                  >
                    Reset
                  </Button>
                  <Button colorScheme="red">Done</Button>
                </Flex>
              </Flex>

              <Flex gap={4} flexDirection={"column"}>
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                  Schedule
                </Text>

                {/* Start and End Date */}
                <Flex justifyContent={"space-evenly"} flexDirection={"column"}>
                  <Flex
                    align="start"
                    gap={"10px"}
                    justifyContent={"space-between"}
                  >
                    <Text fontWeight="bold" my={"auto"}>
                      Start Date
                    </Text>
                    <HStack>
                      <Select
                        value={formatDate(dateRange[0].startDate)}
                        readOnly
                      >
                        <option>{formatDate(dateRange[0].startDate)}</option>
                      </Select>
                    </HStack>
                  </Flex>

                  <Flex
                    alignItems={"center"}
                    gap={"10px"}
                    mt={"10px"}
                    justifyContent={"space-between"}
                  >
                    <Text fontWeight="bold" my={"auto"}>
                      End Date
                    </Text>
                    <Flex>
                      <Select value={formatDate(dateRange[0].endDate)} readOnly>
                        <option>{formatDate(dateRange[0].endDate)}</option>
                      </Select>
                    </Flex>
                  </Flex>
                  <Flex
                    align="start"
                    flexDirection={"column"}
                    gap={"10px"}
                    mt={"10px"}
                  >
                    <Text fontWeight="bold" my={"auto"}>
                      Time
                    </Text>
                    <Flex flexDirection={"column"} gap={"10px"}>
                      <Select
                        value={selectedTimeZone}
                        w={"95px"}
                        onChange={(e) => setSelectedTimeZone(e.target.value)}
                      >
                        <option value="IST">IST</option>
                        <option value="UTC">UTC</option>
                        <option value="EST">EST</option>
                      </Select>

                      <Flex gap={"5px"}>
                        <Select
                          w={"69px"}
                          value={campaignDetails.startTime.hours}
                          onChange={(e) =>
                            setCampaignDetails({
                              ...campaignDetails,
                              startTime: {
                                ...campaignDetails.startTime,
                                hours: e.target.value,
                              },
                            })
                          }
                        >
                          {Array.from({ length: 12 }, (_, i) => (
                            <option
                              key={i}
                              value={String(i + 1).padStart(2, "0")}
                            >
                              {String(i + 1).padStart(2, "0")}
                            </option>
                          ))}
                        </Select>

                        <Select
                          w={"69px"}
                          value={campaignDetails.startTime.minutes}
                          onChange={(e) =>
                            setCampaignDetails({
                              ...campaignDetails,
                              startTime: {
                                ...campaignDetails.startTime,
                                minutes: e.target.value,
                              },
                            })
                          }
                        >
                          {Array.from({ length: 60 }, (_, i) => (
                            <option key={i} value={String(i).padStart(2, "0")}>
                              {String(i).padStart(2, "0")}
                            </option>
                          ))}
                        </Select>

                        <Select
                          w={"79px"}
                          value={campaignDetails.startTime.period}
                          onChange={(e) =>
                            setCampaignDetails({
                              ...campaignDetails,
                              startTime: {
                                ...campaignDetails.startTime,
                                period: e.target.value,
                              },
                            })
                          }
                        >
                          <option value="AM">AM</option>
                          <option value="PM">PM</option>
                        </Select>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>

                {/* Calendar */}

                {/* Buttons */}
              </Flex>
            </Box>
          </Flex>
          <Flex flexDirection={"column"} w={"534px"} gap={"25px"}>
            {/* Campaign Type */}
            <FormControl>
              <FormLabel>Campaign Type</FormLabel>
              <Select
                placeholder="Select campaign type"
                h={"50px"}
                value={campaignDetails.type}
                onChange={(e) =>
                  setCampaignDetails({
                    ...campaignDetails,
                    type: e.target.value,
                  })
                }
              >
                <option value="email">Email</option>
                <option value="sms">SMS</option>
                <option value="social">Social Media</option>
              </Select>
            </FormControl>

            {/* Post Text */}
            <FormControl>
              <FormLabel>Post Text</FormLabel>
              <Textarea
                placeholder="Write your post and tag people with @..."
                h={"200px"}
                value={campaignDetails.text}
                onChange={(e) =>
                  setCampaignDetails({
                    ...campaignDetails,
                    text: e.target.value,
                  })
                }
              />
            </FormControl>

            {/* File Upload */}
            <FormControl>
              <FormLabel>Upload Files</FormLabel>
              <Input
                type="file"
                onChange={handleFileUpload}
                accept="image/*,application/pdf"
              />
              {campaignDetails.file && (
                <Text mt={2} color="green.500">
                  File selected: {campaignDetails.file.name}
                </Text>
              )}
            </FormControl>
            <Button colorScheme="red" onClick={handleAddCampaign}>
              Add Campaign
            </Button>
            <Button colorScheme="red" onClick={() => setIsDrawerOpen(true)}>
              Next
            </Button>
          </Flex>

          {/* Submit Button */}
          {/* <Stack direction={{ base: "column", md: "row" }} spacing={4}>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="outline" onClick={() => window.location.reload()}>
            Reset
          </Button>
        </Stack> */}
        </Flex>
        <Box mt={8}>
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Campaigns on {formatDate(selectedDate)}
          </Text>
          {filterCampaignsByDate().length > 0 ? (
            <Table variant="simple" size="sm">
              <Tbody>
                {filterCampaignsByDate().map((campaign, index) => (
                  <Tr key={index}>
                    <Td>{campaign.name}</Td>
                    <Td>{campaign.type}</Td>
                    <Td>{formatTime(campaign.startTime)}</Td>
                    <Td>{formatTime(campaign.endTime)}</Td>
                    <Td>
                      <Link
                        href={URL.createObjectURL(campaign.file)}
                        isExternal
                        color="blue.500"
                      >
                        {campaign.file.name}
                      </Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Text>No campaigns scheduled for this date.</Text>
          )}
        </Box>

        <Drawer
          isOpen={isDrawerOpen}
          size={""}
          placement="right"
          onClose={() => setIsDrawerOpen(false)}
        >
          <DrawerOverlay bg="blackAlpha.500" />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Create Sequence</DrawerHeader>
            <DrawerBody>
              <CreateSequence />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </ChakraProvider>
  );
};

export default AddSequence


// import React, { useState } from "react";
// import {
//   Box,
//   VStack,
//   FormControl,
//   FormLabel,
//   Input,
//   Button,
//   HStack,
//   Textarea,
//   Select,
//   useToast,
//   Stack,
//   Flex,
//   Text,
//   Drawer,
//   DrawerOverlay,
//   DrawerContent,
//   DrawerCloseButton,
//   DrawerHeader,
//   DrawerBody,
//   Table,
//   Tbody,
//   Tr,
//   Td,
// } from "@chakra-ui/react";
// import { DateRangePicker } from "react-date-range";
// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";

// const AddSequence = () => {
//   const [dateRange, setDateRange] = useState([
//     {
//       startDate: new Date(),
//       endDate: new Date(),
//       key: "selection",
//     },
//   ]);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const [tasks, setTasks] = useState({});
//   const toast = useToast();

//   const handleDateChange = (ranges) => {
//     setDateRange([ranges.selection]);
//   };

//   const handleAddTask = (day, hour, task) => {
//     setTasks((prev) => ({
//       ...prev,
//       [day]: {
//         ...prev[day],
//         [hour]: task,
//       },
//     }));
//     toast({
//       title: "Task Added",
//       description: `Task added for ${day} at ${hour}`,
//       status: "success",
//       duration: 2000,
//       isClosable: true,
//     });
//   };

//   const handleRemoveTask = (day, hour) => {
//     setTasks((prev) => {
//       const newTasks = { ...prev };
//       delete newTasks[day][hour];
//       if (Object.keys(newTasks[day]).length === 0) {
//         delete newTasks[day];
//       }
//       return newTasks;
//     });
//     toast({
//       title: "Task Removed",
//       description: `Task removed for ${day} at ${hour}`,
//       status: "info",
//       duration: 2000,
//       isClosable: true,
//     });
//   };

//   const formatDate = (date) => {
//     const options = { weekday: "short", day: "numeric", month: "short" };
//     return date.toLocaleDateString("en-US", options);
//   };

//   const handleSubmitTasks = () => {
//     console.log("Scheduled Tasks:", tasks);
//     toast({
//       title: "Tasks Submitted",
//       description: "Your tasks have been saved.",
//       status: "success",
//       duration: 3000,
//       isClosable: true,
//     });
//     setIsDrawerOpen(false);
//   };

//   return (
//     <Box p={3} maxW="100%" mx="auto" borderWidth={1} borderRadius="md">
//       <Flex justifyContent="space-between" gap="25px">
//         {/* Campaign Details */}
//         <Flex flexDirection="column" maxW="80%" gap="10px">
//           <FormControl>
//             <FormLabel>Campaign Name</FormLabel>
//             <Input placeholder="Enter campaign name" />
//           </FormControl>

//           <Box
//             p={6}
//             bg="white"
//             boxShadow="lg"
//             borderRadius="md"
//             maxW="100%"
//             display="flex"
//             gap="15px"
//           >
//             <Flex
//               border="1px solid"
//               borderColor="gray.200"
//               borderRadius="md"
//               p={4}
//               flexDirection="column"
//             >
//               <DateRangePicker
//                 ranges={dateRange}
//                 onChange={handleDateChange}
//                 months={1}
//                 direction="horizontal"
//               />
//             </Flex>
//           </Box>
//         </Flex>

//         <Flex flexDirection="column" w="534px" gap="25px">
//           {/* Campaign Type */}
//           <FormControl>
//             <FormLabel>Campaign Type</FormLabel>
//             <Select placeholder="Select campaign type">
//               <option value="email">Email</option>
//               <option value="sms">SMS</option>
//               <option value="social">Social Media</option>
//             </Select>
//           </FormControl>

//           {/* Post Text */}
//           <FormControl>
//             <FormLabel>Post Text</FormLabel>
//             <Textarea placeholder="Write your post and tag people with @..." />
//           </FormControl>

//           {/* File Upload */}
//           <FormControl>
//             <FormLabel>Upload Files</FormLabel>
//             <Input type="file" accept="image/*,application/pdf" />
//           </FormControl>
//           <Button colorScheme="red" onClick={() => setIsDrawerOpen(true)}>
//             Manage Tasks
//           </Button>
//         </Flex>
//       </Flex>

//       {/* Drawer for Task Management */}
//       <Drawer
//         isOpen={isDrawerOpen}
//         placement="right"
//         onClose={() => setIsDrawerOpen(false)}
//       >
//         <DrawerOverlay />
//         <DrawerContent>
//           <DrawerCloseButton />
//           <DrawerHeader>Schedule Tasks</DrawerHeader>
//           <DrawerBody>
//             {Object.entries(tasks).map(([day, hours]) => (
//               <Box key={day} mb={4}>
//                 <Text fontSize="lg" fontWeight="bold">
//                   {day}
//                 </Text>
//                 <Table variant="simple" size="sm">
//                   <Tbody>
//                     {Object.entries(hours).map(([hour, task]) => (
//                       <Tr key={hour}>
//                         <Td>{hour}</Td>
//                         <Td>{task}</Td>
//                         <Td>
//                           <Button
//                             size="xs"
//                             colorScheme="red"
//                             onClick={() => handleRemoveTask(day, hour)}
//                           >
//                             Remove
//                           </Button>
//                         </Td>
//                       </Tr>
//                     ))}
//                   </Tbody>
//                 </Table>
//               </Box>
//             ))}
//             <Box mt={4}>
//               <FormLabel>Day</FormLabel>
//               <Select placeholder="Select day" id="task-day">
//                 {Array.from({ length: 7 }, (_, i) => (
//                   <option
//                     key={i}
//                     value={formatDate(new Date(Date.now() + i * 86400000))}
//                   >
//                     {formatDate(new Date(Date.now() + i * 86400000))}
//                   </option>
//                 ))}
//               </Select>
//               <FormLabel>Hour</FormLabel>
//               <Select placeholder="Select hour" id="task-hour">
//                 {Array.from({ length: 24 }, (_, i) => (
//                   <option key={i} value={`${i}:00`}>
//                     {`${i}:00`}
//                   </option>
//                 ))}
//               </Select>
//               <FormLabel>Task</FormLabel>
//               <Input placeholder="Enter task description" id="task-desc" />
//               <Button
//                 mt={4}
//                 colorScheme="blue"
//                 onClick={() => {
//                   const day = document.getElementById("task-day").value;
//                   const hour = document.getElementById("task-hour").value;
//                   const task = document.getElementById("task-desc").value;
//                   if (day && hour && task) {
//                     handleAddTask(day, hour, task);
//                   } else {
//                     toast({
//                       title: "Incomplete Details",
//                       description: "Please provide all task details.",
//                       status: "error",
//                       duration: 2000,
//                       isClosable: true,
//                     });
//                   }
//                 }}
//               >
//                 Add Task
//               </Button>
//             </Box>
//           </DrawerBody>
//           <Button colorScheme="green" onClick={handleSubmitTasks} m={4}>
//             Submit Tasks
//           </Button>
//         </DrawerContent>
//       </Drawer>
//     </Box>
//   );
// };

// export default AddSequence;

// import React, { useState } from "react";
// import {
//   Box,
//   VStack,
//   FormControl,
//   FormLabel,
//   Input,
//   Button,
//   Textarea,
//   Select,
//   useToast,
//   Stack,
//   Flex,
//   Text,
//   Table,
//   Tbody,
//   Tr,
//   Td,
// } from "@chakra-ui/react";
// import { DateRangePicker } from "react-date-range";
// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";

// const AddSequence = () => {
//   const [dateRange, setDateRange] = useState([
//     {
//       startDate: new Date(),
//       endDate: new Date(),
//       key: "selection",
//     },
//   ]);
//   const [campaigns, setCampaigns] = useState([]); // Stores all campaigns
//   const [filteredCampaigns, setFilteredCampaigns] = useState([]); // Stores campaigns for the selected date
//   const [campaignDetails, setCampaignDetails] = useState({
//     name: "",
//     type: "",
//     text: "",
//   });
//   const toast = useToast();

//   const handleDateChange = (ranges) => {
//     setDateRange([ranges.selection]);
//     const selectedDate = ranges.selection.startDate;

//     // Filter campaigns for the selected date
//     const filtered = campaigns.filter((campaign) => {
//       const start = new Date(campaign.startDate).setHours(0, 0, 0, 0);
//       const end = new Date(campaign.endDate).setHours(23, 59, 59, 999);
//       const date = selectedDate.setHours(12, 0, 0, 0);
//       return date >= start && date <= end;
//     });

//     setFilteredCampaigns(filtered);
//   };

//   const handleAddCampaign = () => {
//     const { name, type, text } = campaignDetails;
//     if (!name || !type || !text) {
//       toast({
//         title: "Missing Details",
//         description: "Please provide all campaign details.",
//         status: "error",
//         duration: 2000,
//         isClosable: true,
//       });
//       return;
//     }

//     const newCampaign = {
//       name,
//       type,
//       text,
//       startDate: dateRange[0].startDate,
//       endDate: dateRange[0].endDate,
//     };

//     setCampaigns((prev) => [...prev, newCampaign]);
//     toast({
//       title: "Campaign Added",
//       description: `Campaign "${name}" has been added.`,
//       status: "success",
//       duration: 2000,
//       isClosable: true,
//     });

//     setCampaignDetails({ name: "", type: "", text: "" });
//   };

//   const formatDate = (date) => {
//     const options = { day: "2-digit", month: "short", year: "numeric" };
//     return date.toLocaleDateString("en-US", options);
//   };

//   return (
//     <Box p={3} maxW="100%" mx="auto" borderWidth={1} borderRadius="md">
//       <Flex justifyContent="space-between" gap="25px">
//         {/* Campaign Form */}
//         <Flex flexDirection="column" maxW="50%" gap="10px">
//           <FormControl>
//             <FormLabel>Campaign Name</FormLabel>
//             <Input
//               placeholder="Enter campaign name"
//               value={campaignDetails.name}
//               onChange={(e) =>
//                 setCampaignDetails({ ...campaignDetails, name: e.target.value })
//               }
//             />
//           </FormControl>

//           <FormControl>
//             <FormLabel>Campaign Type</FormLabel>
//             <Select
//               placeholder="Select campaign type"
//               value={campaignDetails.type}
//               onChange={(e) =>
//                 setCampaignDetails({ ...campaignDetails, type: e.target.value })
//               }
//             >
//               <option value="email">Email</option>
//               <option value="sms">SMS</option>
//               <option value="social">Social Media</option>
//             </Select>
//           </FormControl>

//           <FormControl>
//             <FormLabel>Post Text</FormLabel>
//             <Textarea
//               placeholder="Write your post text"
//               value={campaignDetails.text}
//               onChange={(e) =>
//                 setCampaignDetails({ ...campaignDetails, text: e.target.value })
//               }
//             />
//           </FormControl>

//           <Button colorScheme="blue" onClick={handleAddCampaign}>
//             Add Campaign
//           </Button>
//         </Flex>

//         {/* Calendar */}
//         <Flex
//           border="1px solid"
//           borderColor="gray.200"
//           borderRadius="md"
//           p={4}
//           flexDirection="column"
//         >
//           <DateRangePicker
//             ranges={dateRange}
//             onChange={handleDateChange}
//             months={1}
//             direction="horizontal"
//           />
//         </Flex>
//       </Flex>

//       {/* Campaigns for Selected Date */}
//       <Box mt={8}>
//         <Text fontSize="lg" fontWeight="bold" mb={4}>
//           Campaigns on {formatDate(dateRange[0].startDate)}
//         </Text>
//         {filteredCampaigns.length > 0 ? (
//           <Table variant="simple" size="sm">
//             <Tbody>
//               {filteredCampaigns.map((campaign, index) => (
//                 <Tr key={index}>
//                   <Td>{campaign.name}</Td>
//                   <Td>{campaign.type}</Td>
//                   <Td>{campaign.text}</Td>
//                 </Tr>
//               ))}
//             </Tbody>
//           </Table>
//         ) : (
//           <Text>No campaigns scheduled for this day.</Text>
//         )}
//       </Box>
//     </Box>
//   );
// };

// export default AddSequence;




