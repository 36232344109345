import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Tag,
  Select,
  extendTheme,
  ChakraProvider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";

const dealsData = [
  {
    dealName: "Collins",
    stage: "Conversation",
    dealValue: "$04,51,000",
    probability: "85%",
    status: "Won",
  },
  {
    dealName: "Konopelski",
    stage: "Pipeline",
    dealValue: "$04,14,800",
    probability: "15%",
    status: "Lost",
  },
  {
    dealName: "Adams",
    stage: "Won",
    dealValue: "$04,14,800",
    probability: "95%",
    status: "Won",
  },
  {
    dealName: "Schumm",
    stage: "Lost",
    dealValue: "$9,14,400",
    probability: "47%",
    status: "Won",
  },
  {
    dealName: "Wisokz",
    stage: "Follow Up",
    dealValue: "$11,14,400",
    probability: "98%",
    status: "Won",
  },
];

const theme = extendTheme({
  fonts: {
    heading: `'Noto Sans', sans-serif`,
    body: `'Noto Sans', sans-serif`,
  },
});
const DealsTable = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box p={4} w={'660px'} mt={'26px'}  bg={"white"}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={4}
        >
          <Text fontSize="18px" fontWeight="700">
            Recently Created Deals
          </Text>

          <Menu>
            <MenuButton
              w="auto"
              as={Button}
              fontSize="12px"
              fontWeight="400"
              textAlign={"center"}
              variant="outline"
              rightIcon={<ChevronDownIcon />}
            >
              Last 30 Days
            </MenuButton>
            <MenuList>
              <MenuItem>Last 7 Days</MenuItem>
              <MenuItem>Last 30 Days</MenuItem>
              <MenuItem>All Time</MenuItem>
            </MenuList>
          </Menu>
        </Box>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th fontWeight={"500"} fontSize={"14px"} w={"150px"}>
                Deal Name
              </Th>
              <Th fontWeight={"500"} fontSize={"14px"} w={"171px"}>
                Stage
              </Th>
              <Th fontWeight={"500"} fontSize={"14px"} w={"147px"}>
                Deal Value
              </Th>
              <Th fontWeight={"500"} fontSize={"14px"} w={"148px"}>
                Probability
              </Th>
              <Th fontWeight={"500"} fontSize={"14px"} w={"135px"}>
                Status
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {dealsData.map((deal, index) => (
              <Tr key={index}>
                <Td w={"150px"} h={"47px"} fontWeight={"400"} fontSize={"14px"}>
                  {deal.dealName}
                </Td>
                <Td w={"170px"} h={"47px"} fontWeight={"400"} fontSize={"14px"}>
                  {deal.stage}
                </Td>
                <Td w={"145px"} h={"47px"} fontWeight={"400"} fontSize={"14px"}>
                  {deal.dealValue}
                </Td>
                <Td w={"150px"} h={"47px"} fontWeight={"400"} fontSize={"14px"}>
                  {deal.probability}
                </Td>
                <Td w={"130px"} h={"47px"} fontWeight={"400"} fontSize={"14px"}>
                  <Tag
                    size="md"
                    colorScheme={deal.status === "Won" ? "green" : "red"}
                  >
                    {deal.status}
                  </Tag>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </ChakraProvider>
  );
};

export default DealsTable;
