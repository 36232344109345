import React, { useState } from "react";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Badge,
  Avatar,
  IconButton,
  Stack,
  Input,
  Select,
  Flex,
  Divider,
  HStack,
  useDisclosure,
  useBreakpointValue,
  ChakraProvider,
  extendTheme,
  InputLeftElement,
  InputGroup,
} from "@chakra-ui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
// import DatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import { FiCalendar } from "react-icons/fi";
import AddEstimation from "./AddEstimate";


const estimations = [
  // Same estimation data as above
  {
    id: "#274738",
    client: "NovaWave LLC",
    amount: "$2,15,000",
    project: "Trulysell",
    date: "15 Oct 2023",
    expiryDate: "25 Sep 2023",
    estimator: {
      name: "Darlee Robert",
      role: "Facility Manager",
      avatar: "https://bit.ly/dan-abramov",
    },
    status: "Sent",
  },
  {
    id: "#274737",
    client: "BlueSky Industries",
    amount: "$1,45,000",
    project: "Dreamschat",
    date: "19 Oct 2023",
    expiryDate: "10 Sep 2028",
    estimator: {
      name: "Sharon Roy",
      role: "Installer",
      avatar: "https://bit.ly/prosper-baba",
    },
    status: "Accepted",
  },
  {
    id: "#274736",
    client: "Silver Hawk",
    amount: "$2,15,000",
    project: "Trulysell",
    date: "24 Oct 2023",
    expiryDate: "20 Oct 2026",
    estimator: {
      name: "Vaughan",
      role: "Senior Manager",
      avatar: "https://bit.ly/ryan-florence",
    },
    status: "Draft",
  },
  {
    id: "#274735",
    client: "Summit Peak",
    amount: "$4,80,380",
    project: "Servbook",
    date: "10 Nov 2023",
    expiryDate: "07 Oct 2026",
    estimator: {
      name: "Jessica",
      role: "Test Engineer",
      avatar: "https://bit.ly/kent-c-dodds",
    },
    status: "Accepted",
  },
  {
    id: "#274734",
    client: "RiverStone Venture",
    amount: "$2,12,000",
    project: "DreamPOS",
    date: "18 Nov 2023",
    expiryDate: "10 Oct 2026",
    estimator: {
      name: "Carol Thomas",
      role: "UI/UX Designer",
      avatar: "https://bit.ly/tioluwani-kolawole",
    },
    status: "Declined",
  },
  {
    id: "#274733",
    client: "CoastalStar Co.",
    amount: "$3,50,000",
    project: "Kofejob",
    date: "20 Nov 2023",
    expiryDate: "18 Oct 2027",
    estimator: {
      name: "Dawn Mercha",
      role: "Technician",
      avatar: "https://bit.ly/ryan-florence",
    },
    status: "Draft",
  },
  {
    id: "#274732",
    client: "HarborView",
    amount: "$1,23,000",
    project: "Doccure",
    date: "07 Dec 2023",
    expiryDate: "05 Nov 2026",
    estimator: {
      name: "Rachel Hampton",
      role: "Software Developer",
      avatar: "https://bit.ly/code-beast",
    },
    status: "Sent",
  },
  {
    id: "#274731",
    client: "Golden Gate Ltd",
    amount: "$3,12,600",
    project: "Best@laundry",
    date: "10 Dec 2023",
    expiryDate: "11 Nov 2028",
    estimator: {
      name: "Jonelle Curtiss",
      role: "Supervisor",
      avatar: "https://bit.ly/dan-abramov",
    },
    status: "Accepted",
  },
  {
    id: "#274730",
    client: "Golden Gate Ltd",
    amount: "$4,18,000",
    project: "Dreamsports",
    date: "22 Dec 2023",
    expiryDate: "10 Nov 2027",
    estimator: {
      name: "Jonathan",
      role: "Team Lead Dev",
      avatar: "https://bit.ly/prosper-baba",
    },
    status: "Declined",
  },
  {
    id: "#274729",
    client: "NovaWave LLC",
    amount: "$4,80,380",
    project: "Trulysell",
    date: "28 Dec 2023",
    expiryDate: "25 Nov 2026",
    estimator: {
      name: "Brook",
      role: "Team Lead Dev",
      avatar: "https://bit.ly/sage-adebayo",
    },
    status: "Accepted",
  },
];

const statusColorScheme = {
  Sent: "blue",
  Accepted: "green",
  Declined: "red",
  Draft: "orange",
};

const EstimationTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // const isMobile = useBreakpointValue({ base: true, md: false });
  const [startDate, setStartDate] = useState(null);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [sortOption, setSortOption] = useState(""); // State for sort option
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [selectedPriority, setSelectedPriority] = useState("");
    const [check, setCheck] = useState("");

  const filteredData = estimations.filter((lead) => {
    // Add your filtering logic here
    return true;
  });

  
const onChangeEvent = (e) => {
  setCheck(e.target.checked);
};

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Noto Sans', sans-serif`,
    },
  });

  
 const filteredPipelines = estimations.filter(
   (pipeline) => pipeline?.client?.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
 );

 const sortPipelines = (data, option) => {
   if (option === "client") {
     return [...data].sort((a, b) => a.client.localeCompare(b.client)); // Alphabetical order
   } else if (option === "value") {
     return [...data].sort((a, b) => parseFloat(b.value) - parseFloat(a.value)); // Highest to lowest deal value
   }
   return data;
 };

 const sortedPipelines = selectedPriority
   ? estimations.filter(
       (project) =>
         project?.status?.toLowerCase() === selectedPriority?.toLowerCase()
     )
   : sortPipelines(filteredPipelines, sortOption);


   

     const handlePageChange = (direction) => {
       setCurrentPage((prev) =>
         Math.max(1, Math.min(totalPages, prev + direction))
       );
     };

     const handleItemsPerPageChange = (event) => {
       setItemsPerPage(Number(event.target.value));
       setCurrentPage(1);
     };

  return (
    <ChakraProvider theme={theme}>
      <Box p={{ base: 2, md: 5 }} w="100%">
        <Text fontWeight="600" fontSize="18px" mb={4}>
          Estimations
        </Text>

        <Box bg="white" p={4} rounded="md" boxShadow="md">
          {/* Search and Actions */}
          <Flex
            direction={{ base: "column", md: "row" }}
            justify="space-between"
            align="center"
            mb={2}
          >
            <Input
              placeholder="Search Estimate"
              // mb={{ base: 2, md: 0 }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Flex
              gap={3}
              align="center"
              flexDirection={{ base: "column", md: "row" }}
            >
              <Select placeholder="Export" w="120px">
                <option value="date">Date</option>
                <option value="amount">Amount</option>
              </Select>
              <Button
                colorScheme="red"
                w={{ base: "100%", md: "160px" }}
                onClick={onOpen}
              >
                Add Estimation
              </Button>
            </Flex>
          </Flex>

          <Divider mb={4} />
          <AddEstimation onClose={onClose} isOpen={isOpen} />

          {/* Sorting and Filtering */}
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            p={2}
            mb={4}
            gap={2}
          >
            <Flex
              spacing={4}
              w="100%"
              direction={{ base: "column", md: "row" }}
              gap={2}
            >
              <Select
                placeholder="Sort"
                fontSize="14px"
                w={{ base: "100%", md: "93px" }}
                onChange={(e) => setSortOption(e.target.value)}
                p="3px"
              >
                <option value="name">Name</option>
                <option value="rating">Rating</option>
              </Select>
              <Flex alignItems={"center"}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <FiCalendar color="gray.400" />
                  </InputLeftElement>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    customInput={
                      <Input
                        placeholder="Start Date"
                        textAlign="center"
                        fontSize="14px"
                        w={{ base: "100%", md: "180px" }}
                      />
                    }
                  />
                </InputGroup>
              </Flex>
            </Flex>

            <Flex flexDirection={{ base: "column", md: "row" }} gap={2}>
              <Button
                variant="outline"
                fontSize="14px"
                bg={"#F3EDFF"}
                color={"#4A00E5"}
                w={"164px"}
                h={"41px"}
              >
                Manage Columns
              </Button>
              <Button variant="outline" fontSize="13px" w={"88px"} h={"41px"}>
                Filter
              </Button>
            </Flex>
          </Flex>

          {/* Table */}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th w="50px">
                  <input type="checkbox" />
                </Th>
                <Th className="table-header-style">Estimations ID</Th>
                <Th className="table-header-style">Client</Th>
                <Th className="table-header-style">Amount</Th>
                <Th className="table-header-style">Project</Th>
                <Th className="table-header-style">Date</Th>
                <Th className="table-header-style">Expiry Date</Th>
                <Th className="table-header-style">Estimation By</Th>
                <Th className="table-header-style">Status</Th>
                <Th className="table-header-style">Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedPipelines.map((estimation) => (
                <Tr key={estimation.id}>
                  <Td>
                    <input type="checkbox" />
                  </Td>
                  <Td w="97px" fontWeight={"500"} fontSize={"14px"}>
                    {estimation.id}
                  </Td>
                  <Td w="166px" fontWeight={"500"} fontSize={"14px"}>
                    {estimation.client}
                  </Td>
                  <Td w="113px" fontSize={"14px"}>
                    {estimation.amount}
                  </Td>
                  <Td w="102px" fontWeight={"500"} fontSize={"14px"}>
                    {estimation.project}
                  </Td>
                  <Td w="160px" fontSize={"14px"}>
                    {estimation.date}
                  </Td>
                  <Td w="161px" fontSize={"14px"}>
                    {estimation.expiryDate}
                  </Td>
                  <Td>
                    <Flex
                      alignItems="center"
                      h={"50px"}
                      lineHeight={"2px"}
                      w={"188px"}
                    >
                      <Avatar
                        size="sm"
                        name={estimation.estimator.name}
                        src={estimation.estimator.avatar}
                        mr={2}
                      />
                      <Flex mt={"15px"} flexDirection={"column"}>
                        <Text fontWeight="600" fontSize="12px">
                          {estimation.estimator.name}
                        </Text>
                        <Text fontSize="10px">{estimation.estimator.role}</Text>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td w={"83px"}>
                    <Badge colorScheme={statusColorScheme[estimation.status]}>
                      {estimation.status}
                    </Badge>
                  </Td>
                  <Td w={"69px"}>
                    <IconButton
                      icon={<HamburgerIcon />}
                      variant="outline"
                      aria-label="Actions"
                      size="sm"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {/* Pagination */}
          <Flex
            justify="space-between"
            align="center"
            mt={4}
            direction={{ base: "column", md: "row" }}
          >
            <HStack mb={{ base: 3, md: 0 }}>
              <Box>Show</Box>
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                w="80px"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </Select>
              <Box>entries</Box>
            </HStack>
            <HStack>
              <Button
                onClick={() => handlePageChange(-1)}
                disabled={currentPage === 1}
                leftIcon={<ChevronLeftIcon />}
                fontSize={"16px"}
                fontWeight={"400"}
              >
                Prev
              </Button>
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  size="sm"
                  variant={currentPage === index + 1 ? "solid" : "outline"}
                  w={"26px"}
                  h={"25px"}
                  bg={"#00918E"}
                  color={"white"}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                onClick={() => handlePageChange(1)}
                disabled={currentPage === totalPages}
                size="sm"
                rightIcon={<ChevronRightIcon />}
                fontSize={"16px"}
                fontWeight={"400"}
              >
                Next
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default EstimationTable;