import React from "react";
import Chart from "react-apexcharts";
import { Box, Flex, Heading, Menu, MenuButton, Select ,Button, MenuList, MenuItem, ChakraProvider, extendTheme} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const DealsByStageChart = () => {
  const options = {
    chart: {
      type: "bar",
      height: "100%",
      toolbar: {
        show: false,
      },
    },
    maintainAspectRatio: false ,
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Inpipeline",
        "Follow Up",
        "Schedule",
        "Conversation",
        "Won",
        "Lost",
      ],
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    fill: {
      opacity: 1,
      colors: ["#00A86B"], // Green color for the chart
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " deals";
        },
      },
    },
  };

  const series = [
    {
      name: "Deals",
      data: [400, 130, 248, 470, 470, 180],
    },
  ];
  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Noto Sans', sans-serif`,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box p={"15px"} bg={"white"} >
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Heading
            fontSize={"18px"}
            fontWeight={"700"}
            color="#262A2A"
            mt={"4px"}
            w={"150px"}
          >
            Deals By Stage
          </Heading>
          <Flex gap={"4px"} ml={'-5px'}>
            <Menu>
              <MenuButton
                w="auto"
                as={Button}
                fontSize="12px"
                fontWeight="400"
                textAlign={"center"}
                variant="outline"
                rightIcon={<ChevronDownIcon />}
              >
                Sales pipeline
              </MenuButton>
              <MenuList>
                <MenuItem>Sales</MenuItem>
                <MenuItem>All</MenuItem>
              </MenuList>
            </Menu>

            <Menu>
              <MenuButton
                w="126px"
                as={Button}
                fontSize="12px"
                fontWeight="400"
                textAlign={"center"}
                variant="outline"
                rightIcon={<ChevronDownIcon />}
              >
                Last 30 Days
              </MenuButton>
              <MenuList>
                <MenuItem>Last 7 Days</MenuItem>
                <MenuItem>Last 30 Days</MenuItem>
                <MenuItem>All Time</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>
        <Chart options={options} series={series} type="bar" height={350} />
      </Box>
    </ChakraProvider>
  );
};

export default DealsByStageChart;
