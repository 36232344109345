import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  HStack,
  VStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Text,
  IconButton,
  Flex,
  Avatar,
  Badge,
  extendTheme,
  ChakraProvider,
} from "@chakra-ui/react";
import { AiOutlineDownload, AiOutlineFilter, AiOutlineColumnHeight } from "react-icons/ai";import { Bar, Doughnut } from "react-chartjs-2";
import { DateRangePicker } from "react-date-range"; // For date picker functionality
import DatePicker from "react-datepicker";
import ReactApexChart from "react-apexcharts";
// import { Chart } from "chart.js";
import Chart from "react-apexcharts";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import "@fontsource/noto-sans";


const ProjectReport = () => {
  // const [dateRange, setDateRange] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: "selection",
  // });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

  const [startDate, setStartDate] = useState(null);


  // Mock data for charts
  
  const barData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Leads",
        data: [100, 120, 300, 200, 450, 320, 230, 340, 180, 250, 300, 500],
        backgroundColor: "pink",
      },
    ],
  };

  const doughnutData = {
    labels: ["Develop", "Completed", "Design", "Plan"],
    datasets: [
      {
        data: [44, 58, 41, 17],
        backgroundColor: ["#4299E1", "#48BB78", "#ED8936", "violet"],
      },
    ],
  };
  const chartdata = {
    series: [
      {
        name: "Value 1",
        data: [20, 40, 60, 90, 50, 70, 30, 50, 60, 30, 40, 30],
      },
    ],
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  };

  const options = {
    chart: {
      type: "area",
      height: 400,
      toolbar: { show: false },
    },
    stroke: { width: 2, curve: "smooth" },
    colors: ["#4285F4", "#FF5722", "#FFC107"],
    xaxis: {
      categories: chartdata.categories,
      title: {
        text: "",
      },
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: { position: "top" },
  };
  const barChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        barSpacing: 10,
        endingShape: "rounded",
      },
    },
    colors: ["#28a745", "#dc3545"],
    dataLabels: { enabled: false },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },
    yaxis: {
      title: { text: "" },
    },
    legend: { position: "top" },
  };

  const barChartData = [
    {
      name: "Won Deals",
      data: [100, 80, 100, 90, 85, 100, 95, 85, 90],
    },
    {
      name: "Lost Deals",
      data: [40, 50, 45, 40, 50, 45, 50, 40, 45],
    },
  ];

   const data = [
     {
       name: "Truelysell",
       client: "NovaWave LLC",
       priority: "High",
       startDate: "25 Sep 2023",
       endDate: "15 Oct 2023",
       type: "Web App",
       stage: "Plan",
       budget: "$200,000",
       spent: "$40,000",
     },
     {
       name: "Dreamschat",
       client: "BlueSky Industries",
       priority: "Medium",
       startDate: "29 Sep 2023",
       endDate: "19 Oct 2023",
       type: "Web App",
       stage: "Develop",
       budget: "$300,000",
       spent: "$120,000",
     },
     // ... (Add additional rows similarly)
   ];

   const priorityColors = {
     High: "red",
     Medium: "yellow",
     Low: "green",
   };

   const stageColors = {
     Plan: "purple",
     Develop: "cyan",
     Completed: "green",
     Design: "orange",
   };

    const filteredData = data.filter((lead) => true);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    const handlePageChange = (direction) => {
      setCurrentPage((prev) =>
        Math.max(1, Math.min(totalPages, prev + direction))
      );
    };

    const handleItemsPerPageChange = (event) => {
      setItemsPerPage(Number(event.target.value));
      setCurrentPage(1);
    };

  const doughnutChartOptions = {
    chart: { type: "donut" },
    labels: ["Campaign", "Google", "Referrals", "Paid Social"],
    legend: { position: "bottom" },
  };

  const doughnutChartData = [44, 58, 41, 17];

   const getStageBadge = (stage) => {
     switch (stage) {
       case "Completed":
         return (
           <Flex alignItems={"center"} gap={"1px"}>
             <Box
               height="5px" // Set the height of the line
               width="60px" // Set the width of the line
               bg="green.500" // Set the background color to green
               borderRadius="lg"
               mt={"-20px"} // Optional: Add rounded edges
             />
             <Text>Completed</Text>
           </Flex>
         );
       case "Plan":
         return (
           <Flex alignItems={"center"} gap={"1px"}>
             <Box
               height="5px" // Set the height of the line
               width="60px" // Set the width of the line
               bg="purple.500" // Set the background color to green
               borderRadius="lg"
               mt={"-20px"} // Optional: Add rounded edges
             />
             <Text>Plan</Text>
           </Flex>
         );
       case "Design":
         return (
           <Flex alignItems={"center"} gap={"1px"}>
             <Box
               height="5px" // Set the height of the line
               width="60px" // Set the width of the line
               bg="orange.500" // Set the background color to green
               borderRadius="lg"
               mt={"-20px"} // Optional: Add rounded edges
             />
             <Text>Design</Text>
           </Flex>
         );
       case "Develop":
         return (
           <Flex alignItems={"center"} gap={"3px"}>
             <Box
               height="5px" // Set the height of the line
               width="60px" // Set the width of the line
               bg="red.500" // Set the background color to green
               borderRadius="lg"
               mt={"-20px"} // Optional: Add rounded edges
             />
             <Text>Develop</Text>
           </Flex>
         );
       default:
         return <Badge>{stage}</Badge>;
     }
   };


  // Mock data for table rows
  const leadsData = [
    {
      leadName: "Collins",
      companyName: "NovaWave LLC",
      phone: "+1 8753544533",
      email: "robertson@example.com",
      leadStatus: "Closed",
      leadOwner: "Hendry",
      source: "Paid Social",
      createdDate: "25 Sep 2023, 01:22 pm",
    },
    // Add more data rows here...
  ];


  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Raleway', sans-serif`,
    },
  });


  return (
    <ChakraProvider theme={theme}>
      <Box
        p={5}
        mt={4}
        borderWidth="1px"
        borderRadius="md"
        bg="white"
        overflowX="auto"
      >
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-between"
          mb="4"
        >
          <Input placeholder="Search Leads" w={{ base: "100%", md: "550px" }} />
          <Button colorScheme="red" leftIcon={<AiOutlineDownload />}>
            Download Report
          </Button>
        </Flex>
        <Flex direction={{ base: "column", lg: "row" }} gap={6} mb="6">
          <Box
            width={{ base: "100%", md: "50%" }}
            p="4"
            borderWidth="1px"
            borderRadius="md"
          >
            <Flex
              justify="space-between"
              flexDirection={{ base: "column", md: "row" }}
              gap={"4px"}
              align="center"
              mb="2"
            >
              <Text fontWeight="700" fontSize="18px">
                Project By Year
              </Text>
              <Box border="1px solid #E8E8E8" padding="5px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                />
              </Box>
            </Flex>
            <ReactApexChart
              options={options}
              series={chartdata.series}
              type="line"
              height={350}
            />
          </Box>

          <Box
            width={{ base: "100%", md: "50%" }}
            p="5"
            borderWidth="1px"
            borderRadius="md"
          >
            {/* <Flex justify="space-between" align="center" mb="2">
             */}
            <Flex
              justify="space-between"
              flexDirection={{ base: "column", md: "row" }}
              gap={"4px"}
              align="center"
              mb="2"
            >
              <Text fontWeight="700" fontSize="18px" lineHeight="27px">
                Project By Source
              </Text>
              <Box border="1px solid #E8E8E8" padding="5px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                />
              </Box>
            </Flex>
            <Chart
              options={doughnutChartOptions}
              series={doughnutChartData}
              type="donut"
              height={350}
            />
          </Box>
        </Flex>
        <Box overflowX="auto">
          <Box overflowX="auto" bg={"white"}>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
              mb={4}
            >
              <Flex
                gap="7px"
                maxW={{ base: "100%", md: "30%" }}
                direction={{ base: "column", md: "row" }}
              >
                <Select
                  placeholder="Sort"
                  w={{ base: "100%", md: "30%" }}
                  mt={{ base: 2, md: 0 }}
                  fontWeight={"bold"}
                  fontSize={"14px"}
                  lineHeight={"20px"}
                  p="7px"
                >
                  <option value="date">Date</option>
                  <option value="amount">Amount</option>
                </Select>
                <Flex mt="5px">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    customInput={<Input />}
                  />
                </Flex>
              </Flex>

              <Flex mt={{ base: 2, md: 0 }} gap={"5px"} mr={"3px"}>
                <Button
                  variant="outline"
                  mr={2}
                  bg={"#F3EDFF"}
                  color={"#4A00E5"}
                  fontSize={"13px"}
                  fontWeight={"600"}
                >
                  Manage Columns
                </Button>
                <Button variant="outline" fontWeight={"500"} fontSize={"14px"}>
                  Filter
                </Button>
              </Flex>
            </Flex>
          </Box>

          <Table variant="simple" size="md">
            <Thead p={"5px"}>
              <Tr bg={"#E8E8E8"}>
                <Th>
                  <input type="checkbox" />
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Name
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Client
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Priority
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Start Date
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  End Date
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Type
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Pipeline Stage
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Budget Value
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Currently Spend
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((row, index) => (
                <Tr key={index}>
                  <Td>
                    <input type="checkbox" />
                  </Td>
                  <Td>
                    <Flex
                      alignItems="center"
                      h={"50px"}
                      lineHeight={"2px"}
                      w={"150px"}
                    >
                      <Avatar size="sm" name={row.name} mr={2} />
                      <Flex mt={"15px"} flexDirection={"column"}>
                        <Text fontWeight="600" fontSize="14px">
                          {row.name}
                        </Text>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td fontWeight={"400"} fontSize={"14px"}>
                    {row.client}
                  </Td>
                  <Td>
                    <Badge colorScheme={priorityColors[row.priority]}>
                      {row.priority}
                    </Badge>
                  </Td>
                  <Td fontWeight={"400"} fontSize={"14px"}>
                    {row.startDate}
                  </Td>
                  <Td fontWeight={"400"} fontSize={"14px"}>
                    {row.endDate}
                  </Td>
                  <Td fontWeight={"400"} fontSize={"14px"}>
                    {row.type}
                  </Td>
                  <Td fontWeight={"400"} fontSize={"16px"} mt={"12px"}>
                    {getStageBadge(row.stage)}
                  </Td>
                  <Td fontWeight={"400"} fontSize={"14px"}>
                    {row.budget}
                  </Td>
                  <Td fontWeight={"400"} fontSize={"14px"}>
                    {row.spent}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        <HStack justifyContent="space-between" mt={4} alignItems="center">
          <HStack spacing={2}>
            <Box color="#6F6F6F">Show</Box>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              w="80px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
            <Box color="#6F6F6F">entries</Box>
          </HStack>

          <HStack spacing={2}>
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(-1)}
              leftIcon={<ChevronLeftIcon />}
              disabled={currentPage === 1}
            >
              Prev
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                colorScheme={currentPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(1)}
              rightIcon={<ChevronRightIcon />}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </HStack>
        </HStack>
      </Box>
    </ChakraProvider>
  );
};

// Helper function for Tag color based on status
function getTagColor(status) {
  switch (status) {
    case "Closed":
      return "green";
    case "Not Contacted":
      return "blue";
    case "Lost":
      return "red";
    case "Contacted":
      return "yellow";
    default:
      return "gray";
  }
}

export default ProjectReport;
