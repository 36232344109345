import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  IconButton,
  Flex,
  Text,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  Divider,
  useDisclosure,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";




const Addpipeline = ({isOpen,onClose}) => {
    const [pipelineName, setPipelineName] = useState("");
    const [stages, setStages] = useState([
      { id: 1, name: "Inpipeline" },
      { id: 2, name: "Follow Up" },
      { id: 3, name: "Schedule Service" },
    ]);
    const [access, setAccess] = useState("all");
     const theme = extendTheme({
       fonts: {
         heading: "Poppins, sans-serif",
         body: "Poppins, sans-serif",
         mono: "Poppins, sans-serif",
       },
     });

    const handleAddStage = () => {
      setStages([
        ...stages,
        { id: Date.now(), name: `Stage ${stages.length + 1}` },
      ]);
    };

    const handleEditStage = (id) => {
      const newName = prompt("Edit Stage Name:");
      if (newName) {
        setStages(
          stages.map((stage) =>
            stage.id === id ? { ...stage, name: newName } : stage
          )
        );
      }
    };

    const handleDeleteStage = (id) => {
      setStages(stages.filter((stage) => stage.id !== id));
    };

  return (
    <ChakraProvider theme={theme}>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontSize={"25px"} fontWeight={"400"}>
            Add New Pipeline
          </DrawerHeader>
          <DrawerBody>
            <FormControl isRequired>
              <FormLabel fontSize={"20px"} fontWeight={"400"}>
                Pipeline Name 
              </FormLabel>
              <Input
                placeholder="Enter pipeline name"
                value={pipelineName}
                onChange={(e) => setPipelineName(e.target.value)}
              />
            </FormControl>
            <Flex justifyContent={'space-between'}>
            <FormLabel mt={4} fontSize={"20px"} fontWeight={"500"}>
              Pipeline Stages
            </FormLabel>
            <Button
              leftIcon={<AddIcon />}
              size="sm"
              colorScheme="red"
              variant="outline"
              mt={2}
              onClick={handleAddStage}
            >
              Add New
            </Button>
            </Flex>
            {stages.map((stage) => (
              <Flex
                key={stage.id}
                align="center"
                justify="space-between"
                mb={2}
              >
                <Box
                  p={2}
                  border="1px solid #E2E8F0"
                  borderRadius="md"
                  borderLeft={'solid #CC0808'}
                  flex="1"
                  w={"751px"}
                  h={"67px"}
                  fontSize={"20px"}
                  fontWeight={"400"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  {stage.name}
                  <Flex gap={2} alignItems={"center"}>
                    <Flex mt={"10px"}>
                      <IconButton
                        size="sm"
                        icon={<EditIcon />}
                        aria-label="Edit"
                        onClick={() => handleEditStage(stage.id)}
                      />
                      <Text fontSize={"20px"} fontWeight={"400"}>
                        Edit
                      </Text>
                    </Flex>
                    <Flex mt={"10px"}>
                      <IconButton
                        size="sm"
                        icon={<DeleteIcon />}
                        aria-label="Delete "
                        onClick={() => handleDeleteStage(stage.id)}
                      />
                      <Text fontSize={"20px"} fontWeight={"400"}>
                        Delete
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            ))}

            <Divider my={4} />

            <FormLabel fontSize={"20px"} fontWeight={"400"}>
              Access <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <RadioGroup onChange={setAccess} value={access}>
              <Stack direction="row" spacing={4}>
                <Radio value="all">All</Radio>
                <Radio value="person">Select Person</Radio>
              </Stack>
            </RadioGroup>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red">Create</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );}


  export default Addpipeline