import React from 'react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import AccountSettings from './AccountSettings'
import SessionsSettings from './SeassionsSettings'
import PrivacySettings from './PrivacySettings'
import NotificationSettings from './NotificationSettings'

export default function SettingsPage() {
  return (
    <>
      <Tabs>
        <TabList>
          <Tab
            fontWeight={"400"}
            color={"#000000"}
            _selected={{
              color: "red.500",
              border: "red.500",
              borderBottom: "2px",
            }}
          >
            Account
          </Tab>
          <Tab
            fontWeight={"400"}
            color={"#000000"}
            _selected={{
              color: "red.500",
              border: "red.500",
              borderBottom: "2px",
            }}
          >
            Notifications
          </Tab>
          <Tab
            fontWeight={"400"}
            color={"#000000"}
            _selected={{
              color: "red.500",
              border: "red.500",
              borderBottom: "2px",
            }}
          >
            Language
          </Tab>
          {/* <Tab fontWeight={"400"} color={"#000000"} _selected={{color:"red.500",border:"red.500", borderBottom:"2px"}}>Privacy</Tab> */}
        </TabList>
        <TabPanels>
          <TabPanel>
            <AccountSettings />
          </TabPanel>
          <TabPanel>
            <NotificationSettings />
          </TabPanel>
          <TabPanel>
            <SessionsSettings />
          </TabPanel>

          {/* <TabPanel>
            <PrivacySettings/>
          </TabPanel> */}
        </TabPanels>
      </Tabs>
    </>
  );
}
