import { createContext, useContext, useEffect, useState } from 'react';
import { CallAPI } from '../../../Services/service';
import endpoints from '../../../Services/endpoint';


const AuthContext = createContext();
export { AuthContext };

const useAuthContext = () => {
  return useContext(AuthContext);
};
export { useAuthContext };

const AuthContextProvider = ({ children }) => {
  // State for user data and platform tokens
  const [authUser, setAuthUser] = useState(
    JSON.parse(localStorage.getItem('userData')) || null
  );
  const [platformTokens, setPlatformTokens] = useState(JSON.parse(localStorage.getItem('platformtokens')) || {}); // Tracks token availability
  
 
const getOrganisations =async()=>{
  try {
    const res = await CallAPI(endpoints.getoraganisations, {token:platformTokens.LinkedIn});
    const organizationId = res.data.organization.split(":").pop();

  if(res.message)  {
  
}
  } catch (error) {
    console.error('Error fetching facet entities:', error);
  }
}



  // Update platform tokens (pass this function to components that need to modify it)
  const updatePlatformTokens = (newTokens) => {
    localStorage.setItem("platformtokens", JSON.stringify({
      ...platformTokens, // Retain previous tokens
      ...newTokens // Update with new tokens
    }));
        setPlatformTokens((prevTokens) => ({
      ...prevTokens,
      ...newTokens,
    }));
  };

  return (
    <AuthContext.Provider value={{ authUser, setAuthUser, platformTokens, updatePlatformTokens }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider };
