import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Stack,
  Textarea,
  Flex,
  VStack,
  InputRightElement,
  IconButton,
  Input,
  Box,
  Button,
  Select,
  InputGroup,
  FormLabel,
  Tag,
  FormControl,
  p,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiUpload } from "react-icons/bi";
import CreatableSelect from "react-select/creatable";
import endpoints from "../../Services/endpoint";
import { CallAPI } from "../../Services/service";
import { toast } from "react-toastify";
import { useAuthContext } from "../CommunityGroup/context/AuthContext";
import CampaignGroups from "../Utils/CampaignGroupModal";
import axios from "axios";



const AddCampaignDrawer = ({ onClose, isOpen ,getData}) => {
  const [targetAudience, setTargetAudience] = useState([]);
  const firstField = React.useRef();
  const [selectedPlatforms, setSelectedPlatforms] = useState([]); // Social Platforms
  const [uploadedFiles, setUploadedFiles] = useState([
  ]);

  const [formValues, setFormValues] = useState({
    name: "",
    campaignType: "TEXT_AD",
    dealValue: "500",
    currency: "INR",
    period: "30",
    periodValue: "85",
    description: "dumm",
    attachment: uploadedFiles,
    type:selectedPlatforms
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading,setLoading] = useState(false)
  const [targetingFacets, setTargetingFacets] = useState([]);
  const [facetEntities, setFacetEntities] = useState([]);
  const { platformTokens, updatePlatformTokens } = useAuthContext(); 
  const [selectedfacets,setSelectedFacets] = useState([])
  const [selectedFacets1,setSelectedFacets1] = useState([])
  const [target1,setTarget1] = useState([])
const [campaignGroups,setCampaignGroups] = useState([])
const [ismodalopen,setIsModalOpen] = useState(false)
const handleClose =()=>{setIsModalOpen(false)}
const [selectedCampaignGroup,setSelectedCampaignGroup] = useState()

  // Handle change for text inputs
  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });

    setFormErrors((prevErrors) => ({
        ...prevErrors,
        [e.target.name]: "",
      }));
  };

  useEffect(() => {
    const fetchTargetingFacets = async () => {
      try {
        const response = await CallAPI(endpoints.gettargets, {token: platformTokens.LinkedIn});
         console.log(response)
        setTargetingFacets(response.data.elements);
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
getCampainGroup()
    fetchTargetingFacets();
  }, []);

  const getCampainGroup = async () => {
    try {
      const response = await CallAPI(endpoints.getcampaigngroup, {token: platformTokens.LinkedIn});
       setCampaignGroups(response.data.elements);
    } catch (err) {
      console.error(err);
    } finally {
    }
  };


  //handle change
  const handlePlatformsChange = (selectedOptions) => {
    setSelectedPlatforms(selectedOptions);
    setFormValues((prev) => ({
      ...prev,
      type: selectedOptions,
    }));
  };
  
  const buildTargetingCriteria = () => {
    return {
      include: {
        and: Object.entries(selectedfacets).map(([facetURN, values]) => ({
          or: { [facetURN]: values },
        })),
      },
    };
  };




  const handleAudienceChange = (newValue, facetURN) => {
    setTarget1(newValue)
    setTargetAudience((prev) => {
      const updatedAudience = [...prev];
      const facetIndex = updatedAudience.findIndex((item) => item.facetURN === facetURN);
  
      if (facetIndex >= 0) {
        // Update existing facet
        updatedAudience[facetIndex] = {
          facetURN,
          values: newValue.map((item) => ({ value: item.value, label: item.label })),
        };
      } else {
        // Add new facet
        updatedAudience.push({
          facetURN,
          values: newValue.map((item) => ({ value: item.value, label: item.label })),
        });
      }
      return updatedAudience;
    });
  
    setSelectedFacets((prev) => ({
      ...prev,
      [facetURN]: newValue.map((item) => item.value),
    }));
  };
  

  const handleDelete = (fileName) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.name !== fileName));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        newErrors[key] = "This field is required";
      }
    });
    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = async () => {
    if (!validateForm()) return;
  
    if (!selectedCampaignGroup?.id) {
      setIsModalOpen(true);
      return;
    }
  
    try {
      // Prepare formattedPayload
      const formattedPayload = {
        campaignGroup: selectedCampaignGroup.id,
        dailyBudget: {
          amount: formValues.dealValue,
          currencyCode: formValues.currency,
        },
        locale: {
          country: "US",
          language: "en",
        },
        name: formValues.name,
        offsiteDeliveryEnabled: false,
        runSchedule: {
          start: new Date().getTime(),
          end: new Date().getTime() + parseInt(formValues.period) * 24 * 60 * 60 * 1000,
        },
        targetingCriteria: buildTargetingCriteria(),
        type: formValues.campaignType,
        unitCost: {
          amount: formValues.periodValue,
          currencyCode: formValues.currency,
        },
        mediaAssets: [], // Make sure this is an array
      };
  
      const uploadfile = new FormData();

      uploadedFiles.forEach((file, index) => {
        uploadfile.append(`files`, file); // Append all files under the same key for easier parsing
      });
      uploadfile.append('formattedPayload', JSON.stringify(formattedPayload));
      uploadfile.append('token', platformTokens.LinkedIn);
  
      // Call the backend API
      const res = await CallAPI(endpoints.createCampaigns, uploadfile, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });;
  
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.message || "Campaign created successfully!");
        onClose();
        getData();
        setFormValues({});
      } else {
        toast.error(res?.message || "Failed to create campaign.");
      }
    } catch (error) {
      console.error("Error creating campaign:", error);
      toast.error("Failed to create campaign due to an error.");
    }
  };
  
 
  

  const handleFacetChange = async (event) => {
    const targeturn = event.target.value;
    setLoading(true)
    setSelectedFacets1([...selectedFacets1,targeturn])
    setFacetEntities([])
    try {
      const res = await CallAPI(endpoints.gettargetsbyfacets, {token:platformTokens.LinkedIn,targeturn:targeturn});
      setFacetEntities(res.data.elements); // Adjust this based on the response structure
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error fetching facet entities:', error);
    }
  };

  return (
    <>
    <CampaignGroups isOpen={ismodalopen} onClose={handleClose} campaignGroups={campaignGroups} selectedCampaignGroup={selectedCampaignGroup}
    setCampaignGroup={setSelectedCampaignGroup}/>
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      initialFocusRef={firstField}
      p="20px"
      size="md"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Add New Campaign</DrawerHeader>

        <DrawerBody>
          <Stack spacing="24px">
            <Box>
              <FormLabel htmlFor="name">
                Name <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Input
                ref={firstField}
                id="name"
                name="name"
                value={formValues.name}
                onChange={handleChange}
                placeholder="Enter campaign name"
                isRequired
              />
              {formErrors.name &&  < p style={{color:"red"}}>{formErrors.name}</p>}
            </Box>

            <Box>
              <FormLabel htmlFor="campaignType">
                Campaign Type <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Select
                id="campaignType"
                name="campaignType"
                value={formValues.campaignType}
                onChange={handleChange}
                placeholder="Choose"
                isRequired
              >
                <option value="TEXT_AD">TEXT_AD</option>
                <option value="SPONSORED_UPDATES">SPONSORED_UPDATES</option>
                <option value="SPONSORED_INMAIL">SPONSORED_INMAIL</option>
                <option value="TEXT_AD_LITE">TEXT_AD_LITE</option> 
                <option value="VIDEO_AD">VIDEO_AD</option> 
                <option value="CAROUSEL_AD">CAROUSEL_AD</option>
                <option value="LEAD_GEN_FORM">LEAD_GEN_FORM</option>
              </Select>
              {formErrors.campaignType &&  < p style={{color:"red"}}>{formErrors.campaignType}</p>}
            </Box>
            <Box>
              <FormLabel htmlFor="socialPlatforms">
                Social Platforms <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <CreatableSelect
                isMulti
                id="socialPlatforms"
                name="socialPlatforms"
                value={selectedPlatforms}
                onChange={handlePlatformsChange}
                options={[
                  { value: "facebook", label: "Facebook" },
                  { value: "instagram", label: "Instagram" },
                  { value: "linkedin", label: "LinkedIn" },
                  { value: "twitter", label: "Twitter" },
                  { value: "youtube", label: "YouTube" },
                  { value: "watsapp", label: "WatsApp" },
                  { value: "gmail", label: "Gmail" },

                ]}
                placeholder="Select platforms"
              />
              {formErrors.selectedPlatforms && (
                <p style={{ color: "red" }}>{formErrors.selectedPlatforms}</p>
              )}
            </Box>

            <Flex direction="column" gap="4">
  <Flex gap="4">
    <Box flex="1">
      <FormLabel htmlFor="dealValue">
        Deal Value <span style={{ color: "red" }}>*</span>
      </FormLabel>
      <Input
        id="dealValue"
        name="dealValue"
        value={formValues.dealValue}
        onChange={handleChange}
        placeholder="Enter deal value"
        isRequired
      />
      {formErrors.dealValue &&  < p style={{color:"red"}}>{formErrors.dealValue}</p>}
    </Box>

    <Box flex="1">
      <FormLabel htmlFor="currency">
        Currency <span style={{ color: "red" }}>*</span>
      </FormLabel>
      <Select
                id="currency"
                name="currency"
                value={formValues.currency}
                onChange={handleChange}
                placeholder="Currency"
                isRequired>
                <option value="INR">INR</option>
                <option value="USD">USD</option>
               
              </Select>
   
      {formErrors.currency &&  < p style={{color:"red"}}>{formErrors.currency}</p>}
    </Box>
  </Flex>

  <Flex gap="4">
    <Box flex="1">
      <FormLabel htmlFor="period">
        Period <span style={{ color: "red" }}>*</span>
      </FormLabel>
      <Input
        id="period"
        name="period"
        value={formValues.period}
        onChange={handleChange}
        placeholder="Period"
        isRequired
      />
      {formErrors.period &&  < p style={{color:"red"}}>{formErrors.period}</p>}
    </Box>

    <Box flex="1">
      <FormLabel htmlFor="periodValue">
        Period Value<span style={{ color: "red" }}>*</span>
      </FormLabel>
      <Input
        id="periodValue"
        name="periodValue"
        value={formValues.periodValue}
        onChange={handleChange}
        placeholder="Period Value"
        isRequired
      />
      {formErrors.periodValue &&  < p style={{color:"red"}}>{formErrors.periodValue}</p>}
    </Box>
  </Flex>
</Flex>

            
<Box>
  <FormLabel htmlFor="facet-dropdown">
    Select Facet <span style={{ color: "red" }}>*</span>
  </FormLabel>
  <Select
    id="facet-dropdown"
    onChange={handleFacetChange}
    value={selectedFacets1[selectedFacets1.length - 1] || ""}
  >
    <option value="">Select a facet</option>
    {targetingFacets.map((facet) => (
      <option key={facet.adTargetingFacetUrn} value={facet.adTargetingFacetUrn}>
        {facet.facetName}
      </option>
    ))}
  </Select>
</Box>

{loading ? (
  <Text>Loading...</Text>
) : (
  <Box>
    <FormLabel htmlFor="targetAudience">
      Target Audience <span style={{ color: "red" }}>*</span>
    </FormLabel>
    <CreatableSelect
      isMulti
      id="targetAudience"
      name="targetAudience"
      value={
        target1
      }
      onChange={(newValue) =>
        handleAudienceChange(
          newValue,
          selectedFacets1[selectedFacets1.length - 1]
        )
      }
      options={facetEntities.map((entity) => ({
        value: entity.urn, // Assuming 'urn' is the identifier
        label: entity.name, // Assuming 'name' is what you want to display
      }))}
      placeholder="Select or type target audience"
    />
  </Box>
)}


            <Box>
              <FormLabel htmlFor="description">
                Description <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Textarea
                id="description"
                name="description"
                value={formValues.description}
                onChange={handleChange}
                placeholder="Enter description"
                isRequired
              />
              {formErrors.description &&  < p style={{color:"red"}}>{formErrors.description}</p>}
            </Box>

            <Box>
              <FormLabel htmlFor="attachment">Attachment 
                <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box
    border="1px solid #D6D6D6"
    width="100%"
    height="80px"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <label htmlFor="file-upload" style={{ cursor: "pointer" }}>
      <Button
        as="span"
        variant="outline"
        colorScheme="blue"
        leftIcon={<BiUpload />}
      >
        Upload File
      </Button>
    </label>
    <input
      id="file-upload"
      type="file"
      style={{ display: "none" }}
      onChange={(e) => {
        // handle file upload here
        const files = Array.from(e.target.files);
        setUploadedFiles(files);      }}
    />
  </Box>
              {formErrors.attachment &&  < p style={{color:"red"}}>{formErrors.attachment}</p>}
            </Box>

            <Box>
           {uploadedFiles.length? <FormLabel>Uploaded Files</FormLabel> :""}
              <VStack spacing={2}>
                {uploadedFiles.map((file) => (
                  <Box key={file.name} display="flex" alignItems="center">
                    <InputGroup key={file.name} mb={2} width="100%">
                      <Input
                        isReadOnly
                        value={`${file.name} (${file.size})`}
                        pr="30px"
                        minW={"400px"}
                      />
                      <InputRightElement>
                        <IconButton
                          aria-label="Delete file"
                          icon={<AiOutlineDelete />}
                          onClick={() => handleDelete(file.name)}
                          size="sm"
                          colorScheme="red"
                          variant="ghost"
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                ))}
              </VStack>
            </Box>
          </Stack>
        </DrawerBody>

        <DrawerFooter>
          <Button type="button" variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button type="button" colorScheme="red" onClick={handleSubmit} isLoading={isSubmitting}>
            Create
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
    </>
  );
};

export default AddCampaignDrawer;
