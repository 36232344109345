import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  ChakraProvider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  extendTheme,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Select,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import "@fontsource/noto-sans";


const AdnewDeal = ({ isOpen, onClose }) => {

   const theme = extendTheme({
     fonts: {
       heading: `'Noto Sans', sans-serif`,
       body: `'Noto Sans', sans-serif`,
     },
   });
  return (
    <ChakraProvider theme={theme}>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          {/* Modal Header */}
          <DrawerHeader
            borderBottomWidth="1px"
            fontWeight={"500"}
            fontSize={"18px"}
          >
            Add New Deal
            <DrawerCloseButton />
          </DrawerHeader>

          {/* Modal Body */}
          <DrawerBody>
            <VStack spacing={6} align="stretch">
              {/* Deal Name */}
              <FormControl isRequired>
                <FormLabel fontWeight={"400"} fontSize={"14px"}>
                  Deal Name
                </FormLabel>
                <Input placeholder="Enter Deal Name" />
              </FormControl>

              {/* Pipeline */}
              <Flex
                gap={["10px", "15px"]}
                flexDirection={["column", "row"]} // Stacks on smaller screens
              >
                <FormControl isRequired>
                  <FormLabel fontWeight={"400"} fontSize={"14px"}>
                    Pipeline
                  </FormLabel>
                  <Select placeholder="Select Pipeline">
                    <option value="new">New</option>
                    <option value="existing">Existing</option>
                  </Select>
                </FormControl>

                {/* Status */}
                <FormControl isRequired>
                  <FormLabel fontWeight={"400"} fontSize={"14px"}>
                    Status
                  </FormLabel>
                  <Select placeholder="Select Status">
                    <option value="open">Open</option>
                    <option value="won">Won</option>
                    <option value="lost">Lost</option>
                  </Select>
                </FormControl>
              </Flex>

              {/* Deal Value */}
              <Flex
                gap={["10px", "15px"]}
                flexDirection={["column", "row"]} // Stacks on smaller screens
              >
                <FormControl isRequired>
                  <FormLabel fontWeight={"400"} fontSize={"14px"}>
                    Deal Value
                  </FormLabel>
                  <Input w={["100%", "170px"]} />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel fontWeight={"400"} fontSize={"14px"}>
                    Currency
                  </FormLabel>
                  <Input />
                </FormControl>

                {/* Period */}
                <FormControl isRequired>
                  <FormLabel fontWeight={"400"} fontSize={"14px"}>
                    Period
                  </FormLabel>
                  <Input  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel fontWeight={"400"} fontSize={"14px"}>
                    Period Value
                  </FormLabel>
                  <Input/>
                </FormControl>
              </Flex>

              <FormControl isRequired>
                <FormLabel fontWeight={"400"} fontSize={"14px"}>
                  Project
                </FormLabel>
                <Input placeholder="Devops Design,Ui for cloud" w="100%" />
              </FormControl>

              {/* Due Date */}
              <Flex
                gap={["10px", "15px"]}
                flexDirection={["column", "row"]} // Stacks on smaller screens
              >
                <FormControl isRequired>
                  <FormLabel fontWeight={"400"} fontSize={"14px"}>
                    Due Date
                  </FormLabel>
                  <Input type="date" />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel fontWeight={"400"} fontSize={"14px"}>
                    Expected Closing Date
                  </FormLabel>
                  <Input type="date" />
                </FormControl>
              </Flex>

              {/* Assignee and Follow-Up Date */}
              <FormControl>
                <FormLabel>Assignee</FormLabel>
                <Input w="100%" />
              </FormControl>

              <Flex
                gap={["10px", "15px"]}
                flexDirection={["column", "row"]} // Stacks on smaller screens
              >
                <FormControl>
                  <FormLabel>Follow-Up Date</FormLabel>
                  <Input type="date" />
                </FormControl>

                {/* Source */}
                <FormControl>
                  <FormLabel>Source</FormLabel>
                  <Select
                    fontWeight={"400"}
                    fontSize={"14px"}
                  >
                    <option value="referral">Referral</option>
                    <option value="ads">Ads</option>
                    <option value="other">Other</option>
                  </Select>
                </FormControl>
              </Flex>

              {/* Tags */}
              <Flex
                gap={["10px", "15px"]}
                flexDirection={["column", "row"]} // Stacks on smaller screens
              >
                <FormControl>
                  <FormLabel>Tags</FormLabel>
                  <Input />
                </FormControl>

                {/* Priority */}
                <FormControl>
                  <FormLabel>Priority</FormLabel>
                  <Select
                    fontWeight={"400"}
                    fontSize={"14px"}
                  >
                    <option value="low">Low</option>
                    <option value="medium">Medium</option>
                    <option value="high">High</option>
                  </Select>
                </FormControl>
              </Flex>

              {/* Description */}
              <FormControl>
                <FormLabel>Description</FormLabel>
                <Textarea />
              </FormControl>
            </VStack>
          </DrawerBody>

          {/* Modal Footer */}
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red">Create</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
};

export default AdnewDeal;
