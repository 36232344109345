import React, { useState, useRef, useEffect } from 'react';import {
  Box,
  Text,
  Flex,
  Select,
  Icon,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
  Input,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FaRegCopy } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { IoCallOutline } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import { BsPersonLock } from "react-icons/bs";
import { RiLinksLine } from "react-icons/ri";
import { CiWarning } from "react-icons/ci";
import { AiOutlineMail } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import { FaWhatsapp } from "react-icons/fa";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { RiEditBoxLine } from "react-icons/ri";
import { MdBlock } from "react-icons/md";
import { CallAPI } from "../../Services/service";
import endpoints from "../../Services/endpoint";
const NotificationSettings = () => {
  const direction = useBreakpointValue({ base: "column", md: "row" });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedPhone, setIsCopiedPhone] = useState(false);
  const [input, setInputs] = useState({
    newPassword: "",
    otp: "",
  });
  const toast = useToast();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const savedProfile = JSON.parse(localStorage.getItem("userData"));
    const storedEmail = savedProfile?.email_id;
    const storedMobile = savedProfile?.mobile_number;
    // console.log(storedEmail);

    if (storedEmail) {
      setEmail(storedEmail);
    }
    if (storedMobile) {
      setMobile(storedMobile);
    }
  }, []);


  const handleotp = async () => {
    setIsLoading(true);
    setError("");

    try {
      const response = await CallAPI(endpoints.sendotp, { email });
      console.log(response)
      if (response.status.code == 200) {
        console.log(otpSent)
       setOtpSent(true)
        toast({
          title: "OTP Sent",
          description: "An OTP has been sent to your email address.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // setEmail("");
      } else {
        toast({
          title: "OTP Sent",
          description:
            response.message || "An OTP has been sent to your email address.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while sending OTP.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputs({
      ...input,
      [name]: value,
    });
  };

  const handleChangePassword = async () => {
    const { otp, newPassword, confirmPassword } = input;
    if (!newPassword) {
      toast({
        title: "Error",
        description: "New Password is required.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      toast({
        title: "Error",
        description: "New Password and Confirm Password do not match.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
//     console.log("inputs is ", input);
// console.log("email is : ",email);

    try {
      const response = await CallAPI(endpoints.resetpassword, {
        email,
        otp,
        newPassword,
      });

      console.log("response is password change:", response);
      if (response.status.code == 200) {
        toast({
          title: "Password Changed",
          description: "An Password has been change.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setEmail("");
      } else {
        toast({
          title: "Error",
          description: response.message || "Failed to change passweord.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while change password.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsCopied(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(email).then(() => {
      setIsCopied(true);
    });
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsCopiedPhone(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  const handleCopyToClipboardmobile = () => {
    navigator.clipboard
      .writeText(mobile)
      .then(() => {
        console.log("Mobile number copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
      setIsCopiedPhone(true);

    
  };
  const handleToggleCurrentPassword = () =>
    setShowCurrentPassword(!showCurrentPassword);
  const handleToggleNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleToggleConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <Flex direction="column" padding="10px" pt={-1} pl={1} gap="1">
      <Flex
        direction={direction}
        justify="space-between"
        align="flex-start"
        padding="20px"
        maxW={{ base: "100%", md: "100%", lg: "90%" }}
      >
        <Box flex="0.67" marginRight={direction === "row" ? "20px" : "0"}>
          <Text
            display="flex"
            alignItems="center"
            fontSize="18px"
            fontWeight="700"
            lineHeight="28px"
            textAlign="left"
          >
            <FiUser style={{ marginRight: "8px" }} />
            User Id
          </Text>
        </Box>

        <Flex ref={containerRef} direction="column" align="flex-start" flex="1.3">
          <Flex direction="row" align="center" gap="2">
            <Box
              as="input"
              placeholder="Aj@22225057523"
              value={email}
              // bg={"#F3F4F6"}
              onChange={(e) => setEmail(e.target.value)}
              border="3px solid #F3F4F6"
              pl={5}
              pr={5}
              borderRadius="4px"
              marginLeft={{ lg: "90px" }}
              padding="3px"
              bg={isCopied ? "blue.200" : "#F3F4F6"}
              fontSize="16px"
              width="auto"
            />
            <FaRegCopy
              onClick={handleCopyToClipboard}
              style={{ marginLeft: "10px", color: "#D1202A" }}
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex
        direction={{ base: "column", lg: direction }}
        justify="space-between"
        align="flex-start"
        padding="10px"
        maxW={{ base: "100%", md: "100%", lg: "90%" }}
      >
        <Box  flex="0.6" marginBottom={{ base: "10px", lg: "0" }}>
          <Text
            display="flex"
            alignItems="center"
            fontSize="18px"
            marginLeft={"10px"}
            fontWeight="700"
            lineHeight="28px"
            textAlign="left"
          >
            <IoCallOutline style={{ marginRight: "8px" }} />
            Phone Number
          </Text>
        </Box>

        <Flex ref={containerRef}
          direction={{ base: "column", lg: "row" }}
          align="flex-start"
          flex="0.705"
          borderRadius="6px"
          maxW={{ lg: "790px" }}
          border="2px solid #F3F4F6"
          marginRight={{ lg: "10px" }}
          padding="20px"
          gap={{ base: "10px", lg: "0" }}
        >
          <Box width="100%">
            <Text marginBottom="2px">Enter your phone number:</Text>
            <Flex
              direction={{ base: "row", lg: "row" }}
              align="center"
              wrap="nowrap"
              gap="10px"
            >
              <Select
                flex="1" // Take up space alongside the input
                maxW="80px"
                borderRightRadius="0"
                bg="#D6D6D6"
                
                marginBottom={{ base: "10px", lg: "0" }} // Space below on mobile
              >
                <option value="+1">+91 </option>
                <option value="+91">+1 </option>
                <option value="+44">+44 </option>
                <option value="+61">+61 </option>
                <option value="+30">+30 </option>
              </Select>

              <Input
                flex="2" // Make input larger than select box
                type="tel"
                // bg="#F3F4F6 !important"
                placeholder="Enter your mobile number"
                borderLeftRadius="0"
                value={mobile}
                bg={isCopiedPhone ? "blue.200" : "#F3F4F6"}
                onChange={(e) => setMobile(e.target.value)}
                maxW={{ base: "100%", lg: "250px" }} // Max width for larger screens
                marginBottom={{ base: "10px", lg: "0" }} // Space below on mobile
              />

              <Flex
                align="center"
                gap="10px" // Space between icons
                marginBottom={{ base: "10px", lg: "0" }} // Space below on mobile
              >
                <Icon as={RiEditBoxLine} boxSize="20px" color="#D1202A" />
                <Icon
                  onClick={handleCopyToClipboardmobile}
                  as={FaRegCopy}
                  boxSize="16px"
                  color="#D1202A"
                />
              </Flex>
            </Flex>
          </Box>

          <Button
            _hover={{ bg: "#E03741" }}
            colorScheme="teal"
            bg="#D1202A"
            minW={{ base: "", lg: "110px" }}
            marginTop={{ base: "", lg: "20px" }}
            display={{ base: "block", lg: "inline-block" }} 
          >
            Connect
          </Button>
        </Flex>
      </Flex>

      <Flex
        direction={direction}
        justify="space-between"
        align="flex-start"
        padding="20px"
        maxW={{ base: "100%", md: "100%", lg: "90%" }}
      >
        <Box flex="0.6" marginRight={direction === "row" ? "20px" : "0"}>
          <Text
            display="flex"
            alignItems="center"
            fontSize="18px"
            fontWeight="700"
            lineHeight="28px"
            textAlign="left"
          >
            <RiLockPasswordLine style={{ marginRight: "8px" }} />
            Change Password
          </Text>
        </Box>
        <Flex
          direction="column"
          border="2px solid #F3F4F6"
          borderRadius="6px"
          align="flex-start"
          flex="0.75"
          padding="20px"
        >
          {!otpSent ? (
            <>
              <Box marginBottom="4" width="100%">
                <Text marginBottom="2px">Enter Email</Text>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    placeholder="Enter your Email"
                    width="100%"
                    bg="#F3F4F6"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton h="1.75rem" size="sm" />
                  </InputRightElement>
                </InputGroup>
              </Box>
              <Flex width="100%" justifyContent="flex-end">
                <Button
                  onClick={() => {
                    handleotp();
                    // handleOtpClick();
                  }}
                  _hover={{ bg: "#E03741" }}
                  colorScheme="teal"
                  bg="#D1202A"
                >
                  Get OTP
                </Button>
              </Flex>
            </>
          ) : (
            <>
              <Box marginBottom="4" width="100%">
                <Text marginBottom="2px">Enter OTP</Text>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type="text"
                    placeholder="Enter OTP"
                    width="100%"
                    name="otp"
                    onChange={handleInputChange}
                    bg="#F3F4F6"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton h="1.75rem" size="sm" />
                  </InputRightElement>
                </InputGroup>
              </Box>

              {/* <Box marginBottom="4" width="100%">
                <Text marginBottom="2px">Current password</Text>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder="Enter current password"
                    width="100%"
                    bg="#F3F4F6"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleToggleCurrentPassword}
                      icon={
                        showCurrentPassword ? <ViewOffIcon /> : <ViewIcon />
                      }
                      aria-label={
                        showCurrentPassword ? "Hide password" : "Show password"
                      }
                    />
                  </InputRightElement>
                </InputGroup>
              </Box> */}

              <Box marginBottom="4" width="100%">
                <Text marginBottom="2px">New password</Text>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Enter new password"
                    width="100%"
                    name="newPassword"
                    onChange={handleInputChange}
                    bg="#F3F4F6"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleToggleNewPassword}
                      icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                      aria-label={
                        showNewPassword ? "Hide password" : "Show password"
                      }
                    />
                  </InputRightElement>
                </InputGroup>
              </Box>

              <Box marginBottom="4" width="100%">
                <Text marginBottom="2px">Confirm New password</Text>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm new Password"
                    width="100%"
                    onChange={handleInputChange}
                    name="confirmPassword"
                    bg="#F3F4F6"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      h="1.75rem"
                      size="sm"
                      onClick={handleToggleConfirmPassword}
                      icon={
                        showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />
                      }
                      aria-label={
                        showConfirmPassword ? "Hide password" : "Show password"
                      }
                    />
                  </InputRightElement>
                </InputGroup>
              </Box>

              <Flex width="100%" justifyContent="flex-end">
                <Button
                  onClick={handleChangePassword}
                  _hover={{ bg: "#E03741" }}
                  colorScheme="teal"
                  bg="#D1202A"
                >
                  Change Password
                </Button>
              </Flex>
            </>
          )}
        </Flex>

        {/* <Flex
          direction="column"
          border="2px solid #F3F4F6"
          borderRadius={"6px"}
          align="flex-start"
          flex="0.75"
          padding="20px"
        >
          <Box marginBottom="4" width="100%">
            <Text marginBottom="2px">Enter Email</Text>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                placeholder="Enter your Email"
                width="100%"
                
                bg={"#F3F4F6"} 
              />
              <InputRightElement width="4.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                 
                />
              </InputRightElement>
            </InputGroup>
          </Box>
          <Flex   width="100%" justifyContent="flex-end">
            <Button onClick={handleotp}  _hover={{ bg: "#E03741" }} colorScheme="teal" bg="#D1202A">
              Get OTP
            </Button>
          </Flex>
          <Box marginBottom="4" width="100%">
            <Text marginBottom="2px">Enter OTP</Text>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showCurrentPassword ? "text" : "password"}
                placeholder="Enter OTP"
                width="100%"
                name="otp"
                onChange={handleInputChange}
                bg={"#F3F4F6"} 
              />
              <InputRightElement width="4.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  
                  
                />
              </InputRightElement>
            </InputGroup>
          </Box>
          <Box marginBottom="4" width="100%">
            <Text marginBottom="2px">Current password</Text>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showCurrentPassword ? "text" : "password"}
                placeholder="Enter current password"
                width="100%"
                bg={"#F3F4F6"} 
              />
              <InputRightElement width="4.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  onClick={handleToggleCurrentPassword}
                  icon={showCurrentPassword ? <ViewOffIcon /> : <ViewIcon />}
                  aria-label={
                    showCurrentPassword ? "Hide password" : "Show password"
                  }
                />
              </InputRightElement>
            </InputGroup>
          </Box>


          <Box marginBottom="4" width="100%">
            <Text marginBottom="2px">New password</Text>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showNewPassword ? "text" : "password"}
                placeholder="Enter new password"
                width="100%"
                name="newPassword"
                onChange={handleInputChange}
                bg={"#F3F4F6"}
              />
              <InputRightElement width="4.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  onClick={handleToggleNewPassword}
                  icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                  aria-label={
                    showNewPassword ? "Hide password" : "Show password"
                  }
                />
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box marginBottom="4" width="100%">
            <Text marginBottom="2px">Confirm New password</Text>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm new Password"
                width="100%"
                bg={"#F3F4F6"}
              />
              <InputRightElement width="4.5rem">
                <IconButton
                  h="1.75rem"
                  size="sm"
                  onClick={handleToggleConfirmPassword}
                  icon={showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                  aria-label={
                    showConfirmPassword ? "Hide password" : "Show password"
                  }
                />
              </InputRightElement>
            </InputGroup>
          </Box>

          <Flex width="100%" justifyContent="flex-end">
            <Button onClick={handleChangePassword} _hover={{ bg: "#E03741" }} colorScheme="teal" bg="#D1202A">
              Change Password
            </Button>
          </Flex>
        </Flex>  */}
      </Flex>

      <Flex
        direction={direction}
        justify="space-between"
        align="flex-start"
        padding="20px"
        maxW={{ base: "100%", md: "100%", lg: "90%" }}
      >
        <Box flex="0.6" marginRight={direction === "row" ? "20px" : "0"}>
          <Text
            display="flex"
            alignItems="center"
            fontSize="18px"
            fontWeight="700"
            lineHeight="28px"
            textAlign="left"
          >
            <RiLinksLine style={{ marginRight: "8px" }} />
            Social Links
          </Text>
        </Box>

        <Flex
          direction="column"
          align="flex-start"
          flex="0.75"
          borderRadius={"6px"}
          border="2px solid #F3F4F6"
          padding="20px"
        >
          <Flex
            direction="row"
            padding="10px"
            bg="#FEF6F1"
            align="center" // Vertically align items in the center
            width="100%"
            marginBottom="10px"
            justify="space-between" // Distribute space between items
          >
            <Flex align="center" flex="1">
              <Icon
                as={AiOutlineMail}
                boxSize="30px"
                marginRight="20px"
                color="#D1202A"
              />
              <Text margin="auto" marginLeft={"10px"}>
                Connect your email account
              </Text>{" "}
              {/* Remove any default margin */}
            </Flex>
            <Button _hover={{ bg: "#E03741" }} colorScheme="teal" bg="#D1202A">
              Connect
            </Button>
          </Flex>

          <Flex
            direction="row"
            padding="10px"
            bg="#FEF6F1"
            align="center" // Vertically align items in the center
            width="100%"
            justify="space-between" // Distribute space between items
          >
            <Flex align="center" flex="1">
              <Icon
                as={FaWhatsapp}
                boxSize="30px"
                marginRight="20px"
                color="#D1202A"
              />
              <Text margin="auto" marginLeft={"10px"}>
                Connect What’s app
              </Text>{" "}
              {/* Remove any default margin */}
            </Flex>
            <Button _hover={{ bg: "#E03741" }} colorScheme="teal" bg="#D1202A">
              Connect
            </Button>
          </Flex>
        </Flex>
      </Flex>

      <Flex
        direction={direction}
        justify="space-between"
        align="flex-start"
        padding="20px"
        maxW={{ base: "100%", md: "100%", lg: "90%" }}
      >
        <Box flex="0.6" marginRight={direction === "row" ? "20px" : "0"}>
          <Text
            display="flex"
            alignItems="center"
            fontSize="18px"
            fontWeight="700"
            lineHeight="28px"
            textAlign="left"
          >
            <BsPersonLock style={{ marginRight: "8px" }} />
            2-Step Authentication
          </Text>

          <Text>
            Extra security layer for your account
            <br /> with a second authentication step
          </Text>
        </Box>

        <Flex
          direction="column"
          align="flex-start"
          flex="0.75"
          borderRadius={"6px"}
          border="2px solid #F3F4F6"
          padding="20px"
        >
          <Flex
            direction="row"
            align="center"
            justifyContent="space-between"
            width="100%"
          >
            <Text mb="2">2 Step authentication is off</Text>
            <Button
              _hover={{ bg: "#E03741" }}
              colorScheme="teal"
              bg={"#D1202A"}
            >
              Activate authentication
            </Button>
          </Flex>

          <Box width="100%" marginTop="4">
            <Flex bg={"#FEF6F1"} padding="10px" align="center">
              <Icon
                as={CiWarning}
                boxSize="24px"
                marginRight="8px"
                marginBottom="10px"
                margin={""}
              />
              <Text>
                If we detect a sign-in from an unknown device or browser, we’ll
                ask for your password <br></br> and a verification code.
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Flex>

    
    </Flex>
  );
};

export default NotificationSettings;
