import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  HStack,
  Tr,
  Th,
  Td,
  Checkbox,
  Button,
  Badge,
  Avatar,
  Input,
  Select,
  useDisclosure,
  IconButton,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FiFilter } from "react-icons/fi";
import CompanyProfile from "../Header/CompanyProfile";
import AddNewUser from "./AddUser";
import { CallAPI } from "../../Services/service";
import endpoints from "../../Services/endpoint";
import moment from "moment";
import "@fontsource/noto-sans";


const LeadManagementTable = ({ data }) => (
  <Table variant="simple" border="1px solid" borderColor="gray.200">
    <Thead>
      <Tr bg="#E8E8E8">
        <Th>
          <Checkbox />
        </Th>
        <Th fontWeight={"500"} fontSize={"14px"}>
          Name
        </Th>
        <Th fontWeight={"500"} fontSize={"14px"}>
          Phone
        </Th>
        <Th fontWeight={"500"} fontSize={"14px"}>
          Email
        </Th>
        <Th fontWeight={"500"} fontSize={"14px"}>
          Location
        </Th>
        <Th fontWeight={"500"} fontSize={"14px"}>
          Created
        </Th>
        <Th fontWeight={"500"} fontSize={"14px"}>
          Last Activity
        </Th>
        <Th fontWeight={"500"} fontSize={"14px"}>
          Status
        </Th>
        <Th fontWeight={"500"} fontSize={"14px"}>
          Action
        </Th>
      </Tr>
    </Thead>
    <Tbody>
      {data.map((item) => (
        <Tr key={item.id}>
          <Td>
            <Checkbox />
          </Td>
          <Td>
            <Flex align="center">
              <Avatar
                src={`http://localhost:5223${item.image}`}
                size="sm"
                mr="2"
              />
              <Box>
                <Text fontWeight="400" fontSize="14px">
                  {item.name}
                </Text>
                <Text fontSize="12px" color="gray.500">
                  {item.role}
                </Text>
              </Box>
            </Flex>
          </Td>
          <Td>{item.phone1}</Td>
          <Td>{item.email}</Td>
          <Td>{item.location}</Td>
          <Td>{moment(item.createdAt).format("DD-MM-YYYY")}</Td>
          <Td>{item.lastActivated}</Td>
          <Td>
            <Badge colorScheme={item.status === "Inactive" ? "red" : "green"}>
              {item.status}
            </Badge>
          </Td>
          <Td>
            <IconButton
              icon={<FaEllipsisV />}
              variant="ghost"
              aria-label="Options"
            />
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);

const UserManagements = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [userData, setData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await CallAPI(endpoints.getUsers);
      if (res?.code === 200) {
        setData(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const totalPages = Math.ceil(userData.length / itemsPerPage);
  const paginatedData = userData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Noto Sans', sans-serif`,
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <Box p={2} overflowX="auto">
        <AddNewUser isOpen={isOpen} onClose={onClose} getData={getData} />
        <Box>
          <Flex
            direction={flexDirection}
            justify="space-between"
            align="center"
            mb={4}
          >
            <CompanyProfile />
          </Flex>
          <Box p={4} borderWidth="1px" borderRadius="md" bg="white">
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              mb={4}
            >
              <Input
                placeholder="Search User"
                width={{ base: "100%", md: "300px" }}
                mb={{ base: 4, md: 0 }}
              />
              <HStack spacing={2}>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    colorScheme="gray"
                    variant="outline"
                  >
                    Export
                  </MenuButton>
                  <MenuList>
                    <MenuItem>Export as CSV</MenuItem>
                    <MenuItem>Export as PDF</MenuItem>
                    <MenuItem>Export as Excel</MenuItem>
                  </MenuList>
                </Menu>
                <Button colorScheme="red" onClick={onOpen}>
                  Add User
                </Button>
              </HStack>
            </Flex>
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              mb={4}
            >
              <HStack spacing={4} mb={{ base: 4, md: 0 }}>
                <Select
                  placeholder="Sort by"
                  width={{ base: "100%", md: "150px" }}
                />
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                />
              </HStack>
              <HStack spacing={4}>
                <Button colorScheme="blue">Manage Columns</Button>
                <Button leftIcon={<FiFilter />}>Filter</Button>
              </HStack>
            </Flex>
            <LeadManagementTable data={paginatedData} />
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              mt={4}
            >
              <HStack>
                <Text mt={4}>Show</Text>
                <Select
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(Number(e.target.value))}
                  width="73px"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                </Select>
                <Text mt={4}>entries</Text>
              </HStack>
              <HStack>
                <Button
                  onClick={() => handlePageChange(-1)}
                  disabled={currentPage === 1}
                  leftIcon={<ChevronLeftIcon />}
                >
                  Prev
                </Button>
                {Array.from({ length: totalPages }, (_, index) => (
                  <Button
                    key={index}
                    colorScheme={currentPage === index + 1 ? "blue" : "gray"}
                    onClick={() => setCurrentPage(index + 1)}
                  >
                    {index + 1}
                  </Button>
                ))}
                <Button
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === totalPages}
                  rightIcon={<ChevronRightIcon />}
                >
                  Next
                </Button>
              </HStack>
            </Flex>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default UserManagements;
