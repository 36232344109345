import React, { useState } from 'react'
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Tag,
  TagLabel,
  Flex,
  Text,
  IconButton,
  Button,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
  Input,
  useDisclosure,
  Divider,
  Select,
  Checkbox,
  InputLeftElement,
  InputGroup,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import {
  FiMail,
  FiPhone,
  FiVideo,
  FiMessageSquare,
  FiMoreVertical,
  FiCalendar,
} from "react-icons/fi";
import { StarIcon } from "@chakra-ui/icons";
import CompanyProfile from '../Header/CompanyProfile';
import DatePicker from 'react-datepicker';
import { AiOutlineStar } from 'react-icons/ai';
import AddNew1 from './AddNew1';

const contacts = [
  {
    name: "Darlee Robertson",
    role: "Facility Manager",
    phone: "1234567890",
    email: "robertson@example.com",
    tag: "Collab",
    location: "Germany",
    rating: 4.2,
    owner: "Hendry",
    status: "Active",
  },
  {
    name: "Sharon Roy",
    role: "Installer",
    phone: "+1 989757485",
    email: "sharon@example.com",
    tag: "Promotion",
    location: "USA",
    rating: 5.0,
    owner: "Guillory",
    status: "Inactive",
  },
  {
    name: "Vaughan",
    role: "Senior Manager",
    phone: "+1 546555455",
    email: "vaughan12@example.com",
    tag: "Collab",
    location: "Canada",
    rating: 3.5,
    owner: "Jami",
    status: "Active",
  },
  {
    name: "garfield",
    role: "Manager",
    phone: "+1 546555455",
    email: "garfield12@example.com",
    tag: "Collab",
    location: "Canada",
    rating: 3.5,
    owner: "Jami",
    status: "Active",
  },
  // Add more rows like the above to simulate the data
];


 const theme = extendTheme({
   fonts: {
     heading: `'Noto Sans', sans-serif`,
     body: `'Noto Sans', sans-serif`,
   },
 });


const Contact = () => {
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [startDate, setStartDate] = useState(null);
   const [searchQuery, setSearchQuery] = useState(""); // State for search query
   const [sortOption, setSortOption] = useState(""); // State for sort option
   const [selectedPriority, setSelectedPriority] = useState("");
    const [check, setCheck] = useState("");

    const onChangeEvent = (e) => {
      setCheck(e.target.checked);
    };


    const filteredPipelines = contacts.filter(
      (pipeline) =>
        pipeline.name.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
    );

    const sortPipelines = (data, option) => {
      if (option === "name") {
        return [...data].sort((a, b) => a.name.localeCompare(b.name)); // Alphabetical order
      } else if (option === "rating") {
        return [...data].sort(
          (a, b) => parseFloat(b.rating) - parseFloat(a.rating)
        ); // Highest to lowest deal value
      }
      return data;
    };

      const sortedPipelines = selectedPriority
        ? contacts.filter(
            (project) =>
              project.status.toLowerCase() === selectedPriority.toLowerCase()
          )
        : sortPipelines(filteredPipelines, sortOption);





  return (
    <ChakraProvider theme={theme}>
      <Box p={8} borderWidth="1px" borderRadius="md">
        <CompanyProfile />
        {/* Header Buttons */}
        {/* <Flex justify="space-between" mb={4}>
        <Button colorScheme="red">Add Contacts</Button>
        <Flex gap={4}>
          <Button colorScheme="purple">Manage Columns</Button>
          <Button>Filter</Button>
        </Flex>
      </Flex> */}
        <Box bg={"white"} p={3} mt={3}>
          <Flex
            justifyContent="space-between"
            mt={4}
            flexWrap="wrap"
            gap={4}
            bg={"white"}
          >
            <Input
              placeholder="Search Contacts"
              w={{ base: "100%", md: "505px" }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Flex
              gap={2}
              flexDirection={{ base: "column", md: "row" }}
              justifyContent={"center"}
            >
              <Button
                size={buttonSize}
                w={"109px"}
                fontSize={"14px"}
                fontWeight={"400"}
              >
                Export
              </Button>
              <Button
                colorScheme="red"
                size={buttonSize}
                onClick={onOpen}
                w={"178px"}
                fontWeight={"600"}
                fontSize={"14px"}
              >
                Add New Contact
              </Button>
            </Flex>
          </Flex>
          <Divider />
          <AddNew1 onClose={onClose} isOpen={isOpen} />

          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            p={2}
            mb={4}
            gap={2}
          >
            <Flex
              spacing={4}
              w="100%"
              direction={{ base: "column", md: "row" }}
              gap={2}
            >
              <Select
                placeholder="Sort"
                fontSize="14px"
                w={{ base: "100%", md: "93px" }}
                onChange={(e) => setSortOption(e.target.value)}
                p="3px"
              >
                <option value="name">Name</option>
                <option value="rating">Rating</option>
              </Select>
              <Flex alignItems={"center"}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <FiCalendar color="gray.400" />
                  </InputLeftElement>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    customInput={
                      <Input
                        placeholder="Start Date"
                        textAlign="center"
                        fontSize="14px"
                        w={{ base: "100%", md: "180px" }}
                      />
                    }
                  />
                </InputGroup>
              </Flex>
            </Flex>

            <Flex flexDirection={{ base: "column", md: "row" }} gap={2}>
              <Button
                variant="outline"
                fontSize="14px"
                bg={"#F3EDFF"}
                color={"#4A00E5"}
                w={"164px"}
                h={"41px"}
              >
                Manage Columns
              </Button>
              <Button variant="outline" fontSize="13px" w={"88px"} h={"41px"}>
                Filter
              </Button>
            </Flex>
          </Flex>

          {/* Table Component */}
          <Box bg={"white"} overflow={"auto"}>
            <Table variant="simple" boxShadow="md">
              <Thead>
                <Tr>
                  <Th>
                    <input
                      type="checkbox"
                      checked={check}
                      onChange={(e) => onChangeEvent(e)}
                    />
                  </Th>
                  <Th></Th>
                  <Th>Name</Th>
                  <Th w={"500px"}>Phone</Th>
                  <Th>Email</Th>
                  <Th>Tags</Th>
                  <Th>Location</Th>
                  <Th>Rating</Th>
                  <Th>Owner</Th>
                  <Th>Contact</Th>
                  <Th>Status</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {sortedPipelines.map((contact, index) => (
                  <Tr key={index}>
                    {/* Name with Avatar */}
                    <Td>
                      <input type="checkbox"  />
                    </Td>
                    <Td>
                      <AiOutlineStar size={18} />
                    </Td>
                    <Td>
                      <Flex
                        alignItems="center"
                        h={"50px"}
                        lineHeight={"2px"}
                        w={"155px"}
                      >
                        <Avatar size="sm" name={contact.name} mr={2} />
                        <Flex mt={"15px"} flexDirection={"column"}>
                          <Text fontWeight="600" fontSize="14px">
                            {contact.name}
                          </Text>
                          <Text fontSize="12px" w={"180px"}>
                            {contact.role}
                          </Text>
                        </Flex>
                      </Flex>
                    </Td>

                    {/* Phone */}
                    <Td fontWeight={"400"} fontSize={"14px"}>
                      {contact.phone}
                    </Td>

                    {/* Email */}
                    <Td fontWeight={"400"} fontSize={"14px"}>
                      {contact.email}
                    </Td>

                    {/* Tags */}
                    <Td>
                      <Tag
                        colorScheme={
                          contact.tag === "Collab"
                            ? "green"
                            : contact.tag === "Promotion"
                            ? "purple"
                            : "yellow"
                        }
                      >
                        <TagLabel>{contact.tag}</TagLabel>
                      </Tag>
                    </Td>

                    {/* Location */}
                    <Td fontWeight={"400"} fontSize={"14px"}>
                      {contact.location}
                    </Td>

                    {/* Rating */}
                    <Td>
                      <Flex
                        alignItems="center"
                        justifyContent={"center"}
                        gap={1}
                      >
                        <StarIcon color="orange.400" />
                        {/* <Text fontSize="14px" > */}
                        {contact.rating}
                        {/* </Text> */}
                      </Flex>
                    </Td>

                    {/* Owner */}
                    <Td fontWeight={"400"} fontSize={"14px"}>
                      {contact.owner}
                    </Td>

                    {/* Contact Icons */}
                    <Td fontWeight={"400"} fontSize={"14px"}>
                      <Flex gap={2}>
                        <IconButton
                          icon={<FiMail />}
                          size="sm"
                          aria-label="Email"
                        />
                        <IconButton
                          icon={<FiPhone />}
                          size="sm"
                          aria-label="Call"
                        />
                        <IconButton
                          icon={<FiVideo />}
                          size="sm"
                          aria-label="Video Call"
                        />
                        <IconButton
                          icon={<FiMessageSquare />}
                          size="sm"
                          aria-label="Message"
                        />
                      </Flex>
                    </Td>

                    {/* Status */}
                    <Td>
                      <Badge
                        colorScheme={
                          contact.status === "Active" ? "green" : "red"
                        }
                        px={2}
                        py={1}
                        borderRadius="lg"
                      >
                        {contact.status}
                      </Badge>
                    </Td>

                    {/* Action */}
                    <Td>
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          icon={<FiMoreVertical />}
                          variant="ghost"
                          aria-label="Actions"
                        />
                        <MenuList>
                          <MenuItem>Edit</MenuItem>
                          <MenuItem>Delete</MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default Contact
