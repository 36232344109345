import React from "react";
import {
  Box,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  useBreakpointValue,
  MenuItem,
  MenuList,
  Menu,
  MenuButton,
  Button,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const contacts = [
  {
    name: "Carol Thomas",
    role: "UI/UX Designer",
    email: "caro3@example.com",
    phone: "+1 124547845",
    createdAt: "25 Sep 2023, 12:12 pm",
  },
  {
    name: "Dawn Mercha",
    role: "Technician",
    email: "dercha@example.com",
    phone: "+1 478845447",
    createdAt: "02 Oct 2023, 11:22 am",
  },
  {
    name: "Rachel Hampton",
    role: "Software Developer",
    email: "rael@example.com",
    phone: "+1 215548445",
    createdAt: "04 Oct 2023, 04:12 pm",
  },
  {
    name: "Jonelle Curtiss",
    role: "Supervisor",
    email: "joe@example.com",
    phone: "+1 124547845",
    createdAt: "06 Oct 2023, 11:34 am",
  },
  {
    name: "Sharon Roy",
    role: "Installer",
    email: "sharon@example.com",
    phone: "+1 466701256",
    createdAt: "15 Nov 2023, 07:26 pm",
  },
];

const theme = extendTheme({
  fonts: {
    heading: `'Noto Sans', sans-serif`,
    body: `'Noto Sans', sans-serif`,
  },
});

const ContactList = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <ChakraProvider theme={theme}>
      <Box p={2} w={"660px"} bg={"white"}>
        {/* Header */}
        <Flex justifyContent="space-between" mb={4} w={"100%"} p="5px">
          <Text fontSize="18px" fontWeight="700">
            Recently Created Contacts
          </Text>

          <Menu>
            <MenuButton
              w="auto"
              as={Button}
              fontSize="12px"
              fontWeight="400"
              textAlign={"center"}
              variant="outline"
              rightIcon={<ChevronDownIcon />}
            >
              Last 30 Days
            </MenuButton>
            <MenuList>
              <MenuItem>Last 7 Days</MenuItem>
              <MenuItem>Last 30 Days</MenuItem>
              <MenuItem>All Time</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        {/* Table */}
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th
                w="150.91px"
                fontWeight="500"
                fontSize="14px"
                textAlign="left"
              >
                Contact
              </Th>
              <Th
                w="167.11px"
                fontWeight="500"
                fontSize="14px"
                textAlign="left"
              >
                Email
              </Th>
              <Th
                w="134.06px"
                fontWeight="500"
                fontSize="14px"
                textAlign="left"
              >
                Phone
              </Th>
              <Th
                w="148.36px"
                fontWeight="500"
                fontSize="14px"
                textAlign="left"
              >
                Created At
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {contacts.map((contact, index) => (
              <Tr key={index}>
                <Td w="120.91px" h="63px">
                  <Flex
                    alignItems="center"
                    h={"50px"}
                    lineHeight={"2px"}
                    w={"155px"}
                  >
                    <Avatar size="sm" name={contact.name} mr={2} />
                    <Flex mt={"15px"} flexDirection={"column"}>
                      <Text fontWeight="600" fontSize="14px">
                        {contact.name}
                      </Text>
                      <Text fontSize="12px" w={'180px'}>{contact.role}</Text>
                    </Flex>
                  </Flex>
                </Td>
                <Td w="107.11px">
                  <Text fontWeight="400" fontSize="14px" noOfLines={1}>
                    {contact.email}
                  </Text>
                </Td>
                <Td w="94.06px">
                  <Text fontWeight="400" fontSize="14px" noOfLines={1}>
                    {contact.phone}
                  </Text>
                </Td>
                <Td w="168.36px">
                  <Text fontWeight="400" fontSize="14px" noOfLines={1}>
                    {contact.createdAt}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </ChakraProvider>
  );
};

export default ContactList;
