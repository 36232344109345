import React, { useState } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Avatar,
  Text,
  Button,
  IconButton,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
  HStack,
  VStack,
  Flex,
  Input,
  Select,
  DrawerOverlay,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import CompanyProfile from "../Header/CompanyProfile";
import AddSequence from "./addSequence";
import "@fontsource/noto-sans";



  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Raleway', sans-serif`,
    },
  });


const CampaignTable = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [searchQuery, setSearchQuery] = useState(""); 
  const [sortOption, setSortOption] = useState(""); // State for sort option
  const [selectedPriority, setSelectedPriority] = useState("");
  
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const data = [
    {
      name: "Vaughan",
      role: "Senior Manager",
      campaign: "Facebook",
      status: "Active",
    },
    {
      name: "Darlee Robertson",
      role: "Facility Manager",
      campaign: "LinkedIn",
      status: "Inactive",
    },
    {
      name: "Sharon Roy",
      role: "Installer",
      campaign: "Whatsapp",
      status: "Active",
    },
    {
      name: "Sharon Roy",
      role: "Installer",
      campaign: "Whatsapp",
      status: "Active",
    },
    {
      name: "Sharon Roy",
      role: "Installer",
      campaign: "Whatsapp",
      status: "Active",
    },
  ];

   const filteredPipelines = data.filter(
     (pipeline) =>
       pipeline?.name.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
   );

   const sortPipelines = (data, option) => {
     if (option === "name") {
       return [...data].sort((a, b) => a.name?.localeCompare(b.name)); // Alphabetical order
     } else if (option === "value") {
       return [...data].sort(
         (a, b) => parseFloat(b.value) - parseFloat(a.value)
       ); // Highest to lowest deal value
     }
     return data;
   };

   const sortedPipelines = selectedPriority
     ? data.filter(
         (project) =>
           project?.status.toLowerCase() === selectedPriority.toLowerCase()
       )
     : sortPipelines(filteredPipelines, sortOption);

  return (
    <ChakraProvider theme={theme}>
      <Box p={4} overflowX="auto" bg={'white'} position={"relative"} >
        <Box
          p={4}
          opacity={isDrawerOpen ? 0.3 : 1} // Reduce opacity when drawer is open
          transition="opacity 0.3s ease-in-out"
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Text
              fontWeight="600"
              fontSize="18px"
              lineHeight="21px"
              bg="white"
              width="30%"
              p="8px"
            >
              Sequences
            </Text>
            <Flex
              align="center"
              justifyContent="space-between"
              borderRadius="md"
              width="100%"
              p="3"
              gap={'15px'}
              direction={{ base: "column", md: "row" }}
            >
              <Flex gap={'10px'}>
                <Input
                  placeholder={`Search Sequences`}
                  p={"20px"}
                  textAlign={"left"}
                  w={{ base: "100%", md: "40%" }}
                  boxShadow={"5px"}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Select
                  placeholder="Sort"
                  w="25%"
                  mb={{ base: 2, md: 0 }}
                  onChange={(e) => setSortOption(e.target.value)}
                >
                  <option value="name">Name</option>
                </Select>
                <Select
                  placeholder="Filter"
                  onChange={(e) => setSelectedPriority(e.target.value)}
                  w="120px"
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Select>
              </Flex>
              <Flex gap={'5px'}>
                <Select
                  placeholder="Export"
                  w="50%"
                  fontWeight={"400px"}
                  fontSize={"14px"}
                >
                  <option value="date">Date</option>
                  <option value="amount">Amount</option>
                </Select>
                <Button colorScheme="red" onClick={() => setIsDrawerOpen(true)}>
                  Create Sequences
                </Button>
              </Flex>
            </Flex>
          </Box>

          <Table variant="simple" size={isMobile ? "sm" : "md"}>
            <Thead>
              <Tr>
                <Th>
                  <Checkbox />
                </Th>
                <Th>Name</Th>
                <Th>Campaign Type</Th>
                <Th>Sent</Th>
                <Th>Delivered</Th>
                <Th>Bounce</Th>
                <Th>Clicks</Th>
                <Th>Interested</Th>
                <Th>Reply</Th>
                <Th>Status</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody bg={"white"}>
              {sortedPipelines.map((item, index) => (
                <Tr key={index}>
                  <Td>
                    <Checkbox />
                  </Td>
                  <Td w="120.91px" h="63px">
                    <Flex
                      alignItems="center"
                      h={"50px"}
                      lineHeight={"2px"}
                      w={"155px"}
                    >
                      <Avatar size="sm" name={item.name} mr={2} />
                      <Flex mt={"15px"} flexDirection={"column"}>
                        <Text fontWeight="600" fontSize="14px">
                          {item.name}
                        </Text>
                        <Text fontSize="12px" w={"180px"}>
                          {item.role}
                        </Text>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td>{item.campaign}</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>-</Td>
                  <Td>
                    <Button
                      size="xs"
                      colorScheme={item.status === "Active" ? "green" : "red"}
                    >
                      {item.status}
                    </Button>
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        icon={<FiMoreVertical />}
                        size="sm"
                      />
                      <MenuList>
                        <MenuItem>View</MenuItem>
                        <MenuItem>Edit</MenuItem>
                        <MenuItem>Delete</MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <Drawer
            size={""}
            isOpen={isDrawerOpen}
            placement="center"
            onClose={() => setIsDrawerOpen(false)}
            isCentered
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Create New Sequence</DrawerHeader>
              <DrawerBody>
                <AddSequence />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default CampaignTable;
