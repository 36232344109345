import {
  Box,
  Flex,
  Grid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Select,
  Button,
  Avatar,
  HStack,
  Progress,
  Tag,
} from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ContactList from "./RecentlyCreatedContact";
import Activities from "./Activities";
import DealsTable from "./DealsTable";
import CampaignsList from "./CampaignsData";
import CompaniesTable from "./CompaniesTable";
import LeadTable from "./LeadTable";
import LostLeadsChart from "./LostLeadStage";
import DealsByStageChart from "./DealsByStage";
import WonDealsChart from "./WonDealsChart";
import ClientInfo from "./ClientInfo";
import LeadsByStageChart from "./LeadsByStage";
import CompanyProfile from "../Header/CompanyProfile";
import LostLeadsCharts from "./LostLeadStage copy";

// Dummy Data
const contacts = [
  {
    name: "Carol Thomas",
    email: "carol@example.com",
    phone: "+1 123456789",
    createdAt: "25 Sep 2023",
    role: "UI/UX Designer",
  },
  {
    name: "Dawn Mercha",
    email: "dawn@example.com",
    phone: "+1 478945447",
    createdAt: "27 Sep 2023",
    role: "Technician",
  },
  {
    name: "Rachel Hampton",
    email: "rachel@example.com",
    phone: "+1 215544845",
    createdAt: "04 Oct 2023",
    role: "Software Developer",
  },
];

const dealsData = [
  { name: "Pipeline", value: 400 },
  { name: "Follow Up", value: 130 },
  { name: "Schedule", value: 248 },
  { name: "Conversation", value: 470 },
  { name: "Won", value: 470 },
  { name: "Lost", value: 150 },
];

const Analytics = () => {
  const chartData = {
    labels: dealsData.map((d) => d.name),
    datasets: [
      {
        label: "Deals By Stage",
        data: dealsData.map((d) => d.value),
        backgroundColor: "#3182CE",
      },
    ],
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <Flex
          align="center"
          justifyContent="space-between"
          borderRadius="md"
          width="100%"
          p="3"
          gap={10}
          direction={{ base: "column", md: "row" }}
        >
          <CompanyProfile />
        </Flex>
      </Box>

      <Grid templateColumns={{ base: "1fr", md: "650px 720px" }} gap={6} overflowX={'hidden'}>
        <Box p={2}>
          <ContactList />
        </Box>

        <Box p={2} w={'100%'}>
          <DealsByStageChart />
        </Box>

        <Box
          p={{ base: 1, md: 2 }}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          <WonDealsChart />
        </Box>
        <Box p={2} mt={"-20px"}>
          <Activities />
        </Box>

        <Box
          p={{ base: 1, md: 2 }}
          mt={{ base: "0", md: "-150px", lg: "-250px" }}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          <DealsTable />
        </Box>

        <Box p={2} w={"716px"} mt={"-20px"}>
          <LostLeadsChart />
        </Box>
        <Box p={2} mt={"-220px"} w={"670px"}>
          <LostLeadsChart />
        </Box>

        <Box
          p={{ base: 1, md: 5 }}
          mt={{ base: "0", md: "-15px", lg: "-20px" }}
          ml={{ base: "0", md: "-10px", lg: "-28px" }}
          fontSize={{ base: "sm", md: "md", lg: "lg" }}
        >
          <LeadTable />
        </Box>

        <Box p={5} mt={"-330px"} ml={"-10px"}>
          <LeadsByStageChart />
        </Box>

        <Box p={5} mt={"-40px"}>
          <CampaignsList />
        </Box>
        <Box p={5} mt={"-660px"} ml={"-10px"}>
          <CompaniesTable />
        </Box>
      </Grid>
    </div>
  );
};

export default Analytics;
