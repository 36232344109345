import React, { useState } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";

const CampaignGroupsfun = ({ isOpen, onClose, campaignGroups,selectedCampaignGroup,setCampaignGroup}) => {
  const [formData, setFormData] = useState({
    name: "",
    startDate: "",
    endDate: "",
    status: "ACTIVE",
    totalBudget: {
      amount: "",
      currencyCode: "USD",
    },
  });

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleBudgetChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      totalBudget: {
        ...prev.totalBudget,
        [field]: value,
      },
    }));
  };

  const handleCreateGroup = () => {
    // Validation
    if (!formData.name || !formData.startDate || !formData.endDate || !formData.totalBudget.amount) {
      alert("All fields are required.");
      return;
    }
    if (new Date(formData.endDate) <= new Date(formData.startDate)) {
      alert("End date must be after start date.");
      return;
    }

    // Add the new campaign group to the list
    const newGroup = { ...formData, id: Date.now() };
    setFormData({
      name: "",
      startDate: "",
      endDate: "",
      status: "ACTIVE",
      totalBudget: {
        amount: "",
        currencyCode: "USD",
      },
    });
    onClose(false);
  };
const setCampaignGroup1=(group)=>{
    setCampaignGroup(group)
    onClose()
}
 

  return (
    <Box p={5}>
      {/* Campaign Group List */}
     

     

      {/* Campaign Group Form Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Campaign Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>

         {campaignGroups.length >0 ?   <Box>
            <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Start Date</Th>
            <Th>End Date</Th>
            <Th>Status</Th>
            <Th>Total Budget</Th>
          </Tr>
        </Thead>
        <Tbody>
        {campaignGroups.map((group) => (
                      <Tr
                        key={group.id}
                        onClick={() => setCampaignGroup1(group)}
                        _hover={{ bg: "gray.100", cursor: "pointer" }}
                        bg={
                          selectedCampaignGroup?.id === group.id
                            ? "blue.100"
                            : "transparent"
                        }
                      >
                        <Td>{group?.name}</Td>
                        <Td>{group?.startDate}</Td>
                        <Td>{group?.endDate}</Td>
                        <Td>{group?.status}</Td>
                        <Td>
                          {group?.totalBudget?.amount}{" "}
                          {group?.totalBudget?.currencyCode}
                        </Td>
                      </Tr>
                    ))}
        </Tbody>
      </Table>
            </Box> :
            <Stack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Enter campaign group name"
                  value={formData.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Start Date</FormLabel>
                <Input
                  type="date"
                  value={formData.startDate}
                  onChange={(e) => handleChange("startDate", e.target.value)}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>End Date</FormLabel>
                <Input
                  type="date"
                  value={formData.endDate}
                  onChange={(e) => handleChange("endDate", e.target.value)}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Status</FormLabel>
                <Select
                  value={formData.status}
                  onChange={(e) => handleChange("status", e.target.value)}
                >
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Total Budget</FormLabel>
                <NumberInput
                  min={0}
                  value={formData.totalBudget.amount}
                  onChange={(valueString) => handleBudgetChange("amount", valueString)}
                >
                  <NumberInputField placeholder="Enter amount" />
                </NumberInput>
                <Select
                  mt={2}
                  value={formData.totalBudget.currencyCode}
                  onChange={(e) => handleBudgetChange("currencyCode", e.target.value)}
                >
                  <option value="USD">USD</option>
                  <option value="INR">INR</option>
                  <option value="EUR">EUR</option>
                </Select>
              </FormControl>
            </Stack> }
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCreateGroup}>
              Create
            </Button>
            <Button variant="ghost" onClick={() => onClose(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CampaignGroupsfun;
