import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Select,
  Badge,
  Divider,
  useDisclosure,
  Checkbox,
  extendTheme,
  Heading,
  HStack,
  ChakraProvider,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ClientInfo from "./ClientInfo";
import DatePicker from "react-datepicker";
import { CiSearch } from "react-icons/ci";
import AddNewPropsal from "./AddNewPropsal";
import "@fontsource/noto-sans";

import CompanyProfile from "../Header/CompanyProfile";

const ProposalsTable = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [startDate, setStartDate] = useState(null);
   const [currentPage, setCurrentPage] = useState(1);
   const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const [sortOption, setSortOption] = useState(""); // State for sort option
    const [selectedPriority, setSelectedPriority] = useState("");

  const data = [
    {
      id: "#1493024",
      subject: "SEO Proposal",
      sendTo: "NovaWave LLC",
      value: "$2,05,426",
      date: "15 May 2024",
      openTill: "15 Aug 2023",
      project: "Truelysell",
      createdDate: "21 May 2024",
      status: "Accepted",
    },
    {
      id: "#1493023",
      subject: "Web Design",
      sendTo: "Redwood Inc",
      value: "$2,105",
      date: "16 Jan 2024",
      openTill: "15 Sep 2024",
      project: "Dreamsports",
      createdDate: "15 Sep 2024",
      status: "Declined",
    },
    {
      id: "#1493023",
      subject: "Web Design",
      sendTo: "Redwood Inc",
      value: "$2,105",
      date: "16 Jan 2024",
      openTill: "15 Sep 2024",
      project: "Dreamsports",
      createdDate: "15 Sep 2024",
      status: "Declined",
    },
    {
      id: "#1493023",
      subject: "Web Design",
      sendTo: "Redwood Inc",
      value: "$2,105",
      date: "16 Jan 2024",
      openTill: "15 Sep 2024",
      project: "Dreamsports",
      createdDate: "15 Sep 2024",
      status: "Declined",
    },
    {
      id: "#1493023",
      subject: "Web Design",
      sendTo: "Redwood Inc",
      value: "$2,105",
      date: "16 Jan 2024",
      openTill: "15 Sep 2024",
      project: "Dreamsports",
      createdDate: "15 Sep 2024",
      status: "Declined",
    },
    {
      id: "#1493023",
      subject: "Web Design",
      sendTo: "Redwood Inc",
      value: "$2,105",
      date: "16 Jan 2024",
      openTill: "15 Sep 2024",
      project: "Dreamsports",
      createdDate: "15 Sep 2024",
      status: "Declined",
    },
    {
      id: "#1493023",
      subject: "Web Design",
      sendTo: "Redwood Inc",
      value: "$2,105",
      date: "16 Jan 2024",
      openTill: "15 Sep 2024",
      project: "Dreamsports",
      createdDate: "15 Sep 2024",
      status: "Declined",
    },
    {
      id: "#1493023",
      subject: "Web Design",
      sendTo: "Redwood Inc",
      value: "$2,105",
      date: "16 Jan 2024",
      openTill: "15 Sep 2024",
      project: "Dreamsports",
      createdDate: "15 Sep 2024",
      status: "Declined",
    },
    {
      id: "#1493023",
      subject: "Web Design",
      sendTo: "Redwood Inc",
      value: "$2,105",
      date: "16 Jan 2024",
      openTill: "15 Sep 2024",
      project: "Dreamsports",
      createdDate: "15 Sep 2024",
      status: "Declined",
    },
    // Add more rows as needed
  ];

  

  const theme = extendTheme({
    fonts: {
      heading: `'Noto Sans', sans-serif`,
      body: `'Raleway', sans-serif`,
    },
  });


  const getStatusBadgeColor = (status) => {
    switch (status) {
      case "Accepted":
        return "green";
      case "Declined":
        return "red";
      case "Draft":
        return "blue";
      case "Deleted":
        return "gray";
      case "Paused":
        return "orange";
      case "Sent":
        return "cyan";
      default:
        return "gray";
    }
  };

  const filteredData = data.filter((lead) => {
    // Add your filtering logic here
    return true;
  });

   const totalPages = Math.ceil(filteredData.length / itemsPerPage);
   const paginatedData = filteredData.slice(
     (currentPage - 1) * itemsPerPage,
     currentPage * itemsPerPage
   );

   const handlePageChange = (direction) => {
     setCurrentPage((prev) =>
       Math.max(1, Math.min(totalPages, prev + direction))
     );
   };

   const handleItemsPerPageChange = (event) => {
     setItemsPerPage(Number(event.target.value));
     setCurrentPage(1);
   };

    const filteredPipelines = data.filter(
      (pipeline) =>
        pipeline?.subject.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
    );

    const sortPipelines = (data, option) => {
      if (option === "subject") {
        return [...data].sort((a, b) => a.subject.localeCompare(b.subject)); // Alphabetical order
      } else if (option === "value") {
        return [...data].sort(
          (a, b) => parseFloat(b.value) - parseFloat(a.value)
        ); // Highest to lowest deal value
      }
      return data;
    };

    const sortedPipelines = selectedPriority? data.filter(
          (project) =>
            project.status.toLowerCase() === selectedPriority.toLowerCase()
        )
      : sortPipelines(filteredPipelines, sortOption);


  return (
    <ChakraProvider theme={theme}>
      <Box p={5}>
        {/* <CompanyProfile/> */}
        {/* Filter and Controls */}
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <Flex
            align="center"
            justifyContent="space-between"
            borderRadius="md"
            width="100%"
            p="3"
            gap={10}
            direction={{ base: "column", md: "row" }}
          >
            <CompanyProfile />
          </Flex>
        </Box>
        <Box bg={"white"}>
          <Flex
            justifyContent="space-between"
            mb={4}
            flexWrap="wrap"
            gap={4}
            bg={"white"}
          >
            <Flex
              direction={{ base: "column", md: "row" }}
              justify="space-between"
              mt={"25px"}
              w={"100%"}
            >
              <Input
                placeholder={`Search Proposals`}
                p={"15px"}
                textAlign={"left"}
                w={{ base: "100%", md: "40%" }}
                // mb={{ base: 2, md: 0 }}
                ml={"10px"}
                boxShadow={"5px"}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />

              <Flex
                justifyContent="space-around"
                gap="10px"
                mr="15px"
                w={{ base: "100%", md: "auto" }}
                direction={{ base: "column", md: "row" }}
              >
                <Select
                  placeholder="Export"
                  w="100%"
                  fontWeight={"400px"}
                  fontSize={"14px"}
                >
                  <option value="date">Date</option>
                  <option value="amount">Amount</option>
                </Select>
                <Button
                  colorScheme="red"
                  w="100%"
                  px={5}
                  onClick={onOpen}
                  fontWeight={"600px"}
                  fontSize={"14px"}
                >
                  Add New Proposals
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <AddNewPropsal isOpen={isOpen} onClose={onClose} />
          <Divider mb={4} />
          <Box overflowX="auto" bg={"white"}>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent="space-between"
              mb={4}
            >
              <Flex
                gap="8px"
                p={"11px"}
                maxW={{ base: "100%", md: "80%" }}
                direction={{ base: "column", md: "row" }}
              >
                <Text as="h3" mb={{ base: 2, md: 0 }} fontWeight={"bold"}>
                  <span>Proposals</span>
                </Text>
                <Select
                  placeholder="Sort"
                  w="100%"
                  mt={{ base: 2, md: 0 }}
                  p="7px"
                  onChange={(e) => setSortOption(e.target.value)}
                >
                  <option value="subject">Subject</option>
                  <option value="value">Value</option>
                </Select>
                <Flex mt="5px">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Start Date"
                    customInput={<Input />}
                  />
                </Flex>
              </Flex>

              <Flex mt={{ base: 2, md: 0 }} gap={"5px"} px={'10px'}>
                <Button
                  variant="outline"
                  mr={2}
                  bg={"#F3EDFF"}
                  color={"#4A00E5"}
                  fontSize={"14px"}
                  fontWeight={"600"}
                >
                  Manage Columns
                </Button>
                <Select
                  placeholder="Filter"
                  onChange={(e) => setSelectedPriority(e.target.value)}
                  w="120px"
                >
                  <option value="Accepted">Accepted</option>
                  <option value="Declined">Declined</option>
                </Select>
              </Flex>
            </Flex>
          </Box>

          {/* Table */}
          <Table variant="simple" size="md" bg={"white"}>
            <Thead bg="#E8E8E8" p={"5px"}>
              <Tr>
                <Th>
                  <Checkbox />
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Proposals ID
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Subject
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Send To
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Total Value
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Date
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Open Till
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Project
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Created Date
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Status
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"} lineHeight={"20px"}>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedPipelines.map((item) => (
                <Tr key={item.id}>
                  <Td>
                    <Checkbox />
                  </Td>
                  <Td fontWeight={"500"} fontSize={"14px"} lineHeight={"19px"}>
                    {item.id}
                  </Td>
                  <Td fontWeight={"500"} fontSize={"14px"} lineHeight={"19px"}>
                    {item.subject}
                  </Td>
                  <Td fontWeight={"500"} fontSize={"14px"} lineHeight={"19px"}>
                    {item.sendTo}
                  </Td>
                  <Td fontSize={"14px"} lineHeight={"19px"} fontWeight={"300"}>
                    {item.value}
                  </Td>
                  <Td fontSize={"14px"} lineHeight={"19px"} fontWeight={"300"}>
                    {item.date}
                  </Td>
                  <Td fontSize={"14px"} lineHeight={"19px"} fontWeight={"300"}>
                    {item.openTill}
                  </Td>
                  <Td fontSize={"14px"} fontWeight={"500"} lineHeight={"19px"}>
                    {item.project}
                  </Td>
                  <Td fontSize={"14px"} lineHeight={"19px"}>
                    {item.createdDate}
                  </Td>
                  <Td fontSize={"14px"} lineHeight={"19px"}>
                    <Badge colorScheme={getStatusBadgeColor(item.status)}>
                      {item.status}
                    </Badge>
                  </Td>
                  <Td fontSize={"14px"} lineHeight={"19px"}>
                    <IconButton
                      icon={<ChevronDownIcon />}
                      aria-label="Options"
                      size="sm"
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <HStack
            justifyContent="space-between"
            mt={4}
            alignItems="center"
            flexWrap="wrap"
          >
            <HStack spacing={2} mb={{ base: 4, md: 0 }}>
              <Box color="#6F6F6F">Show</Box>
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                width="80px"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
              </Select>
              <Box color="#6F6F6F">entries</Box>
            </HStack>

            <HStack spacing={2}>
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(-1)}
                disabled={currentPage === 1}
                leftIcon={<ChevronLeftIcon />}
              >
                Prev
              </Button>
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index + 1}
                  onClick={() => setCurrentPage(index + 1)}
                  colorScheme={currentPage === index + 1 ? "green" : "gray"}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                colorScheme="white"
                color="#6F6F6F"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === totalPages}
                rightIcon={<ChevronRightIcon />}
              >
                Next
              </Button>
            </HStack>
          </HStack>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default ProposalsTable;
