import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  Image,
  Text,
  Button,
  Switch,
  Flex,
  useDisclosure,
  VStack,
  IconButton,
} from "@chakra-ui/react";
import { ExternalLinkIcon, RepeatIcon } from "@chakra-ui/icons";
import linkedinLogo from "../Dashboard/icons/linkedinlogo.png";
import twitterLogo from "../Dashboard/icons/twitterlogo.png";
import watsapLogo from "../Dashboard/icons/watsaplogo.png";
import facebookLogo from "../Dashboard/icons/facebooklogo.png";
import emailLogo from "../Dashboard/icons/emaillogo.png";
import instagram from "../Dashboard/icons/instagram.png";
import youtubelogo from "../Dashboard/icons/youtubelogo.png";
import AddSocialmediaCampaign from "./AddSocialMediaCampain";
import CompanyProfile from "../Header/CompanyProfile";
import { CallAPI } from "../../Services/service";
import endpoints from "../../Services/endpoint";
import {useNavigate} from "react-router-dom"
import { useAuthContext } from "../CommunityGroup/context/AuthContext"; // Import the context
import OrganizationModal from "../Utils/postonPlatform";

const socialPlatforms = [
  {
    name: "LinkedIn",
    description:
      "Take your LinkedIn networking to the next level by connecting with the right people at the right time!",
    icon: linkedinLogo,
  },
  {
    name: "WhatsApp",
    description:
      "Take your WhatsApp networking to the next level by connecting with the right people at the right time!",
    icon: watsapLogo,
  },
  {
    name: "Instagram",
    description:
      "Take your Instagram networking to the next level by connecting with the right people at the right time!",
    icon: instagram,
  },
  {
    name: "Facebook",
    description:
      "Take your Facebook networking to the next level by connecting with the right people at the right time!",
    icon: facebookLogo,
  },
  {
    name: "Gmail",
    description:
      "Take your Gmail networking to the next level by connecting with the right people at the right time!",
    icon: emailLogo,
  },
  {
    name: "Twitter",
    description:
      "Take your Twitter networking to the next level by connecting with the right people at the right time!",
    icon: twitterLogo,
  },
  {
    name: "Youtube",
    description:
      "Take your Twitter networking to the next level by connecting with the right people at the right time!",
    icon: youtubelogo,
  },
];

const Integration = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { platformTokens, updatePlatformTokens } = useAuthContext(); // Use context to get and update platformTokens
  const [socialmediaType, setSocialmediaType] = useState();
  const user = JSON.parse(localStorage.getItem("userData"));
  const  [opened,setOpened] = useState(false)
  const handleclose = ()=>setOpened(false)


 const navigate = useNavigate()
  const addIntegrationType = (val) => {
    setSocialmediaType(val.name);
    onOpen();
  };

  useEffect(() => {
    // Capture the code from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      // Call the API to exchange the code for an access token
      getAccessToken(code);
    }
    getSocialTokens();

  }, []);

  const getSocialTokens = async () => {
    try {
      let data = { id: user.id };
      const response = await CallAPI(endpoints.socialmediatokens, data);
      if (response.code === 200) {
        const tokens = response.data;
        const tokenState = {
          LinkedIn: tokens.linkedinToken,
          WhatsApp: tokens.whatsappToken,
          Instagram: tokens.instagramToken,
          Facebook:tokens.facebookToken,
          Gmail:tokens.gmailToken,
          Twitter: tokens.twitterToken,
          Youtube: tokens.youtubeToken,
        };

        // Update context with token state
        updatePlatformTokens(tokenState);
      } else {
        console.error("Failed to get social media tokens:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching social media tokens:", error.message);
    }
  };

  const getAccessToken = async (code) => {
    try {
      let data = { code, id: user.id };
      const response = await CallAPI(endpoints.getlinkedintoken, data);
      if (response.code === 200) {
        const { accessToken } = response.data;
        navigate("/social-media-Integration")
        getSocialTokens()
        
        console.log("Received new access token:", accessToken);
      } else {
        console.error("Failed to get LinkedIn access token:", response.data.message);
      }
    } catch (error) {
      console.error("Error getting LinkedIn access token:", error.message);
    }
  };

  const handleLinkedInConnect = async () => {
    try {
      const response = await CallAPI(endpoints.createlinkedinauth);
      if (response.code === 200) {
        const { authURL } = response.data;
        window.location.href = authURL; // Redirect to LinkedIn authorization URL
      } else {
        console.error("Failed to get LinkedIn authorization URL:", response.data.message);
      }
    } catch (error) {
      console.error("Error connecting to LinkedIn:", error.message);
    }
  };

  // Handle Generic Connect Clicks
  const handleConnect = (platform) => {
    if (platform.name === "LinkedIn") {
      handleLinkedInConnect();
    }
    // Add handlers for other platforms as needed
  };

  return (
    <Box p={4} overflowX="auto">
      {/* Add Social Media Campaign Drawer */}
      <OrganizationModal isOpened={opened} handleClose={handleclose}  />
      <AddSocialmediaCampaign
        socialmediaType={socialmediaType}
        isOpen={isOpen}
        onClose={onClose}
      />

      {/* Company Profile */}
      <Box width="100%" display="flex" justifyContent="space-between" flexWrap="wrap">
        <Flex align="center" justifyContent="space-between" p={4} borderRadius="md" width="100%" gap={6} direction={{ base: "column", md: "row" }}>
          <CompanyProfile />
        </Flex>
      </Box>

      {/* Social Platforms Grid */}
      <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={6} p={4}>
        {socialPlatforms.map((platform, index) => (
          <GridItem
            key={index}
            bg="white"
            position="relative"
            borderWidth="1px"
            borderRadius="25px"
            p={4}
            boxShadow="md"
            _hover={{ boxShadow: "xl" }}
          >
            {/* External link icon */}
            <IconButton
              icon={<ExternalLinkIcon />}
              aria-label="External link"
              position="absolute"
              top="4"
              right="4"
              size="lg"
              variant="ghost"
              colorScheme="gray"
              onClick={() => addIntegrationType(platform)}
            />

            <VStack align="start" spacing={3}>
              <Flex align="center" gap={3}>
                <Image boxSize="48px" w={'57px'} src={platform.icon} alt={platform.name} />
              </Flex>
              <Text fontWeight="500" fontFamily="Poppins" mt="2" lineHeight="1.2">
                {platform.name}
              </Text>
              <Text fontSize="sm" color="gray.600">
                {platform.description}
              </Text>
              <Flex justify="space-between" align="center" w="100%">
              <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={()=>setOpened(true)}
                >
                  Post
                </Button>
                <Button
                  colorScheme="blue"
                  size="sm"
                  onClick={() => handleConnect(platform)}
                  leftIcon={<RepeatIcon />}
                  isDisabled={!!platformTokens[platform.name]} // Disable if token exists
                >
                  Connect
                </Button>
                <Switch colorScheme="blue" />
              </Flex>
            </VStack>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default Integration;
