// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   Checkbox,
//   Flex,
//   HStack,
//   IconButton,
//   Input,
//   Select,
//   Stack,
//   Tag,
//   Text,
//   Avatar,
//   Heading,
//   Divider,
//   extendTheme,
//   ChakraProvider,
//   VStack,
// } from "@chakra-ui/react";
// import {
//   CalendarIcon,
//   ChevronLeftIcon,
//   ChevronRightIcon,
//   HamburgerIcon,
// } from "@chakra-ui/icons";
// import "@fontsource/noto-sans";
// import DatePicker from "react-datepicker";

// const TaskList = () => {
//   const [startDate, setStartDate] = useState(null);
//   const [searchQuery, setSearchQuery] = useState(""); // State for search query
  
//  const [selectedPriority, setSelectedPriority] = useState("");

  
//   const tasks = [
//     {
//       date: "Recent",
//       items: [
//         {
//           title: "Add a form to Update Task",
//           tags: ["Calls", "Pending"],
//           label: "Promotion",
//           labelColor: "purple",
//           date: "25 Oct 2023",
//           borderColor: "#676DFf",
//         },
//         {
//           title: "Make all strokes thinner",
//           tags: ["Email", "Pending"],
//           date: "25 Oct 2023",
//           labelColor: "red",
//           label: "Rejected",
//           borderColor: "#676DFf",
//         },
//         {
//           title: "Update original content",
//           tags: ["Calls", "Inprogress"],
//           date: "25 Oct 2023",
//           label: "Promotion",
//           labelColor: "purple",
//           borderColor: "#FDA700",
//         },
//         {
//           title: "Use only component colours",
//           tags: ["Task", "Inprogress"],
//           date: "25 Oct 2023",
//           labelColor: "yellow",
//           label: "Collab",
//           borderColor: "#FDA700",
//         },
//       ],
//     },
//     {
//       date: "Yesterday",
//       items: [
//         {
//           title: "Add images to the cards section",
//           tags: ["Calls", "Inprogress"],
//           label: "Collab",
//           labelColor: "green",
//           date: "24 Oct 2023",
//           borderColor: "#FDA700",
//         },
//       ],
//     },
//     {
//       date: "23 Oct 2023",
//       items: [
//         {
//           title: "Design description banner & landing page",
//           tags: ["Task", "Inprogress"],
//           date: "23 Oct 2023",
//           borderColor: "#FDA700",
//         },
//         {
//           title: "Make sure all the padding should be 24px",
//           tags: ["Calls", "Completed"],
//           label: "Promotion",
//           date: "23 Oct 2023",
//           labelColor: "purple",
//           borderColor: "#5CB85C",
//         },
//       ],
//     },
//     {
//       date: "22 Oct 2023",
//       items: [
//         {
//           title: "Use border radius as 5px or 10px",
//           tags: ["Meeting", "Completed"],
//           label: "Rejected",
//           date: "22 Oct 2023",
//           labelColor: "red",
//           borderColor: "#5CB85C",
//         },
//       ],
//     },
//   ];

//   const theme = extendTheme({
//     fonts: {
//       heading: `'Noto Sans', sans-serif`,
//       body: `'Noto Sans', sans-serif`,
//     },
//   });

   
     

//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage, setItemsPerPage] = useState(10);
//     const [sortOption, setSortOption] = useState(""); 

//   const totalPages = Math.ceil(tasks.length / itemsPerPage);

//   const handlePageChange = (direction) => {
//     setCurrentPage((prev) =>
//       Math.max(1, Math.min(totalPages, prev + direction))
//     );
//   };

  
//  const filteredPipelines = tasks[1].filter(
//    (pipeline) => pipeline.name.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
//  );

//  const sortPipelines = (data, option) => {
//    if (option === "name") {
//      return [...data].sort((a, b) => a.name.localeCompare(b.name)); // Alphabetical order
//    } else if (option === "value") {
//      return [...data].sort((a, b) => parseFloat(b.value) - parseFloat(a.value)); // Highest to lowest deal value
//    }
//    return data;
//  };

//  const sortedPipelines = selectedPriority
//    ? tasks.filter(
//        (project) =>
//          project.status.toLowerCase() === selectedPriority.toLowerCase()
//      )
//    : sortPipelines(filteredPipelines, sortOption);

  


//   const handleItemsPerPageChange = (event) => {
//     setItemsPerPage(Number(event.target.value));
//     setCurrentPage(1);
//   };

//   return (
//     <ChakraProvider theme={theme}>
//       <Box p={4} w="100%" overflowX="auto">
//         {/* Header */}
//         <Box bg="white" p={4} borderRadius="md" boxShadow="sm">
//           <VStack spacing={4} align="stretch">
//             <Flex justify="space-between" flexWrap="wrap" gap={4}>
//               <Input
//                 placeholder="Search Contacts"
//                 w={{ base: "100%", md: "40%" }}
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//                 h="40px"
//               />
//               <Button
//                 bg="#E41F07"
//                 color="white"
//                 w={{ base: "100%", md: "150px" }}
//                 h="40px"
//               >
//                 Add New Task
//               </Button>
//             </Flex>
//             <Flex justify="space-between" flexWrap="wrap" gap={4}>
//               <Text fontWeight="bold">All Tasks</Text>
//               <Flex flexWrap="wrap" alignItems="center" gap={4}>
//                 <Checkbox defaultChecked>Mark all as read</Checkbox>
//                 <Select placeholder="Sort" w="100px" h="40px">
//                   <option value="newest">Newest</option>
//                   <option value="oldest">Oldest</option>
//                 </Select>
//                 <Box w="150px">
//                   <DatePicker
//                     selected={startDate}
//                     onChange={(date) => setStartDate(date)}
//                     placeholderText="Start Date"
//                     customInput={<Input />}
//                   />
//                 </Box>
//                 <Button variant="outline" w="100px" h="40px">
//                   Filter
//                 </Button>
//               </Flex>
//             </Flex>
//           </VStack>
//         </Box>

//         {/* Task Sections */}
//         {sortedPipelines.map((section, index) => (
//           <Box key={index} mt={6}>
//             <Heading as="h3" size="sm" mb={4}>
//               {section.date}
//             </Heading>
//             <Stack spacing={4}>
//               {section.items.map((task, taskIndex) => (
//                 <Flex
//                   key={taskIndex}
//                   p={4}
//                   bg="white"
//                   borderRadius="md"
//                   borderLeftWidth={4}
//                   borderLeftColor={task.borderColor}
//                   boxShadow="sm"
//                   flexDirection={{ base: "column", md: "row" }}
//                   align="center"
//                   justify="space-between"
//                 >
//                   <Flex align="center" w="100%">
//                     <Checkbox mr={4} />
//                     <Text flex="1" fontWeight="medium">
//                       {task.title}
//                     </Text>
//                     <HStack spacing={2}>
//                       {task.tags.map((tag, i) => (
//                         <Tag
//                           key={i}
//                           colorScheme={
//                             tag === "Pending"
//                               ? "purple"
//                               : tag === "Inprogress"
//                               ? "orange"
//                               : tag === "Completed"
//                               ? "green"
//                               : "blue"
//                           }
//                         >
//                           {tag}
//                         </Tag>
//                       ))}
//                     </HStack>
//                   </Flex>
//                   <HStack spacing={4} mt={{ base: 4, md: 0 }}>
//                     {task.label && (
//                       <Tag colorScheme={task.labelColor}>{task.label}</Tag>
//                     )}
//                     <Text>{task.date}</Text>
//                     <Avatar size="sm" />
//                     <IconButton
//                       icon={<HamburgerIcon />}
//                       size="sm"
//                       aria-label="Options"
//                     />
//                   </HStack>
//                 </Flex>
//               ))}
//             </Stack>
//           </Box>
//         ))}

//         {/* Pagination */}
//         <Flex justify="space-between" mt={8} align="center" flexWrap="wrap">
//           <HStack spacing={2}>
//             <Text>Show</Text>
//             <Select
//               value={itemsPerPage}
//               onChange={handleItemsPerPageChange}
//               w="80px"
//             >
//               <option value={5}>5</option>
//               <option value={10}>10</option>
//               <option value={20}>20</option>
//             </Select>
//             <Text>entries</Text>
//           </HStack>
//           <HStack spacing={2}>
//             <Button
//               leftIcon={<ChevronLeftIcon />}
//               onClick={() => handlePageChange(-1)}
//               disabled={currentPage === 1}
//             >
//               Prev
//             </Button>
//             {Array.from({ length: totalPages }).map((_, idx) => (
//               <Button
//                 key={idx}
//                 onClick={() => setCurrentPage(idx + 1)}
//                 colorScheme={currentPage === idx + 1 ? "blue" : "gray"}
//               >
//                 {idx + 1}
//               </Button>
//             ))}
//             <Button
//               rightIcon={<ChevronRightIcon />}
//               onClick={() => handlePageChange(1)}
//               disabled={currentPage === totalPages}
//             >
//               Next
//             </Button>
//           </HStack>
//         </Flex>
//       </Box>
//     </ChakraProvider>
//   );
// };

// export default TaskList;

import React from "react";
import { Box, Text, Tag, Flex, Grid, VStack } from "@chakra-ui/react";

const tasks = {
  "To Done": [
    {
      team: "Hr Team",
      title: "Set Campaign Budget",
      description: "Define The Daily And Total Budget For The Campaign.",
      tags: "Priority",
      dueDate: "11/20/2022",
      people: 2,
      files: 1,
    },
  ],
  "In Progress": [
    {
      team: "Hr Team",
      title: "Design Campaign Graphics",
      description:
        "Create Visual Assets For The Campaign, Including Images And...",
      tags: "Normal",
      dueDate: "11/20/2022",
      people: 8,
      files: 5,
    },
  ],
  "In Review": [
    {
      team: "Hr Team",
      title: "Set Campaign Budget",
      description: "Define The Daily And Total Budget For The Campaign.",
      tags: "Priority",
      dueDate: "11/20/2022",
      people: 2,
      files: 1,
    },
    {
      team: "Hr Team",
      title: "Schedule Social Media Posts",
      description: "Set Up Posts On Facebook, Instagram, And LinkedIn.",
      tags: "Normal",
      dueDate: "11/20/2022",
      people: 2,
      files: 1,
    },
  ],
  Completed: [
    {
      team: "Marketing Team",
      title: "Campaign Strategy Planning",
      description: "Outline Objectives, Key Messages, And Campaign Structure.",
      tags: "Priority",
      dueDate: "11/20/2022",
      people: 6,
      files: 0,
    },
  ],
};

const Task = () => {
  return (
    <Grid
      templateColumns="repeat(4, 1fr)"
      gap={4}
      p={4}
      bg="gray.50"
      minH="100vh"
    >
      {Object.keys(tasks).map((column) => (
        <Box
          key={column}
          bg="white"
          borderRadius="md"
          shadow="md"
          p={4}
          border="1px solid"
          borderColor="gray.200"
        >
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            {column}
          </Text>
          <VStack spacing={4}>
            {tasks[column].map((task, index) => (
              <Box
                key={index}
                border="1px solid"
                borderColor="gray.300"
                borderRadius="md"
                p={4}
                width="100%"
              >
                <Text fontSize="sm" color="gray.500" mb={2}>
                  By: {task.team}
                </Text>
                <Text fontSize="md" fontWeight="semibold">
                  Title: {task.title}
                </Text>
                <Text fontSize="sm" color="gray.600" mt={1}>
                  Description: {task.description}
                </Text>
                <Flex mt={4} justify="space-between" align="center">
                  <Tag
                    size="sm"
                    colorScheme={task.tags === "Priority" ? "red" : "blue"}
                  >
                    {task.tags}
                  </Tag>
                  <Text fontSize="xs" color="gray.500">
                    Due Date: {task.dueDate}
                  </Text>
                </Flex>
                <Flex mt={2} justify="space-between" align="center">
                  <Text fontSize="xs" color="gray.500">
                    👥 {task.people}
                  </Text>
                  <Text fontSize="xs" color="gray.500">
                    📄 {task.files}
                  </Text>
                </Flex>
              </Box>
            ))}
          </VStack>
        </Box>
      ))}
    </Grid>
  );
};

export default Task;

