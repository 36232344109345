import React from "react";
import {
  Box,
  Flex,
  Text,
  Avatar,
  HStack,
  Tag,
  Button,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Select,
  extendTheme,
  ChakraProvider,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const activities = [
  {
    title: "We scheduled a meeting for next week",
    dueDate: "10 Feb 2024, 09:00 am",
    name: "Darlene Robertson",
    avatar: "",
    type: "Meeting",
    status: "Inprogress",
    tagColor: "purple",
  },
  {
    title: "Regarding latest updates in project",
    dueDate: "29 Sep 2023, 08:20 am",
    name: "Dawn Mercha",
    avatar: "",
    type: "Email",
    status: "Inprogress",
    tagColor: "orange",
  },
  {
    title: "Call John and discuss about project",
    dueDate: "05 Oct 2023, 10:35 am",
    name: "Carol Thomas",
    avatar: "",
    type: "Task",
    status: "Inprogress",
    tagColor: "blue",
  },
  {
    title: "Discussed budget proposal with Edwin",
    dueDate: "16 Oct 2023, 04:40 pm",
    name: "Sharon Roy",
    avatar: "",
    type: "Calls",
    status: "Inprogress",
    tagColor: "green",
  },
];

const theme = extendTheme({
  fonts: {
    heading: `'Noto Sans', sans-serif`,
    body: `'Noto Sans', sans-serif`,
  },
});

const Activities = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box p={2} bg={"white"} >
        <Flex justify="space-between" align="center" mb={4}>
          <Text fontSize={"18px"} fontWeight={"700"} color="#262A2A">
            Activities
          </Text>
          <HStack spacing={4}>
            <Menu>
              <MenuButton
                as={Button}
                fontSize="12px"
                fontWeight="400"
                variant="outline"
                rightIcon={<ChevronDownIcon />}
              >
                Last 30 Days
              </MenuButton>
              <MenuList>
                <MenuItem>Last 7 Days</MenuItem>
                <MenuItem>Last 30 Days</MenuItem>
                <MenuItem>All Time</MenuItem>
              </MenuList>
            </Menu>
            <Button colorScheme="red" size="sm">
              Add New Activity
            </Button>
          </HStack>
        </Flex>

        <Stack spacing={4}>
          {activities.map((activity, index) => (
            <Flex key={index} p={2} bg="white" shadow="sm">
              <Box bg={"#F6F6F6"} p={"10px"} w={"full"} h={"auto"}>
                <Flex justify="space-between" align="center">
                  <Box>
                    <Text fontWeight="bold">{activity.title}</Text>
                    <Text fontSize="sm" color="gray.500" w={"291px"} h={"21px"}>
                      Due Date: {activity.dueDate}
                    </Text>
                  </Box>

                  <Flex alignItems={"center"}>
                    <Flex gap={"10px"} mt={"20px"} alignItems={"center"}>
                      <Avatar name={activity.name} size="sm" />
                      <Text fontWeight="700" mt={"10px"} fontSize={"14px"}>
                        {activity.name}
                      </Text>
                      <Box>
                        {/* <Tag colorScheme="blue" variant="outline">
                    {activity.status}
                  </Tag> */}
                        <Select bg={"white"}>
                          <option>Inprogress</option>
                          <option>Completed</option>
                        </Select>
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex justify="flex-start" mt={0}>
                  <Tag colorScheme={activity.tagColor} variant="solid" mr={2}>
                    {activity.type}
                  </Tag>
                </Flex>
              </Box>
            </Flex>
          ))}
        </Stack>
      </Box>
    </ChakraProvider>
  );
};

export default Activities;
