import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  HStack,
  VStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Text,
  IconButton,
  Flex,
  useBreakpointValue,
  extendTheme,
  ChakraProvider,
} from "@chakra-ui/react";
import {
  AiOutlineDownload,
  AiOutlineFilter,
  AiOutlineColumnHeight,
} from "react-icons/ai";
import { Doughnut } from "react-chartjs-2";
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker";
import Chart from "react-apexcharts";
import "@fontsource/noto-sans";

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const CompanyReport = () => {
  const [startDate, setStartDate] = useState(new Date());
      const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

  // const [startDate, setStartDate] = useState(null);



  const [endDate, setEndDate] = useState(new Date());
  

const series = [{ name: 'Contacts', data: [20, 18, 25, 40, 35, 30, 28, 20, 50, 30, 22, 25] }]; 
const options = { chart: { type: 'line', height: 350, toolbar: { show: false } }, stroke: { curve: 'straight', colors: ['#FFA500'], // Orange color for the 
   } ,
   xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] },
   yaxis: { labels: { formatter: (value) => `${value}K` } }, title: { text: 'Contacts by Year', align: 'left' }, tooltip: { y: { formatter: (value) => `${value}K` } }
  }

  const chartSeries = [ { name: 'Contacts', data: [20, 18, 25, 40, 35, 30, 25, 20, 55, 30, 20, 25]}]

  const barData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Leads",
        data: [100, 120, 300, 200, 450, 320, 230, 340, 180, 250, 300, 500],
        backgroundColor: "#00B5D8",
      },
    ],
  };

  const doughnutData = {
    labels: ["Campaign", "Google", "Referrals", "Paid Social"],
    datasets: [
      {
        data: [44, 58, 41, 17],
        backgroundColor: ["#4299E1", "#48BB78", "#ED8936", "#D53F8C"],
      },
    ],
  };

  const leadsData = [
    {
      leadName: "Collins",
      companyName: "NovaWave LLC",
      phone: "+1 8753544533",
      email: "robertson@example.com",
      leadStatus: "Closed",
      leadOwner: "Hendry",
      source: "Paid Social",
      createdDate: "25 Sep 2023, 01:22 pm",
    },
    // Add more data rows here...
  ];
     const theme = extendTheme({
       fonts: {
         heading: `'Noto Sans', sans-serif`,
         body: `'Noto Sans', sans-serif`,
       },
     });



const filteredData = leadsData.filter((lead) => true);


    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const paginatedData = filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    const handlePageChange = (direction) => {
      setCurrentPage((prev) =>
        Math.max(1, Math.min(totalPages, prev + direction))
      );
    };

    const handleItemsPerPageChange = (event) => {
      setItemsPerPage(Number(event.target.value));
      setCurrentPage(1);
    };

  return (
    <ChakraProvider theme={theme}>
      <Box
        p={4}
        mt={4}
        borderWidth="1px"
        borderRadius="md"
        bg="white"
        overflowX="auto"
      >
        {/* Search and Download Section */}
        <HStack
          justifyContent="space-between"
          mb="4"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Input
            placeholder="Search Leads"
            w={{ base: "100%", md: "250px" }}
            mb={{ base: 4, md: 0 }}
          />
          <Button colorScheme="red" leftIcon={<AiOutlineDownload />}>
            Download Report
          </Button>
        </HStack>

        {/* Chart Section */}
        <HStack
          spacing="6"
          mb="6"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box
            width={{ base: "100%", md: "48%" }}
            p="4"
            borderWidth="1px"
            borderRadius="md"
            mb={{ base: 4, md: 0 }}
          >
            {/* <Flex justify="space-between" align="center"> */}
            <Flex
              justify="space-between"
              flexDirection={{ base: "column", md: "row" }}
              gap={"6px"}
              align="center"
              mb="2"
            >
              <Text
                as="h2"
                fontWeight="500"
                fontSize={{ base: "16px", md: "18px" }}
              >
                Contacts By Year
              </Text>
              <Box border="1px solid #E8E8E8" padding="5px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                />
              </Box>
            </Flex>
            {/* <Chart options={chartOptions} series={chartSeries} type="line" height={350}/> */}
            <Chart options={options} series={series} type="line" height={350} />
          </Box>

          <Box
            width={{ base: "100%", md: "48%" }}
            p="4"
            borderWidth="1px"
            borderRadius="md"
          >
            <Flex justify="space-between" align="center">
              <Text
                as="h2"
                fontWeight="500"
                fontSize={{ base: "16px", md: "18px" }}
              >
                Contacts By Source
              </Text>
              <Box border="1px solid #E8E8E8" padding="5px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                />
              </Box>
            </Flex>
            <Doughnut data={doughnutData} height={350} />
          </Box>
        </HStack>
        <Box overflowX="auto" bg={"white"}>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            mb={4}
          >
            <Flex
              gap="7px"
              maxW={{ base: "100%", md: "30%" }}
              direction={{ base: "column", md: "row" }}
            >
              <Select
                placeholder="Sort"
                w={{ base: "100%", md: "30%" }}
                mt={{ base: 2, md: 0 }}
                fontWeight={"bold"}
                fontSize={"14px"}
                lineHeight={"20px"}
                p="7px"
              >
                <option value="date">Date</option>
                <option value="amount">Amount</option>
              </Select>
              <Flex mt="5px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                  customInput={<Input />}
                />
              </Flex>
            </Flex>

            <Flex mt={{ base: 2, md: 0 }} gap={"5px"} mr={"3px"}>
              <Button
                variant="outline"
                mr={2}
                bg={"#F3EDFF"}
                color={"#4A00E5"}
                fontSize={"13px"}
                fontWeight={"600"}
              >
                Manage Columns
              </Button>
              <Button variant="outline" fontWeight={"500"} fontSize={"14px"}>
                Filter
              </Button>
            </Flex>
          </Flex>
        </Box>
        {/* Table Section */}
        <HStack
          justifyContent="space-between"
          mb="4"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Select
            placeholder="Sort By"
            w={{ base: "100%", md: "200px" }}
            mb={{ base: 4, md: 0 }}
          >
            <option value="name">Lead Name</option>
            <option value="company">Company</option>
            <option value="status">Lead Status</option>
          </Select>
          <HStack>
            <IconButton
              icon={<AiOutlineColumnHeight />}
              aria-label="Manage Columns"
            />
            <IconButton icon={<AiOutlineFilter />} aria-label="Filter" />
          </HStack>
        </HStack>

        {/* Leads Table */}
        <Box overflowX="auto">
          <Table variant="simple">
            <Thead bg={"#E8E8E8"}>
              <Tr>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Lead Name
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Company Name
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Phone
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Email
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Lead Status
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Lead Owner
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Source
                </Th>
                <Th fontWeight={"500"} fontSize={"14px"}>
                  Created Date
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {leadsData.map((lead, index) => (
                <Tr key={index}>
                  <Td>{lead.leadName}</Td>
                  <Td>{lead.companyName}</Td>
                  <Td>{lead.phone}</Td>
                  <Td>{lead.email}</Td>
                  <Td>
                    <Tag colorScheme={getTagColor(lead.leadStatus)}>
                      {lead.leadStatus}
                    </Tag>
                  </Td>
                  <Td>{lead.leadOwner}</Td>
                  <Td>{lead.source}</Td>
                  <Td>{lead.createdDate}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <HStack justifyContent="space-between" mt={4} alignItems="center">
          <HStack spacing={2}>
            <Box color="#6F6F6F">Show</Box>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              w="80px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
            <Box color="#6F6F6F">entries</Box>
          </HStack>

          <HStack spacing={2}>
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(-1)}
              leftIcon={<ChevronLeftIcon />}
              disabled={currentPage === 1}
            >
              Prev
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                colorScheme={currentPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(1)}
              rightIcon={<ChevronRightIcon />}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </HStack>
        </HStack>
      </Box>
    </ChakraProvider>
  );
};

// Helper function for Tag color based on status
function getTagColor(status) {
  switch (status) {
    case "Closed":
      return "green";
    case "Not Contacted":
      return "blue";
    case "Lost":
      return "red";
    case "Contacted":
      return "yellow";
    default:
      return "gray";
  }
}

export default CompanyReport;
