import {
  Box,
  Flex,
  Text,
  Button,
  Grid,
  GridItem,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  VStack,
  Divider,
  IconButton,
  Image,
  Stack,
  HStack,
  Circle,
  Link,
  Icon,
  Avatar,
  Input,
  InputRightElement,
  InputGroup
} from '@chakra-ui/react';
import { FiHome, FiUser, FiPieChart, FiSettings } from 'react-icons/fi';
import { BiBarChartAlt2 } from 'react-icons/bi';
import { AiOutlineTeam } from 'react-icons/ai';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import {
  FaEye,
  FaLayerGroup,
  FaLeaf,
  FaLock,
  FaCloud,
  FaTabletAlt,
} from "react-icons/fa";
import { StarIcon } from '@chakra-ui/icons';
import Header from '../Header/Header';
const features = [
  {
    icon: FaEye,
    title: "High Resolution",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec tellus tincidunt, hendrerit eros vel, vulputate ipsum.",
  },
  {
    icon: FaLayerGroup,
    title: "Retina Ready Screen",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec tellus tincidunt, hendrerit eros vel, vulputate ipsum.",
  },
  {
    icon: FaLeaf,
    title: "Easy Editable Data",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec tellus tincidunt, hendrerit eros vel, vulputate ipsum.",
  },
  {
    icon: FaLock,
    title: "Fully Secured",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec tellus tincidunt, hendrerit eros vel, vulputate ipsum.",
  },
  {
    icon: FaCloud,
    title: "Cloud Storage",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec tellus tincidunt, hendrerit eros vel, vulputate ipsum.",
  },
  {
    icon: FaTabletAlt,
    title: "Responsive Ready",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec tellus tincidunt, hendrerit eros vel, vulputate ipsum.",
  },
];
const reviews = [
  {
    name: "Deanna Hodges",
    role: "Spotify Developer",
    avatar: "", // Replace with the correct avatar path
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },
  {
    name: "Deanna Hodges",
    role: "Spotify Developer",
    avatar: "", // Replace with the correct avatar path
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },
  {
    name: "Deanna Hodges",
    role: "Spotify Developer",
    avatar: "", // Replace with the correct avatar path
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
  },
];



const Home = () => {
  return (
    <Box bg={"white"}>
      <Header/>
      <Flex
        direction={{ base: "column", lg: "row" }}
        bgImage={
          "vecteezy_abstract-red-speed-neon-light-effect-on-black-background_8440572 1.jpg"
        }
        color="white"
        align="center"
        justify="center"
        p={16}
      >
        {/* Left Section */}
        <Box flex="1" textAlign={{ base: "center", lg: "left" }}>
          <Text
            fontSize="sm"
            color={"white"}
            textAlign={"center"}
            mb={2}
            rounded={"xl"}
            bg={"black"}
            w={"20%"}
          >
            #Get Your's
          </Text>
          <Heading size="xl" fontWeight={"300"} mb={4}>
            Empower Your Business, <br /> Connect With Customers!
          </Heading>
          <Text mb={6} fontSize={"12px"} color={"white"}>
            Our CRM platform helps you streamline your marketing efforts, engage
            with your audience, and drive growth. Manage campaigns, track
            customer interactions, and optimize your business strategies all in
            one place.
          </Text>
          <Button colorScheme="red" bg="white" color="red.600" size="lg">
            Get Started
          </Button>
        </Box>

        {/* Right Section */}
        <Box flex="1" mt={{ base: 8, lg: 0 }}>
          <Flex gap={2}>
            <Image src="sidebar.jpg" h={"250"} w={"100"} />
            <Image
              src="1.jpg"
              alt="CRM Dashboard"
              borderRadius="md"
              boxShadow="xl"
            />
          </Flex>
        </Box>
      </Flex>
      {/* icons */}
      <Box maxW={"80%"} mx={"auto"} mt={10}>
        <Flex>
          <Image src="3.jpg" w={"250px"} h={"146px"} />
          <Image src="4.jpg" w={"250px"} h={"146px"} />
          <Image src="5.jpg" w={"250px"} h={"146px"} />
          <Image src="6.jpg" w={"250px"} h={"146px"} />
        </Flex>
      </Box>
      <Flex
        direction={{ base: "column", lg: "row" }}
        align="flex-start"
        justify="space-between"
        p={8}
        bg="white"
        color="gray.800"
        mt={10}
        gap={8}
        maxW={"90%"}
        mx={"auto"}
      >
        {/* Left Section */}
        <Box flex="1" textAlign={{ base: "center", lg: "left" }}>
          <Text fontSize="sm" fontWeight="bold" color="red.500" mb={2}>
            KEY FEATURES
          </Text>
          <Text fontSize={"34px"} mb={4}>
            Most Probably Included Best Features Ever
          </Text>
          <Text mb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec
            tellus tincidunt, hendrerit eros vel, vulputate ipsum. Ut vel urna
            vitae mi sodales volutpat non vel justo. Pellentesque pulvinar nulla
            a auctor malesuada. Aliquam tincidunt molestie ipsum, at vulputate
            nibh hendrerit at.
          </Text>
          <Text mb={6}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec
            tellus tincidunt, hendrerit eros vel, vulputate ipsum.
          </Text>
          <Button colorScheme="red" size="lg">
            Get Started
          </Button>
        </Box>

        {/* Right Section */}
        <Box flex="1" mt={{ base: 8, lg: 0 }}>
          <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6}>
            {/* Feature 1 */}
            <GridItem>
              <Flex align="flex-start" flexDirection={"column"}>
                <Image
                  src="11.jpg"
                  alt="Digital Marketing"
                  boxSize="50px"
                  mr={4}
                  mt={1}
                />
                <Box>
                  <Heading as="h3" size="md" mb={2}>
                    Digital Marketing
                  </Heading>
                  <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    nec tellus tincidunt, hendrerit eros vel, vulputate ipsum.
                  </Text>
                  <Text color="red.500" mt={2} fontWeight="bold">
                    Learn More &raquo;
                  </Text>
                </Box>
              </Flex>
            </GridItem>

            {/* Feature 2 */}
            <GridItem>
              <Flex align="flex-start" flexDirection={"column"}>
                <Image
                  src="12.jpg"
                  alt="Social Media Marketing"
                  boxSize="50px"
                  mr={4}
                  mt={1}
                />
                <Box>
                  <Heading as="h3" size="md" mb={2}>
                    Social Media Marketing
                  </Heading>
                  <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    nec tellus tincidunt, hendrerit eros vel, vulputate ipsum.
                  </Text>
                  <Text color="red.500" mt={2} fontWeight="bold">
                    Learn More &raquo;
                  </Text>
                </Box>
              </Flex>
            </GridItem>

            {/* Feature 3 */}
            <GridItem>
              <Flex align="flex-start" flexDirection={"column"}>
                <Image
                  src="13.jpg"
                  alt="Collaborative Campaigns"
                  boxSize="50px"
                  mr={4}
                  mt={1}
                />
                <Box>
                  <Heading as="h3" size="md" mb={2}>
                    Collaborative Campaigns
                  </Heading>
                  <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    nec tellus tincidunt, hendrerit eros vel, vulputate ipsum.
                  </Text>
                  <Text color="red.500" mt={2} fontWeight="bold">
                    Learn More &raquo;
                  </Text>
                </Box>
              </Flex>
            </GridItem>

            {/* Feature 4 */}
            <GridItem>
              <Flex align="flex-start" flexDirection={"column"}>
                <Image
                  src="14.jpg"
                  alt="Management Consulting"
                  boxSize="50px"
                  mr={4}
                  mt={1}
                />
                <Box>
                  <Heading as="h3" size="md" mb={2}>
                    Management Consulting
                  </Heading>
                  <Text>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    nec tellus tincidunt, hendrerit eros vel, vulputate ipsum.
                  </Text>
                  <Text color="red.500" mt={2} fontWeight="bold">
                    Learn More &raquo;
                  </Text>
                </Box>
              </Flex>
            </GridItem>
          </Grid>
        </Box>
      </Flex>
      <Box
        maxW="100%"
        mx={"auto"}
        h="100vh"
        display="flex"
        alignItems="center"
        mt={10}
        justifyContent="center"
        px={{ base: 4, md: 10 }}
        bgImage={"bg2.jpeg"}
      >
        <HStack
          spacing={{ base: 6, md: 16 }}
          alignItems="center"
          justifyContent="space-between"
          maxW="1200px"
          w="100%"
        >
          {/* Left Content */}

          {/* Right Image Content */}
          <Box position="relative">
            {/* Main Image */}
            <Image
              src="services2.jpg"
              alt="Dashboard"
              borderRadius="lg"
              boxShadow="lg"
              maxW="600px"
              zIndex="1"
            />
            {/* Overlay Image */}
            <Image
              src="services3.jpg"
              alt="Chart"
              borderRadius="lg"
              boxShadow="lg"
              maxW="300px"
              position="absolute"
              bottom="-40px"
              right="-30px"
            />
          </Box>

          <Box width="30%" padding="4" flex={2}>
            <Text fontSize="2xl" fontWeight="bold" color="white">
              Set Up The Challenges and Track Your Progress
            </Text>
            <Text mt="4" color="white">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Text>
            <Button mt="4" bg={"white"} color={"red"} rounded={"2xl"}>
              Get Started
            </Button>
          </Box>
        </HStack>
      </Box>
      features
      <Box textAlign="center" py={7} px={5}>
        <Text
          size="lg"
          fontWeight={"500"}
          color="orange.400"
          textTransform="uppercase"
          mb={4}
        >
          Key Features
        </Text>
        <Text fontSize={"25px"} mb={10} fontWeight={500}>
          Most Probably Included Best Features Ever
        </Text>
        <Flex wrap="wrap" justify="center" gap={8}>
          {features.map((feature, index) => (
            <Box key={index} textAlign="center" maxW="250px" p={5}>
              <Box
                bgGradient="linear(to-b, orange.500, red.400)"
                borderRadius="full"
                boxSize="60px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mx="auto"
                mb={4}
              >
                <Icon as={feature.icon} color="white" boxSize={6} />
              </Box>
              <Heading as="h4" size="md" mb={2}>
                {feature.title}
              </Heading>
              <Text fontSize="sm" color="gray.600">
                {feature.description}
              </Text>
            </Box>
          ))}
        </Flex>
      </Box>
      {/* screenshots */}
      {/* <Box>
      <Flex bgImage={"bg2.jpeg"} maxW="70%" mx={"auto"} bgSize="cover" mt={10}>
        <Image src="16.jpeg" />
      </Flex>
      </Box> */}
      <Box
        backgroundImage="url('/bg2.jpeg')" // Path to the uploaded background image
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        maxW={"100%"}
        mx={"auto"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={8}
      >
        <Box textAlign="center">
          <Text fontSize="2xl" color="white" mb={6}>
            Beautifully Crafted All App Screenshots
          </Text>
          <Flex
            justify="center"
            align="center"
            gap={4}
            flexWrap="wrap"
            maxW="90%"
            mx="auto"
          >
            <Image
              src="16.jpeg" // Replace with the actual screenshot path
              alt="Screenshot 1"
              borderRadius="lg"
              boxShadow="lg"
            />
          </Flex>
        </Box>
      </Box>
      {/* software integrations */}
      <Flex
        direction={{ base: "column", lg: "row" }}
        align="center"
        justify="space-between"
        p={8}
        bg="white"
        maxW={"90%"}
        mx={"auto"}
        gap={16}
        mt={10}
      >
        {/* Left Section */}
        <Box flex="1" textAlign={{ base: "center", lg: "left" }}>
          <Text fontSize="sm" fontWeight="bold" color="red.500" mb={2}>
            SOFTWARE INTEGRATIONS
          </Text>
          <Heading size="lg" mb={4}>
            Easy & Perfect Solution With Latest Software Integrations
          </Heading>
          <Text mb={4}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec
            tellus tincidunt, hendrerit eros vel, vulputate ipsum. Ut vel urna
            vitae mi sodales volutpat non vel justo. Pellentesque pulvinar nulla
            a auctor malesuada. Aliquam tincidunt molestie ipsum, at vulputate
            nibh hendrerit at.
          </Text>
          <Text mb={6}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut nec
            tellus tincidunt, hendrerit eros vel, vulputate ipsum.
          </Text>
          <Button colorScheme="red" size="lg">
            Get Started
          </Button>
        </Box>

        {/* Right Section */}

        <Image
          flex={1}
          src="15.jpeg"
          bg={"white"}
          alt="Marcom Logo"
          boxSize="60px"
          w={"200px"}
          h={"310px"}
        />
      </Flex>
      {/* reviews */}
      <Box textAlign="center" py={10} px={5}>
        <Text fontSize="xs" color="red.500" fontWeight="bold" mb={1}>
          CLIENT REVIEWS
        </Text>
        <Text fontSize="3xl" mb={4}>
          Some of Our Happy Customer Reviews About Our Products
        </Text>
        <Flex
          justify="center"
          align="center"
          gap={8}
          flexWrap="wrap"
          maxW="90%"
          mx="auto"
        >
          {reviews.map((review, index) => (
            <Box
              key={index}
              bg="white"
              boxShadow="xl"
              borderRadius="lg"
              p={10}
              w={{ base: "90%", md: "30%" }}
              textAlign="center"
            >
              <Flex justifyContent="center" mb={4}>
                {[...Array(5)].map((_, i) => (
                  <StarIcon
                    key={i}
                    color={i < review.rating ? "yellow.400" : "gray.300"}
                    boxSize={5}
                  />
                ))}
              </Flex>
              <Text fontSize="sm" color="gray.600" mb={4}>
                "{review.review}"
              </Text>

              <VStack spacing={2}>
                <Flex
                  alignItems="center"
                  flexDirection={"column"}
                  h={"50px"}
                  lineHeight={"2px"}
                  w={"155px"}
                >
                  <Flex mt={"15px"} flexDirection={"column"}>
                    <Text fontWeight="600" fontSize="14px">
                      {review.name}
                    </Text>
                    <Text fontSize="12px" w={"180px"}>
                      {review.role}
                    </Text>
                  </Flex>
                </Flex>
              </VStack>
            </Box>
          ))}
        </Flex>
      </Box>
      {/* <Box textAlign="center" py={10} px={5} backgroundColor="white">
        <Text fontSize="xs" color="pink.500" fontWeight="bold" mb={1}>
          CLIENT REVIEWS
        </Text>
        <Text fontSize="2xl" fontWeight="bold" color="gray.800" mb={8}>
          Some of Our Happy Customer Reviews About Our Products
        </Text>
        <Flex
          justify="center"
          align="center"
          gap={8}
          flexWrap="wrap"
          maxW="90%"
          mx="auto"
        >
          {reviews.map((review, index) => (
            <Box
              key={index}
              bg="white"
              boxShadow="lg"
              borderRadius="lg"
              p={6}
              w={{ base: "90%", md: "30%" }}
              textAlign="center"
              border="1px solid #E2E8F0"
            >
              <VStack spacing={4}>
                <HStack spacing={1}>
                  {[...Array(5)].map((_, i) => (
                    <StarIcon
                      key={i}
                      color={i < review.rating ? "yellow.400" : "gray.300"}
                      boxSize={4}
                    />
                  ))}
                </HStack>
                <Text fontSize="md" color="gray.600">
                  "{review.review}"
                </Text>
                <Divider borderColor="gray.200" />
                <Avatar src={review.avatar} name={review.name} size="lg" />
                <Box>
                  <Text fontWeight="bold" fontSize="lg" color="gray.800">
                    {review.name}
                  </Text>
                  <Text fontSize="sm" color="gray.500">
                    {review.role}
                  </Text>
                </Box>
              </VStack>
            </Box>
          ))}
        </Flex>
      </Box> */}


      {/* email */}
      <Box
        bgImage="bg4.jpeg" // Replace with the correct path
        bgSize="cover"
        bgPosition="center"
        py={10}
        mx={"auto"}
        maxW={"90%"}
        px={5}
        textAlign="center"
        position="relative"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(0, 0, 0, 0.5)"
          zIndex="0"
        />
        <Text
          fontSize="2xl"
          fontWeight="bold"
          color="white"
          mb={4}
          zIndex="1"
          p={5}
          position="relative"
        >
          Start Your's Today!
        </Text>
        <Box width="100%" maxW="600px" mx="auto" position="relative" zIndex="1">
          <InputGroup size="lg" borderRadius="full" bg="white" boxShadow="sm">
            <Input placeholder="Enter Your Email Address" borderRadius="full" />
            <InputRightElement width="auto" p={2}>
              <Button
                size="lg"
                colorScheme="red"
                borderRadius="full"
                px={2}
                my={2}
              >
                Get Started
              </Button>
            </InputRightElement>
          </InputGroup>
        </Box>
      </Box>



      {/* footer */}
      <Box
        bgImage="/vecteezy_abstract-red-speed-neon-light-effect-on-black-background_8440572 1.jpg"
        bgSize="cover"
        bottom={"0"}
        bgPosition="center"
        color="white"
        py={8}
        mt={"5%"}
      >
        <Flex direction="column" align="center" gap={"10px"} bottom={"0px"}>
          <Flex alignItems={"center"} gap={"5px"}>
            <Image
              src="MarcomLogo.jpg"
              boxSize="60px"
              borderRadius="full"
              fit="cover"
            />
            <Image
              src="marcom2.png"
              alt="Marcom Logo"
              w={"150px"}
              h={"40px"}
              borderRadius="full"
              fit="cover"
              bg={"red"}
            />
          </Flex>

          <Flex gap={8} mb={4}>
            <Link
              href="#home"
              fontSize="lg"
              _hover={{ textDecoration: "underline" }}
            >
              Home
            </Link>
            <Link
              href="#about"
              fontSize="lg"
              _hover={{ textDecoration: "underline" }}
            >
              About Us
            </Link>
            <Link
              href="#services"
              fontSize="lg"
              _hover={{ textDecoration: "underline" }}
            >
              Services
            </Link>
            <Link
              href="#pricing"
              fontSize="lg"
              _hover={{ textDecoration: "underline" }}
            >
              Pricing
            </Link>
          </Flex>
          <Flex gap={6} mb={4}>
            <Link href="https://facebook.com" isExternal>
              <FaFacebook size="24px" />
            </Link>
            <Link href="https://instagram.com" isExternal>
              <FaInstagram size="24px" />
            </Link>
            <Link href="https://youtube.com" isExternal>
              <FaYoutube size="24px" />
            </Link>
            <Link href="https://twitter.com" isExternal>
              <FaTwitter size="24px" />
            </Link>
            <Link href="https://linkedin.com" isExternal>
              <FaLinkedin size="24px" />
            </Link>
          </Flex>
          <Text fontSize="sm" mt={4} color={"#FFFFFF"}>
            Copyright © 2024 Marcom. All rights reserved.
          </Text>
        </Flex>
      </Box>
    </Box>
  );
}

export default Home
