import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Button,
  Badge,
  Avatar,
  Input,
  HStack,
  VStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tag,
  Text,
  IconButton,
  Flex,
  useDisclosure,
  ChakraProvider,
  extendTheme,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import "@fontsource/noto-sans";
import {
  AiOutlineDownload,
  AiOutlineFilter,
  AiOutlineColumnHeight,
} from "react-icons/ai";
import Chart from "react-apexcharts"; // Import ApexCharts component
import DatePicker from "react-datepicker";
import { FiFilter } from "react-icons/fi";
import companyicon from "../Dashboard/icons/companyicon.png";

const LeadsReport = () => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [filter, setFilter] = useState("Active Campaign");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const leadManagementData = [
    {
      id: 1,
      leadName: "John Doe",
      companyName: "ABC Corp",
      phone: "123-456-7890",
      email: "john.doe@abccorp.com",
      leadStatus: "Success",
      createdDate: "25 Sep 2023",
      leadOwner: "Alice Johnson",
      location: "New York, USA",
      avatar: companyicon,
    },
    {
      id: 2,
      leadName: "Jane Smith",
      companyName: "XYZ Inc",
      phone: "098-765-4321",
      email: "jane.smith@xyzinc.com",
      leadStatus: "Pending",
      createdDate: "03 Oct 2023",
      location: "New York, USA",
      avatar: companyicon,
      leadOwner: "Bob Lee",
    },
    // Add more lead records as needed
  ];

  const filteredData = leadManagementData.filter((lead) => {
    return true; // Add filtering logic here
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prev) =>
      Math.max(1, Math.min(totalPages, prev + direction))
    );
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  // Bar chart configuration

   const theme = extendTheme({
     fonts: {
       heading: `'Noto Sans', sans-serif`,
       body: `'Noto Sans', sans-serif`,
     },
   });

  const barChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: { borderRadius: 4, horizontal: false },
    },
    dataLabels: { enabled: false },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: { title: { text: "" } },
  };

  const barChartData = [
    {
      name: "Leads",
      data: [100, 120, 300, 200, 450, 320, 230, 340, 180, 250, 300, 500],
    },
  ];

  // Doughnut chart configuration
  const doughnutChartOptions = {
    chart: { type: "donut" },
    labels: ["Campaign", "Google", "Referrals", "Paid Social"],
    legend: { position: "bottom" },
  };

  const doughnutChartData = [44, 58, 41, 17];

  const LeadManagementTable = ({ data }) => (
    <Table variant="simple" border="1px solid" borderColor="gray.200">
      <Thead>
        <Tr bg={"#E8E8E8"}>
          <Th>
            <Checkbox />
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Lead Name
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Company Name
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Phone
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Email
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Lead Status
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Created Date
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Lead Owner
          </Th>
          <Th fontWeight={"500"} fontSize={"14px"}>
            Action
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((lead) => (
          <Tr key={lead.id}>
            <Td>
              <Checkbox />
            </Td>
            <Td fontSize="16px" fontWeight="500" color="#26262A">
              {lead.leadName}
            </Td>
            <Td>
              <Flex align="center">
                <Avatar src={lead.avatar} size="sm" mr="2" />
                <Box mt="2">
                  <Text fontWeight="medium" fontSize="sm" mb="0.5">
                    {lead.companyName}
                  </Text>
                  <Text fontSize="xs" color="gray.500" lineHeight="2">
                    {lead.location}
                  </Text>
                </Box>
              </Flex>
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {lead.phone}
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {lead.email}
            </Td>
            <Td>
              <Badge
                bg={"orange"}
                borderRadius="md"
                p="2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                width="auto"
                fontWeight="600"
                color="white"
                fontFamily="Poppins"
              >
                {lead.leadStatus}
              </Badge>
            </Td>
            <Td fontSize="14px" fontWeight="400" color="#6F6F6F">
              {lead.createdDate}
            </Td>
            <Td fontSize="14px" fontWeight="500" color="#26262A">
              {lead.leadOwner}
            </Td>
            <Td>
              <IconButton
                icon={<FaEllipsisV />}
                variant="ghost"
                aria-label="Options"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <ChakraProvider theme={theme}>
      <Box
        p={5}
        borderWidth="1px"
        borderRadius="md"
        bg="white"
        overflowX="auto"
      >
        {/* Header Section */}
        <HStack
          justifyContent="space-between"
          mb="6"
          alignItems="center"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Input placeholder="Search Leads" w={{ base: "100%", sm: "300px" }} />
          <Button
            colorScheme="red"
            leftIcon={<AiOutlineDownload />}
            width={{ base: "100%", sm: "auto" }}
          >
            Download Report
          </Button>
        </HStack>

        {/* Charts Section */}
        <HStack
          spacing="6"
          mb="6"
          flexDirection={{ base: "column", lg: "row" }}
        >
          <Box
            width={{ base: "100%", lg: "50%" }}
            p="4"
            borderWidth="1px"
            borderRadius="md"
            bg="white"
          >
            {/* <Flex align="center" justifyContent={"space-between"}> */}
            <Flex
              justify="space-between"
              flexDirection={{ base: "column", md: "row" }}
              gap={"4px"}
              align="center"
              mb="2"
            >
              <Text
                as="h2"
                fontFamily="Poppins, sans-serif"
                fontWeight="500"
                fontSize="18px"
                lineHeight="27px"
                m="0"
              >
                Leads By Year
              </Text>
              <Box border="1px solid #E8E8E8" padding="2px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                />
              </Box>
            </Flex>
            <Chart
              options={barChartOptions}
              series={barChartData}
              type="bar"
              height={350}
            />
          </Box>

          <Box
            width={{ base: "100%", lg: "50%" }}
            p="5"
            borderWidth="1px"
            borderRadius="md"
            bg="white"
          >
            {/* <Flex align="center" justifyContent={"space-between"}> */}
            <Flex
              justify="space-between"
              flexDirection={{ base: "column", md: "row" }}
              gap={"4px"}
              align="center"
              mb="2"
            >
              <Text
                as="h2"
                fontFamily="Poppins, sans-serif"
                fontWeight="500"
                fontSize="18px"
                lineHeight="27px"
                m="0"
              >
                Leads By Source
              </Text>
              <Box border="1px solid #E8E8E8" padding="1px">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Start Date"
                />
              </Box>
            </Flex>
            <Chart
              options={doughnutChartOptions}
              series={doughnutChartData}
              type="donut"
              height={350}
            />
          </Box>
        </HStack>

        {/* Filters and Table */}
        <Box
          display="flex"
          justifyContent="space-between"
          mb={4}
          flexDirection={{ base: "column", sm: "row" }}
        >
          <Flex
            gap="7px"
            maxW={{ base: "100%", md: "30%" }}
            direction={{ base: "column", md: "row" }}
          >
            <Select
              placeholder="Sort"
              w={{ base: "100%", md: "30%" }}
              mt={{ base: 2, md: 0 }}
              fontWeight={"bold"}
              fontSize={"14px"}
              lineHeight={"20px"}
              p="7px"
            >
              <option value="date">Date</option>
              <option value="amount">Amount</option>
            </Select>
            <Flex mt="5px">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="Start Date"
                customInput={<Input />}
              />
            </Flex>
          </Flex>

          <Flex mt={{ base: 2, md: 0 }} gap={"5px"} mr={"3px"}>
            <Button
              variant="outline"
              mr={2}
              bg={"#F3EDFF"}
              color={"#4A00E5"}
              fontSize={"13px"}
              fontWeight={"600"}
            >
              Manage Columns
            </Button>
            <Button variant="outline" fontWeight={"500"} fontSize={"14px"}>
              Filter
            </Button>
          </Flex>
        </Box>

        {/* Table */}
        <LeadManagementTable data={paginatedData} />

        {/* Pagination */}
        <HStack
          justifyContent="space-between"
          mt={4}
          alignItems="center"
          flexDirection={{ base: "column", sm: "row" }}
        >
          <HStack spacing={2}>
            <Box color="#6F6F6F">Show</Box>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              width="80px"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
            <Box color="#6F6F6F">entries</Box>
          </HStack>

          <HStack spacing={4}>
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(-1)}
              disabled={currentPage === 1}
              leftIcon={<ChevronLeftIcon />}
            >
              Prev
            </Button>
            {Array.from({ length: totalPages }, (_, index) => (
              <Button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                colorScheme={currentPage === index + 1 ? "blue" : "gray"}
              >
                {index + 1}
              </Button>
            ))}
            <Button
              colorScheme="white"
              color="#6F6F6F"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === totalPages}
              rightIcon={<ChevronRightIcon />}
            >
              Next
            </Button>
          </HStack>
        </HStack>
      </Box>
    </ChakraProvider>
  );
};

export default LeadsReport;
